import { Component } from '@angular/core';
import { ModalService } from '../../services/modal.service';
import { SelectModalDataInterface } from '../../interfaces/select-modal-data.interface';

@Component({
  selector: 'app-success-modal',
  templateUrl: './select-modal.component.html',
  styleUrls: ['../../../../../styles/blocks/modals.scss'],
})
export class SelectModalComponent {
  public static readonly modalIdentifier: string = 'confirmModal';
  public data: SelectModalDataInterface;

  constructor(private modalService: ModalService) {
    this.data = this.modalService.getModalData(SelectModalComponent.modalIdentifier);
  }

  public close(): void {
    this.modalService.close(SelectModalComponent.modalIdentifier);
  }
}
