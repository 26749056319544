import { UserInterface } from '../../../user/interfaces/user.interface';

export interface UserState {
  user?: UserInterface;
  wallpaperUrl: string;
  loading: boolean;
}

export const initialUserState: UserState = {
  wallpaperUrl: '',
  loading: false,
};
