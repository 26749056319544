// export const FILE_TYPES_IMAGE = 'application/pdf,image/jpeg,image/jpg,image/png';
export const FILE_TYPES_IMAGE_FOR_VIEW = 'JPG, PNG';
export const FILE_TYPES_IMAGE = [
  'image/jpeg',
  'image/jpg',
  'image/png',
].join(',');

// E.g. Excel changes 'text/csv' to 'application/vnd.ms-excel'
export const FILE_TYPES_CSV = [
  'text/comma-separated-values',
  'text/csv',
  'application/csv',
  'application/excel',
  'application/vnd.ms-excel',
  'application/vnd.msexcel',
  'text/anytext',
];

export const FILE_TYPES_DOCUMENTS_FOR_VIEW = 'PDF';
export const FILE_TYPES_DOCUMENTS = [
  'application/pdf',
  ...FILE_TYPES_CSV,
  'text/plain',
  'application/msword',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'application/vnd.ms-excel',
].join(',');
