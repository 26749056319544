<div class="filter-search">

  <div class="filter-search__label">{{ 'common.filter.search' | translate }}</div>

  <div class="filter-search__header">
    <div class="filter-search-input-wrapper">
      <div class="filter-search-icon"></div>
      <input
        type="text"
        class="input-main filter-search-input"
        [(ngModel)]="searchInputValue"
        (ngModelChange)="searchInput$.next(searchInputValue)"
      >
    </div>
  </div>

  <div
    class="filter-search__body"
    [class.hide]="loading"
  >
    <div
      *ngFor="let item of filteredItems"
      class="filter-search__item"
      (click)="setValue(item.value)"
    >
      <img
        class="filter-search__image"
        src="../../../../../../assets/images/avatar.png"
        alt="avatar"
      >

      <div class="filter-search__name">{{item.label}}</div>
    </div>
  </div>

  <ngx-spinner
    name="filter-spinner"
    [fullScreen]="false"
    [size]="'medium'"
    bdColor="rgba(0,0,0,0.0)"
    size="small"
    color="#258C60"
    type="ball-clip-rotate"
  >
  </ngx-spinner>
</div>

