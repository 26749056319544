<div class="modal" [formGroup]="form">
  <div class="modal__input">
    <div class="data-group">
      <app-field-input-exchange #from
                                (keyup)="fromInputChanges()"
                                (selectChange)="fromSelectChanges()"
                                [formControlName]="'from'"
                                [placeholder]="'0'"
                                [balance]="tradeContext?.fromBalance?.balance || fromBalance"
                                [currencies]="tokens"
                                [balanceCurrency]="tradeContext?.fromToken?.symbol"
                                [selectDisabled]="fieldFromDisabled">
      </app-field-input-exchange>

      <div class="arrow-block">
        <div class="arrow"><img class="arrow-image" src="assets/icons/sh-arrow.svg" alt="arrow"></div>
      </div>
    </div>

    <div class="data-group">
      <app-field-input-exchange [formControlName]="'to'"
                                [placeholder]="'0'"
                                (keyup)="toInputChanges()"
                                (selectChange)="toSelectChanges()"
                                [balance]="tradeContext?.toBalance || toBalance"
                                [currencies]="tokens"
                                [balanceCurrency]="tradeContext?.toToken?.symbol"
                                [selectDisabled]="fieldToDisabled">
      </app-field-input-exchange>
    </div>
  </div>

  <ul class="modal__total-info total-info" *ngIf="tradeContext">
    <li class="total-info__row">
      <div class="total-info__label">
        {{ 'popups.exchange.route' | translate }}:
      </div>

      <div class="total-info__value">
        <span class="rout-text" *ngFor="let routeText of routeTexts" [innerHTML]="routeText"></span>
      </div>
    </li>

    <li class="total-info__row">
      <div class="total-info__label">
        {{ 'popups.exchange.minimal_received_amount' | translate }}:
      </div>

      <div class="total-info__value">
        <strong>{{ tradeContext?.expectedConvertQuote }}</strong> {{ tradeContext?.toToken?.symbol }}
      </div>
    </li>
  </ul>

  <div *ngIf="fromBalance && from.inputValue > fromBalance && !blockConfirmBnt"
       class="modal__warning-window">
    <div class="warning-window__info">
      <div class="warning-window__icon"><img src="./../../../assets/icons/warning-triangle.svg" alt="warning"></div>
      <div class="warning-window__description">{{'pages.modals.do_not_have_balance' | translate : { currency: currency } }}
        <a>{{'pages.modals.buy_more' | translate}}</a>
      </div>
    </div>
  </div>

  <div class="modal__buttons">
    <app-button primary="primary"
                class="modal__button"
                [disabled]="!from.inputValue || (fromBalance && from.inputValue > fromBalance) || blockConfirmBnt || isNoLiquidityTrade"
                (btnClick)="submit()">
      {{ 'common.continue' | translate }}
    </app-button>

    <app-button secondary="secondary"
                class="modal__button"
                tabindex="0"
                (btnClick)="cancel()">
      {{ 'common.cancel' | translate }}
    </app-button>
  </div>
</div>
