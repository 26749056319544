import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReadWtcService } from './services/read-wtc.service';
import { WtcRoutingModule } from './wtc-routing.module';
import { WriteWtcService } from './services/write-wtc.service';
import { SharedModule } from '../shared/shared.module';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    SharedModule,
    WtcRoutingModule,
  ],
  providers: [
    ReadWtcService,
    WriteWtcService,
  ],
})
export class WtcModule { }
