<div class="modal">
  <div class="modal__title">{{ 'pages.modals.shard_to_nft' | translate }}</div>

  <div class="modal__subtitle">{{ 'pages.modals.upon_completion_nft_will_be_returned_to_your_account' | translate }}</div>

  <div class="modal__location-info land-description">
    <div class="land-description__block">
      <img src="assets/icons/marker.svg" class="land-description__marker" alt="Marker" />
      <span class="land-description__name">{{ land.name }}</span>
      <span class="land-description__location">{{ land.location }}</span>
    </div>
    <img src="assets/icons/dotted-arrow.svg" alt="Arrow" class="land-description__arrow">
    <img [src]="'assets/icons/shards/' + (land.productDetails.cropShareType | currencyFromCulture | uppercase) + '.svg'" alt="Shard" class="land-description__icon">
  </div>

  <ul class="modal__total-info total-info">
    <li class="total-info__row">
      <span class="total-info__label">{{ 'pages.modals.nft_price' | translate }}:</span>
      <span class="total-info__value">
        {{ land.token.shards | number : '1.0-16'  }} {{land.productDetails.cropShareType | currencyFromCulture | uppercase}}
      </span>
    </li>

    <li class="total-info__row">
      <span class="total-info__label">{{ 'pages.modals.total_amount' | translate }}:</span>
      <span class="total-info__value">
        {{ land.token.shards | number : '1.0-16' }} {{land.productDetails.cropShareType | currencyFromCulture | uppercase}}
      </span>
    </li>
  </ul>

  <p class="modal__bottom-note">{{ 'pages.modals.by_clicking_deploy_custody_contract' | translate }}</p>

  <div *ngIf="this.balance! < +this.land.token.shards" class="modal__warning-window">
    <div class="warning-window__info">
      <div class="warning-window__icon"><img src="./../../../assets/icons/warning-triangle.svg"></div>
      <div class="warning-window__description">{{'pages.modals.do_not_have_balance' | translate :{ currency: land.productDetails.cropShareType | currencyFromCulture | uppercase} }}
        <a>{{'pages.modals.buy_more' | translate}}</a>
      </div>
    </div>
  </div>

  <div class="modal__buttons">
    <app-button primary class="modal__button" [disabled]="this.balance! < +this.land.token.shards" (btnClick)="submit()">{{ 'pages.modals.buy_back_nft' | translate }}</app-button>
  </div>
</div>

