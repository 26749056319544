import { Component } from '@angular/core';
import { ModalService } from '../../services/modal.service';
import { ConfirmModalDataInterface } from '../../interfaces/confirm-modal-data.interface';

@Component({
  selector: 'app-confirm-modal',
  templateUrl: './confirm-modal.component.html',
  styleUrls: ['../../../../../styles/blocks/modals.scss'],
})
export class ConfirmModalComponent {
  public static readonly modalIdentifier: string = 'confirmModal';
  public data: ConfirmModalDataInterface;

  constructor(private modalService: ModalService) {
    this.data = this.modalService.getModalData(ConfirmModalComponent.modalIdentifier);
  }

  public close(): void {
    this.modalService.close(ConfirmModalComponent.modalIdentifier);
  }
}
