export enum DealTypes {
  Auction = 'auction',
  FixPrice = 'fix_price',
}

export const DealTypesSelectItems = [
  {
    value: DealTypes.FixPrice, label: DealTypes.FixPrice,
  },
  {
    value: DealTypes.Auction, label: DealTypes.Auction,
  },
];
