<div class="icon-container">
  <div class="icon"></div>
</div>

<input
  class="input"
  type="text"
  [placeholder]="placeholder"
  [ngModel]="value"
  (ngModelChange)="setValue($event)"
/>

<div class="clear-btn" *ngIf="value">
  <img src="assets/icons/cross-dark.svg" class="clear-btn__cross" alt="Clear" (click)="clear()">
</div>
