import { Injectable } from '@angular/core';
import {
  CanActivate, Router,
} from '@angular/router';
import { AuthService } from '../../auth/services/auth.service';

@Injectable()
export class LockGuard implements CanActivate {
  constructor(
    private authService: AuthService,
    private router: Router,
  ) {
  }

  canActivate(): boolean {
    if (!this.authService.isLocked()) {
      return true;
    }
    this.router.navigate(['auth/sign-in']);
    return false;
  }
}
