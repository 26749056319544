import { Injectable } from '@angular/core';
import { Observable, Subscriber } from 'rxjs';
import { AbiItem } from 'ethereum-abi-types-generator';
import { switchMap } from 'rxjs/operators';
import { Web3Service } from '../../core/common/services/web3.service';
import { ContractService } from '../../core/common/services/contract.service';

@Injectable()
export class ReadNftService {
  constructor(private web3: Web3Service, private contractService: ContractService) {}

  public isApprovedForAll(from: string, operator: string): Observable<boolean> {
    return this.contractService.contracts().pipe(
      switchMap((contracts) => new Observable((subscriber: Subscriber<boolean>) => {
        (async () => {
          try {
            const abi: AbiItem[] = [
              {
                name: 'isApprovedForAll',
                inputs: [
                  {
                    internalType: 'address',
                    name: 'account',
                    type: 'address',
                  },
                  {
                    internalType: 'address',
                    name: 'operator',
                    type: 'address',
                  },
                ],
                outputs: [
                  {
                    internalType: 'bool',
                    name: '',
                    type: 'bool',
                  },
                ],
                stateMutability: 'payable',
                type: 'function',
              },
            ];
            const contract = new this.web3.eth.Contract(abi, contracts.nftContractAddress);
            contract.methods.isApprovedForAll(from, operator).call().then((approved: any) => {
              subscriber.next(approved);
            }).catch((error: any) => {
              subscriber.error(error);
            });
          } catch (error) {
            subscriber.error(error);
          }
        })();
      })),
    );
  }

  public uri(tokenId: number): Observable<string> {
    return this.contractService.contracts().pipe(
      switchMap((contracts) => new Observable((subscriber: Subscriber<string>) => {
        (async () => {
          try {
            const abi: AbiItem[] = [
              {
                name: 'uri',
                inputs: [
                  {
                    internalType: 'uint256',
                    name: 'uri',
                    type: 'uint256',
                  },
                ],
                outputs: [
                  {
                    internalType: 'string',
                    name: '',
                    type: 'string',
                  },
                ],
                stateMutability: 'payable',
                type: 'function',
              },
            ];
            const contract = new this.web3.eth.Contract(abi, contracts.nftContractAddress);
            contract.methods.uri(tokenId).call().then((uri: any) => {
              subscriber.next(uri);
            }).catch((error: any) => {
              subscriber.error(error);
            });
          } catch (error) {
            subscriber.error(error);
          }
        })();
      })),
    );
  }
}
