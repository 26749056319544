import { Injectable } from '@angular/core';
import {
  TokenFactoryPublic,
  TradeContext,
  TradeDirection, Transaction,
  UniswapPair,
  UniswapPairSettings,
  UniswapVersion,
} from 'simple-uniswap-sdk';
import { TransactionReceipt } from 'web3-core';
import { Observable, Subscriber } from 'rxjs';
import { AbiItem } from 'ethereum-abi-types-generator';
import { Web3Service } from '../../common/services/web3.service';

@Injectable({
  providedIn: 'root',
})
export class UniswapService {
  public constructor(private web3: Web3Service) {}

  public tradeInfo(
    chainId: number,
    amount: number,
    fromTokenContractAddress: string,
    toTokenContractAddress: string,
    ethereumAddress: string,
  ): Observable<TradeContext> {
    const uniswapPair = new UniswapPair({
      fromTokenContractAddress,
      toTokenContractAddress,
      ethereumAddress,
      chainId,
      settings: new UniswapPairSettings({
        slippage: 0.005,
        deadlineMinutes: 20,
        disableMultihops: false,
        uniswapVersions: [UniswapVersion.v2, UniswapVersion.v3],
      }),
    });

    return new Observable((subscriber) => {
      (async () => {
        try {
          uniswapPair.createFactory()
            .then((factory) => factory.trade(amount.toString(), TradeDirection.input).catch((error) => {
              subscriber.error(error);
              return undefined;
            })).catch((error) => {
              subscriber.error(error);
              return undefined;
            })
            .then((trade) => {
              subscriber.next(trade);
            })
            .catch((error) => {
              subscriber.error(error);
            });
        } catch (error) {
          subscriber.error(error);
        }
      })();
    });
  }

  public getAllowance(owner: string, fromTokenContractAddress: string, spender: string): Observable<string> {
    return new Observable((subscriber: Subscriber<string>) => {
      (async () => {
        try {
          const abi: AbiItem[] = [
            {
              name: 'allowance',
              inputs: [
                {
                  internalType: 'address',
                  name: 'owner',
                  type: 'address',
                },
                {
                  internalType: 'address',
                  name: 'spender',
                  type: 'address',
                },
              ],
              outputs: [
                {
                  name: '',
                  type: 'uint256',
                },
              ],
              stateMutability: 'view',
              payable: false,
              type: 'function',
            },
          ];
          const contract = new this.web3.eth.Contract(abi, fromTokenContractAddress);
          contract.methods.allowance(owner, spender).call().then((data: string) => {
            subscriber.next(data);
          }).catch((error: any) => {
            subscriber.error(error);
          });
        } catch (error) {
          subscriber.error(error);
        }
      })();
    });
  }

  public approveAllowance(
    from: string,
    fromTokenContractAddress: string,
    spender: string,
    value: string,
  ): Observable<any> {
    return new Observable((subscriber: Subscriber<string>) => {
      (async () => {
        try {
          const abi: AbiItem[] = [
            {
              name: 'approve',
              inputs: [
                {
                  internalType: 'address',
                  name: '_spender',
                  type: 'address',
                },
                {
                  internalType: 'uint256',
                  name: '_value',
                  type: 'uint256',
                },
              ],
              outputs: [],
              stateMutability: 'payable',
              type: 'function',
            },
          ];
          const contract = new this.web3.eth.Contract(abi, fromTokenContractAddress);
          contract.methods.approve(spender, this.web3.utils.toBN(value)).send({
            from,
          }).then((block: { blockHash: string }) => {
            subscriber.next(block.blockHash);
          }).catch((error: any) => {
            subscriber.error(error);
          });
        } catch (error) {
          subscriber.error(error);
        }
      })();
    });
  }

  public executeTrade(transaction: Transaction): Observable<TransactionReceipt> {
    return new Observable((subscriber) => {
      (async () => {
        try {
          this.web3.eth.sendTransaction({
            from: transaction.from,
            to: transaction.to,
            data: transaction.data,
            value: transaction.value,
          }).then((receipt) => {
            subscriber.next(receipt);
          }).catch((error: any) => {
            subscriber.error(error);
          });
        } catch (error) {
          subscriber.error(error);
        }
      })();
    });
  }

  public getBalance(chainId: number, ethAddress: string, contractAddress: string): Observable<string> {
    return new Observable((subscriber) => {
      (async () => {
        try {
          const tokenFactoryPublic = new TokenFactoryPublic(
            contractAddress,
            { chainId },
          );

          const balanceHex = await tokenFactoryPublic.balanceOf(ethAddress);
          subscriber.next(this.web3.utils.fromWei(balanceHex));
        } catch (error) {
          subscriber.error(error);
        }
      })();
    });
  }
}
