import { Action, createReducer, on } from '@ngrx/store';
import { initialSettingsState, SettingsState } from '../states/settings.state';
import * as SettingsActions from '../actions/settings.actions';

const SettingsReducer = createReducer(
  initialSettingsState,
  on(SettingsActions.LoadSettings, (state): SettingsState => ({
    ...state,
    loading: true,
  })),
  on(SettingsActions.LoadSettingsSuccess, (state, { settings }): SettingsState => ({
    ...state,
    settings,
    loading: false,
  })),
  on(SettingsActions.LoadSettingsFailure, (state): SettingsState => ({
    ...state,
    loading: false,
  })),
  on(SettingsActions.SaveSettings, (state): SettingsState => ({
    ...state,
    saving: true,
  })),
  on(SettingsActions.SaveSettingsSuccess, (state, { settings }): SettingsState => ({
    ...state,
    settings,
    saving: false,
  })),
  on(SettingsActions.SaveSettingsFailure, (state): SettingsState => ({
    ...state,
    saving: false,
  })),
);

export function reducer(state: SettingsState | undefined, action: Action) {
  return SettingsReducer(state, action);
}
