<div class="modal">
  <div class="modal__title">
    {{ 'pages.modals.share_link' | translate }}
  </div>

  <div class="modal__input">
    <share-buttons [url]="url"
                   [showText]="true"
                   [include]="[
                    'facebook',
                    'twitter',
                    'linkedin',
                    'vk',
                    'telegram',
                    'messenger',
                    'whatsapp',
                    'email',
                    'copy',
                 ]">
    </share-buttons>
  </div>
</div>
