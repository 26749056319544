import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { CropSharePayable } from '../../interfaces/crop-share-payable';
import { IHttpResponse } from '../../../core/common/intefaces/http-response.interface';
import { CROP_SHARE_API } from '../../api/crop-share.api';
import { CropShareReceivable } from '../../interfaces/crop-share-receivable';
import { CropShareTypeEnum } from '../../enums/crop-share-type.enum';
import { DiagramDataResponseInterface } from '../../interfaces/diagram-data-response.interface';
import { WithdrawHistoryItemInterface } from '../../interfaces/withdraw-history-item.interface';
import { PaymentHistoryItemInterface } from '../../interfaces/payment-history-item.interface';
import { WithdrawInterface } from '../../interfaces/widthdraw.interface';

@Injectable()
export class CropShareApiService {
  constructor(
    private http: HttpClient,
  ) {
  }

  public getPayables(): Observable<IHttpResponse<CropSharePayable[]>> {
    return this.http.get<IHttpResponse<CropSharePayable[]>>(
      CROP_SHARE_API.payables,
    );
  }

  public getReceivables(): Observable<IHttpResponse<CropShareReceivable>> {
    return this.http.get<IHttpResponse<CropShareReceivable>>(
      CROP_SHARE_API.receivables,
    );
  }

  public getDiagramData(
    year: string,
    type: CropShareTypeEnum,
  ): Observable<IHttpResponse<DiagramDataResponseInterface>> {
    return this.http.get<IHttpResponse<DiagramDataResponseInterface>>(
      CROP_SHARE_API.diagramData,
      {
        params: {
          year,
          type,
        },
      },
    );
  }

  public getWithdrawHistory(startDate?: Date): Observable<IHttpResponse<WithdrawHistoryItemInterface[]>> {
    return this.http.get<IHttpResponse<WithdrawHistoryItemInterface[]>>(
      CROP_SHARE_API.withdrawHistory,
      {
        params: startDate != null ? {
          'createdAt:gte': startDate.toDateString(),
        } : {},
      },
    );
  }

  public getPaymentHistory(startDate?: Date): Observable<IHttpResponse<PaymentHistoryItemInterface[]>> {
    return this.http.get<IHttpResponse<PaymentHistoryItemInterface[]>>(
      CROP_SHARE_API.paymentHistory,
      {
        params: startDate != null ? {
          'createdAt:gte': startDate.toDateString(),
        } : {},
      },
    );
  }

  public withdraw(): Observable<IHttpResponse<WithdrawInterface>> {
    return this.http.post<IHttpResponse<WithdrawInterface>>(
      CROP_SHARE_API.withdraw,
      {},
    );
  }
}
