import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'yearsUntil',
})
export class YearsUntilPipe implements PipeTransform {
  transform(date: string): string {
    const dateTime = new Date(date);
    const currentDate = new Date();
    const yearsDiff = dateTime.getFullYear() - currentDate.getFullYear();
    return yearsDiff.toString();
  }
}
