import { APP_INITIALIZER, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Store, StoreModule } from '@ngrx/store';
import { Actions, EffectsModule } from '@ngrx/effects';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { TranslateService } from '@ngx-translate/core';
import { LayoutModule } from '@angular/cdk/layout';
import { PlatformModule } from '@angular/cdk/platform';
import { MainLayoutComponent } from './common/layout/main-layout/main-layout.component';
import { CoreReducers } from './common/store/reducers';
import { EndpointInterceptor } from './common/interceptors/endpoint.interceptor';
import { environment } from '../../environments/environment';
import { AuthenticationInterceptor } from './common/interceptors/authentication.interceptor';
import { SpinnerService } from './common/services/spinner.service';
import { ApiErrorInterceptor } from './common/interceptors/api-error.interceptor';
import { ApiErrorService } from './common/services/api-error.service';
import { CallerService } from './common/services/caller.service';
import { LocalStorageService } from './common/services/local-storage.service';
import { NotificationsCustomService } from './common/services/notifications-custom.service';
import { NotificationsServiceWithTranslate } from './common/services/notificationsServiceWithTranslate';
import { TranslateCustomService } from './common/services/translate-custom.service';
import { HeaderModule } from '../header/header.module';
import { SettingsApiService } from './settings/services/settings.api.service';
import { LocaleInterceptor } from './common/interceptors/locale.interceptor';
import { RefreshInterceptor } from './common/interceptors/refresh.interceptor';
import { Web3Service } from './common/services/web3.service';
import { ContractApiService } from './common/services/contract-api.service';
import { ContractService } from './common/services/contract.service';
import { initializeAppFactory } from './common/helpers/initialize-app.helper';
import { CoreEffects } from './common/store/effects';
import { LockGuard } from './common/guards/lock-guard.service';
import { HeaderWarningComponent } from '../header/container-components/header-warning/header-warning.component';
import { SharedModule } from '../shared/shared.module';

@NgModule({
  declarations: [
    MainLayoutComponent,
    HeaderWarningComponent,
  ],
  imports: [
    CommonModule,
    PlatformModule,
    LayoutModule,
    HttpClientModule,
    StoreModule.forRoot(CoreReducers, {}),
    EffectsModule.forRoot(CoreEffects),
    StoreDevtoolsModule.instrument({ maxAge: 25, logOnly: environment.production }),
    HeaderModule,
    SharedModule,
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: initializeAppFactory,
      deps: [Store, Actions],
      multi: true,
    },
    { provide: HTTP_INTERCEPTORS, useClass: EndpointInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: RefreshInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: AuthenticationInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ApiErrorInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: LocaleInterceptor, multi: true },
    SpinnerService,
    CallerService,
    LocalStorageService,
    NotificationsCustomService,
    NotificationsServiceWithTranslate,
    TranslateService,
    ApiErrorService,
    TranslateCustomService,
    SettingsApiService,
    Web3Service,
    ContractApiService,
    ContractService,
    LockGuard,
  ],
  exports: [
    MainLayoutComponent,
  ],
})
export class CoreModule { }
