import {
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { BreakpointObserver } from '@angular/cdk/layout';
import {
  FilterGroupFieldsInterface,
} from '../../interfaces/filter-group.interface';
import { FilterSortingInterface } from '../../interfaces/filter-sorting.interface';

@Component({
  selector: 'app-filter-side-menu',
  templateUrl: './filter-side-menu.component.html',
  styleUrls: ['./filter-side-menu.component.scss'],
})
export class FilterSideMenuComponent {
  @Input() public groups?: FilterGroupFieldsInterface[];
  @Input() public form?: FormGroup;
  @Input() public resultsCount?: number;
  @Input() public sorting?: FilterSortingInterface[];
  @Input() public isFilterButtonHidden?: boolean = false;

  @Output() openedSideBar = new EventEmitter<boolean>();

  public opened = true;

  constructor(
    public breakpointObserver: BreakpointObserver,
  ) {
    this.opened = !this.breakpointObserver.isMatched('(max-width: 768px)');
  }

  public toggle() {
    this.opened = !this.opened;
    this.openedSideBar.emit(this.opened);
  }
}
