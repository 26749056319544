<div class="input-amount-filed"
     (click)="focus()"
     [class.white]="white">
  <div class="input-amount-filed__container">
    <div class="input-amount-filed__top-container">
      <label [attr.for]="label"
             class="input-amount-filed__label">
        {{label}}
      </label>

      <div appEllipsis
           *ngIf="available !== null && availableCurrency"
           class="input-amount-filed__available">
        {{available}} {{availableCurrency}} {{'common.fields.available' | translate}}
      </div>
    </div>

    <div class="input-amount-filed__input-wrapper">
      <input #input
             class="input-amount-filed__input"
             [attr.name]="label"
             type="number"
             [min]="minValue"
             [(ngModel)]="value"
             (ngModelChange)="setValue()"
             (blur)="setTouched()"
             (input)="resize()"
             [disabled]="disabled"
             [placeholder]="placeholder"
             [ngStyle]="{'width.px': width}">
      <span #hiddenText
            class="input-amount-filed__hidden-text">
        {{value}}
      </span>

      <span class="input-amount-filed__unit">{{unit}}</span>
    </div>
  </div>

  <div *ngIf="equivalent && equivalentCurrency"
       class="input-amount-filed__equivalent">
    {{'common.fields.equivalent' | translate}}:{{equivalent}}{{equivalentCurrency}}
  </div>

  <div *ngIf="note" class="input-amount-filed__note">{{note}}</div>
</div>
