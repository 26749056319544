import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { NgxSpinnerModule } from 'ngx-spinner';
import { FilterSideMenuComponent } from './components/filter-side-menu/filter-side-menu.component';
import { FilterGroupComponent } from './components/filter-group/filter-group.component';
import { FilterFormComponent } from './components/filter-form/filter-form.component';
import { FilterButtonsComponent } from './components/filter-buttons/filter-buttons.component';
import { FilterComponent } from './container-components/filter/filter.component';
import { FilterSearchComponent } from './components/filter-search/filter-search.component';
import { FilterSelectedListComponent } from './components/filter-selected-list/filter-selected-list.component';
import { FilterCheckboxListComponent } from './components/filter-checkbox-list/filter-checkbox-list.component';
import { SharedModule } from '../../shared.module';
import { FilterCrumbsComponent } from './components/filter-crumbs/filter-crumbs.component';
import { SortBarComponent } from './components/sort-bar/sort-bar.component';

@NgModule({
  declarations: [
    FilterSideMenuComponent,
    FilterGroupComponent,
    FilterFormComponent,
    FilterButtonsComponent,
    FilterComponent,
    FilterSearchComponent,
    FilterSelectedListComponent,
    FilterCheckboxListComponent,
    FilterCrumbsComponent,
    SortBarComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    ReactiveFormsModule,
    NgxSpinnerModule,
  ],
  exports: [
    FilterSideMenuComponent,
    FilterGroupComponent,
    FilterFormComponent,
    FilterButtonsComponent,
    FilterComponent,
    FilterSearchComponent,
    FilterSelectedListComponent,
    FilterCheckboxListComponent,
    FilterCrumbsComponent,
    SortBarComponent,
  ],
})
export class FilterModule { }
