import { Component } from '@angular/core';
import {
  combineLatest, of, Subject, throwError,
} from 'rxjs';
import {
  catchError, switchMap, takeUntil,
} from 'rxjs/operators';
import { LandInterface } from '../../../lends/interfaces/land.interface';
import { ModalService } from '../../services/modal.service';
import { CheckoutFailedModalComponent } from '../checkout-failed-modal/checkout-failed-modal.component';
import { ShardToNftFacadeService } from '../../../../../landx-shard/services/shard-to-nft-facade.service';
import { ShardToNftSuccessModalComponent } from '../shard-to-nft-success-modal/shard-to-nft-success-modal.component';
import { PendingModalComponent } from '../pending-modal/pending-modal.component';
import {
  UnsubscribeDestroyHelperComponent,
} from '../../../../../core/common/helpers/unsubscribe-destroy-helper.component';

@Component({
  selector: 'app-shard-to-nft-modal',
  templateUrl: './shard-to-nft-modal.component.html',
  styleUrls: ['../../../../../styles/blocks/modals.scss'],
})
export class ShardToNftModalComponent extends UnsubscribeDestroyHelperComponent {
  static readonly modalIdentifier = 'shardToNftModal';
  public land: LandInterface;
  public balance?: number;
  constructor(
    private modalService: ModalService,
    private shardToNftFacadeService: ShardToNftFacadeService,
  ) {
    super();
    this.land = this.modalService.getModalData(ShardToNftModalComponent.modalIdentifier).land;
    this.subscribeToEthAccount();
  }

  public submit() {
    const unsubscribeSubject = new Subject();
    this.modalService.close(ShardToNftModalComponent.modalIdentifier);
    this.modalService.open(PendingModalComponent.modalIdentifier, PendingModalComponent, {
      unsubscribeSubject,
    });
    this.shardToNftFacadeService.account().pipe(
      takeUntil(unsubscribeSubject),
      switchMap((ethAccount) => this.shardToNftFacadeService.getNft(
        ethAccount!.address,
        this.land.token.id,
        this.land.token.shardManagerContractAddress,
      )),
      catchError((error) => {
        this.modalService.close(PendingModalComponent.modalIdentifier);
        this.modalService.open(CheckoutFailedModalComponent.modalIdentifier, CheckoutFailedModalComponent);
        return throwError(error);
      }),
    ).subscribe(() => {
      this.modalService.close(PendingModalComponent.modalIdentifier);
      this.modalService.open(ShardToNftSuccessModalComponent.modalIdentifier,
        ShardToNftSuccessModalComponent, { land: this.land });
    });
  }

  private subscribeToEthAccount(): void {
    this.shardToNftFacadeService.account().pipe(
      takeUntil(this.unsubscribeSubject),
      switchMap((account) => combineLatest(
        of(account),
        of(this.land),
      )),
      switchMap(([account, land]) => combineLatest(
        of(account),
        of(land),
        this.shardToNftFacadeService.getNetwork(),
      )),
      switchMap(([account, land, network]) => combineLatest(
        of(account),
        of(network),
        of(land.token.shardManagerContractAddress),
      )),
      switchMap(([account, network, tokenAddress]) => combineLatest(
        this.shardToNftFacadeService.getBalance(
          network.id,
          account!.address,
          tokenAddress!,
        ),
      )),
    ).subscribe(([balance]) => {
      this.balance = parseFloat(balance);
    });
  }
}
