import { Action, createReducer, on } from '@ngrx/store';
import * as UnreadMessageActions from '../actions/unread-message.actions';
import { initialUnreadMessageState, UnreadMessageState } from '../states/unread-message.state';

const unreadMessageReducer = createReducer(
  initialUnreadMessageState,
  on(UnreadMessageActions.LoadUnreadMessageCount, (state): UnreadMessageState => ({
    ...state,
    loading: true,
  })),
  on(
    UnreadMessageActions.LoadUnreadMessageCountSuccess,
    (state, { newNotificationsCount, unreadNotificationsCount }): UnreadMessageState => ({
      ...state,
      newNotificationsCount,
      unreadNotificationsCount,
      loading: false,
    }),
  ),
  on(UnreadMessageActions.LoadUnreadMessageCountFailure, (state): UnreadMessageState => ({
    ...state,
    loading: false,
  })),
  on(UnreadMessageActions.ResetNewNotificationsCounter, (state): UnreadMessageState => ({
    ...state,
    newNotificationsCount: 0,
    unreadNotificationsCount: 0,
  })),
);

export function reducer(state: UnreadMessageState | undefined, action: Action) {
  return unreadMessageReducer(state, action);
}
