<ngx-spinner
  bdColor="rgba(0, 0, 0, 0.8)"
  size="default"
  color="#fff"
  type="ball-clip-rotate"
  [fullScreen]="true"
>
</ngx-spinner>
<app-modal-notifications></app-modal-notifications>
<notifier-container></notifier-container>
<app-main-layout>
  <router-outlet></router-outlet>
</app-main-layout>
