import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { BehaviorSubject, Observable } from 'rxjs';
import { HeaderSideMenuTypes } from '../constants/header-side-menu-types';
import { selectAuthLoggedIn } from '../../../../core/common/store/selectors/autth.selectors';

export const TRANSITION_TIME = 400;

@Injectable()
export class HeaderSideMenuService {
  public opened$ = new BehaviorSubject<boolean>(false);
  public openedType$ = new BehaviorSubject<string>('');

  constructor(
    private store: Store,
  ) {}

  public getOpened$() {
    return this.opened$.asObservable();
  }

  public getOpenedType$() {
    return this.openedType$.asObservable();
  }

  public toggle() {
    this.opened$.next(!this.opened$.value);
  }

  public get loggedIn$(): Observable<boolean> {
    return this.store.select(selectAuthLoggedIn);
  }

  private open(type: string) {
    if (this.opened$.value) {
      this.close();
      // Wait for closing animation
      setTimeout(() => {
        this.opened$.next(true);
        this.openedType$.next(type);
      }, TRANSITION_TIME);
    } else {
      this.opened$.next(true);
      this.openedType$.next(type);
    }
  }

  public close() {
    this.opened$.next(false);
  }

  public openSettingMenu() {
    if (this.openedType$.value === HeaderSideMenuTypes.Settings) {
      this.toggle();
    } else {
      this.open(HeaderSideMenuTypes.Settings);
    }
  }

  public openUserMenu() {
    if (this.openedType$.value === HeaderSideMenuTypes.User) {
      this.toggle();
    } else {
      this.open(HeaderSideMenuTypes.User);
    }
  }
}
