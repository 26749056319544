import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { SettingsModel } from '../../../../../core/settings/models/settings.model';
import { HeaderFacadeService } from '../../../../services/header.facade.service';
import { SettingsItemsValues } from '../../../../../core/settings/constants/settings-items-values';
import {
  UnsubscribeDestroyHelperComponent,
} from '../../../../../core/common/helpers/unsubscribe-destroy-helper.component';

@Component({
  selector: 'app-header-settings',
  templateUrl: './header-settings.component.html',
  styleUrls: ['./header-settings.component.scss'],
})
export class HeaderSettingsComponent extends UnsubscribeDestroyHelperComponent implements OnInit {
  public form: FormGroup;
  public userSettings$: Observable<SettingsModel>;
  public userSettingsLoading$: Observable<boolean>;
  public headerUserSettingsItemsValues = SettingsItemsValues;

  constructor(
    private headerService: HeaderFacadeService,
    private formBuilder: FormBuilder,
    private translateService: TranslateService,
  ) {
    super();
    this.userSettings$ = this.headerService.getUserSettings().pipe(takeUntil(this.unsubscribeSubject));
    this.userSettingsLoading$ = this.headerService.getUserSettingsLoading().pipe(takeUntil(this.unsubscribeSubject));
    this.form = this.getForm();
  }

  ngOnInit(): void {
    this.subscribeToUserSettings();
  }

  private getForm(): FormGroup {
    return this.formBuilder.group({
      lang: null,
      currency: null,
      units: null,
    });
  }

  private subscribeToUserSettings(): void {
    this.userSettings$
      .pipe(takeUntil(this.unsubscribeSubject))
      .subscribe(
        (value) => {
          this.patchForm(value);
          this.translateService.use(this.form.value.lang);
        },
      );
  }

  private patchForm(value: SettingsModel): void {
    this.form.patchValue(value);
  }

  public setUserSettings(): void {
    this.translateService.use(this.form.value.lang);
    this.headerService.setUserSettings(new SettingsModel(this.form.value));
  }
}
