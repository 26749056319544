import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { MetamaskService } from '../../core/auth/services/metamask.service';
import { NetworkApiService } from '../../core/common/services/network/network.api.service';
import { HeaderWarningStateService } from '../state/header-warning.state.service';
import { NetworkInterface } from '../../core/common/intefaces/network.interface';
import { selectAuthLoggedIn } from '../../core/common/store/selectors/autth.selectors';

@Injectable()
export class HeaderWarningFacadeService {
  constructor(
    private metamaskService: MetamaskService,
    private networkApiService: NetworkApiService,
    private headerWarningState: HeaderWarningStateService,
    private store: Store,
  ) {
  }

  public loadRequiredNetwork(): void {
    this.networkApiService.network().subscribe(({ data }) => this.headerWarningState.setRequiredNetwork(data));
  }

  public getRequiredNetwork$(): Observable<NetworkInterface> {
    return this.headerWarningState.getRequiredNetwork$();
  }

  public loadWalletNetwork(): void {
    this.metamaskService.getNetwork()
      .subscribe((network) => this.headerWarningState.setCurrentNetworkId(network.id));
  }

  public getWalletNetworkId$(): Observable<number | null> {
    return this.headerWarningState.getCurrentNetworkId$();
  }

  public walletNetworkChangedId$(): Observable<number> {
    return this.metamaskService.getNetworkChanged$();
  }

  public switchNetwork$(id: number): Observable<null> {
    return this.metamaskService.switchNetwork$(id);
  }

  public get loggedIn$(): Observable<boolean> {
    return this.store.select(selectAuthLoggedIn);
  }
}
