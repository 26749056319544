import {
  Component, Input,
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FilterGroupFieldsInterface } from '../../interfaces/filter-group.interface';
import { FilterGroupsTypes } from '../../constants/filter-group-types';

@Component({
  selector: 'app-filter',
  templateUrl: './filter.component.html',
  styleUrls: ['./filter.component.scss'],
})
export class FilterComponent {
  @Input() public groups: FilterGroupFieldsInterface[] = [];
  @Input() public form?: FormGroup;

  public readonly GroupsTypes = FilterGroupsTypes;
}
