export interface AuthState {
  loggedIn: boolean;
  loading: boolean;
  token: string | null;
  role: string | null;
  initialized: boolean;
}

export const initialAuthState: AuthState = {
  loggedIn: false,
  loading: false,
  token: null,
  role: null,
  initialized: false,
};
