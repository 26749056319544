import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { NetworkInterface } from '../../core/common/intefaces/network.interface';

@Injectable()
export class HeaderWarningStateService {
  private requiredNetwork$ = new BehaviorSubject<NetworkInterface>({});
  private currentNetworkId$ = new BehaviorSubject<number | null>(null);

  public setRequiredNetwork(network: NetworkInterface): void {
    this.requiredNetwork$.next(network);
  }

  public getRequiredNetwork$(): Observable<NetworkInterface> {
    return this.requiredNetwork$.asObservable();
  }

  public setCurrentNetworkId(networkId: number): void {
    this.currentNetworkId$.next(networkId);
  }

  public getCurrentNetworkId$(): Observable<number | null> {
    return this.currentNetworkId$.asObservable();
  }
}
