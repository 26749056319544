import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReadAuctionService } from './services/read-auction.service';
import { AuctionRoutingModule } from './auction-routing.module';
import { PlaceBidFacadeService } from './services/place-bid.facade.service';
import { WriteAuctionService } from './services/write-auction.service';
import { SharedModule } from '../shared/shared.module';
import { BuyNowFacadeService } from './services/buy-now.facade.service';
import { MakeOfferFacadeService } from './services/make-offer.facade.service';
import { LandDetailsApiService } from '../land-details/services/land-details.api.service';
import { RemoveFromSaleFacadeService } from './services/remove-from-sale.facade.service';
import { CancelOfferFacadeService } from './services/cancel-offer.facade.service';
import { AcceptOfferFacadeService } from './services/accept-offer.facade.service';
import { RejectOfferFacadeService } from './services/reject-offer.facade.service';
import { BuyPopupsStateService } from './states/buy-popups-state.service';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    SharedModule,
    AuctionRoutingModule,
  ],
  providers: [
    ReadAuctionService,
    WriteAuctionService,
    PlaceBidFacadeService,
    BuyNowFacadeService,
    MakeOfferFacadeService,
    LandDetailsApiService,
    RemoveFromSaleFacadeService,
    CancelOfferFacadeService,
    AcceptOfferFacadeService,
    RejectOfferFacadeService,
    BuyPopupsStateService,
  ],
})
export class AuctionModule { }
