export const CLAIM_LAND_API = {
  search: '/private/lands/claim/search',
  file: '/private/lands/file',
  fileByVerifier: '/private/verifier/lands/file',
  landDraft: '/private/lands/claim/draft',
  getLandVerification: (landId: number) => `/private/verifier/lands/verification/${landId}`,
  saveLandVerification: (landId: string) => `/private/verifier/lands/verification/save/${landId}`,
  approveLandVerification: (landId: string) => `/private/verifier/lands/verification/approve/${landId}`,
  landClaim: '/private/lands/claim/',
  countries: '/public/countries',
  fileId: (removedFileId: number) => `/private/lands/file/${removedFileId}`,
  fileIdByVerifier: (removedFileId: number) => `/private/verifier/lands/file/${removedFileId}`,
  decline: (id: number) => `/private/verifier/lands/verification/decline/${id}`,
};
