import * as Auth from './auth.reducer';
import * as Settings from './settings.reducer';
import * as User from './user.reducer';
import * as UnreadMessage from './unread-message.reducer';
import * as Network from './network.reducer';
import * as ProcessingTransaction from './processing-transaction.reducer';

export const CoreReducers = {
  auth: Auth.reducer,
  settings: Settings.reducer,
  user: User.reducer,
  unreadMessage: UnreadMessage.reducer,
  network: Network.reducer,
  processingTransaction: ProcessingTransaction.reducer,
};
