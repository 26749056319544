import { createAction, props } from '@ngrx/store';

export enum NetworkActionsTypes {
  LoadNetworkContracts = '[NetworkContracts] Load',
  LoadNetworkContractsSuccess = '[NetworkContracts] Load Success',
  LoadNetworkContractsFailure = '[NetworkContracts] Load Failure',
}

export const LoadNetworkContracts = createAction(
  NetworkActionsTypes.LoadNetworkContracts,
);

export const LoadNetworkContractsSuccess = createAction(
  NetworkActionsTypes.LoadNetworkContractsSuccess,
  props<{
    nftContractAddress: '',
    auctionContractAddress: '',
    shardManagerContractAddress: '',
    wtcContractAddress: '',
    usdcContractAddress: '',
    ldxmShardManagerContractAddress: '',
    ldxrShardManagerContractAddress: '',
    ldxsShardManagerContractAddress: '',
    ldxwShardManagerContractAddress: '',
  }>(),
);

export const LoadNetworkContractsFailure = createAction(
  NetworkActionsTypes.LoadNetworkContractsFailure,
);
