<div class="filter">
  <div class="filter__side-bar"
       [class.opened]="opened"
       [class.hidden]="!groups || groups.length === 0">
    <div class="filter__button" (click)="toggle()">
      <img class="filter__open-arrow"
           src="../../../../../../assets/icons/arrow-menu-right.svg"
           alt="right-arrow">
    </div>

    <div class="filter__closed-panel"></div>

    <div class="filter__opened-panel">
      <div class="filter__header">
        <div class="filter__icon">
          <img src="../../../../../../assets/icons/filter.svg" alt="filter icon">
        </div>

        <div class="filter__title">{{'common.filter.filter' | translate}}</div>
      </div>

      <div class="filter__body">
        <app-filter [groups]="groups"
                    [form]="form">
        </app-filter>
      </div>
    </div>
  </div>

  <div class="filter__content">
    <div class="filter__top-bar" *ngIf="resultsCount !== undefined">
      <div class="filter__top-row">
        <span class="filter__counter"
              *ngIf="resultsCount !== null && resultsCount !== undefined">
          {{resultsCount}} {{'common.filter.results' | translate}}
        </span>

        <div class="filter__actions-group">
          <app-sort-bar [sorting]="sorting"
                        [form]="form">
          </app-sort-bar>

          <app-button *ngIf="!isFilterButtonHidden"
                      class="filter__button"
                      light="light"
                      (btnClick)="toggle()">
            {{'common.filter.filter' | translate}}
          </app-button>
        </div>
      </div>

      <app-filter-crumbs [groups]="groups"
                         [form]="form"
                         [resultCounter]="resultsCount">
      </app-filter-crumbs>
    </div>

    <ng-content></ng-content>
  </div>
</div>
