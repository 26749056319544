import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { Culture } from '../../core/common/intefaces/culture.interface';
import { LandInterface } from '../../shared/modules/lends/interfaces/land.interface';

@Injectable()
export class ClaimLandFormStateService {
  private land$ = new Subject<LandInterface>();
  private culture$ = new BehaviorSubject<Culture[]>([]);
  private pricePerHa$ = new BehaviorSubject<number | null>(null);
  private price$ = new BehaviorSubject<number | null>(null);
  private auctionPrice$ = new BehaviorSubject<number | null>(null);

  public setLand(land: LandInterface): void {
    this.land$.next(land);
  }

  public getLand$(): Observable<LandInterface> {
    return this.land$.asObservable();
  }

  public setCultures(cultures: Culture[]): void {
    this.culture$.next(cultures);
  }

  public getCultures$(): Observable<Culture[]> {
    return this.culture$.asObservable();
  }

  public setPricePerHa(amount: number | null): void {
    this.pricePerHa$.next(amount);
  }

  public getPricePerHa$(): Observable<number | null> {
    return this.pricePerHa$.asObservable();
  }

  public setPrice(amount: number | null): void {
    this.price$.next(amount);
  }

  public getPrice$(): Observable<number | null> {
    return this.price$.asObservable();
  }

  public setAuctionPrice(amount: number | null): void {
    this.auctionPrice$.next(amount);
  }

  public getAuctionPrice$(): Observable<number | null> {
    return this.auctionPrice$.asObservable();
  }
}
