import {
  Component, forwardRef, Host, Input, OnInit, Optional, SkipSelf,
} from '@angular/core';
import { NgxDropzoneChangeEvent } from 'ngx-dropzone';
import {
  ControlContainer,
  ControlValueAccessor, FormBuilder, FormControl,
  NG_VALUE_ACCESSOR,
} from '@angular/forms';
import { zip } from 'rxjs';
import { VALIDATORS_VALUES } from '../../../core/common/constants/validators-values';
import {
  FILE_TYPES_DOCUMENTS, FILE_TYPES_IMAGE, FILE_TYPES_IMAGE_FOR_VIEW,
} from '../../../core/common/constants/mimeTypes';
import { ClaimLandFacadeService } from '../../../claim-land/services/claim-land.facade.service';
import { IFileInterface } from '../../../core/common/intefaces/file.interface';
import { ErrorCodes } from '../../../core/common/constants/error-codes';

export const SIZE_REASON = 'size';

@Component({
  selector: 'app-drag-and-drop-files',
  templateUrl: './drag-and-drop-files.component.html',
  styleUrls: ['./drag-and-drop-files.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DragAndDropFilesComponent),
      multi: true,
    },
  ],
})
export class DragAndDropFilesComponent implements ControlValueAccessor, OnInit {
  @Input() multiple = true;
  @Input() disabled = false;
  @Input() dragZoneLabel = 'Drag  & Drop or <br>Browse your file';
  @Input() resolution: string = '500x500px';
  @Input() extension: string = FILE_TYPES_IMAGE;
  @Input() extensionView: string = FILE_TYPES_IMAGE_FOR_VIEW;
  @Input() maxFileSize: number = VALIDATORS_VALUES.fileSizeLimitMb;
  @Input() formControlName!: string;

  public filesMap: Map<number, File> = new Map<number, File>();

  private control: FormControl = new FormControl();
  private inProgress = false;

  public get files(): File[] {
    return Array.from(this.filesMap.values());
  }

  public get docs(): File[] {
    return this.files.filter((file) => FILE_TYPES_DOCUMENTS.includes(file.type));
  }

  public get images(): File[] {
    return this.files.filter((file) => FILE_TYPES_IMAGE.includes(file.type));
  }

  public get filesIds(): number[] {
    return Array.from(this.filesMap.keys());
  }

  public ids: string[] = [];
  public maxFileSizeView: string;
  public touched = false;
  public uploaded: boolean = false;

  private onChange = (value: number[]) => {}; // eslint-disable-line @typescript-eslint/no-unused-vars
  private onTouched = () => {};

  constructor(
    private formBuilder: FormBuilder,
    @Optional() @Host() @SkipSelf()
    private controlContainer: ControlContainer,
    private claimLandService: ClaimLandFacadeService,
  ) {
    this.maxFileSizeView = String(this.maxFileSize / 1024 / 1024);
  }

  ngOnInit() {
    if (this.controlContainer && this.formControlName) {
      this.control = this.controlContainer.control!.get(this.formControlName) as FormControl;
    }
  }

  public writeValue(value: IFileInterface[]) {
    if (!value?.length) {
      return;
    }
    zip(...value.map((file) => this.claimLandService.downloadLandFile(file.url, file.type)))
      .subscribe(
        (files: File[]) => {
          this.filesMap.clear();
          if (this.multiple) {
            files.forEach((file, index) => {
              this.filesMap.set(value[index].id, file);
            });
          } else {
            this.filesMap.set(value[0].id, files[0]);
          }
          this.onChange(this.filesIds);
          this.setUploadedClass();
        },
      );
  }

  public registerOnChange(onChange: any) {
    this.onChange = onChange;
  }

  public registerOnTouched(onTouched: any) {
    this.onTouched = onTouched;
  }

  public setDisabledState(disabled: boolean) {
    this.disabled = disabled;
  }

  public setTouched() {
    if (!this.touched) {
      this.onTouched();
      this.touched = true;
    }
  }

  onSelect($event: NgxDropzoneChangeEvent): void {
    if (this.inProgress) {
      return;
    }
    this.inProgress = true;
    if ($event.rejectedFiles.length) {
      // @ts-ignore
      if ($event.rejectedFiles.find(({ reason }) => reason === SIZE_REASON)) {
        this.control.setErrors({ [ErrorCodes.FileSize]: { value: this.maxFileSize } });
      } else {
        this.control.setErrors({ [ErrorCodes.InvalidFileType]: { fileType: this.extension } });
      }
    }
    this.setTouched();
    const newFiles = $event.addedFiles;
    zip(...newFiles.map((newFile) => this.claimLandService.uploadLandFile(newFile)))
      .subscribe(
        (files: IFileInterface[]) => {
          if (this.multiple) {
            files.forEach((file, index) => {
              this.filesMap.set(file.id, newFiles[index]);
            });
          } else {
            this.filesMap.clear();
            this.filesMap.set(files[0].id, newFiles[0]);
          }
          this.onChange(this.filesIds);
          this.setUploadedClass();
        },
        null,
        () => {
          this.inProgress = false;
        },
      );

    // TODO use for testing without server
    // if (this.multiple) {
    //   newFiles.forEach((newFile) => {
    //     this.filesMap.set(newFile.size, newFile);
    //   });
    // } else {
    //   this.filesMap.clear();
    //   this.filesMap.set(newFiles[0].size, newFiles[0]);
    // }
  }

  public onRemove(file: any) {
    if (this.inProgress) {
      return;
    }
    this.inProgress = true;
    let removedFileId: number = 0;
    this.filesMap.forEach((value, key) => {
      if (value === file) {
        removedFileId = key;
      }
    });
    if (removedFileId) {
      this.claimLandService.deleteFIle(removedFileId).subscribe(
        () => {
          this.filesMap.delete(removedFileId);
          this.onChange(this.filesIds);
          this.setUploadedClass();
        },
        null,
        () => {
          this.inProgress = false;
        },
      );

      // TODO use for testing without server
      // this.filesMap.delete(removedFileId);
      // this.onChange(this.filesIds);
      // this.setUploadedClass();
    }
  }

  private setUploadedClass() {
    this.uploaded = this.files.length > 0;
  }

  public isImage(f: File) {
    return FILE_TYPES_IMAGE.includes(f.type);
  }

  public hasImage(): boolean {
    return !!this.files.find((file) => FILE_TYPES_IMAGE.includes(file.type));
  }

  public hasDoc(): boolean {
    return !!this.files.find((file) => FILE_TYPES_DOCUMENTS.includes(file.type));
  }

  public getSize(size: number) {
    return size / 1024 / 1024;
  }

  public openFile(file: File) {
    const fileURL = window.URL.createObjectURL(file);
    const tab = window.open();
    tab!.location.href = fileURL;
  }
}
