import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import {
  catchError, debounceTime, finalize, tap,
} from 'rxjs/operators';
import { SpinnerService } from './spinner.service';
import { NotificationContextInterface } from '../intefaces/notification-context.interface';
import { NotificationsServiceWithTranslate } from './notificationsServiceWithTranslate';
import { DEFAULT_DEBOUNCE_TIME } from '../constants/common-settings-values';
import {
  ModalNotificationsService,
} from '../../../shared/modules/modal-notifications/services/modal-notifications.service';
import {
  ModalNotificationContextInterface,
} from '../../../shared/modules/modal-notifications/interfaces/modal-notofication.interfece';

@Injectable()
export class CallerService {
  constructor(
    private spinnerService: SpinnerService,
    private notificationsServiceWithTranslate: NotificationsServiceWithTranslate,
    private modalNotificationsService: ModalNotificationsService,
  ) {
  }

  public call<T>(
    request: Observable<T>,
    notificationSuccessContext?: NotificationContextInterface | null,
    notificationErrorContext?: NotificationContextInterface | null,
    spinner: boolean = true,
    notificationModalContext?: ModalNotificationContextInterface | null,
  ): Observable<T> {
    if (spinner) {
      this.spinnerService.show();
    }
    return request.pipe(
      debounceTime(DEFAULT_DEBOUNCE_TIME),
      finalize(() => {
        if (spinner) {
          this.spinnerService.hide();
        }
      }),
      tap(() => {
        if (notificationSuccessContext) {
          this.notificationsServiceWithTranslate
            .success(notificationSuccessContext);
        }
        if (notificationModalContext) {
          this.modalNotificationsService
            .show(notificationModalContext);
        }
      }),
      catchError((error) => {
        if (notificationErrorContext) {
          this.notificationsServiceWithTranslate
            .error(notificationErrorContext);
        }
        return throwError(error);
      }),
    );
  }
}
