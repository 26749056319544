/* eslint-disable no-param-reassign */
import { Directive, ElementRef, HostBinding } from '@angular/core';

@Directive({
  selector: '[appEllipsis]',
})
export class EllipsisDirective {
  @HostBinding('title') get getInnerText() {
    return this.elementRef.nativeElement.innerText;
  }
  constructor(
    private elementRef: ElementRef,
  ) {
    elementRef.nativeElement.style.overflow = 'hidden';
    elementRef.nativeElement.style.textOverflow = 'ellipsis';
    elementRef.nativeElement.style.whiteSpace = 'nowrap';
  }
}
