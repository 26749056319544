import { Injectable } from '@angular/core';
import { AbiItem } from 'ethereum-abi-types-generator';
import { Observable, Subscriber } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { Web3Service } from '../../core/common/services/web3.service';
import { ContractService } from '../../core/common/services/contract.service';

@Injectable()
export class WriteWtcService {
  constructor(private web3: Web3Service, private contractService: ContractService) {}

  public approve(from: string, spender: string, amount: number): Observable<string> {
    return this.contractService.contracts().pipe(
      switchMap((contracts) => new Observable((subscriber: Subscriber<string>) => {
        (async () => {
          try {
            const abi: AbiItem[] = [
              {
                name: 'approve',
                inputs: [
                  {
                    internalType: 'address',
                    name: 'spender',
                    type: 'address',
                  },
                  {
                    internalType: 'uint256',
                    name: 'amount',
                    type: 'uint256',
                  },
                ],
                outputs: [],
                stateMutability: 'payable',
                type: 'function',
              },
            ];
            const contract = new this.web3.eth.Contract(abi, contracts.wtcContractAddress);
            contract.methods.approve(spender, this.web3.utils.toWei(amount.toString())).send({
              from,
            }).then((data: { blockHash: string }) => {
              subscriber.next(data.blockHash);
            }).catch((error: any) => {
              subscriber.error(error);
            });
          } catch (error) {
            subscriber.error(error);
          }
        })();
      })),
    );
  }
}
