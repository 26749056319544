import {
  AfterViewInit,
  Directive,
  ElementRef,
  HostListener,
  Input,
  OnDestroy,
  Renderer2,
} from '@angular/core';
import {
  merge, Observable, of, Subject,
} from 'rxjs';
import {
  AbstractControl,
  ControlContainer,
  FormGroup,
  FormGroupDirective,
  NgControl,
} from '@angular/forms';
import {
  bufferCount,
  debounceTime,
  map,
  takeUntil,
  tap,
} from 'rxjs/operators';
import { ErrorCodes } from '../../core/common/constants/error-codes';
import { ApiError } from '../../core/common/models/api-error.model';
import { ApiErrorService } from '../../core/common/services/api-error.service';
import { TranslateCustomService } from '../../core/common/services/translate-custom.service';
import { ErrorTargetsNames } from '../../core/common/constants/error-targets-names';

@Directive({
  selector: '[appErrorHandle]',
})
export class ControlErrorsDirective implements OnDestroy, AfterViewInit {
  @Input() bufferSize = 1;
  @Input() errorHandleCodes: ErrorCodes[] = [];
  @Input() readonly setErrorLastChild = false;
  @Input() controlName = '';

  private alertElement = this.renderer2.createElement('div');
  private unsubscribeSubject: Subject<void> = new Subject<void>();
  private touched: Subject<void> = new Subject<void>();
  private errors: ApiError[] = [];
  private relativeFormControlName: string = '';

  constructor(
    private elementRef: ElementRef,
    private renderer2: Renderer2,
    private apiErrorService: ApiErrorService,
    private controlContainer: ControlContainer,
    private control: NgControl,
    private translateCustomService: TranslateCustomService,
  ) {
    this.alertElement.classList.add('error-word');
  }

  private get form(): FormGroup {
    return this.controlContainer.formDirective
      ? (this.controlContainer.formDirective as FormGroupDirective).form : new FormGroup({});
  }

  private get parentNode(): ElementRef {
    return this.elementRef.nativeElement.parentNode;
  }

  ngOnDestroy() {
    this.unsubscribeSubject.next();
    this.unsubscribeSubject.complete();
    this.touched.complete();
  }

  ngAfterViewInit(): void {
    this.appendAlertElement();
    this.initRelativeControlName();
    this.subscribeToValueChanges();
  }

  @HostListener('blur', ['$event'])
  private onBlur(): void {
    this.touched.next();
  }

  private getValidatorsErrors(): ApiError[] {
    if (this.control !== null && this.control.errors) {
      return Object.keys(this.control.errors)
        .map((code: string) => {
          // @ts-ignore
          const error = this.control.errors[code];
          let source: { [key: string]: string };
          switch (code) {
            case ErrorCodes.MinValue:
              source = { value: error.min.toString() };
              break;
            case ErrorCodes.MaxValue:
              source = { value: error.max.toString() };
              break;
            case ErrorCodes.Minlength:
            case ErrorCodes.Maxlength:
              source = { value: error.requiredLength.toString() };
              break;
            default:
              source = { ...error };
          }
          return new ApiError({ code, source });
        });
    }
    return [];
  }

  private getApiErrorsPipe(): Observable<ApiError[]> {
    return this.apiErrorService.errorsPipe.pipe(
      bufferCount(this.bufferSize),
      map((errors: ApiError[][]): ApiError[] => errors.flat()
        .filter((error) => this.errorMatch(error))),
      tap((errors: ApiError[]) => {
        this.errors = errors;
      }),
    );
  }

  private getFormSubmitPipe(): Observable<{}> {
    return (this.controlContainer.formDirective as FormGroupDirective).ngSubmit
      .pipe(tap(() => {
        // @ts-ignore
        this.control.control.markAsTouched({ onlySelf: true });
      }));
  }

  private errorMatch(error: ApiError): boolean {
    if (error.target === ErrorTargetsNames.Field && error.source) {
      const { field } = error.source;
      return field === this.relativeFormControlName
        || field.slice(field.indexOf('.') + 1) === this.relativeFormControlName
        || field.slice(0, field.indexOf('.')) === this.relativeFormControlName;
    }
    return this.errorHandleCodes && this.errorHandleCodes.includes(<ErrorCodes>error.code);
  }

  private initRelativeControlName(): void {
    const formPath = this.controlContainer.path;
    let formControlName = this.control.name;

    if (!formControlName) {
      formControlName = this.getControlName(this.form.controls);
    }

    if (!formControlName && formPath && formPath.length) {
      formControlName = this.getControlName(this.recursiveGetControls(formPath));
    }

    if (formPath && formPath.length) {
      this.relativeFormControlName = `${formPath.join('.')}.${formControlName}`;
    } else {
      this.relativeFormControlName = String(formControlName);
    }
  }

  private recursiveGetControls(formPaths: string[]): { [key: string]: AbstractControl } {
    return formPaths.reduce(
      // @ts-ignore
      (controls: { [key: string]: AbstractControl }, partName: string) => controls[partName].controls,
      this.form.controls,
    );
  }

  private getControlName(controls: { [key: string]: AbstractControl }): string {
    let controlName = '';

    Object.keys(controls).forEach((key) => {
      if (controls[key] === this.control.control) {
        controlName = key;
      }
    });

    return controlName;
  }

  private appendAlertElement(): void {
    if (!this.setErrorLastChild) {
      this.renderer2.appendChild(this.elementRef.nativeElement, this.alertElement);
    } else {
      this.renderer2.appendChild(this.parentNode, this.alertElement);
    }
  }

  private subscribeToValueChanges(): void {
    // @ts-ignore
    merge(
      this.getApiErrorsPipe(),
      this.getFormSubmitPipe(),
      this.form.statusChanges,
      this.touched.asObservable(),
      // @ts-ignore
      this.control.valueChanges,
      of(''),
    ).pipe(
      debounceTime(50),
      takeUntil(this.unsubscribeSubject),
    ).subscribe(() => {
      if ((this.control.invalid && this.control.touched) || this.errors.length) {
        const textError = this.getTextError();
        if (textError) {
          this.renderer2.addClass(this.elementRef.nativeElement, 'error-field');
          this.alertElement.innerHTML = textError;
        }
      } else {
        this.renderer2.removeClass(this.elementRef.nativeElement, 'error-field');
        this.alertElement.innerHTML = '';
      }
      this.errors = [];
    });
  }

  private getTextError(): string {
    const errorsTexts = [...this.getValidatorsErrors(), ...this.errors]
      .map((error) => this.translateCustomService.instant(
        `errors.${error.code}`,
        error.source ? error.source : undefined,
      ));
    return [...new Set(errorsTexts)].join(' ');
  }
}
