import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { environment } from '../../../../environments/environment';

@Injectable()
export class EndpointInterceptor implements HttpInterceptor {
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (req.url.startsWith('./assets/i18n/') || req.url.startsWith('http')) {
      return next.handle(req);
    }
    return next.handle(req.clone({ url: `${environment.apiEndpoint}${req.url}` }));
  }
}
