<label class="container"
       [class.filter]="filter">
  {{label}}

  <input type="checkbox"
         [attr.name]="label"
         [(ngModel)]="value"
         (ngModelChange)="setValue()"
         (blur)="setTouched()"
         [disabled]="disabled">

  <span class="checkmark"></span>
</label>
