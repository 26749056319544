import {
  AfterContentInit,
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  ViewChild,
} from '@angular/core';

@Component({
  selector: 'app-filter-group',
  templateUrl: './filter-group.component.html',
  styleUrls: ['./filter-group.component.scss'],
})
export class FilterGroupComponent implements AfterViewInit, AfterContentInit {
  @ViewChild('body', { static: false }) body?: ElementRef;

  @Input() public title: string = 'Title';

  public opened: boolean = true;
  public opening: boolean = false;

  ngAfterViewInit(): void {
    this.setHeight();
  }

  ngAfterContentInit(): void {
    this.setHeight();
  }

  toggle() {
    if (!this.opening) {
      if (this.body && !this.body.nativeElement.style.transition) {
        this.body.nativeElement.style.transition = 'height 0.8s ease-out';
      }
      this.opened = !this.opened;
      this.setHeight();
    }
  }

  setHeight() {
    this.opening = true;
    if (this.body) {
      if (!this.opened) {
        this.body.nativeElement.style.height = `${this.body.nativeElement.scrollHeight}px`;
        setTimeout(() => {
          // @ts-ignore
          this.body.nativeElement.style.height = '0px';
          // @ts-ignore
          this.body.nativeElement.style.overflow = 'hidden';
          this.opening = false;
        }, 0);
      } else {
        this.body.nativeElement.style.height = `${this.body.nativeElement.scrollHeight}px`;
        setTimeout(() => {
          // @ts-ignore
          this.body.nativeElement.style.height = 'auto';
          // @ts-ignore
          this.body.nativeElement.style.overflow = 'inherit';
          this.opening = false;
        }, 800);
      }
    }
  }
}
