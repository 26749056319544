import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { IHttpResponse } from '../../intefaces/http-response.interface';
import { REFERENCE_API } from '../../api/reference.api';

@Injectable({
  providedIn: 'root',
})
export class ReferenceApiService {
  constructor(
    private httpClient: HttpClient,
  ) {
  }

  public getCulturesList(): Observable<IHttpResponse<{ id: number, name: string }[]>> {
    return this.httpClient.get<IHttpResponse<{ id: number, name: string }[]>>(
      REFERENCE_API.getCulturesList,
    );
  }

  public convertCurrency(
    from: string, to: string, amount: number,
  ): Observable<IHttpResponse<{ amount: number, convertedAmount: number }>> {
    return this.httpClient.get<IHttpResponse<{ amount: number, convertedAmount: number }>>(
      REFERENCE_API.convertCurrency(from, to),
      { params: { amount } },
    );
  }

  public convertProductivity(
    from: string, to: string, amount: number,
  ): Observable<IHttpResponse<{ amount: number, convertedAmount: number }>> {
    return this.httpClient.get<IHttpResponse<{ amount: number, convertedAmount: number }>>(
      REFERENCE_API.convertProductivity(from, to),
      { params: { amount } },
    );
  }
}
