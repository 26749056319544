import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  catchError,
  distinctUntilChanged, first, map, switchMap, tap,
} from 'rxjs/operators';
import { Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { of } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { AuthActionsTypes } from '../actions/auth.actions';
import { LocalStorageService } from '../../services/local-storage.service';
// import { SettingsActionsTypes } from '../actions/settings.actions';
import { UserActionsTypes } from '../actions/user.actions';
import { MainRoutes } from '../../constants/main-routes';
import { UnreadMessageActionsTypes } from '../actions/unread-message.actions';
import { AuthService } from '../../../auth/services/auth.service';
import { MetamaskService } from '../../../auth/services/metamask.service';
import { AuthApiService } from '../../../auth/services/auth.api.service';
import { RolesNames } from '../../constants/roles-names';
import { IHttpResponse } from '../../intefaces/http-response.interface';
import {
  PendingModalComponent,
} from '../../../../shared/modules/modals/components/pending-modal/pending-modal.component';
import { ModalService } from '../../../../shared/modules/modals/services/modal.service';
import {
  CheckoutFailedModalComponent,
} from '../../../../shared/modules/modals/components/checkout-failed-modal/checkout-failed-modal.component';
// import { NetworkActionsTypes } from '../actions/network.actions';
import { ONE_DAY_MILLISECONDS } from '../../constants/common-settings-values';
// import { ProcessingTransactionActionsTypes } from '../actions/processing-transaction.actions';

const helper = new JwtHelperService();

@Injectable()
export class AuthEffects {
  LoginSuccessSetToken$ = createEffect(() => this.actions$.pipe(
    ofType(AuthActionsTypes.LoginSuccess),
    map(({ token }) => {
      this.localStorageService.setItem('token', token);
    }),
  ), { dispatch: false });

  LoginSuccessSubscribeToAccountChanged$ = createEffect(() => this.actions$.pipe(
    ofType(AuthActionsTypes.LoginSuccess),
    map(() => {
      this.authService.getAccountChanged();
    }),
  ), { dispatch: false });

  LoginSuccessLoadSettings$ = createEffect(() => this.actions$.pipe(
    ofType(AuthActionsTypes.LoginSuccess),
    map(() => ({ type: AuthActionsTypes.FinishAppInitializer })),
  ));

  LoginSuccessLoadUser$ = createEffect(() => this.actions$.pipe(
    ofType(AuthActionsTypes.LoginSuccess),
    map(() => ({ type: UserActionsTypes.LoadUser })),
  ));

  // LoginSuccessLoadUnreadMessageCount$ = createEffect(() => this.actions$.pipe(
  //   ofType(AuthActionsTypes.LoginSuccess),
  //   map(() => {
  //     const token = this.localStorageService.getItem('token');
  //     if (token) {
  //       const role = helper.decodeToken(token).role || null;
  //       if (role === RolesNames.User) {
  //         return { type: UnreadMessageActionsTypes.LoadUnreadMessageCount };
  //       }
  //     }
  //     return { type: UnreadMessageActionsTypes.LoadUnreadMessageCountSuccess };
  //   }),
  // ));

  // LoginSuccessLoadLoadProcessingTransaction$ = createEffect(() => this.actions$.pipe(
  //   ofType(AuthActionsTypes.LoginSuccess),
  //   map(() => {
  //     const token = this.localStorageService.getItem('token');
  //     if (token) {
  //       const role = helper.decodeToken(token).role || null;
  //       if (role === RolesNames.User) {
  //         // return { type: ProcessingTransactionActionsTypes.LoadProcessingTransaction };
  //       }
  //     }
  //     return { type: ProcessingTransactionActionsTypes.LoadProcessingTransactionSuccess };
  //   }),
  // ));

  LogOut$ = createEffect(() => this.actions$.pipe(
    ofType(AuthActionsTypes.LogOut),
    tap(() => {
      this.localStorageService.removeItem('token');
      this.router.navigate([MainRoutes.Home]);
    }),
  ), { dispatch: false });

  LogOutAndClearUser$ = createEffect(() => this.actions$.pipe(
    ofType(AuthActionsTypes.LogOut),
    map(() => ({ type: UserActionsTypes.ClearUser })),
  ));

  LogOutAndClearUnreadCounter$ = createEffect(() => this.actions$.pipe(
    ofType(AuthActionsTypes.LogOut),
    map(() => ({ type: UnreadMessageActionsTypes.ResetNewNotificationsCounter })),
  ));

  StartAppInitializer$ = createEffect(() => this.actions$.pipe(
    ofType(AuthActionsTypes.StartAppInitializer),
    map(() => {
      this.translateService.use('ru');

      const locker = this.localStorageService.getItem('locker') as string;
      if (locker && (Date.now() - ONE_DAY_MILLISECONDS) < Number(locker)) {
        this.authService.unLock();
      }
      const token = this.localStorageService.getItem('token');

      if (token && !helper.isTokenExpired(token)) {
        const role = helper.decodeToken(token).role || null;
        return { type: AuthActionsTypes.LoginSuccess, token, role };
      }
      return { type: AuthActionsTypes.LoginFailure };
    }),
  ));

  // FinishAppInitializer$ = createEffect(() => this.actions$.pipe(
  //   ofType(AuthActionsTypes.FinishAppInitializer),
  //   map(() => {
  //     const token = this.localStorageService.getItem('token');
  //
  //     if (token && !helper.isTokenExpired(token)) {
  //       const role = helper.decodeToken(token).role || null;
  //       if (RolesNames.User === role) {
  //         return { type: NetworkActionsTypes.LoadNetworkContracts };
  //       }
  //     }
  //     return {
  //       type: NetworkActionsTypes.LoadNetworkContractsFailure,
  //     };
  //   }),
  // ),{ dispatch: false });

  LoginWithWallet$ = createEffect(() => this.actions$.pipe(
    ofType(AuthActionsTypes.LoginWithWallet),
    switchMap(() => {
      let metamaskEthAddress: string;
      let metamaskEthNonce: string;
      return this.metamaskService.connectWallet().pipe(
        distinctUntilChanged(),
        switchMap(({ data }) => {
          metamaskEthAddress = String(data);
          return this.authApiService.generateNonceWithMetamask({ metamaskEthAddress });
        }),
        switchMap((response) => {
          metamaskEthNonce = response.data.nonce;
          return this.authService.generateSignature(metamaskEthAddress, metamaskEthNonce);
        }),
        switchMap((metamaskEthSignature) => this.authApiService.loginWithMetamask(
          { metamaskEthAddress, metamaskEthSignature },
        )),
        catchError(() => of({ data: null })),
        first(),
      );
    }),
    map(({ data }: IHttpResponse<{ token: string }> | { data: null }) => {
      this.modalService.close(PendingModalComponent.modalIdentifier);
      if (!data) {
        this.modalService.close(PendingModalComponent.modalIdentifier);
        this.modalService.open(CheckoutFailedModalComponent.modalIdentifier, CheckoutFailedModalComponent);
        return { type: AuthActionsTypes.LoginFailure };
      }
      return ({
        type: AuthActionsTypes.LoginSuccess,
        token: data.token,
        role: RolesNames.User,
      });
    }),
  ));

  LoginFailure$ = createEffect(() => this.actions$.pipe(
    ofType(AuthActionsTypes.LoginFailure),
    map(() => ({ type: AuthActionsTypes.FinishAppInitializer })),
  ));

  constructor(
    private actions$: Actions,
    private localStorageService: LocalStorageService,
    private router: Router,
    private authService: AuthService,
    private authApiService: AuthApiService,
    private metamaskService: MetamaskService,
    private modalService: ModalService,
    private translateService: TranslateService,
  ) {}
}
