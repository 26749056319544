<div class="filter" *ngFor="let group of groups">
  <app-filter-group
    [title]="group.label"
  >
    <ng-container [ngSwitch]="group.type">
      <app-filter-form
        *ngSwitchCase="GroupsTypes.Form"
        [form]="form.get(group.name)"
        [fields]="group.items"
      >
      </app-filter-form>

      <app-filter-buttons
        *ngSwitchCase="GroupsTypes.Buttons"
        [form]="form.get(group.name)"
        [buttonsGroup]="group"
      >
      </app-filter-buttons>

      <app-filter-search
        *ngSwitchCase="GroupsTypes.Search"
        [form]="form"
        [searchGroup]="group"
      >
      </app-filter-search>

      <app-filter-selected-list
        *ngSwitchCase="GroupsTypes.SelectedList"
        [form]="form"
        [selectedListGroup]="group"
      >
      </app-filter-selected-list>

      <app-filter-checkbox-list
        *ngSwitchCase="GroupsTypes.CheckboxList"
        [form]="form.get(group.name)"
        [checkboxListGroup]="group"
      >
      </app-filter-checkbox-list>
    </ng-container>
  </app-filter-group>
</div>
