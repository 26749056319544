export interface UnreadMessageState {
  newNotificationsCount: number;
  unreadNotificationsCount: number;
  loading: boolean;
}

export const initialUnreadMessageState: UnreadMessageState = {
  newNotificationsCount: 0,
  unreadNotificationsCount: 0,
  loading: false,
};
