import { createSelector, State } from '@ngrx/store';
import { ProcessingTransactionState } from '../states/processing-transaction.state';

// @ts-ignore
const selectProcessingTransactionState = (state: State) => state.processingTransaction;

export const selectProcessingTransactionItems = createSelector(
  selectProcessingTransactionState,
  (state: ProcessingTransactionState) => state.items,
);

export const selectProcessingTransactionTotalCount = createSelector(
  selectProcessingTransactionState,
  (state: ProcessingTransactionState) => state.totalCount,
);
