import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  catchError, map, merge, startWith, switchMap, takeUntil,
} from 'rxjs/operators';
import { interval, of } from 'rxjs';
import { AuthActionsTypes } from '../actions/auth.actions';
import { ProcessingTransactionActionsTypes } from '../actions/processing-transaction.actions';
import { WalletInfoApiService } from '../../../../header/services/wallet-info.api.service';

@Injectable()
export class ProcessingTransactionEffects {
  LoadProcessingTransaction$ = createEffect(() => this.actions.pipe(
    ofType(ProcessingTransactionActionsTypes.LoadProcessingTransaction),
    switchMap(() => interval(30000).pipe(
      startWith(0),
      takeUntil(
        this.actions.pipe(ofType(AuthActionsTypes.LogOut))
          .pipe(merge(this.actions.pipe(ofType(AuthActionsTypes.Login)))),
      ),
    )),
    switchMap(() => this.walletInfoApiService.loadProcessingTransactions().pipe(
      catchError(() => of({ data: null })),
    )),
    map(({ data }) => {
      if (data) {
        return {
          type: ProcessingTransactionActionsTypes.LoadProcessingTransactionSuccess,
          items: data.items,
          totalCount: data.totalCount,
        };
      }
      return { type: ProcessingTransactionActionsTypes.LoadProcessingTransactionFailure };
    }),
  ));

  constructor(
    private actions: Actions,
    private walletInfoApiService: WalletInfoApiService,
  ) {}
}
