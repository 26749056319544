import { Injectable } from '@angular/core';
import { AbiItem } from 'ethereum-abi-types-generator';
import { Observable, Subscriber } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { Web3Service } from '../../core/common/services/web3.service';
import { ContractService } from '../../core/common/services/contract.service';

@Injectable()
export class ReadWtcService {
  constructor(
    private web3: Web3Service,
    private contractService: ContractService,
    private store: Store,
  ) {}

  public allowance(owner: string, spender: string): Observable<string> {
    return this.contractService.contracts().pipe(
      switchMap((contracts) => new Observable((subscriber: Subscriber<string>) => {
        (async () => {
          try {
            const abi: AbiItem[] = [
              {
                name: 'allowance',
                inputs: [
                  {
                    internalType: 'address',
                    name: 'owner',
                    type: 'address',
                  },
                  {
                    internalType: 'address',
                    name: 'spender',
                    type: 'address',
                  },
                ],
                outputs: [
                  {
                    internalType: 'uint256',
                    name: '',
                    type: 'uint256',
                  },
                ],
                stateMutability: 'view',
                type: 'function',
              },
            ];
            const contract = new this.web3.eth.Contract(abi, contracts.wtcContractAddress);
            const result = await contract.methods.allowance(owner, spender).call();
            subscriber.next(this.web3.utils.fromWei(result));
          } catch (error) {
            subscriber.error(error);
          }
        })();
      })),
    );
  }
}
