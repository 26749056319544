<div class="input-filed">
  <label
    *ngIf="label"
    class="input-filed__label"
    [attr.for]="label"
  >
    {{label}}
  </label>
  <ng-select
    class="input-filed__input"
    [class.white]="white"
    #select
    [attr.name]="label"
    (change)="setValue($event)"
    [items]="items"
    (blur)="setTouched()"
    [placeholder]="placeholder | translate"
    [clearable]="clearable"
  ></ng-select>
  <div *ngIf="note" class="input-filed__note">{{note}}</div>
</div>
