import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-avatar-image',
  templateUrl: './avatar-image.component.html',
  styleUrls: ['./avatar-image.component.scss'],
})
export class AvatarImageComponent {
  @Input() public alt?: string;
  @Input() public src!: string;

  public showStub = true;

  public loadSuccess() {
    this.showStub = false;
  }

  public loadFail() {
    this.showStub = true;
  }
}
