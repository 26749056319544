import {
  Attribute,
  Component, EventEmitter, forwardRef, Input, Output, ViewChild,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { NgSelectComponent } from '@ng-select/ng-select';
import { SelectItemInterface } from '../../../core/common/intefaces/select-item.interface';

@Component({
  selector: 'app-field-select',
  templateUrl: './field-select.component.html',
  styleUrls: ['./field-select.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FieldSelectComponent),
      multi: true,
    },
  ],
})
export class FieldSelectComponent implements ControlValueAccessor {
  @ViewChild('select', { static: true }) select?: NgSelectComponent;

  @Input() public searchable = true;
  @Input() public clearable = true;
  @Input() public bindValue = '';
  @Input() public bindLabel = '';
  @Input() public items: SelectItemInterface[] | [] = [];
  @Input() public placeholder = 'common.select';
  @Input() public label = '';
  @Input() public note = '';
  @Input() public error = '';

  // eslint-disable-next-line @angular-eslint/no-output-native
  @Output() blur: EventEmitter<void> = new EventEmitter();

  public touched = false;
  public disabled = false;
  public white: boolean = false;
  public value: any;

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  private onChange = (value: SelectItemInterface | null) => {};
  private onTouched = () => {};

  constructor(
    @Attribute('white') public attrWhite: string | null,
  ) {
    this.white = typeof attrWhite === 'string';
  }

  public writeValue(value: SelectItemInterface | any) {
    if (this.select) {
      const currentValue = this.items.find((item) => value === item.value || value?.value === item.value);
      if (currentValue) {
        this.value = currentValue.value;
        this.select.writeValue(currentValue.label);
      } else if (value === null) {
        this.select.handleClearClick();
        this.select.blur();
      } else {
        this.value = value.value;
        this.select.writeValue(value.label);
      }
    }
  }

  public registerOnChange(onChange: any) {
    this.onChange = onChange;
  }

  public registerOnTouched(onTouched: any) {
    this.onTouched = onTouched;
  }

  public setDisabledState(disabled: boolean) {
    if (this.select) {
      this.select.setDisabledState(disabled);
    }
  }

  public setValue($event: SelectItemInterface) {
    if (!this.disabled) {
      this.value = $event?.value;
      this.onChange($event?.value);
    }
  }

  public setTouched() {
    this.blur.emit();
    if (!this.touched) {
      this.onTouched();
      this.touched = true;
    }
  }
}
