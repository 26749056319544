<div class="modal">
  <img class="modal__title" src="assets/icons/success.svg" [alt]="'common.modals.success' | translate">

  <div class="modal__title">{{ 'common.modals.success' | translate }}</div>

  <div *ngIf="subTitle" class="modal__subtitle">{{ subTitle}}</div>

  <ng-content></ng-content>

  <div class="modal__buttons">
    <app-button primary class="modal__button" (btnClick)="closeModal.emit()">{{ 'common.modals.ok' | translate }}</app-button>
  </div>
</div>
