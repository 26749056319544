import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { WALLET_INFO_API } from '../api/wallet-info.api';
import { TradingHistoryInterface } from '../../land-details/interfaces/trading-history.interface';

@Injectable()
export class WalletInfoApiService {
  constructor(
    private http: HttpClient,
  ) {
  }

  public loadProcessingTransactions(): Observable<{ data: { items: TradingHistoryInterface[], totalCount: number } }> {
    return this.http
      .get<{ data: { items: TradingHistoryInterface[], totalCount: number } }>(WALLET_INFO_API.transactionHistory);
  }
}
