<ngx-smart-modal
  [backdrop]="true"
  [closable]="false"
  #modalNotification
  identifier="modalNotification"
>
  <div class="modal-notification form-modal">
    <img src="assets/icons/success.svg" alt="success" class="modal-notification__icon" *ngIf="type === modalNotificationsTypes.Success">

    <div class="modal-notification__title" *ngIf="type === modalNotificationsTypes.Success">
      {{'pages.admin.success' | translate}}
    </div>

    <div class="modal-notification__text">
      {{context | translate}}
    </div>

    <div class="modal-notification__button">
      <app-button
        primary
        type="button"
        (btnClick)="close()"
      >
        {{'pages.admin.ok' | translate}}
      </app-button>
    </div>
  </div>
</ngx-smart-modal>
