import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  catchError,
  map,
  repeat,
  switchMap,
} from 'rxjs/operators';
import { of } from 'rxjs';
import { Store } from '@ngrx/store';
import { UserActionsTypes } from '../actions/user.actions';
import { UserApiService } from '../../../user/services/user.api.service';
import { selectUser } from '../selectors/user.selector';
import { CallerService } from '../../services/caller.service';

@Injectable()
export class UserEffects {
  LoadUser$ = createEffect(() => this.actions.pipe(
    ofType(UserActionsTypes.LoadUser),
    switchMap(() => this.userApiService.getUser()),
    map(({ data }) => ({ type: UserActionsTypes.LoadUserSuccess, user: data })),
    catchError(() => of({ type: UserActionsTypes.LoadUserFailure })),
    repeat(),
  ));

  LoadWallpaper$ = createEffect(() => this.actions.pipe(
    ofType(UserActionsTypes.LoadWallpaper),
    switchMap(() => this.store.select(selectUser)),
    switchMap((user) => {
      if (user) {
        return this.userApiService.getWallpaper(user.wallpaper.url);
      }

      return of(new Blob());
    }),
    map((blob) => new File([blob], 'profile-image.png', { type: 'image/png' })),
    map((file) => ({ type: UserActionsTypes.LoadWallpaperSuccess, wallpaperUrl: URL.createObjectURL(file) })),
    catchError(() => of({ type: UserActionsTypes.LoadWallpaperFailure })),
    repeat(),
  ));

  UploadUserImage$ = createEffect(() => this.actions.pipe(
    ofType(UserActionsTypes.UploadUserImage),
    switchMap(({ file }) => {
      const formData = new FormData();
      formData.append('file', file);
      return this.callerService.call(
        this.userApiService.uploadUserImage(formData),
      );
    }),
    map(({ data }) => ({ type: UserActionsTypes.UploadUserImageSuccess, profileImage: data })),
    catchError(() => of({ type: UserActionsTypes.UploadUserImageFailure })),
    repeat(),
  ));

  UploadUserWallpaper$ = createEffect(() => this.actions.pipe(
    ofType(UserActionsTypes.UploadWallpaper),
    switchMap(({ file }) => {
      const formData = new FormData();
      formData.append('file', file);
      return this.callerService.call(
        this.userApiService.uploadUserWallpaper(formData),
      );
    }),
    map(({ data }) => ({ type: UserActionsTypes.UploadWallpaperSuccess, wallpaper: data })),
    catchError(() => of({ type: UserActionsTypes.UploadWallpaperFailure })),
    repeat(),
  ));

  UploadWallpaperSuccess$ = createEffect(() => this.actions.pipe(
    ofType(UserActionsTypes.UploadWallpaperSuccess),
    map(() => ({ type: UserActionsTypes.LoadWallpaper })),
    repeat(),
  ));

  constructor(
    private actions: Actions,
    private userApiService: UserApiService,
    private store: Store,
    private callerService: CallerService,
  ) {
  }
}
