import { Component } from '@angular/core';
import {
  combineLatest, Observable, of, throwError,
} from 'rxjs';
import { catchError, switchMap, takeUntil } from 'rxjs/operators';
import { LandInterface } from '../../../lends/interfaces/land.interface';
import { ModalService } from '../../services/modal.service';
import {
  UnsubscribeDestroyHelperComponent,
} from '../../../../../core/common/helpers/unsubscribe-destroy-helper.component';
import { DealTypes } from '../../../../../core/common/constants/deal-types';
import { RemoveFromSaleFacadeService } from '../../../../../auction/services/remove-from-sale.facade.service';
import { PendingModalComponent } from '../pending-modal/pending-modal.component';
import { CheckoutFailedModalComponent } from '../checkout-failed-modal/checkout-failed-modal.component';
import { SuccessModalComponent } from '../success-modal/success-modal.component';

@Component({
  selector: 'app-remove-from-sale-modal',
  templateUrl: './remove-from-sale-modal.component.html',
  styleUrls: ['../../../../../styles/blocks/modals.scss'],
})
export class RemoveFromSaleModalComponent extends UnsubscribeDestroyHelperComponent {
  public static readonly modalIdentifier: string = 'removeFromSaleModalComponent';
  public land$: Observable<LandInterface | null>;

  constructor(private modalService: ModalService, private removeFromSellFacadeService: RemoveFromSaleFacadeService) {
    super();
    this.land$ = this.modalService.getModalData(RemoveFromSaleModalComponent.modalIdentifier).land$;
  }

  public submit() {
    this.modalService.open(PendingModalComponent.modalIdentifier, PendingModalComponent, {});
    this.modalService.close(RemoveFromSaleModalComponent.modalIdentifier);
    this.land$.pipe(
      takeUntil(this.unsubscribeSubject),
      switchMap((land) => combineLatest(
        of(land),
        this.removeFromSellFacadeService.account(),
      )),
      switchMap(([land, ethAccount]) => {
        if (land!.productDetails.dealType === DealTypes.Auction) {
          return this.removeFromSellFacadeService.cancelAuction(
            ethAccount!.address, land!.productDetails.auctionId, land!.token.auctionContractAddress,
          );
        } if (land!.productDetails.dealType === DealTypes.FixPrice) {
          return this.removeFromSellFacadeService.removeFromSale(
            ethAccount!.address, land!.productDetails.saleId!, land!.token.auctionContractAddress,
          );
        }
        return throwError(new Error('Land cannot be removed from sale or '
          + 'auction as sale id and auction id does not exist'));
      }),
      catchError((error) => {
        this.modalService.close(PendingModalComponent.modalIdentifier);
        this.modalService.open(CheckoutFailedModalComponent.modalIdentifier, CheckoutFailedModalComponent, {});
        return throwError(error);
      }),
    ).subscribe(() => {
      this.modalService.close(PendingModalComponent.modalIdentifier);
      this.modalService.open(SuccessModalComponent.modalIdentifier, SuccessModalComponent, {});
    });
  }
}
