<ngx-dropzone
  #drop
  [id]="formControlName"
  class="drag-zone"
  [class.uploaded]="uploaded"
  (change)="onSelect($event)"
  [multiple]="multiple"
  [maxFileSize]="maxFileSize"
  accept="{{extension}}"
  [disabled]="disabled"
>
  <!--    used when no error   -->

  <ngx-dropzone-label class="drag-zone__label">
    <img class="drag-zone__label-icon" src="../../../../assets/icons/drag-and-drop.svg" alt="drag-zone-label">
    <span class="drag-zone__label-text" [innerHTML]="dragZoneLabel"></span>
  </ngx-dropzone-label>

  <!--    used when error   -->

  <div class="drag-zone__label drag-zone__label--custom" (click)="drop.showFileSelector()">
    <img class="drag-zone__label-icon" src="../../../../assets/icons/drag-and-drop-error.svg" alt="drag-zone-label">
    <span class="drag-zone__label-text" [innerHTML]="dragZoneLabel"></span>
  </div>
</ngx-dropzone>

<div class="drag-and-drop__notes">
  {{('common.drag_and_drop.recommended_size' | translate)}}
  <span *ngIf="resolution">{{ resolution }}. </span>
  <span *ngIf="extensionView">{{ extensionView }}. </span>
  <span *ngIf="maxFileSizeView">{{('common.drag_and_drop.max_size' | translate : { maxFileSizeView: maxFileSizeView })}}</span>
</div>

<ng-container *ngIf="hasImage()">
  <div class="drag-zone__preview-container">
    <div
      (click)="openFile(file)"
      class="drag-zone__preview-wrapper"
      *ngFor="let file of images"
    >
      <ngx-dropzone-image-preview
        ngProjectAs="ngx-dropzone-preview"
        [file]="file"
        [removable]="true"
        (removed)="onRemove(file)"
        class="drag-zone__preview"
      >
        <div class="drag-zone__custom-button"></div>
      </ngx-dropzone-image-preview>
    </div>
  </div>
</ng-container>

<ng-container *ngIf="hasDoc()">
  <div class="drag-zone__preview-doc-container">
    <div
      class="drag-and-drop__preview docs-preview"
      *ngFor="let file of docs; let i = index"
    >
      <img
        src="assets/icons/doc.svg"
        class="docs-preview__icon"
        alt="doc icon"
        (click)="openFile(file)"
      >

      <div
        class="docs-preview__name"
        (click)="openFile(file)"
      >
        {{ file.name }}
      </div>

      <div class="docs-preview__size">
        {{getSize(file.size) | number: '1.0-1'}} Mb
      </div>

      <img
        class="docs-preview__close"
        src="assets/icons/remove-file.svg"
        alt="remove icon"
        (click)="onRemove(file)"
      >
    </div>
  </div>
</ng-container>

