import { Component } from '@angular/core';
import { Observable, Subject, throwError } from 'rxjs';
import {
  catchError, switchMap, take, takeUntil,
} from 'rxjs/operators';
import { ModalService } from '../../services/modal.service';
import { LandInterface } from '../../../lends/interfaces/land.interface';
import { PRICE_FORMAT } from '../../../../../core/common/constants/common-settings-values';
import { CheckoutSuccessModalComponent } from '../checkout-success-modal/checkout-success-modal.component';
import { CheckoutModalDataInterface } from '../../interfaces/checkout-modal-data.interface';
import { PlaceBidFacadeService } from '../../../../../auction/services/place-bid.facade.service';
import {
  UnsubscribeDestroyHelperComponent,
} from '../../../../../core/common/helpers/unsubscribe-destroy-helper.component';
import { MakeOfferFacadeService } from '../../../../../auction/services/make-offer.facade.service';
import { CheckoutFailedModalComponent } from '../checkout-failed-modal/checkout-failed-modal.component';
import { PendingModalComponent } from '../pending-modal/pending-modal.component';
import { AreaUnits } from '../../../../../core/settings/constants/units-names';

@Component({
  selector: 'app-checkout-confirm-modal',
  templateUrl: './checkout-confirm-modal.component.html',
  styleUrls: ['../../../../../styles/blocks/modals.scss'],
})
export class CheckoutConfirmModalComponent extends UnsubscribeDestroyHelperComponent {
  public static readonly modalIdentifier: string = 'buyCheckoutConfirmModal';
  public land$: Observable<LandInterface | null>;
  public units$: Observable<string>;
  public data: CheckoutModalDataInterface;
  public amountFormat = PRICE_FORMAT;
  public areaUnits = AreaUnits;

  constructor(
    private modalService: ModalService,
    private placeBidFacadeService: PlaceBidFacadeService,
    private makeOfferFacadeService: MakeOfferFacadeService,
  ) {
    super();
    this.land$ = this.modalService.getModalData(CheckoutConfirmModalComponent.modalIdentifier).land$.pipe(
      take(1),
    );
    this.data = this.modalService.getModalData(CheckoutConfirmModalComponent.modalIdentifier).data;
    this.units$ = this.placeBidFacadeService.getUnits$;
  }

  public next(): void {
    if (this.data.auction?.auctionId || this.data.isMakeBid) {
      this.makeBid();
    } else if (this.data.listingId || this.data.isMakeOffer) {
      this.makeOffer();
    }
  }

  public close(): void {
    this.modalService.close(CheckoutConfirmModalComponent.modalIdentifier);
  }

  private makeBid(): void {
    const unsubscribeSubject = new Subject();
    this.modalService.close(CheckoutConfirmModalComponent.modalIdentifier);
    this.modalService.open(PendingModalComponent.modalIdentifier, PendingModalComponent, {
      unsubscribeSubject,
    });
    this.placeBidFacadeService.approveIfNotEnough(
      this.data.ethAccount!.address,
      this.data.tokenAddress!,
      this.data.totalAmount!,
    ).pipe(
      takeUntil(unsubscribeSubject),
      switchMap(() => this.placeBidFacadeService.bid(
        this.data.ethAccount!.address,
        this.data.auction!.auctionId,
        this.data.bidAmount!,
      )),
      catchError((error) => {
        this.modalService.close(PendingModalComponent.modalIdentifier);
        this.modalService.open(
          CheckoutFailedModalComponent.modalIdentifier,
          CheckoutFailedModalComponent,
          {},
        );
        return throwError(error);
      }),
    ).subscribe(() => {
      this.modalService.close(PendingModalComponent.modalIdentifier);
      this.modalService.open(
        CheckoutSuccessModalComponent.modalIdentifier,
        CheckoutSuccessModalComponent,
        { land$: this.land$, data: this.data },
      );
    });
  }

  private makeOffer(): void {
    const unsubscribeSubject = new Subject();
    this.modalService.close(CheckoutConfirmModalComponent.modalIdentifier);
    this.modalService.open(PendingModalComponent.modalIdentifier, PendingModalComponent, {
      unsubscribeSubject,
    });
    this.makeOfferFacadeService.approveIfNotEnough(
      this.data.ethAccount!.address,
      this.data.tokenAddress!,
      this.data.totalAmount!,
    ).pipe(
      takeUntil(unsubscribeSubject),
      switchMap(() => this.land$),
      switchMap((land) => this.makeOfferFacadeService.makeOffer(
        this.data.ethAccount!.address,
        this.data.nftId!,
        this.data.offerAmount!,
        land!.productDetails.currency === 'USDC' ? 1 : 0,
        this.data.listingId ? this.data.listingId : 0,
        this.data.duration || 604800,
      )),
      catchError((error) => {
        this.modalService.close(PendingModalComponent.modalIdentifier);
        this.modalService.open(
          CheckoutFailedModalComponent.modalIdentifier,
          CheckoutFailedModalComponent,
          {},
        );
        return throwError(error);
      }),
    ).subscribe(() => {
      this.modalService.close(PendingModalComponent.modalIdentifier);
      this.modalService.open(
        CheckoutSuccessModalComponent.modalIdentifier,
        CheckoutSuccessModalComponent,
        { land$: this.land$, data: this.data },
      );
    });
  }
}
