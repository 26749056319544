import { TradingHistoryInterface } from '../../../../land-details/interfaces/trading-history.interface';

export interface ProcessingTransactionState {
  items: TradingHistoryInterface[],
  totalCount: number,
  loading: boolean;
}

export const initialProcessingTransactionState: ProcessingTransactionState = {
  items: [],
  totalCount: 0,
  loading: false,
};
