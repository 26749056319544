import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { TradingHistoryInterface } from '../interfaces/trading-history.interface';
import { IHttpResponse } from '../../core/common/intefaces/http-response.interface';
import { LAND_DETAILS_API } from '../api/land-details.api';
import { BidModel } from '../interfaces/bidModel';
import { LandIndexStatisticsInterface } from '../interfaces/land-index-statistics-interface';
import { LandWeatherStatisticsInterface } from '../interfaces/land-weather-statistics.interface';
import { OfferInterface } from '../interfaces/offer.interface';
import { PriceHistoryResponseItemInterface } from '../interfaces/price-history-response-item.interface';

@Injectable()
export class LandDetailsApiService {
  constructor(
    private httpClient: HttpClient,
  ) { }

  public tradingHistory(
    landId: number, params: { type: string },
  ): Observable<IHttpResponse<TradingHistoryInterface[]>> {
    return this.httpClient.get<IHttpResponse<TradingHistoryInterface[]>>(
      LAND_DETAILS_API.tradingHistory(landId),
      {
        params,
      },
    );
  }

  public offers(landId: number): Observable<IHttpResponse<OfferInterface[]>> {
    return this.httpClient.get<IHttpResponse<OfferInterface[]>>(
      LAND_DETAILS_API.offers(landId),
    );
  }

  public bids(tokenId: number, params: { landId: number }): Observable<IHttpResponse<BidModel[]>> {
    return this.httpClient.get<IHttpResponse<BidModel[]>>(
      LAND_DETAILS_API.bids(tokenId),
      {
        params,
      },
    );
  }

  public statistics(
    landId: number,
    startDate: string = '',
    endDate: string = '',
  ): Observable<IHttpResponse<LandIndexStatisticsInterface>> {
    return this.httpClient.get<IHttpResponse<LandIndexStatisticsInterface>>(
      LAND_DETAILS_API.statistics(landId),
      {
        params: {
          'date:gte': startDate ?? '',
          'date:lte': endDate ?? '',
          index: 'NDVI',
          sort: 'date',
        },
      },
    );
  }

  public weatherStatistics(
    landId: number,
    startDate: string = '',
    endDate: string = '',
  ): Observable<IHttpResponse<LandWeatherStatisticsInterface>> {
    return this.httpClient.get<IHttpResponse<LandWeatherStatisticsInterface>>(
      LAND_DETAILS_API.statistics(landId),
      {
        params: {
          'date:gte': startDate ?? '',
          'date:lte': endDate ?? '',
          index: 'weather',
          sort: 'date',
        },
      },
    );
  }

  public priceHistory(
    tokenId: number,
    startDate: string = '',
    endDate: string = '',
  ): Observable<IHttpResponse<PriceHistoryResponseItemInterface[]>> {
    return this.httpClient.get<IHttpResponse<any>>(
      LAND_DETAILS_API.priceHistory(tokenId),
      {
        params: {
          'blockDate:gte': startDate,
          'blockDate:lte': endDate,
        },
      },
    );
  }
}
