import { createSelector, State } from '@ngrx/store';
import { UserState } from '../states/user.state';

// @ts-ignore
const selectUserState = (state: State) => state.user;

export const selectUserLoading = createSelector(
  selectUserState,
  (state: UserState) => state.loading,
);

export const selectUser = createSelector(
  selectUserState,
  (state: UserState) => state.user,
);

export const selectUserId = createSelector(
  selectUserState,
  (state: UserState) => state.user?.id,
);

export const selectUserWallpaperUrl = createSelector(
  selectUserState,
  (state: UserState) => state.wallpaperUrl,
);
