import { ActionInterface } from './action-interface';

interface IconConfigInterface {
  background: string
}

export enum TableDataTypes {
  PERCENT = 'percent',
  MONTH = 'month',
  WTC = 'WTC',
  PROGRESS = 'progress',
  NAME_WITH_LOGO = 'name-with-logo',
  TRANSLATION = 'translation',
  DATE = 'date',
  LINK = 'link',
}

export interface TableDataTypesInterface {
  type: TableDataTypes;
  value: any;
  iconConfig?: IconConfigInterface;
  dateFormat?: string;
  linkUrl?: string;
}

export interface SimpleTableRowInterface {
  items: string[] | TableDataTypesInterface[];
  options?: ActionInterface[];
  optionsDisabled?: boolean;
}
