import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
  selector: 'app-custom-spinner',
  template: '<div class="lds-ring"><div></div><div></div><div></div><div></div></div>',
  styles: [
    `.lds-ring {
      display: inline-block;
      position: relative;
      width: 100%;
      height: 100%;
    }
    .lds-ring div {
      box-sizing: border-box;
      display: block;
      position: absolute;
      width: 100%;
      height: 100%;
      margin: 0;
      border: 4px solid var(--color-secondary);
      border-radius: 50%;
      animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
      border-color: var(--color-secondary) transparent transparent transparent;
    }
    .lds-ring div:nth-child(1) {
      animation-delay: -0.45s;
    }
    .lds-ring div:nth-child(2) {
      animation-delay: -0.3s;
    }
    .lds-ring div:nth-child(3) {
      animation-delay: -0.15s;
    }
    @keyframes lds-ring {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
      }
    }`,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CustomSpinnerComponent {
}
