import { Component } from '@angular/core';
import { ModalService } from '../../services/modal.service';

@Component({
  selector: 'app-checkout-failed-modal',
  templateUrl: './checkout-failed-modal.component.html',
  styleUrls: ['../../../../../styles/blocks/modals.scss'],
})
export class CheckoutFailedModalComponent {
  public static readonly modalIdentifier: string = 'checkoutFailedModalComponent';

  constructor(private modalService: ModalService) {}

  public close(): void {
    this.modalService.close(CheckoutFailedModalComponent.modalIdentifier);
  }
}
