<p class="title">{{ 'popups.wallet_info' | translate }}</p>

<div class="wallet-info">
  <p class="wallet-info__title">{{ 'popups.connected_with_metamask' | translate }}</p>

  <div class="wallet-info__main">
    <img
      [src]="(user$ | async)?.profileImage?.url || 'assets/images/avatar-account.png'"
      alt="avatar"
      class="wallet-info__avatar"
    >
    <p class="wallet-info__address">{{ (user$ | async)?.metamaskEthAddress | hiddenWalletAddress }}</p>
  </div>

  <div class="wallet-info__buttons">
    <div class="wallet-info__button" (click)="copyAddress()">
      <div [style]="{'-webkit-mask-image': 'url(/assets/icons/copy.svg)'}" alt=""
           class="wallet-info__button-icon"></div>
      <span class="wallet-info__button-text">{{ 'popups.copy_address' | translate }}</span>
    </div>

    <div class="wallet-info__button" (click)="exploreAddress()">
      <div [style]="{'-webkit-mask-image': 'url(/assets/icons/open-in.svg)'}" alt=""
           class="wallet-info__button-icon"></div>
      <span class="wallet-info__button-text">{{ 'popups.view_on_block_explorer' | translate }}</span>
    </div>
  </div>
</div>

<div *ngIf="walletAddressWrong$ | async" class="switch-button" (click)="connectWallet()">
  {{ 'popups.switch_account' | translate }}
</div>

<div *ngIf="(processingTransaction$ | async)?.length" class="transactions">
  <p class="transactions__title">{{ 'popups.recent_transactions' | translate }}</p>
  <div class="transactions-block">
    <div *ngFor="let transaction of (processingTransaction$ | async); let index = index;">
      <div class="transaction">
        <a (click)="openNewWindow(transaction.url)" class="transaction__link">
          <span class="transaction-link__text">{{ ('common.activities.' + transaction.type) | translate }}</span>
          <div [style]="{'-webkit-mask-image': 'url(/assets/icons/open-in.svg)'}" alt=""
               class="transaction-link__icon"></div>
        </a>
        <img src="assets/icons/pending-transaction.svg" alt="" class="transaction-link__status-icon">
      </div>
    </div>
  </div>
</div>
