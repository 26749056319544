import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
// import { DevelopModeGuard } from './core/common/guards/develop-mode.guard';
import { AuthGuard } from './core/auth/guards/auth.guard';
// import { VerifierLoggedInResolver } from './core/common/resolvers/verifier-loged-in-resolver.service';
import { ConnectWalletIfLoggedOutGuard } from './core/common/guards/connect-wallet-if-logged-out.guard';
import { LogoutGuard } from './core/common/guards/logout.guard';
import { LockGuard } from './core/common/guards/lock-guard.service';
import { CheckRoleGuard } from './core/common/guards/check-role.guard';

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    canActivate: [CheckRoleGuard],
    loadChildren: () => import('./landing/landing.module').then((m) => m.LandingModule),
    data: {
      hideHeaderSearchBar: true,
    },
  },
  {
    path: 'user-settings',
    canActivate: [AuthGuard, CheckRoleGuard],
    loadChildren: () => import('./user-settings/user-settings.module').then((m) => m.UserSettingsModule),
  },
  // {
  //   path: 'kyc',
  //   canActivate: [AuthGuard, LockGuard, CheckRoleGuard],
  //   loadChildren: () => import('./kyc/kyc.module').then((m) => m.KycModule),
  //   data: {
  //     hideHeader: true,
  //   },
  // },
  // {
  //   path: 'guideline',
  //   canActivate: [DevelopModeGuard, LockGuard, CheckRoleGuard],
  //   loadChildren: () => import('./shared/modules/guideline/guideline.module').then((m) => m.GuidelineModule),
  // },
  {
    path: 'marketplace',
    canActivate: [CheckRoleGuard],
    loadChildren: () => import('./marketplace/marketplace.module').then((m) => m.MarketplaceModule),
  },
  {
    path: 'land/:id',
    canActivate: [CheckRoleGuard],
    loadChildren: () => import('./land-details/land.module').then((m) => m.LandModule),
  },
  {
    path: 'lands/claim',
    canActivate: [AuthGuard, ConnectWalletIfLoggedOutGuard, CheckRoleGuard],
    loadChildren: () => import('./claim-land/claim-land.module').then((m) => m.ClaimLandModule),
  },
  {
    path: 'admin-lands/claim',
    canActivate: [AuthGuard],
    loadChildren: () => import('./claim-land/claim-land.module').then((m) => m.ClaimLandModule),
    data: {
      showAdminHeader: true,
    },
  },
  {
    path: 'auth',
    loadChildren: () => import('./onboard/onboard.module').then((m) => m.OnboardModule),
    data: {
      hideHeader: true,
    },
  },
  {
    path: 'admin',
    loadChildren: () => import('./admin/admin.module').then((m) => m.AdminModule),
    data: {
      showAdminHeader: true,
    },
  },
  {
    path: 'user-profile',
    canActivate: [AuthGuard, CheckRoleGuard],
    loadChildren: () => import('./user-profile/user-profile.module').then((m) => m.UserProfileModule),
  },
  // {
  //   path: 'messages',
  //   canActivate: [LockGuard],
  //   loadChildren: () => import('./messaging/messaging.module').then((m) => m.MessagingModule),
  //   resolve: {
  //     showAdminHeader: VerifierLoggedInResolver,
  //   },
  // },
  // {
  //   path: 'shards',
  //   canActivate: [AuthGuard, LockGuard, CheckRoleGuard],
  //   loadChildren: () => import('./land-shards/land-shards.module').then((m) => m.LandShardsModule),
  // },
  // {
  //   path: 'exchange',
  //   canActivate: [LockGuard, CheckRoleGuard],
  //   loadChildren: () => import('./exchange/exchange.module').then((m) => m.ExchangeModule),
  // },
  {
    path: 'logout',
    canActivate: [LogoutGuard, LockGuard],
    loadChildren: () => import('./marketplace/marketplace.module').then((m) => m.MarketplaceModule),
  },
  // {
  //   path: 'auction',
  //   canActivate: [LockGuard, CheckRoleGuard],
  //   loadChildren: () => import('./auction/auction.module').then((m) => m.AuctionModule),
  // },
  // {
  //   path: 'notifications',
  //   canActivate: [AuthGuard, LockGuard, CheckRoleGuard],
  //   loadChildren: () => import('./notification/notification.module').then((m) => m.NotificationModule),
  // },
  // {
  //   path: 'wtc',
  //   canActivate: [AuthGuard, LockGuard],
  //   loadChildren: () => import('./wtc/wtc.module').then((m) => m.WtcModule),
  // },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {
}
