import { Injectable } from '@angular/core';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { Content } from 'ngx-smart-modal/src/services/ngx-smart-modal.service';
import { INgxSmartModalOptions } from 'ngx-smart-modal/src/config/ngx-smart-modal.config';
import { NgxSmartModalComponent } from 'ngx-smart-modal/src/components/ngx-smart-modal.component';

@Injectable({
  providedIn: 'root',
})

export class ModalService {
  constructor(private ngxSmartModalService: NgxSmartModalService) {}

  public open(
    id: string,
    content: Content<any>,
    data: any = {},
    additionalOption?: INgxSmartModalOptions,
  ): void {
    this.ngxSmartModalService.closeAll();
    this.ngxSmartModalService.removeModal(id);

    const defaultOptions: INgxSmartModalOptions = {
      customClass: 'custom-modal',
      closable: true,
      refocus: false,
    };

    const options: INgxSmartModalOptions = Object.assign(defaultOptions, additionalOption);
    this.ngxSmartModalService.create(id, content, options).setData(data).open();
  }

  public getModalData(id: string): any {
    return this.ngxSmartModalService.getModalData(id);
  }

  public close(id: string): void {
    this.ngxSmartModalService.close(id);
  }

  public get(id: string): NgxSmartModalComponent {
    return this.ngxSmartModalService.get(id);
  }
}
