import {
  Component, EventEmitter, OnInit, Output,
} from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { HeaderWarningFacadeService } from '../../services/header-warning.facade.service';
import { NetworkInterface } from '../../../core/common/intefaces/network.interface';
import { UnsubscribeDestroyHelperComponent } from '../../../core/common/helpers/unsubscribe-destroy-helper.component';

@Component({
  selector: 'app-header-warning',
  templateUrl: './header-warning.component.html',
  styleUrls: ['./header-warning.component.scss'],
})
export class HeaderWarningComponent extends UnsubscribeDestroyHelperComponent implements OnInit {
  public requiredNetwork?: NetworkInterface;
  public currentWalletNetworkId: number | null = null;
  public loggedIn$: Observable<boolean>;

  @Output() setRequiredNetwork: EventEmitter<NetworkInterface> = new EventEmitter<NetworkInterface>();
  @Output() setCurrentWalletNetworkId: EventEmitter<number> = new EventEmitter<number>();

  constructor(
    private headerWarningFacade: HeaderWarningFacadeService,
  ) {
    super();

    this.loggedIn$ = this.headerWarningFacade.loggedIn$;
  }

  ngOnInit(): void {
    this.headerWarningFacade.loadRequiredNetwork();
    this.headerWarningFacade.loadWalletNetwork();

    this.subscribeRequiredNetwork();
    this.subscribeCurrentWalletNetworkId();
    this.subscribeNetworkIdChanges();
  }

  public get networkName(): string {
    if (!this.requiredNetwork?.network) {
      return '';
    }

    return this.requiredNetwork.network[0].toUpperCase() + this.requiredNetwork.network.substring(1);
  }

  private subscribeRequiredNetwork(): void {
    this.headerWarningFacade.getRequiredNetwork$()
      .pipe(takeUntil(this.unsubscribeSubject))
      .subscribe((network) => {
        this.requiredNetwork = network;
        this.setRequiredNetwork.emit(network);
      });
  }

  private subscribeCurrentWalletNetworkId(): void {
    this.headerWarningFacade.getWalletNetworkId$()
      .pipe(takeUntil(this.unsubscribeSubject))
      .subscribe((id) => {
        this.currentWalletNetworkId = id;
        this.setCurrentWalletNetworkId.emit(id as number);
      });
  }

  private subscribeNetworkIdChanges(): void {
    this.headerWarningFacade.walletNetworkChangedId$()
      .pipe(takeUntil(this.unsubscribeSubject))
      .subscribe((id) => {
        this.currentWalletNetworkId = id;
        this.setCurrentWalletNetworkId.emit(id);
      });
  }

  public switchNetwork(id: number): void {
    this.headerWarningFacade.switchNetwork$(id)
      .pipe(takeUntil(this.unsubscribeSubject))
      .subscribe();
  }
}
