import { Component } from '@angular/core';
import { TradeContext } from 'simple-uniswap-sdk';
import { ModalService } from '../../../shared/modules/modals/services/modal.service';

@Component({
  selector: 'app-exchange-pending',
  templateUrl: './exchange-pending.component.html',
  styleUrls: ['../../../styles/blocks/modals.scss', './exchange-pending.component.scss'],
})
export class ExchangePendingComponent {
  public static readonly modalIdentifier: string = 'exchangePendingComponent';

  public tradeContext: TradeContext;

  constructor(private modalService: ModalService) {
    this.tradeContext = modalService.getModalData(ExchangePendingComponent.modalIdentifier).tradeContext;
  }

  public close(): void {
    this.modalService.close(ExchangePendingComponent.modalIdentifier);
  }
}
