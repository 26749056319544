import { ChainId } from 'simple-uniswap-sdk/dist/esm/enums/chain-id';
import { UniswapError } from 'simple-uniswap-sdk/dist/esm/common/errors/uniswap-error';
import { ErrorCodes } from 'simple-uniswap-sdk/dist/esm/common/errors/error-codes';
import { TokenInterface } from '../../exchange/interfaces/token.interface';

const symbol = 'USDC';
const name = 'USDC';

export class USDC {
  static MAINNET() {
    return {
      chainId: ChainId.MAINNET,
      contractAddress: '0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48',
      decimals: 18,
      symbol,
      name,
    };
  }

  static ROPSTEN() {
    return {
      chainId: ChainId.ROPSTEN,
      contractAddress: '0x70cdfb73f78c51bf8a77b36c911d1f8c305d48e6',
      decimals: 18,
      symbol,
      name,
    };
  }

  static RINKEBY() {
    return {
      chainId: ChainId.RINKEBY,
      contractAddress: '0x4DBCdF9B62e891a7cec5A2568C3F4FAF9E8Abe2b',
      decimals: 18,
      symbol,
      name,
    };
  }

  static GÖRLI() {
    return {
      chainId: ChainId.GÖRLI,
      contractAddress: '0x4DBCdF9B62e891a7cec5A2568C3F4FAF9E8Abe2b',
      decimals: 18,
      symbol,
      name,
    };
  }

  static KOVAN() {
    return {
      chainId: ChainId.KOVAN,
      contractAddress: '0x4DBCdF9B62e891a7cec5A2568C3F4FAF9E8Abe2b',
      decimals: 18,
      symbol,
      name,
    };
  }

  static info(chainId: number): TokenInterface {
    switch (chainId) {
      case ChainId.MAINNET:
        return this.MAINNET();
      case ChainId.ROPSTEN:
        return this.ROPSTEN();
      case ChainId.RINKEBY:
        return this.RINKEBY();
      case ChainId.GÖRLI:
        return this.GÖRLI();
      case ChainId.KOVAN:
        return this.KOVAN();
      default:
        throw new UniswapError(`${chainId} is not allowed`, ErrorCodes.tokenChainIdContractDoesNotExist);
    }
  }
}
