<form
  class="modal"
  (submit)="submit()"
  [formGroup]="form"
  *ngIf="land$ | async as land"
>
  <div class="modal__title">
    {{ 'pages.modals.make' | translate }}
  </div>

  <div class="modal__subtitle">
    {{ 'pages.modals.you_placing_bid_for_nft' | translate }}
  </div>

  <div *ngIf="(currency$| async) === currenciesNames.USDC" class="warning__subtitle-payments">
    <div class="subtitle-payments__icon">
      <img src="./assets/icons/info-full-blue.svg" alt="payment icon">
    </div>
    <div class="subtitle-payments__info">{{ 'pages.modals.all_payments_are_available_only_in_wtc' | translate }}</div>
    <a class="subtitle-payments__link">{{ 'pages.landing.more' | translate }}</a>
  </div>

  <ul class="modal__total-info total-info modal__total-info--big-margin">
    <li class="total-info__row total-info__row--secondary-extra-light">
      <div class="total-info__label">{{ 'pages.modals.highest_bid' | translate }}</div>

      <div class="total-info__value"><strong>{{ data.auction?.higherBid }}</strong> {{ land.productDetails.currency }}</div>
    </li>
  </ul>

  <div class="modal__input">
    <app-field-input-amount
      [label]="'pages.modals.enter_your_bid' | translate"
      [placeholder]="'0'"
      [formControlName]="'bidAmount'"
      [available]="balance | number : '1.1-4' || ''"
      [availableCurrency]="land.productDetails.currency"
      [unit]="land.productDetails.currency"
      [minValue]="'0'"
      class="input-amount"
      appErrorHandle
      white="white"
    >
    </app-field-input-amount>
  </div>

  <ul class="modal__total-info total-info">
    <li class="total-info__row total-info__row--line-divider"></li>

    <li class="total-info__row total-info__row--main">
      <div class="total-info__label">{{ 'pages.modals.total_bid' | translate }}:</div>

      <div class="total-info__value"><strong>{{ this.data.totalAmount }}</strong> {{ land.productDetails.currency }}</div>
    </li>
  </ul>

  <div *ngIf="balance < data.totalAmount" class="modal__warning-window">
    <div class="warning-window__info">
      <div class="warning-window__icon"><img src="./../../../assets/icons/warning-triangle.svg" alt="warning"></div>
      <div class="warning-window__description">{{'pages.modals.do_not_have_balance' | translate : { currency: land.productDetails.currency} }}
        <a>{{'pages.modals.buy_more' | translate}}</a>
      </div>
    </div>
  </div>

  <div class="modal__buttons">
    <app-button type="submit"
                primary="primary"
                class="modal__button"
                [disabled]="!form.valid || data.totalAmount < form.value.bidAmount || form.value.bidAmount <= data.auction?.higherBid || balance < data.totalAmount">
      {{ 'pages.modals.continue' | translate }}
    </app-button>

    <app-button type="button"
                (btnClick)="close()"
                secondary="secondary"
                class="modal__button">
        {{ 'pages.modals.cancel' | translate | titlecase }}
      </app-button>
  </div>
</form>

