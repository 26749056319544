<div class="header-search">
<!--  <div class="header-search__icon"></div>-->
  <ng-select
    #headerSearchInput
    class="header-search__input ng-select-header-search"
    [loading]="loading$ | async"
    [items]="result$ | async"
    [typeahead]="searchInput$"
    [bindLabel]="'name'"
    [bindValue]="'id'"
    [groupBy]="'location'"
    [placeholder]="'common.header.search' | translate"
    [clearable]="false"
    [searchable]="true"
    [hideSelected]="true"
    [notFoundText]="'common.header.no_result' | translate"
    [loadingText]="'common.header.loading' | translate"
    [typeToSearchText]="'common.header.type_to_search' | translate"
    (change)="navigateToSelectedLand($event)"
  >
  </ng-select>
</div>
