import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { STAKE_POOLS_ICON_COLORS } from '../../../core/common/constants/stake-pool';

@Component({
  selector: 'app-stake-name',
  templateUrl: './stake-name.component.html',
  styleUrls: ['./stake-name.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StakeNameComponent {
  @Input() bgColor: string = STAKE_POOLS_ICON_COLORS[0];
  @Input() icon: string = 'assets/icons/stake-pool.svg';
  @Input() name: string = '';
}
