import {
  Component, Inject, Renderer2,
} from '@angular/core';
import { Store } from '@ngrx/store';
import { DOCUMENT } from '@angular/common';
import { environment } from '../environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  private title = 'land-x';

  constructor(
    private store: Store,
    private renderer2: Renderer2,
    @Inject(DOCUMENT) private document: Document,
  ) {
    this.loadScript();
  }

  private loadScript(): void {
    const script = this.renderer2.createElement('script');
    script.src = `https://maps.googleapis.com/maps/api/js?key=${environment.googleApiKey}`;
    this.renderer2.appendChild(this.document.body, script);
  }
}
