<app-header *ngIf="(hideHeader$ | async) !== true"
            [showAdminHeader]="showAdminHeader$ | async"
            [hideHeaderSearchBar]="hideHeaderSearchBar$ | async">
</app-header>

<!--<app-header-warning *ngIf="(loggedAdminOrVerifier$ | async) === false"-->
<!--                    (setRequiredNetwork)="setRequiredNetwork($event)"-->
<!--                    (setCurrentWalletNetworkId)="setCurrentWalletNetworkId($event)">-->
<!--</app-header-warning>-->

<div class="content"
     id="main-layout"
     [class.full-height]="(hideHeader$ | async)"
     [class.height-with-warning]="(loggedIn$ | async) && requiredNetwork && requiredNetwork.id !== currentWalletNetworkId">
  <ng-content></ng-content>
</div>

<!--<ng-container *ngIf="{-->
<!--  hideHeader: hideHeader$ | async,-->
<!--  showAdminHeader: showAdminHeader$ | async,-->
<!--  loggedIn: loggedIn$ | async,-->
<!--  hideHeaderSearchBar: hideHeaderSearchBar$ | async-->
<!--} as observables">-->
<!--  <app-header *ngIf="observables.hideHeader"-->
<!--              [showAdminHeader]="observables.showAdminHeader"-->
<!--              [hideHeaderSearchBar]="observables.hideHeaderSearchBar">-->
<!--  </app-header>-->

<!--  &lt;!&ndash;<app-header-warning *ngIf="(loggedAdminOrVerifier$ | async) === false"&ndash;&gt;-->
<!--  &lt;!&ndash;                    (setRequiredNetwork)="setRequiredNetwork($event)"&ndash;&gt;-->
<!--  &lt;!&ndash;                    (setCurrentWalletNetworkId)="setCurrentWalletNetworkId($event)">&ndash;&gt;-->
<!--  &lt;!&ndash;</app-header-warning>&ndash;&gt;-->

<!--  <div class="content"-->
<!--       id="main-layout"-->
<!--       [class.full-height]="observables.hideHeader"-->
<!--       [class.height-with-warning]="observables.loggedIn && requiredNetwork && requiredNetwork.id !== currentWalletNetworkId">-->
<!--    <ng-content></ng-content>-->
<!--  </div>-->
<!--</ng-container>-->

