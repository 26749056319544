import {
  Component, OnDestroy, OnInit, ViewChild,
} from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { NgxSmartModalComponent } from 'ngx-smart-modal/src/components/ngx-smart-modal.component';
import { ModalNotificationsService } from '../../services/modal-notifications.service';
import { ModalNotificationContextInterface } from '../../interfaces/modal-notofication.interfece';
import { ModalNotificationsTypes } from '../../constants/modal-notifications-types';

@Component({
  selector: 'app-modal-notifications',
  templateUrl: './modal-notifications.component.html',
  styleUrls: ['./modal-notifications.component.scss'],
})
export class ModalNotificationsComponent implements OnInit, OnDestroy {
  @ViewChild('modalNotification') modalNotification: NgxSmartModalComponent | undefined;

  public context: string = '';
  public type: ModalNotificationsTypes = ModalNotificationsTypes.Info;
  public modalNotificationsTypes = ModalNotificationsTypes;

  private onDone?: () => void;
  private unsubscribeSubject = new Subject();

  constructor(
    private modalNotificationsService: ModalNotificationsService,
  ) {
  }

  public ngOnInit() {
    this.subscribeToSuccess();
  }

  public ngOnDestroy() {
    this.unsubscribeSubject.next();
    this.unsubscribeSubject.complete();
  }

  private subscribeToSuccess() {
    this.modalNotificationsService.getShow$().pipe(
      takeUntil(this.unsubscribeSubject),
    ).subscribe(
      (context: ModalNotificationContextInterface) => {
        this.context = context.content;
        this.type = context.type;
        this.onDone = context.onDone;
        this.show();
      },
    );
  }

  private show() {
    this.modalNotification?.open();
  }

  public close() {
    if (this.onDone) {
      this.onDone();
    }

    this.modalNotification!.close();
  }
}
