import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderSideMenuComponent } from './container-components/side-menu/header-side-menu.component';
import { HeaderSettingsComponent } from './container-components/header-settigs/header-settings.component';
import {
  SideUserMenuContentComponent,
} from './container-components/side-menu-content/side-user-menu-content.component';
import {
  SideSettingMenuContentComponent,
} from './container-components/side-setting-menu-content/side-setting-menu-content.component';
import { HeaderSideMenuService } from './services/header-side-menu.service';
import { SharedModule } from '../../../shared/shared.module';

@NgModule({
  declarations: [
    HeaderSideMenuComponent,
    HeaderSettingsComponent,
    SideUserMenuContentComponent,
    SideSettingMenuContentComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
  ],
  exports: [
    SideUserMenuContentComponent,
    SideSettingMenuContentComponent,
    HeaderSideMenuComponent,
    HeaderSettingsComponent,
  ],
  providers: [
    HeaderSideMenuService,
  ],
})
export class HeaderSideMenuModule { }
