import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, first, switchMap } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { environment } from '../../../../environments/environment';
import { selectSettings } from '../store/selectors/settings.selectors';
import { SettingsModel } from '../../settings/models/settings.model';
import { SettingsDefaultValues } from '../../settings/constants/settings-default-values';

@Injectable()
export class LocaleInterceptor implements HttpInterceptor {
  constructor(
    private store: Store,
  ) {}

  private static isApiUrl(url: string): boolean {
    return url.startsWith(environment.apiEndpoint);
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (!LocaleInterceptor.isApiUrl(req.url)) {
      return next.handle(req);
    }

    return this.store.select(selectSettings).pipe(
      first(),
      switchMap((settings) => {
        let reqClone = req.clone();
        if (settings && (!req.headers.has('X-Currency')
          && !req.headers.has('X-Units')
          && !req.headers.has('X-Lang'))) {
          reqClone = LocaleInterceptor.addLocaleSettings(reqClone, settings);
        }
        return next.handle(reqClone);
      }),
      catchError((error) => throwError(error)),
    );
  }

  private static addLocaleSettings(request: HttpRequest<any>, settings: SettingsModel): HttpRequest<any> {
    return request.clone({
      headers: request.headers
        .set('X-Currency', SettingsDefaultValues.currency)
        .set('X-Units', settings.units || SettingsDefaultValues.units)
        .set('X-Lang', settings.lang || SettingsDefaultValues.lang),
    });
  }
}
