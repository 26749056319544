export const AreaUnits: { [key: string]: string } = {
  'kg/ha': 'ha',
  'bu/ac': 'ac',
};

export const WeightUnits: { [key: string]: string } = {
  'kg/ha': 'kg',
  'bu/ac': 'bu',
};

export const AreaUnitsNames: { [key: string]: string } = {
  'kg/ha': 'hectares',
  'bu/ac': 'acres',
};

export enum UnitsNames {
  KgHa = 'kg/ha',
  BuAc = 'bu/ac',
}
