import { take } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { Actions, ofType } from '@ngrx/effects';
import { AuthActionsTypes, StartAppInitializer } from '../store/actions/auth.actions';

export function initializeAppFactory(store: Store, actions$: Actions) {
  return () => new Promise((resolve) => {
    actions$.pipe(
      ofType(AuthActionsTypes.FinishAppInitializer),
      take(1),
    ).subscribe(() => {
      resolve(true);
    });
    store.dispatch(StartAppInitializer());
  });
}
