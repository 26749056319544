import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'currencyFromCulture',
})
export class CurrencyFromCulturePipe implements PipeTransform {
  private currencyFromCulture: { [key: string]: string } = {
    soy_bean: 'ldxs',
    maize: 'ldxm',
    rice: 'ldxr',
    wheat: 'ldxw',
  };

  transform(value: string): string {
    return this.currencyFromCulture[value];
  }
}
