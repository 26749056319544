import { createAction, props } from '@ngrx/store';
import { UserInterface } from '../../../user/interfaces/user.interface';
import { ProfileImageInterface } from '../../../user/interfaces/profile-image.interface';

export enum UserActionsTypes {
  LoadUser = '[User] Load',
  LoadUserSuccess = '[User] Load Success',
  LoadUserFailure = '[User] Load Failure',

  LoadWallpaper = '[User] Load Wallpaper',
  LoadWallpaperSuccess = '[User] Load Wallpaper Success',
  LoadWallpaperFailure = '[User] Load Wallpaper Failure',

  UploadUserImage = '[User] Upload User Image',
  UploadUserImageSuccess = '[User] Upload User Image Success',
  UploadUserImageFailure = '[User] Upload User Image Failure',

  UploadWallpaper = '[User] Upload Wallpaper',
  UploadWallpaperSuccess = '[User] Upload Wallpaper Success',
  UploadWallpaperFailure = '[User] Upload Wallpaper Failure',

  ClearUser = '[User] Clear',
}

export const LoadUser = createAction(
  UserActionsTypes.LoadUser,
);

export const LoadUserSuccess = createAction(
  UserActionsTypes.LoadUserSuccess,
  props<{ user: UserInterface }>(),
);

export const LoadUserFailure = createAction(
  UserActionsTypes.LoadUserFailure,
);

export const LoadWallpaper = createAction(
  UserActionsTypes.LoadWallpaper,
);

export const LoadWallpaperSuccess = createAction(
  UserActionsTypes.LoadWallpaperSuccess,
  props<{ wallpaperUrl: string }>(),
);

export const LoadWallpaperFailure = createAction(
  UserActionsTypes.LoadWallpaperFailure,
);

export const UploadUserImage = createAction(
  UserActionsTypes.UploadUserImage,
  props<{ file: File }>(),
);

export const UploadUserImageSuccess = createAction(
  UserActionsTypes.UploadUserImageSuccess,
  props<{ profileImage: ProfileImageInterface }>(),
);

export const UploadUserImageFailure = createAction(
  UserActionsTypes.UploadUserImageFailure,
);

export const UploadWallpaper = createAction(
  UserActionsTypes.UploadWallpaper,
  props<{ file: File }>(),
);

export const UploadWallpaperSuccess = createAction(
  UserActionsTypes.UploadWallpaperSuccess,
  props<{ wallpaper: ProfileImageInterface }>(),
);

export const UploadWallpaperFailure = createAction(
  UserActionsTypes.UploadWallpaperFailure,
);

export const ClearUser = createAction(
  UserActionsTypes.ClearUser,
);
