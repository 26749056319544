<div class="modal">
  <div class="modal__title modal__title--confirm">
    {{ data.title | translate }}
  </div>
  <div class="modal__subtitle">
    {{ data.subtitle | translate :{ param: data.subtitleParam || '' } }}
  </div>
  <div *ngIf="data.note" class="modal__subtitle">
    {{ data.note | translate }}
  </div>
  <div class="modal__buttons">
    <app-button primary
                class="modal__button"
                (btnClick)="data.confirmAction()">
      {{ data.confirmBtnText | translate }}
    </app-button>
    <app-button secondary
                class="modal__button"
                (btnClick)="close()">
      {{ 'pages.modals.cancel' | translate }}
    </app-button>
  </div>
</div>
