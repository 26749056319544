import { Component } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import {
  combineLatest, Observable, of, Subject, throwError,
} from 'rxjs';
import {
  catchError, switchMap, takeUntil,
} from 'rxjs/operators';
import { LandInterface } from '../../../lends/interfaces/land.interface';
import { PRICE_FORMAT } from '../../../../../core/common/constants/common-settings-values';
import { ModalService } from '../../services/modal.service';
import { BuyLandDataInterface } from '../../interfaces/buy-land-data.interface';
import { CheckoutSuccessModalComponent } from '../checkout-success-modal/checkout-success-modal.component';
import { CheckoutModalDataInterface } from '../../interfaces/checkout-modal-data.interface';
import {
  UnsubscribeDestroyHelperComponent,
} from '../../../../../core/common/helpers/unsubscribe-destroy-helper.component';
import { BuyNowFacadeService } from '../../../../../auction/services/buy-now.facade.service';
import { EthAccountInterface } from '../../../../../core/common/intefaces/eth-account.interface';
import { CheckoutFailedModalComponent } from '../checkout-failed-modal/checkout-failed-modal.component';
import { PendingModalComponent } from '../pending-modal/pending-modal.component';
import { CurrenciesNames } from '../../../../../core/settings/constants/currencies-names';
import { AreaUnits } from '../../../../../core/settings/constants/units-names';

@Component({
  selector: 'app-buy-modal',
  templateUrl: './buy-modal.component.html',
  styleUrls: ['../../../../../styles/blocks/modals.scss'],
})
export class BuyModalComponent extends UnsubscribeDestroyHelperComponent {
  public static readonly modalIdentifier: string = 'buyCheckoutModal';
  public form: FormGroup = new FormGroup({});
  public land$: Observable<LandInterface | null>;
  public currency$: Observable<string>;
  public units$: Observable<string>;
  public amountFormat = PRICE_FORMAT;
  public balance?: number;
  public areaUnits = AreaUnits;
  public data: BuyLandDataInterface = {
    feeAmount: 0,
    fee: 0,
    totalAmount: 0,
    nftPrice: 0,
    outLand: false,
    outLandAmount: 0,
  };
  private landTokenId?: number;
  public currenciesNames = CurrenciesNames;

  constructor(
    public modalService: ModalService,
    private formBuilder: FormBuilder,
    private buyNowFacadeService: BuyNowFacadeService,
  ) {
    super();
    this.land$ = this.modalService.getModalData(BuyModalComponent.modalIdentifier).land$;
    this.currency$ = this.buyNowFacadeService.getCurrency$();
    this.units$ = this.buyNowFacadeService.getUnits$;
    this.initForm();
    this.subscribeToEthAccountChanged();
    this.subscribeToEthAccount();
  }

  public submit(): void {
    const unsubscribeSubject = new Subject();
    this.data.outLand = this.form.value.outLand;
    this.modalService.close(BuyModalComponent.modalIdentifier);
    this.modalService.open(PendingModalComponent.modalIdentifier, PendingModalComponent, {
      unsubscribeSubject,
    });

    this.land$.pipe(
      takeUntil(unsubscribeSubject),
      switchMap((land) => combineLatest(
        of(land),
        this.buyNowFacadeService.loadMinSellAmount(land!.token.id),
      )),
      switchMap(([land, minAmount]) => combineLatest(
        of(land),
        land!.token.collectRent
          ? of(null)
          : this.buyNowFacadeService.wtcRentFoundationAllowance(this.data.ethAccount!.address!).pipe(
            switchMap((allowance) => (allowance < minAmount
              ? this.buyNowFacadeService.wtcApproveRentFoundation(
                this.data.ethAccount!.address!,
                minAmount,
              )
              : of(null))),
          ),
      )),
      switchMap(([land]) => this.buyNowFacadeService.approveIfNotEnough(
        this.data.ethAccount!.address!,
        this.data.tokenAddress!,
        land!.productDetails.price,
      )),
      switchMap(() => this.buyNowFacadeService.buyItem(
        this.data.ethAccount!.address!,
        this.data.saleId!,
      )),
      catchError((error) => {
        this.modalService.close(PendingModalComponent.modalIdentifier);
        this.modalService.open(
          CheckoutFailedModalComponent.modalIdentifier,
          CheckoutFailedModalComponent,
          {},
        );
        return throwError(error);
      }),
    ).subscribe(() => {
      this.modalService.close(PendingModalComponent.modalIdentifier);
      const data: CheckoutModalDataInterface = {
        ...this.data,
        successSubTitle: 'successfully_ntf',
        totalLabel: 'total_amount',
        priceLabel: 'nft_price',
      };

      this.modalService.open(
        CheckoutSuccessModalComponent.modalIdentifier,
        CheckoutSuccessModalComponent,
        { land$: this.land$, data },
      );
    });
  }

  public close(): void {
    this.modalService.close(BuyModalComponent.modalIdentifier);
  }

  private initForm(): void {
    this.form = this.formBuilder.group({
      outLand: false,
    });
  }

  private subscribeToEthAccount(): void {
    this.buyNowFacadeService.account().pipe(
      takeUntil(this.unsubscribeSubject),
      switchMap((account) => combineLatest(
        of(account),
        this.land$,
      )),
      switchMap(([account, land]) => combineLatest(
        of(account),
        of(land),
        this.buyNowFacadeService.getNetwork(),
      )),
      switchMap(([account, land, network]) => combineLatest(
        of(account),
        of(land),
        of(network),
        this.buyNowFacadeService.getContractAddress(network.id, land!.productDetails.currency!),
      )),
      switchMap(([account, land, network, tokenAddress]) => combineLatest(
        of(account),
        of(land),
        of(tokenAddress),
        this.buyNowFacadeService.getBalance(
          network.id,
          account!.address,
          tokenAddress!,
        ),
      )),
    ).subscribe(([account, land, tokenAddress, balance]) => {
      if (land?.token.id) {
        this.landTokenId = land?.token.id;
      }
      if (!account) {
        this.buyNowFacadeService.openWallet();
        return;
      }
      this.balance = parseFloat(balance);
      this.data.ethAccount = account;
      this.data.nftPrice = land?.nftPriceHa || land?.productDetails.price || 0;
      this.data.saleId = land?.productDetails.saleId;
      this.data.totalAmount = land?.productDetails.price;
      this.data.outLand = land?.landOptions.outLand;
      this.data.tokenAddress = tokenAddress;
    });
  }

  private subscribeToEthAccountChanged(): void {
    this.buyNowFacadeService.accountChanged().pipe(
      takeUntil(this.unsubscribeSubject),
    ).subscribe((address) => {
      this.data.ethAccount = {
        address,
      } as EthAccountInterface;
    });
  }

  public getTotalAmount() {
    return this.data.totalAmount;
  }
}
