import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { ReadShardService } from './read-shard.service';
import { WriteShardService } from './write-shard.service';
import { MetamaskService } from '../../core/auth/services/metamask.service';
import { EthAccountInterface } from '../../core/common/intefaces/eth-account.interface';
import { PreviewInterface } from '../interfaces/preview.interface';
import { WriteNftService } from '../../landx-nft/services/write-nft.service';
import { ContractService } from '../../core/common/services/contract.service';
import { ReadNftService } from '../../landx-nft/services/read-nft.service';
import { selectShardsAddressesWithCultureNames } from '../../core/common/store/selectors/network.selector';

@Injectable()
export class NftToShardFacadeService {
  constructor(
    private contractService: ContractService,
    private metamaskService: MetamaskService,
    private readShardService: ReadShardService,
    private writeShardService: WriteShardService,
    private readNftService: ReadNftService,
    private writeNftService: WriteNftService,
    private store: Store,
  ) {}

  public account(): Observable<EthAccountInterface|undefined> {
    return this.metamaskService.getAccounts().pipe(
      map((accounts) => (accounts ? accounts[0] : undefined)),
    );
  }

  public preview(id: number, shardType: string): Observable<PreviewInterface> {
    return this.readShardService.preview(id, shardType);
  }

  public isShardContractApprovedToUseNft(from: string, shardType: string): Observable<boolean> {
    return this.store.select(selectShardsAddressesWithCultureNames).pipe(
      switchMap((
        contracts: { [key: string]: string },
      ) => this.readNftService.isApprovedForAll(from, contracts[shardType])),
    );
  }

  public increaseAllowance(from: string, addedValue: number): Observable<string> {
    return this.writeShardService.increaseAllowance(from, addedValue);
  }

  public getShards(from: string, id: number, shardType: string): Observable<string> {
    return this.writeShardService.getShards(from, id, shardType);
  }

  public approveShardContractToUseNft(from: string, shardType: string): Observable<string> {
    return this.store.select(selectShardsAddressesWithCultureNames).pipe(
      switchMap((
        contracts: { [key: string]: string },
      ) => this.writeNftService.setApprovalForAll(from, contracts[shardType])),
    );
  }
}
