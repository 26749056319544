import { createSelector, State } from '@ngrx/store';
import { NetworkState } from '../states/network.state';

// @ts-ignore
const selectNetworkState = (state: State) => state.network;

export const selectNftContractAddress = createSelector(
  selectNetworkState,
  (state: NetworkState) => state.nftContractAddress,
);

export const selectAuctionContractAddress = createSelector(
  selectNetworkState,
  (state: NetworkState) => state.auctionContractAddress,
);

export const selectShardsAddressesWithCultureNames = createSelector(
  selectNetworkState,
  (state: NetworkState) => ({
    soy_bean: state.ldxsShardManagerContractAddress,
    maize: state.ldxmShardManagerContractAddress,
    rice: state.ldxrShardManagerContractAddress,
    wheat: state.ldxwShardManagerContractAddress,
  }),
);

export const selectShardsAddresses = createSelector(
  selectNetworkState,
  (state: NetworkState) => ({
    LDXS: state.ldxsShardManagerContractAddress,
    LDXM: state.ldxmShardManagerContractAddress,
    LDXR: state.ldxrShardManagerContractAddress,
    LDXW: state.ldxwShardManagerContractAddress,
  }),
);
