import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  catchError, map, merge, startWith, switchMap, takeUntil,
} from 'rxjs/operators';
import { interval, of } from 'rxjs';
import { UserApiService } from '../../../user/services/user.api.service';
import { UnreadMessageActionsTypes } from '../actions/unread-message.actions';
import { AuthActionsTypes } from '../actions/auth.actions';

@Injectable()
export class UnreadMessageEffects {
  LoadUnreadMessageCount$ = createEffect(() => this.actions.pipe(
    ofType(UnreadMessageActionsTypes.LoadUnreadMessageCount),
    switchMap(() => interval(30000).pipe(
      startWith(0),
      takeUntil(
        this.actions.pipe(ofType(AuthActionsTypes.LogOut))
          .pipe(merge(this.actions.pipe(ofType(AuthActionsTypes.Login)))),
      ),
    )),
    switchMap(() => this.userApiService.getUnreadMessageCount().pipe(
      catchError(() => of({ data: null })),
    )),
    map(({ data }) => {
      if (data) {
        return {
          type: UnreadMessageActionsTypes.LoadUnreadMessageCountSuccess,
          newNotificationsCount: data.newCount,
          unreadCount: data.unreadCount,
        };
      }
      return { type: UnreadMessageActionsTypes.LoadUnreadMessageCountFailure };
    }),
  ));

  constructor(
    private actions: Actions,
    private userApiService: UserApiService,
  ) {}
}
