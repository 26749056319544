import { Action, createReducer, on } from '@ngrx/store';
import { AuthState, initialAuthState } from '../states/auth.state';
import * as AuthActions from '../actions/auth.actions';

const authReducer = createReducer(
  initialAuthState,
  on(AuthActions.Login, (state): AuthState => ({
    ...state,
    loading: true,
  })),
  on(AuthActions.LoginWithWallet, (state): AuthState => ({
    ...state,
    loading: true,
  })),
  on(AuthActions.LoginSuccess, (state, { token, role }): AuthState => ({
    ...state,
    loggedIn: true,
    loading: false,
    token,
    role,
  })),
  on(AuthActions.LoginFailure, (state): AuthState => ({
    ...state,
    loading: false,
  })),
  on(AuthActions.LogOut, (state): AuthState => ({
    ...state,
    loggedIn: false,
    token: null,
    role: null,
  })),
  on(AuthActions.StartAppInitializer, (state): AuthState => ({
    ...state,
    loading: true,
  })),
  on(AuthActions.FinishAppInitializer, (state): AuthState => ({
    ...state,
    initialized: true,
    loading: false,
  })),
);

export function reducer(state: AuthState | undefined, action: Action) {
  return authReducer(state, action);
}
