import {
  RouteQuote,
  Token,
  TradeContext,
  TradeContext as UniswapTradeContext,
  TradeDirection,
  Transaction,
  UniswapVersion,
} from 'simple-uniswap-sdk';
import { Observable, of } from 'rxjs';

export class NoLiquidityTradeContext implements UniswapTradeContext {
  allTriedRoutesQuotes: RouteQuote[];
  baseConvertRequest: string;
  expectedConvertQuote: string;
  fromBalance: { hasEnough: boolean; balance: string };
  fromToken: Token;
  gasPriceEstimatedBy: string | undefined;
  hasEnoughAllowance: boolean;
  liquidityProviderFee: string;
  liquidityProviderFeePercent: number;
  maximumSent: string | null;
  minAmountConvertQuote: string | null;
  quoteChanged$: Observable<TradeContext>;
  quoteDirection: TradeDirection;
  routePath: string[];
  routePathTokenMap: Token[];
  routeText: string;
  toBalance: string;
  toToken: Token;
  tradeExpires: number;
  transaction: Transaction;
  uniswapVersion: UniswapVersion;
  isNoLiquidityTrade: boolean;

  public constructor(balance: string) {
    this.allTriedRoutesQuotes = [];
    this.baseConvertRequest = '0';
    this.expectedConvertQuote = '0';
    this.fromBalance = {
      balance,
      hasEnough: true,
    };
    this.fromToken = {} as Token;
    this.hasEnoughAllowance = true;
    this.liquidityProviderFee = '0';
    this.liquidityProviderFeePercent = 0;
    this.maximumSent = '0';
    this.minAmountConvertQuote = '0';
    this.quoteChanged$ = of(this);
    this.quoteDirection = TradeDirection.input;
    this.routePath = [];
    this.routePathTokenMap = [];
    this.routeText = '<span class="error-word">No liquidity found</span>';
    this.toBalance = '0';
    this.toToken = {} as Token;
    this.tradeExpires = 0;
    this.transaction = {} as Transaction;
    this.uniswapVersion = UniswapVersion.v3;
    this.isNoLiquidityTrade = true;
  }

  public destroy(): void {
  }
}
