<div class="modal">
  <div class="modal__title modal__title--confirm">
    {{ data.title | translate }}
  </div>

  <app-field-select
    class="modal__select"
    #country
    [label]="data.selectData.label | translate"
    [bindLabel]="data.selectData.bindLabel"
    [bindValue]="data.selectData.bindValue"
    [items]="data.selectData.items"
    [searchable]="data.selectData.searchable"
    [clearable]="data.selectData.clearable"
  >
  </app-field-select>

  <div class="modal__buttons">
    <app-button class="modal__button"
                primary
                [disabled]="!country.value"
                (btnClick)="data.confirmAction(country.value)">
      {{ data.btnText | translate }}
    </app-button>
  </div>
</div>
