import { Injectable } from '@angular/core';
import { map, tap } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { BehaviorSubject, Observable } from 'rxjs';
import { CallerService } from '../../core/common/services/caller.service';
import { SettingsModel } from '../../core/settings/models/settings.model';
import { SaveSettings } from '../../core/common/store/actions/settings.actions';
import { selectSettings, selectSettingsLoading } from '../../core/common/store/selectors/settings.selectors';
import { UserInterface } from '../../core/user/interfaces/user.interface';
import { selectUser } from '../../core/common/store/selectors/user.selector';
import { LandService } from '../../core/land/services/land.service';
import {
  selectAuthLoggedIn,
  selectRoleName,
} from '../../core/common/store/selectors/autth.selectors';
import { RolesNames } from '../../core/common/constants/roles-names';
import { selectNewNotificationsCount } from '../../core/common/store/selectors/unread-message.selector';
import {
  selectProcessingTransactionTotalCount,
} from '../../core/common/store/selectors/processing-transaction.selectors';
import { WalletInfoFacadeService } from './wallet-info.facade.service';
import { TradingHistoryInterface } from '../../land-details/interfaces/trading-history.interface';

@Injectable()
export class HeaderFacadeService {
  private userSearchLoading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(
    private walletInfoFacade: WalletInfoFacadeService,
    private landService: LandService,
    private callerService: CallerService,
    private store: Store,
  ) {}

  public getProcessingTransactions$(): Observable<TradingHistoryInterface[]> {
    return this.walletInfoFacade.getProcessingTransactions$();
  }

  public get loggedIn$(): Observable<boolean> {
    return this.store.select(selectAuthLoggedIn);
  }

  public totalCountProcessingTransaction(): Observable<number> {
    return this.store.select(selectProcessingTransactionTotalCount);
  }

  public get loggedAdminOrVerifier$(): Observable<boolean> {
    return this.store.select(selectRoleName).pipe(
      map((roleName) => roleName === RolesNames.Verifier || roleName === RolesNames.Admin),
    );
  }

  public get loggedVerifier$(): Observable<boolean> {
    return this.store.select(selectRoleName).pipe(
      map((roleName) => roleName === RolesNames.Verifier),
    );
  }

  public get loggedAdmin$(): Observable<boolean> {
    return this.store.select(selectRoleName).pipe(
      map((roleName) => roleName === RolesNames.Admin),
    );
  }

  public setUserSettings(userSettings: SettingsModel): void {
    this.store.dispatch(SaveSettings({ settings: userSettings }));
  }

  public getUserSettings(): Observable<SettingsModel> {
    return this.store.select(selectSettings);
  }

  public getUserSettingsLoading() {
    return this.store.select(selectSettingsLoading);
  }

  public loadSearchResults(query: string): Observable<any> {
    this.userSearchLoading$.next(true);
    const filtersNftOnly = {
      query,
      tokenStatus: 'onSale,new,minted',
    };
    return this.callerService.call(
      this.landService.lands(filtersNftOnly).pipe(
        tap(
          () => {
            this.userSearchLoading$.next(false);
          },
          () => {
            this.userSearchLoading$.next(false);
          },
        ),
        map(({ data }) => data),
      ),
      null,
      null,
      false,
    );
  }

  public getSearchLoading() {
    return this.userSearchLoading$.asObservable();
  }

  public getUser$(): Observable<UserInterface | undefined> {
    return this.store.select(selectUser);
  }

  public getNewNotificationsCount$(): Observable<number> {
    return this.store.select(selectNewNotificationsCount);
  }
}
