import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { ReactiveFormsModule } from '@angular/forms';
import { NgxSpinnerModule } from 'ngx-spinner';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TranslateModule } from '@ngx-translate/core';
import { NgxSmartModalModule } from 'ngx-smart-modal';
import { HttpClientModule } from '@angular/common/http';
import { TooltipModule, TooltipOptions } from 'ng2-tooltip-directive';
import { NotifierModule } from 'angular-notifier';
import { NgCircleProgressModule } from 'ng-circle-progress';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CoreModule } from './core/core.module';
import { TranslateOptions } from './core/common/constants/translate-options';
import { NotificationOptions } from './core/common/constants/notification-options';
import { ModalNotificationsModule } from './shared/modules/modal-notifications/modal-notifications.module';
import { ExchangeModule } from './exchange/exchange.module';

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    ReactiveFormsModule,
    CoreModule,
    NgxSpinnerModule,
    HttpClientModule,
    TranslateModule.forRoot(TranslateOptions),
    NotifierModule.withConfig(NotificationOptions),
    NgxSmartModalModule.forRoot(),
    TooltipModule.forRoot({
      'tooltip-class': 'app-tooltip',
    } as TooltipOptions),
    ModalNotificationsModule,
    ExchangeModule,
    NgCircleProgressModule.forRoot(),
  ],
  providers: [],
  bootstrap: [
    AppComponent,
  ],
})
export class AppModule { }
