export interface NetworkState {
  nftContractAddress: string;
  auctionContractAddress: string;
  shardManagerContractAddress: string;
  wtcContractAddress: string;
  usdcContractAddress: string;
  ldxmShardManagerContractAddress: string;
  ldxrShardManagerContractAddress: string;
  ldxsShardManagerContractAddress: string;
  ldxwShardManagerContractAddress: string;
  loading: boolean;
}

export const initialNetworkState: NetworkState = {
  nftContractAddress: '',
  auctionContractAddress: '',
  shardManagerContractAddress: '',
  wtcContractAddress: '',
  usdcContractAddress: '',
  ldxmShardManagerContractAddress: '',
  ldxrShardManagerContractAddress: '',
  ldxsShardManagerContractAddress: '',
  ldxwShardManagerContractAddress: '',
  loading: false,
};
