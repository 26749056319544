<div class="container" [class.inline]="isInline">
  <div class="icon-wrapper" [class.icon-circle]="isCircle">
    <img class="icon"
         *ngIf="currency"
         [width]="iconWidth"
         [height]="iconHeight"
         [src]="iconUrl || 'assets/icons/currencies/' + currency + '.svg'" [attr.alt]="currency">
  </div>
  <span class="value">{{ showConverted ? convertedAmount : amount }}</span>
  <span class="currency" *ngIf="showCurrencyName">{{ currency }}</span>
</div>
