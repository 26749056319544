import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { IHttpResponse } from '../../common/intefaces/http-response.interface';
import { UserInterface } from '../interfaces/user.interface';
import { USER_API } from '../api/user.api';
import { ProfileImageInterface } from '../interfaces/profile-image.interface';

@Injectable({
  providedIn: 'root',
})
export class UserApiService {
  constructor(
    private httpClient: HttpClient,
  ) {
  }

  public getUser(): Observable<IHttpResponse<UserInterface>> {
    return this.httpClient.get<IHttpResponse<UserInterface>>(
      USER_API.profile,
    );
  }

  public getWallpaper(url: string): Observable<Blob> {
    return this.httpClient.get(
      url,
      { responseType: 'blob' },
    );
  }

  public uploadUserImage(body: FormData): Observable<IHttpResponse<ProfileImageInterface>> {
    return this.httpClient.post<IHttpResponse<ProfileImageInterface>>(
      USER_API.profileImage,
      body,
    );
  }

  public uploadUserWallpaper(body: FormData): Observable<IHttpResponse<ProfileImageInterface>> {
    return this.httpClient.post<IHttpResponse<ProfileImageInterface>>(
      USER_API.wallpaper,
      body,
    );
  }

  public getUnreadMessageCount(): Observable<IHttpResponse<{ newCount: number, unreadCount: number }>> {
    return this.httpClient.get<IHttpResponse<{ newCount: number, unreadCount: number }>>(
      USER_API.newNotificationsCount,
    );
  }
}
