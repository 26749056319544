<div
  class="input-filed"
  (click)="wrapperFocus()"
  [class.focused]="inputFocused || selectFocused"
>
  <div class="input-filed__input-wrapper">
    <div class="input-filed__label" *ngIf="label">
      {{label}}
    </div>

    <div class="input-filed__select-wrapper">
      <ng-select
        class="input-filed__select"
        #select
        (focus)="selectFocus()"
        (blur)="setTouched(); selectBlur()"
        [(ngModel)]="selectValue"
        (ngModelChange)="setValue(); emitSelectChange()"
        [items]="currencies"
        [clearable]="false"
        [bindValue]="'value'"
        [bindLabel]="'label'"
        [searchable]="false"
        [disabled]="selectDisabled"
      >
        <ng-template ng-label-tmp let-item="item">
          <app-amount
            class="input-filed__select-item"
            [currency]="item.label"
            [iconUrl]="item.data?.iconUrl"
          >
          </app-amount>
        </ng-template>

        <ng-template ng-option-tmp let-item="item">
          <app-amount
            [currency]="item.label"
            [iconUrl]="item.data?.iconUrl"
          >
          </app-amount>
        </ng-template>
      </ng-select>

      <input
        class="input-filed__input"
        #input
        (focus)="inputFocus()"
        (blur)="setTouched(); inputBlur()"
        [(ngModel)]="inputValue"
        (ngModelChange)="setValue()"
        [attr.name]="formControlName"
        [disabled]="disabled"
        [placeholder]="placeholder"
        type="number"
      >
    </div>

    <div class="input-filed__balance" *ngIf="balance">
      {{'common.fields.balance' | translate}}: {{balance}} {{balanceCurrency}}
    </div>
  </div>
</div>
