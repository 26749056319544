<app-success-modal-wrapper
  [subTitle]="'pages.modals.you_successfully_bought_out_nft' | translate"
  (closeModal)="close()"
>
  <ul class="modal__total-info modal__total-info--big-margin total-info">
    <li class="total-info__row">
      <span class="total-info__label">{{ 'pages.modals.total_amount' | translate }}:</span>
      <span class="total-info__value">
        {{ land.token.shards | number : '1.0-16' }} {{land.productDetails.cropShareType | currencyFromCulture | uppercase}}
      </span>
    </li>
  </ul>
</app-success-modal-wrapper>
