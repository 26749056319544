import { Component } from '@angular/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { ModalService } from '../../../shared/modules/modals/services/modal.service';
import { WalletInfoFacadeService } from '../../services/wallet-info.facade.service';
import { UserInterface } from '../../../core/user/interfaces/user.interface';
import { AuthService } from '../../../core/auth/services/auth.service';
import { NotificationsServiceWithTranslate } from '../../../core/common/services/notificationsServiceWithTranslate';
import { TradingHistoryInterface } from '../../../land-details/interfaces/trading-history.interface';

@Component({
  selector: 'app-wallet-info-popup',
  templateUrl: './wallet-info-popup.component.html',
  styleUrls: ['./wallet-info-popup.component.scss'],
})
export class WalletInfoPopupComponent {
  public static readonly modalIdentifier: string = 'connectWalletPopupComponent';
  public user$: Observable<UserInterface | undefined>;
  public walletAddressWrong$: Observable<boolean>;
  public processingTransaction$: Observable<TradingHistoryInterface[]>;
  public walletAddress: string = '';

  constructor(
    private modalService: ModalService,
    private walletInfoFacade: WalletInfoFacadeService,
    private authService: AuthService,
    private notificationService: NotificationsServiceWithTranslate,
  ) {
    this.user$ = walletInfoFacade.getUser$().pipe(
      tap((user) => {
        this.walletAddress = user?.metamaskEthAddress ?? '';
      }),
    );
    this.walletAddressWrong$ = authService.walletAddressWrong$();
    this.processingTransaction$ = this.walletInfoFacade.getProcessingTransactions$();
  }

  public connectWallet(): void {
    this.modalService.close(WalletInfoPopupComponent.modalIdentifier);
    this.authService.connectWallet();
  }

  public async copyAddress() {
    await navigator.clipboard.writeText(this.walletAddress);
    this.notificationService.success({
      content: 'common.notifications.copied',
    });
  }

  public exploreAddress(): void {
    window.open(`https://rinkeby.etherscan.io/address/${this.walletAddress}`);
  }

  public openNewWindow(url: string): void {
    window.open(url);
  }
}
