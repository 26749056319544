import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-amount',
  templateUrl: './amount.component.html',
  styleUrls: ['./amount.component.scss'],
})
export class AmountComponent {
  @Input() isCircle: boolean = false;
  @Input() iconWidth: number = 16;
  @Input() iconHeight: number = 16;
  @Input() currency: string = '';
  @Input() iconUrl?: string;
  @Input() amount: string | number = '';
  @Input() convertedAmount: string | number = '';
  @Input() showConverted: boolean = false;
  @Input() isInline: boolean = false;
  @Input() showCurrencyName: boolean = true;
}
