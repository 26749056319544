import { createAction, props } from '@ngrx/store';

export enum UnreadMessageActionsTypes {
  LoadUnreadMessageCount = '[UnreadMessageCount] Load',
  LoadUnreadMessageCountSuccess = '[UnreadMessageCount] Load Success',
  LoadUnreadMessageCountFailure = '[UnreadMessageCount] Load Failure',
  ResetNewNotificationsCounter = '[UnreadMessageCount] Reset new notifications counter',
}

export const LoadUnreadMessageCount = createAction(
  UnreadMessageActionsTypes.LoadUnreadMessageCount,
);

export const LoadUnreadMessageCountSuccess = createAction(
  UnreadMessageActionsTypes.LoadUnreadMessageCountSuccess,
  props<{ newNotificationsCount: number, unreadNotificationsCount: number }>(),
);

export const LoadUnreadMessageCountFailure = createAction(
  UnreadMessageActionsTypes.LoadUnreadMessageCountFailure,
);

export const ResetNewNotificationsCounter = createAction(
  UnreadMessageActionsTypes.ResetNewNotificationsCounter,
);
