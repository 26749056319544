import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { combineLatest, Observable, of } from 'rxjs';
import { switchMap, takeUntil, tap } from 'rxjs/operators';
import { LandInterface } from '../../../lends/interfaces/land.interface';
import { PRICE_FORMAT } from '../../../../../core/common/constants/common-settings-values';
import { ModalService } from '../../services/modal.service';
import { MakeOfferDataInterface } from '../../interfaces/make-offer-data.interface';
import { CheckoutModalDataInterface } from '../../interfaces/checkout-modal-data.interface';
import { CheckoutConfirmModalComponent } from '../checkout-confirm-modal/checkout-confirm-modal.component';
import { ExchangeComponent } from '../../../../../exchange/container-components/exchange/exchange.component';
import { EthAccountInterface } from '../../../../../core/common/intefaces/eth-account.interface';
import { MakeOfferFacadeService } from '../../../../../auction/services/make-offer.facade.service';
import {
  UnsubscribeDestroyHelperComponent,
} from '../../../../../core/common/helpers/unsubscribe-destroy-helper.component';
import { DealTypes } from '../../../../../core/common/constants/deal-types';
import { lowestAmountValidator } from '../../../../../core/common/validators/lowest-amount.validator';
import { CurrenciesNames } from '../../../../../core/settings/constants/currencies-names';

const TREE_DAYS = 3;
const SEVEN_DAYS = 7;
const ONE_MONTH = 1;

@Component({
  selector: 'app-make-offer-modal',
  templateUrl: './make-offer-modal.component.html',
  styleUrls: ['../../../../../styles/blocks/modals.scss'],
})
export class MakeOfferModalComponent extends UnsubscribeDestroyHelperComponent {
  public static readonly modalIdentifier: string = 'makeOfferModal';

  public form: FormGroup = new FormGroup({});
  public land$: Observable<LandInterface | null>;
  public amountFormat = PRICE_FORMAT;
  public data: MakeOfferDataInterface = {
    feeAmount: 0,
    fee: 0,
    totalAmount: 0,
    offerAmount: 0,
    outLand: false,
    outLandAmount: 0,
  };
  public minSellAmount$: Observable<number> | undefined;
  public currency$: Observable<string>;
  public minSellAmount: number | undefined;
  public todayDate: Date = new Date();
  public dateFormat: string = 'YYYY-MM-dd';
  public balance?: number;
  public timeItems: any[] = [
    { value: new Date().setDate(new Date().getDate() + TREE_DAYS), label: 'in_3_days' },
    { value: new Date().setDate(new Date().getDate() + SEVEN_DAYS), label: 'in_week' },
    { value: new Date().setMonth(new Date().getMonth() + ONE_MONTH), label: 'in_month' },
    { value: null, label: 'custom_date' },
  ];

  private landTokenId?: number;
  public currenciesNames = CurrenciesNames;

  constructor(
    private modalService: ModalService,
    private formBuilder: FormBuilder,
    private makeOfferFacadeService: MakeOfferFacadeService,
  ) {
    super();
    this.land$ = this.modalService.getModalData(MakeOfferModalComponent.modalIdentifier).land$;
    this.currency$ = this.makeOfferFacadeService.getCurrency$();
    this.initForm();
    this.subscribeToLand();
    this.subscribeToEthAccountChanged();
    this.subscribeToEthAccount();
    this.subscribeToForm();
  }

  private initForm(): void {
    this.form = this.formBuilder.group({
      amount: [null, Validators.required],
      outLand: false,
      expirationDate: new Date().toISOString().split('T')[0],
      presetDate: new Date().setDate(new Date().getDate() + SEVEN_DAYS),
    });
  }

  public submit(): void {
    this.modalService.close(MakeOfferModalComponent.modalIdentifier);
    this.data.outLand = this.form.value.outLand;
    this.data.offerAmount = this.form.value.amount;
    this.data.duration = this.getOfferDurationTime();

    const data: CheckoutModalDataInterface = {
      ...this.data,
      successSubTitle: 'youve_successfully_placed_an_offer',
      confirmSubTitle: 'youre_placing_a_offer',
      totalLabel: 'total_offer_amount',
      priceLabel: 'offer_price',
      nextButtonLabel: 'place_offer',
      isMakeOffer: true,
    };

    this.modalService.open(
      CheckoutConfirmModalComponent.modalIdentifier,
      CheckoutConfirmModalComponent,
      { land$: this.land$, data },
    );
  }

  public close(): void {
    this.modalService.close(MakeOfferModalComponent.modalIdentifier);
  }

  public exchange(): void {
    this.modalService.close(MakeOfferModalComponent.modalIdentifier);
    this.modalService.open(ExchangeComponent.modalIdentifier, ExchangeComponent);
  }

  private subscribeToLand(): void {
    this.land$.pipe(
      takeUntil(this.unsubscribeSubject),
    ).subscribe((land) => {
      if (land && !land.token.collectRent) {
        this.loadMinSellAmount(land?.token.id);
        this.minSellAmount$ = this.makeOfferFacadeService.getMinSellAmount$().pipe(
          tap((amount) => {
            this.minSellAmount = amount;
          }),
        );
      }
      this.data.nftId = land?.token.id;
      if (land?.productDetails.dealType === DealTypes.Auction) {
        this.data.listingId = land!.productDetails.auctionId;
      } else {
        this.data.listingId = land!.productDetails.saleId;
      }
    });
  }

  private subscribeToEthAccount(): void {
    combineLatest(
      this.land$,
      this.makeOfferFacadeService.account(),
      this.makeOfferFacadeService.getNetwork(),
    ).pipe(
      takeUntil(this.unsubscribeSubject),
      switchMap(([land, account, network]) => combineLatest(
        of(land),
        of(account),
        of(network),
        this.makeOfferFacadeService.getContractAddress(network.id, land!.productDetails.currency!),
        of(land!.productDetails.currency!),
      )),
      switchMap(([land, account, network, tokenAddress, currency]) => combineLatest(
        of(land),
        of(account),
        of(tokenAddress),
        this.makeOfferFacadeService.getBalance(
          network.id,
          account!.address,
          tokenAddress!,
        ),
        of(currency),
      )),
    ).subscribe((([land, account, tokenAddress, balance]) => {
      if (land?.token.id) {
        this.landTokenId = land?.token.id;
      }

      if (!account) {
        this.makeOfferFacadeService.openWallet();
        return;
      }
      this.data.ethAccount = account;
      this.data.tokenAddress = tokenAddress;
      this.balance = parseFloat(balance);
      this.form.controls.amount.setValidators(
        [
          lowestAmountValidator(this.minSellAmount as number, 'WTC'),
        ],
      );
    }));
  }

  private subscribeToEthAccountChanged(): void {
    this.makeOfferFacadeService.accountChanged().pipe(
      takeUntil(this.unsubscribeSubject),
    ).subscribe((address) => {
      this.data.ethAccount = {
        address,
      } as EthAccountInterface;
    });
  }

  private loadMinSellAmount(tokenId: number) {
    this.makeOfferFacadeService.loadMinSellAmount$(tokenId);
  }

  private subscribeToForm() {
    this.form.valueChanges.pipe(
      takeUntil(this.unsubscribeSubject),
    ).subscribe(
      (value) => {
        if (this.data.outLandAmount && value.outLand) {
          this.data.totalAmount = Number(value.amount) + Number(this.data.outLandAmount);
        } else {
          this.data.totalAmount = Number(value.amount);
        }
      },
    );
  }

  private getOfferDurationTime() {
    let endDate;
    if (this.form.value.presetDate) {
      endDate = new Date(this.form.value.presetDate);
    } else {
      endDate = new Date(this.form.value.expirationDate);
    }
    endDate.setHours(this.todayDate.getHours(), this.todayDate.getMinutes(), this.todayDate.getSeconds());
    return parseInt(((endDate.getTime() - Date.now()) / 1000).toFixed(0), 10);
  }
}
