<ng-container *ngIf="sorting">
<div class="sort-bar" [formGroup]="form">
  <div class="sort-bar__item" *ngFor="let field of sorting">
    <ng-select class="sort-bar__select input-filed__input white"
               [items]="field.items | translateSelectItems : 'common.filter'"
               bindLabel="label"
               bindValue="value"
               [clearable]="true"
               [dropdownPosition]="'bottom'"
               [searchable]="false"
               [formControlName]="field.name"
               [placeholder]="field.placeholder | translate">
    </ng-select>
  </div>
</div>
</ng-container>
