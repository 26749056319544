import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { ReadAuctionService } from './read-auction.service';
import { WriteAuctionService } from './write-auction.service';
import { MetamaskService } from '../../core/auth/services/metamask.service';
import { EthAccountInterface } from '../../core/common/intefaces/eth-account.interface';
import { UniswapService } from '../../core/auth/services/uniswap.service';
import { NetworkInterface } from '../../exchange/interfaces/network.interface';
import { TokensListService } from '../../exchange/services/tokens-list.service';
import { BuyPopupsStateService } from '../states/buy-popups-state.service';
import { selectCurrency } from '../../core/common/store/selectors/settings.selectors';

@Injectable()
export class MakeOfferFacadeService {
  constructor(
    private metamaskService: MetamaskService,
    private writeAuctionService: WriteAuctionService,
    private readAuctionService: ReadAuctionService,
    private uniswapService: UniswapService,
    private store: Store,
    private tokensListService: TokensListService,
    private buyPopupsStateService: BuyPopupsStateService,
  ) {
  }

  public account(): Observable<EthAccountInterface|undefined> {
    return this.metamaskService.getAccounts().pipe(
      map((accounts) => (accounts ? accounts[0] : undefined)),
    );
  }

  public makeOffer(from: string, nftId: number, price: number,
    currencyId: number, listingId: number, duration: number): Observable<string> {
    return this.writeAuctionService.makeOffer(from, nftId, price, currencyId, listingId, duration);
  }

  public getNetwork(): Observable<NetworkInterface> {
    return this.metamaskService.getNetwork();
  }

  public getCurrency$():Observable<string> {
    return this.store.select(selectCurrency);
  }

  public getBalance(chainId: number, ethAddress: string, contractAddress: string): Observable<string> {
    return this.uniswapService.getBalance(chainId, ethAddress, contractAddress);
  }

  public accountChanged(): Observable<string|undefined> {
    return this.metamaskService.getAccountChanged$().pipe(
      map((accounts) => (accounts ? accounts[0] : undefined)),
    );
  }

  public openWallet(): void {
    return this.metamaskService.enable();
  }

  public approveIfNotEnough(from: string, tokenAddress: string, amount: number): Observable<string|null> {
    return this.readAuctionService.allowance(from, tokenAddress).pipe(
      switchMap((allowance) => {
        if (parseFloat(allowance) < amount) {
          return this.writeAuctionService.approve(from, tokenAddress, amount);
        }
        return of(null);
      }),
    );
  }

  public getContractAddress(chainId: number, currencyCode: string): Observable<string|undefined> {
    return this.tokensListService.getTokens(chainId).pipe(
      map((tokens) => tokens.filter((token) => token.symbol === currencyCode)),
      map((tokens) => (tokens.length ? tokens[0].contractAddress : undefined)),
    );
  }

  public loadMinSellAmount$(tokenId: number): void {
    this.readAuctionService.loadMinSellAmount(tokenId).subscribe(
      ((amount) => {
        this.buyPopupsStateService.setMinSellAmount(Number(amount));
      }),
    );
  }

  public getMinSellAmount$(): Observable<number> {
    return this.buyPopupsStateService.getMinSellAmount$();
  }
}
