import { ErrorTargetsNames } from '../constants/error-targets-names';

export interface IApiErrorInterface {
  code: string;
  target?: ErrorTargetsNames;
  message?: string;
  source?: { [key: string]: string } ;
}

export class ApiError {
  public code: string;
  public target?: ErrorTargetsNames;
  public message?: string;
  public source?: { [key: string]: string };

  constructor(data: IApiErrorInterface) {
    this.code = data.code;
    this.target = data.target;
    this.message = data.message;
    this.source = data.source;
  }
}
