import { createAction, props } from '@ngrx/store';

export enum AuthActionsTypes {
  Login = '[Auth] Login',
  LoginWithWallet = '[Auth] Login with wallet',
  LoginSuccess = '[Auth] Login success',
  LoginFailure = '[Auth] Login failure',
  LogOut = '[Auth] Log out',
  StartAppInitializer = '[Auth] Start app initializer',
  FinishAppInitializer = '[Auth] Finish app initializer',
}

export const Login = createAction(
  AuthActionsTypes.Login,
);

export const LoginWithWallet = createAction(
  AuthActionsTypes.LoginWithWallet,
);

export const LoginSuccess = createAction(
  AuthActionsTypes.LoginSuccess,
  props<{ token: string, role: string }>(),
);

export const LoginFailure = createAction(
  AuthActionsTypes.LoginFailure,
);

export const LogOut = createAction(
  AuthActionsTypes.LogOut,
);

export const StartAppInitializer = createAction(
  AuthActionsTypes.StartAppInitializer,
);

export const FinishAppInitializer = createAction(
  AuthActionsTypes.FinishAppInitializer,
);
