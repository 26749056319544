import { ChainId } from 'simple-uniswap-sdk/dist/esm/enums/chain-id';
import { UniswapError } from 'simple-uniswap-sdk/dist/esm/common/errors/uniswap-error';
import { ErrorCodes } from 'simple-uniswap-sdk/dist/esm/common/errors/error-codes';
import { TokenInterface } from '../../exchange/interfaces/token.interface';

const symbol = 'WTC';
const name = 'WTC';

export class WTC {
  static MAINNET() {
    return {
      chainId: ChainId.MAINNET,
      contractAddress: '0xd641B15Cb3e83C4b0f6Fa01d715605bb601e72Aa',
      decimals: 18,
      symbol,
      name,
    };
  }

  static RINKEBY() {
    return {
      chainId: ChainId.RINKEBY,
      contractAddress: '0xd641B15Cb3e83C4b0f6Fa01d715605bb601e72Aa',
      decimals: 18,
      symbol,
      name,
    };
  }

  static info(chainId: number): TokenInterface {
    switch (chainId) {
      case ChainId.RINKEBY:
        return this.RINKEBY();
      default:
        throw new UniswapError(`${chainId} is not allowed`, ErrorCodes.tokenChainIdContractDoesNotExist);
    }
  }
}
