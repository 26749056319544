import { Component } from '@angular/core';
import { take } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { Platform } from '@angular/cdk/platform';
import { Store } from '@ngrx/store';
import { MetamaskService } from '../../../core/auth/services/metamask.service';
import { LoginFailure, LoginWithWallet } from '../../../core/common/store/actions/auth.actions';
import { ModalService } from '../../modules/modals/services/modal.service';
import { PendingModalComponent } from '../../modules/modals/components/pending-modal/pending-modal.component';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-connect-wallet-popup',
  templateUrl: './connect-wallet-popup.component.html',
  styleUrls: ['./connect-wallet-popup.component.scss'],
})
export class ConnectWalletPopupComponent {
  public static readonly modalIdentifier: string = 'connectWalletPopupComponent';
  public metamaskInstalled$: Observable<boolean>;
  public mobilePlatform: boolean;
  public deeplink = environment.deeplink;
  public reconnect: boolean = false;

  constructor(
    private metamaskService: MetamaskService,
    private platform: Platform,
    private store: Store,
    private modalService: ModalService,
  ) {
    this.metamaskInstalled$ = this.metamaskService.extensionInstalled();
    this.mobilePlatform = this.platform.ANDROID || this.platform.IOS;
    this.reconnect = this.modalService.getModalData(ConnectWalletPopupComponent.modalIdentifier).reconnect;
  }

  connectWallet() {
    this.metamaskService.extensionInstalled().pipe(
      take(1),
    ).subscribe(
      (installed) => {
        if (!installed) {
          this.metamaskService.installExtension().subscribe(
            () => {},
          );
        } else {
          this.store.dispatch(LoginWithWallet());
          this.modalService.close(ConnectWalletPopupComponent.modalIdentifier);
          this.modalService.open(
            PendingModalComponent.modalIdentifier,
            PendingModalComponent,
            {
              closeAction: () => {
                this.store.dispatch(LoginFailure());
              },
              data: {
                subtitle: 'popups.you_are_connecting_your_wallet',
                note: 'popups.confirm_the_operation_in_your_wallet',
              },
            },
          );
        }
      },
    );
  }
}
