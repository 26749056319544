import { createAction, props } from '@ngrx/store';
import { SettingsModel } from '../../../settings/models/settings.model';

export enum SettingsActionsTypes {
  LoadSettings = '[Settings] Load Settings',
  LoadSettingsSuccess = '[Settings] Load Settings Success',
  LoadSettingsFailure = '[Settings] Load Settings Failure',
  SaveSettings = '[Settings] Save Settings',
  SaveSettingsSuccess = '[Settings] Save Settings Success',
  SaveSettingsFailure = '[Settings] Save Settings Failure',
}

export const LoadSettings = createAction(
  SettingsActionsTypes.LoadSettings,
);

export const LoadSettingsSuccess = createAction(
  SettingsActionsTypes.LoadSettingsSuccess,
  props<{ settings: SettingsModel }>(),
);

export const LoadSettingsFailure = createAction(
  SettingsActionsTypes.LoadSettingsFailure,
);

export const SaveSettings = createAction(
  SettingsActionsTypes.SaveSettings,
  props<{ settings: SettingsModel }>(),
);

export const SaveSettingsSuccess = createAction(
  SettingsActionsTypes.SaveSettingsSuccess,
  props<{ settings: SettingsModel }>(),
);

export const SaveSettingsFailure = createAction(
  SettingsActionsTypes.SaveSettingsFailure,
);
