import { SettingsDefaultValues } from '../../../settings/constants/settings-default-values';
import { UserSettings } from '../../../user/interfaces/user-settings.interface';

export interface SettingsState {
  settings: UserSettings
  loading: boolean,
  saving: boolean,
}

export const initialSettingsState: SettingsState = {
  settings: SettingsDefaultValues,
  loading: false,
  saving: false,
};
