<ng-container *ngIf="fields && form" [formGroup]="form">
  <div class="filter-form" *ngFor="let field of fields" [class.small-offset]="!field.label">
    <ng-container [ngSwitch]="field.type">
      <div class="" *ngSwitchCase="FieldsTypes.Select">
        <div class="filter-select">
          <app-field-select
            white="true"
            [items]="field.items"
            [label]="field.label | translate"
            [bindLabel]="'label'"
            [bindValue]="'value'"
            [clearable]="true"
            [formControlName]="field.name"
          ></app-field-select>
        </div>
      </div>

      <div class="" *ngSwitchCase="FieldsTypes.MinMax">
        <div class="filter-min-max">
          <app-field-input
            class="filter-form__min-max-input"
            white="true"
            [label]="field.label | translate"
            [placeholder]="'common.filter.min' | translate"
            [formControlName]="field.name + ':gte'"
            type="number"
            minValue="0"
          ></app-field-input>

          <div class="filter-min-max__separator">to</div>

          <app-field-input
            white="true"
            class="filter-form__min-max-input"
            [placeholder]="'common.filter.max' | translate"
            [formControlName]="field.name + ':lte'"
            type="number"
            minValue="0"
          ></app-field-input>
        </div>

        <div *ngIf="form.get(field.name + ':gte').invalid" class="filter-min-max__error">
          {{'errors.' + getErrors(form.get(field.name + ':gte').errors) | translate}}
        </div>
      </div>

      <div *ngSwitchCase="FieldsTypes.DateFrom">
        <div class="filter-date">
          <app-field-input
            white="true"
            type="date"
            date-from
            [label]="field.label | translate"
            [formControlName]="field.name"
          ></app-field-input>
        </div>
      </div>

      <div *ngSwitchCase="FieldsTypes.DateTo">
        <div class="filter-date">
          <app-field-input
            white="true"
            type="date"
            date-to
            [label]="field.label | translate"
            [formControlName]="field.name"
          ></app-field-input>
        </div>
      </div>

      <div class="" *ngSwitchCase="FieldsTypes.Submit">
        <div class="filter-button">
          <app-button
            ghost
            type="submit"
          >
            {{field.label | translate}}
          </app-button>
        </div>
      </div>
    </ng-container>
  </div>
</ng-container>
