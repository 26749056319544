import { Component, Input } from '@angular/core';
import { SimpleTableRowInterface, TableDataTypes } from '../../../core/common/intefaces/simple-table-row.interface';
import { DATE_FORMAT_12_HOURS } from '../../../core/common/constants/common-settings-values';

@Component({
  selector: 'app-simple-table',
  templateUrl: './simple-table.component.html',
  styleUrls: ['./simple-table.component.scss'],
})
export class SimpleTableComponent {
  @Input() public headers: string[] = [];
  @Input() public rows: SimpleTableRowInterface[] = [];
  @Input() public avatarUrls: string[] = [];

  public dateFormat = DATE_FORMAT_12_HOURS;
  public TableDataTypes = TableDataTypes;

  public checkStringOfDate(date: string | number | object): number | false {
    return (typeof date !== 'object'
      && typeof date !== 'number'
      && typeof date !== 'undefined'
      && date.split(' ').length < 2 && Date.parse(date));
  }
}
