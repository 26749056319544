<div class="image-wrapper">
  <img
    [class.hide]="showStub"
    class="image"
    [ngStyle]="{'object-fit': objectFit}"
    [src]="src"
    [attr.alt]="alt"
    (load)="loadSuccess()"
    (error)="loadFail()"
  >
  <img
    *ngIf="showStub && backupSrc && !backUpTemplate"
    class="image"
    [ngStyle]="{'object-fit': objectFit}"
    [src]="backupSrc"
    [alt]="alt"
  >
  <ng-container *ngIf="showStub && backUpTemplate">
    <ng-container [ngTemplateOutlet]="backUpTemplate">

    </ng-container>
  </ng-container>
</div>
