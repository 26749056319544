import { TranslateLoader } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';

export class CustomTranslateLoader implements TranslateLoader {
  constructor(
    private http: HttpClient,
  ) {
  }

  /**
   * Gets the translations from the server or form assets
   */
  public getTranslation(lang: string): Observable<Object> {
    return this.http.get(`./assets/i18n/${lang}.json`)
      .pipe(catchError(() => of({})));
  }
}
