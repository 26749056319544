<div class="filter-buttons"
     *ngIf="buttonsGroup">
  <div *ngFor="let button of buttonsGroup.items"
       class="filter-button__button"
       [class.full-width]="button.fullWidth">
    <app-button light="light"
                [selected]="chekButtonSelectedStatus(button.name)"
                (btnClick)="toggleValue(button.name)">
      {{button.label | translate}}
    </app-button>
  </div>
</div>
