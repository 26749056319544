import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { WriteShardService } from './write-shard.service';
import { MetamaskService } from '../../core/auth/services/metamask.service';
import { EthAccountInterface } from '../../core/common/intefaces/eth-account.interface';
import { UniswapService } from '../../core/auth/services/uniswap.service';
import { NetworkInterface } from '../../exchange/interfaces/network.interface';
import { TokensListService } from '../../exchange/services/tokens-list.service';

@Injectable()
export class ShardToNftFacadeService {
  constructor(
    private metamaskService: MetamaskService,
    private writeShardService: WriteShardService,
    private uniswapService: UniswapService,
    private tokensListService: TokensListService,
  ) {}

  public account(): Observable<EthAccountInterface|undefined> {
    return this.metamaskService.getAccounts().pipe(
      map((accounts) => (accounts ? accounts[0] : undefined)),
    );
  }

  public getBalance(chainId: number, ethAddress: string, contractAddress: string): Observable<string> {
    return this.uniswapService.getBalance(chainId, ethAddress, contractAddress);
  }

  public getNft(from: string, id: number, contractAddress: string): Observable<string> {
    return this.writeShardService.getNft(from, id, contractAddress);
  }

  public getNetwork(): Observable<NetworkInterface> {
    return this.metamaskService.getNetwork();
  }

  public getContractAddress(chainId: number, currencyCode: string): Observable<string|undefined> {
    return this.tokensListService.getTokens(chainId).pipe(
      map((tokens) => tokens.filter((token) => token.symbol === currencyCode)),
      map((tokens) => (tokens.length ? tokens[0].contractAddress : undefined)),
    );
  }
}
