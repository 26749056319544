import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { NgxSpinner, Spinner } from 'ngx-spinner/lib/ngx-spinner.enum';
import { NgxSpinnerService } from 'ngx-spinner';

@Injectable()
export class SpinnerService {
  constructor(
    private ngxSpinnerService: NgxSpinnerService,
  ) {
  }

  public getSpinner(name: string): Observable<NgxSpinner> {
    return this.ngxSpinnerService.getSpinner(name);
  }

  public show(name?: string, spinner?: Spinner): Promise<unknown> {
    return this.ngxSpinnerService.show(name, spinner);
  }

  public hide(name?: string, debounce?: number): Promise<unknown> {
    return this.ngxSpinnerService.hide(name, debounce);
  }
}
