import { Injectable } from '@angular/core';
import { MissingTranslationHandler, MissingTranslationHandlerParams, TranslateParser } from '@ngx-translate/core';

export const NOT_TRANSLATED_PREFIX = '*NOT_TRANSLATED*';
@Injectable()
export class CustomMissingTranslationHandler implements MissingTranslationHandler {
  private allNotTranslated: any = {};
  constructor(private parser: TranslateParser) {
  }

  handle(params: MissingTranslationHandlerParams): string {
    const keys = params.key.split(/\.(?=[\w{<])/);
    const lastKey = keys[keys.length - 1];
    const prefix = keys.slice(0, keys.length - 1).join('.');
    if (this.allNotTranslated[prefix]) {
      if (!this.allNotTranslated[prefix].includes(lastKey)) {
        this.allNotTranslated[prefix].push(lastKey);
      }
    } else {
      this.allNotTranslated[prefix] = [lastKey];
    }
    // eslint-disable-next-line no-console
    console.log('All Not Translated keys:', this.allNotTranslated);
    return NOT_TRANSLATED_PREFIX + this.parser.interpolate(keys[keys.length - 1], params.interpolateParams);
  }
}
