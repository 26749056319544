import { Component, Input } from '@angular/core';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { AuthService } from '../../../core/auth/services/auth.service';
import { HeaderFacadeService } from '../../services/header.facade.service';
import { HeaderSideMenuService } from '../../modules/header-side-menu/services/header-side-menu.service';
import { UserInterface } from '../../../core/user/interfaces/user.interface';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent {
  @Input() public showAdminHeader = false;
  @Input() public hideHeaderSearchBar = true;

  public loggedIn$: Observable<boolean>;
  public loggedIn: boolean = false;
  public loggedAdminOrVerifier$: Observable<boolean>;
  public loggedVerifier$: Observable<boolean>;
  public loggedAdmin$: Observable<boolean>;
  public notificationsCount$: Observable<number>;
  public walletAddressWrong$: Observable<boolean>;
  public user$: Observable<UserInterface | undefined>;
  public avatarUrl$: Observable<string | undefined>;

  constructor(
    private authService: AuthService,
    private headerService: HeaderFacadeService,
    private headerSideMenuService: HeaderSideMenuService,
  ) {
    this.loggedIn$ = headerService.loggedIn$.pipe(
      tap((value) => {
        this.loggedIn = value;
      }),
    );
    this.loggedAdminOrVerifier$ = this.headerService.loggedAdminOrVerifier$;
    this.notificationsCount$ = this.headerService.getNewNotificationsCount$();
    this.walletAddressWrong$ = this.authService.walletAddressWrong$();
    this.loggedVerifier$ = this.headerService.loggedVerifier$;
    this.loggedAdmin$ = this.headerService.loggedAdmin$;
    this.user$ = this.headerService.getUser$();
    this.avatarUrl$ = this.headerService.getUser$().pipe(
      map((user) => user?.profileImage?.url),
    );
  }

  public connectWallet() {
    this.authService.connectWallet();
  }

  openUserMenu() {
    if (this.loggedIn) {
      this.headerSideMenuService.openUserMenu();
    }
  }
}
