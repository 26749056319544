import { AbstractControl, ValidatorFn } from '@angular/forms';
import { ErrorCodes } from '../constants/error-codes';

export function highestAmountValidator(highestAmount: number, currency?: string): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    if (!control.value && highestAmount) {
      return null;
    }
    let currencyForError = '';
    if (currency) {
      currencyForError = currency;
    }
    const { value } = control;
    return highestAmount >= value
      ? { [ErrorCodes.HighestAmount]: { value: (`${highestAmount} ${currencyForError}`) } } : null;
  };
}
