import {
  Component, Input,
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FilterGroupFieldsInterface } from '../../interfaces/filter-group.interface';

@Component({
  selector: 'app-filter-selected-list',
  templateUrl: './filter-selected-list.component.html',
  styleUrls: ['./filter-selected-list.component.scss'],
})
export class FilterSelectedListComponent {
  private value: string = '';

  @Input() selectedListGroup?: FilterGroupFieldsInterface;
  @Input() form?: FormGroup;

  public setValue(value: string) {
    if (this.value === value) {
      this.value = '';
    } else {
      this.value = value;
    }
    if (this.form && this.selectedListGroup) {
      this.form.get(this.selectedListGroup.name)?.setValue(this.value);
    }
  }

  public chekItemSelectedStatus(value: string): boolean {
    return this.value === value;
  }
}
