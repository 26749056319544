<div class="table-wrapper">
  <table class="table" *ngIf="rows?.length">
    <thead>
    <tr class="table__head">
      <th
        class="table__th"
        *ngFor="let th of headers"
      >{{th | translate}}</th>
    </tr>
    </thead>

    <tbody>
    <tr *ngFor="let row of rows; let rowIndex = index"
        class="table__row">
      <ng-container *ngFor="let item of row.items; let columnIndex = index">
        <ng-container *ngIf="!item?.type">
          <td class="table__td"
              [title]="item">
            <div *ngIf="avatarUrls.length && columnIndex === 0"
                 class="logo-container">
              <div class="logo-wrapper">
                <img *ngIf="avatarUrls[rowIndex]"
                     class="logo"
                     [src]="avatarUrls[rowIndex]"
                     alt="avatar">
              </div>
              {{ item }}
            </div>
            <span *ngIf="!(avatarUrls.length && columnIndex === 0)">
              {{ checkStringOfDate(item) ? (item | date : dateFormat) : item }}
            </span>
          </td>
        </ng-container>

        <ng-container *ngIf="item?.type">
          <td class="table__td">
            <ng-container [ngSwitch]="item.type">
              <ng-container *ngSwitchCase="TableDataTypes.NAME_WITH_LOGO">
                <app-stake-name [bgColor]="item.iconConfig.background"
                                [name]="item.value">
                </app-stake-name>
              </ng-container>
              <ng-container *ngSwitchCase="TableDataTypes.TRANSLATION">
                <span >{{ item.value | translate }}</span>
              </ng-container>
              <ng-container *ngSwitchCase="TableDataTypes.DATE">
                <span >{{ item.value | date: item.dateFormat }}</span>
              </ng-container>
              <ng-container *ngSwitchCase="TableDataTypes.LINK">
                <a target="_blank" [href]="item.linkUrl">{{ item.value | translate }}</a>
              </ng-container>
              <ng-container *ngSwitchCase="TableDataTypes.PERCENT">
                <span >{{ item.value | percent }}</span>
              </ng-container>
              <ng-container *ngSwitchCase="TableDataTypes.MONTH">
                <span>{{ item.value | secondsToMonth }}</span>
              </ng-container>
              <ng-container *ngSwitchCase="TableDataTypes.WTC">
                <span>{{ item.value | number }} WTC</span>
              </ng-container>
              <ng-container *ngSwitchCase="TableDataTypes.PROGRESS">
                <app-progress-circle [fill]="item.value"></app-progress-circle>
              </ng-container>
            </ng-container>
          </td>
        </ng-container>
      </ng-container>
      <td class="table__td" *ngIf="row.options">
        <app-context-menu [items]="row.options" [disabled]="row.optionsDisabled"></app-context-menu>
      </td>
    </tr>
    </tbody>
  </table>
</div>
