import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { IHttpResponse } from '../../common/intefaces/http-response.interface';
import { SettingsModel } from '../models/settings.model';
import { SETTINGS_API } from '../api/settings.api';

@Injectable()
export class SettingsApiService {
  constructor(
    private httpClient: HttpClient,
  ) {
  }

  public loadUserSettings(): Observable<IHttpResponse<SettingsModel>> {
    return this.httpClient.get<IHttpResponse<SettingsModel>>(
      SETTINGS_API.settings,
    );
  }

  public saveUserSettings(body: SettingsModel): Observable<IHttpResponse<SettingsModel>> {
    return this.httpClient.put<IHttpResponse<SettingsModel>>(
      SETTINGS_API.settings,
      body,
    );
  }
}
