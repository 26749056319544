export class SettingsModel {
  lang: string;
  units: string;
  currency: string;

  constructor(data: SettingsModel | any) {
    this.lang = data.lang?.value ? data.lang.value : data.lang;
    this.currency = data.currency?.value ? data.currency.value : data.currency;
    this.units = data.units?.value ? data.units.value : data.units;
  }
}
