<div class="side-menu-content">
  <div class="side-menu-content__header" *ngIf="selectedMenuData?.parentTitle" (click)="selectedMenuData = menuData">
    <img class="side-menu-content__arrow" src="../../../../../../assets/icons/arrow-back-black.svg" alt="arrow icon">

    <span class="side-menu-content__title">
      {{selectedMenuData?.parentTitle | translate}}
    </span>
  </div>

  <ul class="side-menu-content__list">
    <li class="side-menu-content__item" *ngFor="let item of selectedMenuData.items" (click)="close()">
      <a
        *ngIf="item?.url"
        class="side-menu-content__link"
        [routerLink]="item?.url"
      >
        {{item.title | translate}}
        <div class="side-menu-content__badge" *ngIf="item.counter | async as counter">{{counter}}</div>
      </a>

      <a
        *ngIf="item?.subMenu"
        class="side-menu-content__link"
        (click)="selectedMenuData = item.subMenu"
      >
        {{item.title | translate}}
      </a>
    </li>
  </ul>
</div>
