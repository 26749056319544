<div class="filter-selected-list" *ngIf="selectedListGroup && form" [formGroup]="form">

  <div
    *ngFor="let item of selectedListGroup.items"
    class="filter-selected-list__item"
    (click)="setValue(item.value)"
    [class.selected]="chekItemSelectedStatus(item.value)"
  >
    <img
      class="filter-selected-list__image"
      src="assets/images/avatar.png"
      alt="avatar"
    >

    <div class="filter-selected-list__label">{{item.label | translate}}</div>
  </div>
</div>
