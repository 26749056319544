import {
  Component, Input,
} from '@angular/core';
import { FormGroup, ValidationErrors } from '@angular/forms';
import { FilterFormFieldsInterface } from '../../interfaces/filter-group.interface';
import { FilterFieldsTypes } from '../../constants/filter-group-types';

@Component({
  selector: 'app-filter-form',
  templateUrl: './filter-form.component.html',
  styleUrls: ['./filter-form.component.scss'],
})
export class FilterFormComponent {
  @Input() fields?: FilterFormFieldsInterface;
  @Input() form?: FormGroup;

  public readonly FieldsTypes = FilterFieldsTypes;

  public getErrors(errors: ValidationErrors) {
    return Object.keys(errors)[0];
  }
}
