import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  catchError, map, switchMap,
} from 'rxjs/operators';
import { of } from 'rxjs';
import { ContractApiService } from '../../services/contract-api.service';
import { NetworkActionsTypes } from '../actions/network.actions';

@Injectable()
export class NetworkEffects {
  LoadNetworkContracts$ = createEffect(() => this.actions.pipe(
    ofType(NetworkActionsTypes.LoadNetworkContracts),
    switchMap(() => this.contractApiService.contracts().pipe(
      catchError(() => of({ data: null })),
    )),
    map(({ data }) => {
      if (data) {
        return {
          type: NetworkActionsTypes.LoadNetworkContractsSuccess,
          nftContractAddress: data.nftContractAddress || '',
          auctionContractAddress: data.auctionContractAddress || '',
          shardManagerContractAddress: data.ldxsShardManagerContractAddress || '',
          wtcContractAddress: data.wtcContractAddress || '',
          usdcContractAddress: data.usdcContractAddress || '',
          ldxmShardManagerContractAddress: data.ldxmShardManagerContractAddress || '',
          ldxrShardManagerContractAddress: data.ldxrShardManagerContractAddress || '',
          ldxsShardManagerContractAddress: data.ldxsShardManagerContractAddress || '',
          ldxwShardManagerContractAddress: data.ldxwShardManagerContractAddress || '',
        };
      }
      return { type: NetworkActionsTypes.LoadNetworkContractsFailure };
    }),
  ));

  constructor(
    private actions: Actions,
    private contractApiService: ContractApiService,
  ) {
  }
}
