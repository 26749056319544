<div class="modal" *ngIf="land$ | async as land">
  <div class="modal__title">
    {{ 'pages.modals.checkout' | translate }}
  </div>

  <div class="modal__subtitle">
    {{ 'pages.modals.' + data.confirmSubTitle | translate }}
  </div>

  <div class="modal__location-info location-info">
    <div class="location-info__title">
      <img class="location-info__icon" src="assets/images/marker.svg" alt="marker.svg"> {{ land.name }}

      <div class="location-info__sub-title">{{ land.location }}</div>
    </div>
  </div>

  <div class="modal__land-info land-info">
    <div class="land-info__item">
      {{ 'pages.land.crop_share' | translate }}, {{ ('common.cultures.' + land.productDetails.cropShareType) | translate | lowercase}}:
      <span class="land-info__highlight">{{land.productDetails?.cropShare | number : amountFormat}}</span>
      {{ ('common.units.' + (units$ | async)) | translate }}
    </div>
    &nbsp;&#8231;&nbsp;
    <div class="land-info__item">
      <span class="land-info__highlight">{{ land.area }}</span> {{ ('common.units.' + areaUnits[(units$ | async)]) | translate }}
    </div>
  </div>

  <ul class="modal__total-info total-info">
    <li class="total-info__row" *ngIf="data.bidAmount || data.offerAmount || data.totalAmount">
      <div class="total-info__label">{{ 'pages.modals.' + data.priceLabel | translate }}:</div>

      <div class="total-info__value"><strong>{{ data.bidAmount || data.offerAmount || data.nftPrice }}</strong> {{ land.productDetails.currency }}</div>
    </li>

    <li class="total-info__row" *ngIf="data.outLand">
      <div class="total-info__label">{{ 'pages.modals.premium' | translate }}:</div>

      <div class="total-info__value"><strong>{{ data.outLandAmount }}</strong>{{ land.productDetails.currency }}</div>
    </li>

    <li class="total-info__row total-info__row--line-divider"></li>

    <li class="total-info__row total-info__row--main">
      <div class="total-info__label">{{ 'pages.modals.' + data.totalLabel | translate }}:</div>

      <div class="total-info__value"><strong>{{ data.totalAmount }}</strong> {{ land.productDetails.currency }}</div>
    </li>
  </ul>

  <div class="modal__buttons">
    <app-button primary (btnClick)="next()" class="modal__button">
      {{ 'pages.modals.' + data.nextButtonLabel | translate | titlecase }}
    </app-button>

    <app-button (btnClick)="close()" secondary class="modal__button" tabindex="0">
      {{ 'pages.modals.cancel' | translate | titlecase }}
    </app-button>
  </div>
</div>
