import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { WriteAuctionService } from './write-auction.service';
import { MetamaskService } from '../../core/auth/services/metamask.service';
import { EthAccountInterface } from '../../core/common/intefaces/eth-account.interface';

@Injectable()
export class RemoveFromSaleFacadeService {
  constructor(
    private metamaskService: MetamaskService,
    private writeAuctionService: WriteAuctionService,
  ) {
  }

  public account(): Observable<EthAccountInterface|undefined> {
    return this.metamaskService.getAccounts().pipe(
      map((accounts) => (accounts ? accounts[0] : undefined)),
    );
  }

  public removeFromSale(from: string, saleId: number, contractAddress: string): Observable<string> {
    return this.writeAuctionService.removeFromSale(from, saleId, contractAddress);
  }

  public cancelAuction(from: string, auctionId: number, contractAddress: string): Observable<string> {
    return this.writeAuctionService.cancelAuction(from, auctionId, contractAddress);
  }

  public claim(from: string, auctionId: number, contractAddress: string): Observable<string> {
    return this.writeAuctionService.claim(from, auctionId, contractAddress);
  }
}
