import {
  Component, ElementRef, EventEmitter, forwardRef, Input, Output, ViewChild,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { NgSelectComponent } from '@ng-select/ng-select';
import { SelectItemInterface } from '../../../core/common/intefaces/select-item.interface';

@Component({
  selector: 'app-field-input-exchange',
  templateUrl: './field-input-exchange.component.html',
  styleUrls: ['./field-input-exchange.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FieldInputExchangeComponent),
      multi: true,
    },
  ],
})

export class FieldInputExchangeComponent implements ControlValueAccessor {
  @ViewChild('input') input!: ElementRef;
  @ViewChild('select') select!: NgSelectComponent;

  @Input() placeholder = '';
  @Input() formControlName: string | undefined;
  @Input() disabled = false;
  @Input() inputValue: string | number | null | undefined = null;
  @Input() label: string = '';
  @Input() balance: string | number = '';
  @Input() balanceCurrency = '';
  @Input() currencies: SelectItemInterface[] = [];
  @Input() selectDisabled = false;

  // eslint-disable-next-line @angular-eslint/no-output-native
  @Output() blur: EventEmitter<void> = new EventEmitter();
  @Output() selectChange: EventEmitter<void> = new EventEmitter<void>();

  public selectFocused = false;
  public inputFocused = false;
  public selectValue: string | null | undefined = null;
  public touched = false;

  private onChange = (
    value: { // eslint-disable-line @typescript-eslint/no-unused-vars
      amount: string | number | null | undefined,
      currency: string | null | undefined,
    } | null,
  ) => {};
  private onTouched = () => {};

  public focus() {
    setTimeout(() => { // this will make the execution after the above boolean has changed
      // this.input.nativeElement.focus();
    }, 0);
  }

  public writeValue(value: { amount: string | number | null, currency: string | null }) {
    this.inputValue = value.amount;
    this.selectValue = value.currency;
  }

  public registerOnChange(onChange: any) {
    this.onChange = onChange;
  }

  public registerOnTouched(onTouched: any) {
    this.onTouched = onTouched;
  }

  public setDisabledState(disabled: boolean) {
    this.disabled = disabled;
  }

  public setValue() {
    if (!this.disabled) {
      this.onChange({ amount: this.inputValue, currency: this.selectValue });
    }
  }

  public setTouched() {
    this.blur.emit();
    if (!this.touched) {
      this.onTouched();
      this.touched = true;
    }
  }

  public wrapperFocus(): void {
    if (this.selectFocused || this.inputFocused) {
      return;
    }
    this.inputFocus();
  }

  public selectFocus() {
    this.selectFocused = true;
    this.inputFocused = false;
  }

  public inputFocus() {
    this.selectFocused = false;
    this.inputFocused = true;
    this.input.nativeElement.focus();
  }

  public selectBlur() {
    this.selectFocused = false;
  }

  public inputBlur() {
    this.inputFocused = false;
  }

  public emitSelectChange(): void {
    this.selectChange.emit();
  }
}
