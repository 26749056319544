<div class="avatar-wrapper">
  <img
    [class.hide]="showStub"
    class="avatar-image"
    [src]="src"
    [attr.alt]="alt"
    (load)="loadSuccess()"
    (error)="loadFail()"
  >
  <img
    *ngIf="showStub"
    class="avatar-image"
    src="assets/images/avatar-account.png"
    alt="avatar"
  >
</div>
