import { MissingTranslationHandler, TranslateLoader } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import { CustomMissingTranslationHandler } from '../services/customMissingTranslationHandler';
import { CustomTranslateLoader } from '../services/customTranslateLoader';

export const TranslateOptions = {
  defaultLanguage: 'en',
  missingTranslationHandler: { provide: MissingTranslationHandler, useClass: CustomMissingTranslationHandler },
  loader: {
    provide: TranslateLoader,
    useClass: CustomTranslateLoader,
    deps: [HttpClient],
  },
};
