<ng-container *ngIf="{
  loggedAdminOrVerifier: loggedAdminOrVerifier$ | async,
  loggedVerifier: loggedVerifier$ | async,
  loggedAdmin: loggedAdmin$ | async,
  loggedIn: loggedIn$ | async,
  user: user$ | async,
  avatarUrl: avatarUrl$ | async
} as observables">
  <div class="header">
    <div *ngIf="!observables.loggedAdminOrVerifier"
         class="header__logo"
         routerLink="/">
      <p class="header__logo-text">{{ 'common.title' | translate }}</p>
    </div>

    <div *ngIf="observables.loggedAdmin"
         class="header__logo"
         routerLink="/admin/verifiers">
      <p class="header__logo-text">{{ 'common.title' | translate }}</p>
    </div>

    <div *ngIf="observables.loggedVerifier"
         class="header__logo"
         routerLink="/admin/products/in-progress">
      <p class="header__logo-text">{{ 'common.title' | translate }}</p>
    </div>

    <div class="header__search" *ngIf="!showAdminHeader">
      <!--    <app-header-search *ngIf="!hideHeaderSearchBar"></app-header-search>-->
    </div>

    <div class="header__actions"
         *ngIf="!showAdminHeader">

      <app-button *ngIf="loggedIn$ | async"
                  text="text"
                  headerNavigation="headerNavigation"
                  class="header__action"
                  routerLink="/marketplace">
        {{ 'common.header.marketplace' | translate }}
      </app-button>

      <app-button *ngIf="loggedIn$ | async"
                  text="text"
                  headerNavigation="headerNavigation"
                  class="header__action"
                  routerLink="/user-profile">
        {{ 'common.header.my_lands' | translate }}
      </app-button>

      <app-button *ngIf="observables.loggedIn"
                  class="header__action-create-nfts"
                  primary="primary"
                  routerLink="/lands/claim">
        {{ 'common.header.apply' | translate }}
      </app-button>
    </div>

    <div class="header__menus header_menus--wide" *ngIf="!showAdminHeader && !observables.loggedAdminOrVerifier">
      <app-user-menu [loggedIn]="observables.loggedIn"
                     [user]="observables.user"
                     [avatarUrl]="observables.avatarUrl">
        <app-user-menu-item routerLink="logout">{{ 'common.header.log_out' | translate }}</app-user-menu-item>
      </app-user-menu>
    </div>

    <div class="header__menus header_menus--short" *ngIf="!showAdminHeader && !observables.loggedAdminOrVerifier">
      <div class="header__menus-item menu-action" (click)="openUserMenu()">
        <app-user-menu
          [loggedIn]="observables.loggedIn"
          [avatarUrl]="observables.avatarUrl"
          [user]="observables.user"
          class="header__menus-item"
        ></app-user-menu>
      </div>

      <app-header-side-menu></app-header-side-menu>
    </div>

    <div class="header__menus"
         *ngIf="showAdminHeader || observables.loggedAdminOrVerifier"
         [class.header__menus--admin]="showAdminHeader">
      <app-user-menu [loggedIn]="observables.loggedIn"
                     [avatarUrl]="observables.avatarUrl"
                     [admin]="true">
        <app-user-menu-item routerLink="logout">{{ 'common.header.log_out' | translate }}</app-user-menu-item>
      </app-user-menu>
    </div>
  </div>

</ng-container>
