import { LandRequestModel } from './land-request.model';
import { DealTypes } from '../../core/common/constants/deal-types';

export class LandVerificationRequestModel extends LandRequestModel {
  public ratings?: {
    yield?: number;
    profitability?: number;
    levelOfProtection?: number;
    climaticZone?: number;
    varietyRotation?: number;
    landQuality?: number;
    logistics?: number;
    processingFacilities?: number;
    price?: number;
    convenience?: number;
  };
  public landInsights? : {
    growingSeasonWinter?: boolean;
    growingSeasonSummer?: boolean;
    growingSeasonAutumn?: boolean;
    growingSeasonSpring?: boolean;
    averageAnnualRainfall?: number;
    droughtStress?: boolean;
    floodIndex?: number;
    bushfireIndex?: number;
    frostDays?: number;
    primarySoilType?: string;
  };

  public productDetails?: {
    dealType?: DealTypes;
    nftPriceHa?: number;
    cropShare?: number;
    cropShareType?: string;
    auctionSalePeriod?: number;
    auctionStartPrice?: number;
    maxCropShare?: number;
    productivity?: number;
    productivityIndex?: string;
    totalRevenueEstimated?: string;
    estimatedEarnings?: string;
    estimatedApy?: string;
  };

  constructor(data: any) {
    super(data);

    const ratings:{ [key: string]: any } = {};
    ratings.yield = data.ratings?.yield || 0;
    ratings.profitability = data.ratings?.profitability || 0;
    ratings.levelOfProtection = data.ratings?.levelOfProtection || 0;
    ratings.climaticZone = data.ratings?.climaticZone || 0;
    ratings.varietyRotation = data.ratings?.varietyRotation || 0;
    ratings.landQuality = data.ratings?.landQuality || 0;
    ratings.logistics = data.ratings?.logistics || 0;
    ratings.processingFacilities = data.ratings?.processingFacilities || 0;
    ratings.price = data.ratings?.price || 0;
    ratings.convenience = data.ratings?.convenience || 0;

    const landInsights:{ [key: string]: any } = {};
    landInsights.growingSeasonWinter = data.growingSeasonWinter || false;
    landInsights.growingSeasonSummer = data.growingSeasonSummer || false;
    landInsights.growingSeasonAutumn = data.growingSeasonAutumn || false;
    landInsights.growingSeasonSpring = data.growingSeasonSpring || false;

    if (data.averageAnnualRainfall) {
      landInsights.averageAnnualRainfall = data.averageAnnualRainfall;
    }
    landInsights.droughtStress = data.droughtStress || false;
    if (data.floodIndex) {
      landInsights.floodIndex = data.floodIndex;
    }
    if (data.bushfireIndex) {
      landInsights.bushfireIndex = data.bushfireIndex;
    }
    if (data.frostDays) {
      landInsights.frostDays = data.frostDays;
    }
    if (data.primarySoilType) {
      landInsights.primarySoilType = data.primarySoilType;
    }

    const productDetails:{ [key: string]: any } = {};
    if (data.dealType === DealTypes.FixPrice) {
      if (data.dealType) {
        productDetails.dealType = data.dealType;
      }
      if (data.nftPriceHa) {
        productDetails.nftPriceHa = data.nftPriceHa;
      }
      if (data.cropShare) {
        productDetails.cropShare = data.cropShare;
      }
    } else {
      if (data.dealType) {
        productDetails.dealType = data.dealType;
      }
      if (parseFloat(data.nftPriceHa)) {
        productDetails.nftPriceHa = parseFloat(data.nftPriceHa);
      }
      if (parseFloat(data.cropShare)) {
        productDetails.cropShare = parseFloat(data.cropShare);
      }
      if (data.auctionSalePeriod) {
        productDetails.auctionSalePeriod = data.auctionSalePeriod;
      }
      if (data.maxCropShare) {
        productDetails.maxCropShare = data.maxCropShare;
      }
      if (data.productivity) {
        productDetails.productivity = data.productivity;
      }
      if (data.cropShareType) {
        productDetails.cropShareType = data.cropShareType;
      }
      if (parseFloat(data.auctionStartPrice)) {
        productDetails.auctionStartPrice = parseFloat(data.auctionStartPrice);
      }
    }
    if (data.productivityIndex) {
      productDetails.productivityIndex = data.productivityIndex;
    }
    if (data.totalRevenueEstimated) {
      productDetails.totalRevenueEstimated = data.totalRevenueEstimated;
    }
    if (data.estimatedEarnings) {
      productDetails.estimatedEarnings = data.estimatedEarnings;
    }
    if (data.estimatedApy) {
      productDetails.estimatedApy = data.estimatedApy;
    }

    if (Object.keys(productDetails).length > 0) {
      this.productDetails = productDetails;
    }

    if (Object.keys(landInsights).length > 0) {
      this.landInsights = landInsights;
    }
    if (Object.keys(ratings).length > 0) {
      this.ratings = ratings;
    }
  }
}
