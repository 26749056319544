import {
  ChangeDetectionStrategy, Component, Input, OnInit,
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-pagination-controls',
  templateUrl: './pagination-controls.component.html',
  styleUrls: ['./pagination-controls.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PaginationControlsComponent implements OnInit {
  @Input() form = new FormGroup({});
  private control = new FormControl('1');

  ngOnInit(): void {
    this.form.addControl('page', this.control);
  }

  public pageChange(page: number) {
    this.control.setValue(page);
  }
}
