import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'firstSymbolUppercase',
})

export class FirstSymbolUppercasePipe implements PipeTransform {
  transform(template: string): string {
    if (!template) {
      return template;
    }
    return template.charAt(0).toUpperCase() + template.slice(1);
  }
}
