import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { ModalService } from '../../services/modal.service';

@Component({
  selector: 'app-success-modal',
  templateUrl: './success-modal.component.html',
  styleUrls: ['../../../../../styles/blocks/modals.scss'],
})
export class SuccessModalComponent {
  public static readonly modalIdentifier: string = 'successModalComponent';

  private redirect?: string;

  constructor(private modalService: ModalService, private router: Router) {
    this.redirect = this.modalService.getModalData(SuccessModalComponent.modalIdentifier).redirect;
  }

  public close(): void {
    this.modalService.close(SuccessModalComponent.modalIdentifier);
    if (this.redirect) {
      this.router.navigate([this.redirect]);
    }
  }
}
