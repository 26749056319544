<div class="input-filed">
  <label
    *ngIf="label"
    [attr.for]="label"
    [class.black]="black"
    class="input-filed__label"
  >
    {{label}}
  </label>

  <div class="input-filed__input-wrapper">
    <div
      *ngIf="iconPath"
      class="input-filed__icon">
      <img [src]="iconPath" [alt]="iconPath.substring(iconPath.lastIndexOf('/') + 1, iconPath.lastIndexOf('.'))">
    </div>

    <input
      #input
      class="input-filed__input input-main"
      [class.white]="white"
      [class.password]="initialType === 'password'"
      [class.with-no-left-border]="iconPath"
      [attr.name]="label"
      [type]="type"
      [(ngModel)]="value"
      (ngModelChange)="setValue()"
      (blur)="setTouched()"
      [disabled]="disabled"
      [placeholder]="placeholder"
      [min]="minValue"
      [id]="inputId"
    >

    <div
      *ngIf="initialType === 'password'"
      class="input-filed__eye"
      (click)="showPassword()"
    >
      <img src="assets/icons/eye.svg" alt="eye" *ngIf="type !== 'password'">
      <img src="assets/icons/eye-cross.svg" alt="eye" *ngIf="type === 'password'">
    </div>
  </div>

  <div *ngIf="note" class="input-filed__note">{{note}}</div>
</div>
