const env = require('../../.env.json');

export const envName = 'prod';

export const environment = {
  name: envName,
  production: true,
  apiEndpoint: 'https://api.kupizemlya.ru/api',
  domain: 'https://kupizemlya.ru',
  googleApiKey: env.GOOGLE_API_KEY,
  deeplink: 'https://metamask.app.link/dapp/stage.dev-landx.be/',
};
