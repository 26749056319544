<div class="modal">
  <div class="pending">
    <div class="pending__spinner">
      <app-custom-spinner></app-custom-spinner>
    </div>
  </div>

  <div class="modal__title">
    {{ 'common.notifications.pending' | translate }}!
  </div>

  <div class="modal__subtitle">
    <span *ngIf="data?.subtitle; else defaultSubTitle">{{data?.subtitle | translate}}</span>

    <ng-template #defaultSubTitle>
      {{ 'pages.modals.please_wait_until_operation_is_completed_in_blockchain' | translate }}
    </ng-template>
  </div>

  <div class="modal__bottom-note" *ngIf="data?.note">
    <span>{{data?.note | translate}}</span>
  </div>

  <div class="modal__buttons">
    <app-button (btnClick)="close()" primary class="modal__button" [disabled]="true">
      {{ 'pages.modals.ok' | translate }}
    </app-button>
  </div>
</div>
