import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { TradeContext, Transaction } from 'simple-uniswap-sdk';
import { TransactionReceipt } from 'web3-core';
import { map } from 'rxjs/operators';
import { MetamaskService } from '../../core/auth/services/metamask.service';
import { UniswapService } from '../../core/auth/services/uniswap.service';
import { EthAccountInterface } from '../../core/common/intefaces/eth-account.interface';
import { NetworkInterface } from '../interfaces/network.interface';
import { TokenInterface } from '../interfaces/token.interface';
import { TokensListService } from './tokens-list.service';
import { ContractService } from '../../core/common/services/contract.service';
import { ContractsInterface } from '../../core/common/intefaces/contracts.interface';
import { ShardService } from '../../landx-nft/services/shard.service';

const MAX_ALLOWANCE_HEX = '0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff';
const MAX_ALLOWANCE_DEC = 115792089237316195423570985008687907853269984665640564039457584007913129639935;

@Injectable()
export class ExchangeFacadeService {
  constructor(
    private metamaskService: MetamaskService,
    private uniswapService: UniswapService,
    private tokensListService: TokensListService,
    private contractsService: ContractService,
    private shardService: ShardService,
  ) {}

  public importTokenToMetamask(contracts: ContractsInterface, shardType: string): Observable<boolean> {
    return this.metamaskService.addToken$(this.shardService.getShardAddress(contracts, shardType), shardType);
  }

  public tradeInfo(
    chainId: number,
    amount: number,
    fromTokenContractAddress: string,
    toTokenContractAddress: string,
    ethereumAddress: string,
  ): Observable<TradeContext> {
    return this.uniswapService.tradeInfo(
      chainId,
      amount,
      fromTokenContractAddress,
      toTokenContractAddress,
      ethereumAddress,
    );
  }

  public isApprovalRequired(owner: string, fromTokenContractAddress: string, spender: string): Observable<boolean> {
    return this.uniswapService.getAllowance(owner, fromTokenContractAddress, spender).pipe(
      map((allowance) => MAX_ALLOWANCE_DEC > parseInt(allowance, 10)),
    );
  }

  public approveAllowance(
    chainId: number,
    from: string,
    fromTokenContractAddress: string,
    spender: string,
    value: string = MAX_ALLOWANCE_HEX,
  ): Observable<any> {
    return this.uniswapService.approveAllowance(from, fromTokenContractAddress, spender, value);
  }

  public executeTrade(transaction: Transaction): Observable<TransactionReceipt> {
    return this.uniswapService.executeTrade(transaction);
  }

  public getBalance(chainId: number, ethAddress: string, contractAddress: string): Observable<string> {
    return this.uniswapService.getBalance(chainId, ethAddress, contractAddress);
  }

  public getTokens(chainId: number): Observable<TokenInterface[]> {
    return this.tokensListService.getTokens(chainId);
  }

  public getNetwork(): Observable<NetworkInterface> {
    return this.metamaskService.getNetwork();
  }

  public getAccounts(): Observable<EthAccountInterface[]> {
    return this.metamaskService.getAccounts();
  }

  public account(): Observable<EthAccountInterface|undefined> {
    return this.metamaskService.getAccounts().pipe(
      map((accounts) => (accounts ? accounts[0] : undefined)),
    );
  }

  public getDecimalPlacesByContractAddress(chainId: number, address: string): Observable<number|undefined> {
    return this.tokensListService.getTokens(chainId).pipe(
      map((tokens) => tokens.filter((token) => token.contractAddress === address)),
      map((tokens) => (tokens.length ? tokens[0].decimals : undefined)),
    );
  }

  public contracts(): Observable<ContractsInterface> {
    return this.contractsService.contracts();
  }

  public getTokenBySymbol(symbol: string, chainId: number): Observable<TokenInterface|null> {
    return this.tokensListService.getTokenBySymbol(symbol, chainId);
  }
}
