import { Action, createReducer, on } from '@ngrx/store';
import { initialNetworkState, NetworkState } from '../states/network.state';
import * as NetworkActions from '../actions/network.actions';

const networkReducer = createReducer(
  initialNetworkState,
  on(NetworkActions.LoadNetworkContracts, (state): NetworkState => ({
    ...state,
    loading: true,
  })),
  on(NetworkActions.LoadNetworkContractsSuccess, (state, payload): NetworkState => ({
    ...state,
    ...payload,
    loading: false,
  })),
  on(NetworkActions.LoadNetworkContractsFailure, (state): NetworkState => ({
    ...state,
    loading: false,
  })),
);

export function reducer(state: NetworkState | undefined, action: Action) {
  return networkReducer(state, action);
}
