<div class="modal" *ngIf="offer$ | async as offer">
  <div class="modal__title">
    {{ 'pages.modals.reject_offer_title' | translate }}
  </div>

  <div class="modal__subtitle">
    {{ 'pages.modals.do_you_want_to_reject_offer' | translate }}
  </div>

  <div class="modal__location-info location-info">
    <div class="location-info__title">
      <img class="location-info__icon" src="assets/images/marker.svg" alt="marker.svg"> {{ offer.token.land.name }}
      <div class="location-info__sub-title">{{ offer.token.land.location }}</div>
    </div>
  </div>

  <div class="modal__land-info land-info">
    <div class="land-info__item">
      {{ 'pages.land.crop_share' | translate }}, {{ ('common.cultures.' + offer?.token.land.productDetails.cropShareType) | translate | lowercase}}:
      <span class="font-weight">{{offer?.token.land.productDetails?.cropShare | number : amountFormat}}</span>
      {{ ('common.units.' + (units$ | async)) | translate }}
    </div>
    &nbsp;&#8231;&nbsp;
    <div class="land-info__item">
      <span class="font-weight">{{ offer?.token.land.area }}</span>
      {{ ('common.units.' + areaUnits[(units$ | async)]) | translate }}
    </div>
  </div>

  <ul class="modal__total-info total-info" *ngIf="offer.price">
    <li class="total-info__row">
      <div class="total-info__label">{{'pages.modals.offer_price' | translate}}:</div>

      <div class="total-info__value" [title]="(offer.price | number : amountFormat) + ' ' + offer.currency">
        <strong>{{ offer.price | number : amountFormat}}</strong> {{ offer.currency }}
      </div>
    </li>
  </ul>

  <div class="modal__buttons">
    <app-button (btnClick)="submit()" ghost warn class="modal__button">
      {{ 'pages.modals.reject_offer_title' | translate }}
    </app-button>

    <app-button (btnClick)="close()" secondary class="modal__button" tabindex="0">
      {{ 'pages.modals.cancel' | translate | titlecase }}
    </app-button>
  </div>
</div>
