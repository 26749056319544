import { Pipe, PipeTransform } from '@angular/core';
import { FormatBytes } from '../../core/common/helpers/format-bytes.helper';

@Pipe({
  name: 'formatBytes',
})
export class FormatBytesPipe implements PipeTransform {
  transform(size: number | string): string {
    return FormatBytes((typeof size === 'string') ? Number.parseFloat(size) : size);
  }
}
