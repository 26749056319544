<div class="modal" *ngIf="$preview | async as preview">
  <div class="modal__title">{{ 'pages.modals.nft_to_shard' | translate }}</div>

  <div class="modal__subtitle">{{ 'pages.modals.this_nft_will_be_deposited_into_a_smart_contract' | translate: { total: preview.total } }}</div>

  <div class="modal__location-info land-description">
    <div class="land-description__block">
      <img src="assets/icons/marker.svg" class="land-description__marker" alt="Marker" />
      <span class="land-description__name">{{ land.name }}</span>
      <span class="land-description__location">{{ land.location }}</span>
    </div>

    <img src="assets/icons/dotted-arrow.svg" alt="Arrow" class="land-description__arrow">
    <img [src]="'assets/icons/shards/' + (preview.cropShareType  | currencyFromCulture | uppercase) + '.svg'" alt="Shard" class="land-description__icon">
  </div>

  <ul class="modal__total-info total-info">
    <li class="total-info__row">
      <span class="total-info__label" appEllipsis>{{ 'pages.modals.blockchain' | translate }}:</span>
      <span class="total-info__value">{{ 'pages.modals.ethereum' | translate }}</span>
     </li>

    <li class="total-info__row">
      <span class="total-info__label" appEllipsis>{{ 'pages.modals.ticker' | translate }}:</span>
      <span class="total-info__value">{{land.productDetails.cropShareType | currencyFromCulture | uppercase}}</span>
    </li>

    <li class="total-info__row">
      <span class="total-info__label" appEllipsis>{{ 'pages.modals.name' | translate }}:</span>
      <span class="total-info__value">{{ 'pages.user_profile.nft_shards.' + getCultureByType(land.productDetails.cropShareType | currencyFromCulture | uppercase) | translate | titlecase }} {{ 'pages.user_profile.nft_shards.shards' | translate }}</span>
    </li>

    <li class="total-info__row">
      <span class="total-info__label" appEllipsis>{{ 'pages.modals.shard_to_mint' | translate }}:</span>
      <span class="total-info__value">{{ preview.shards }}</span>
    </li>

    <li class="total-info__row total-info__row--gray">
      <span class="total-info__label" appEllipsis>{{ 'pages.modals.protocol_fee' | translate: { percents: 3 } }}:</span>
      <span class="total-info__value">{{ preview.fee }}</span>
    </li>

    <li class="total-info__row total-info__row--secondary">
      <span class="total-info__label" appEllipsis>{{ 'pages.modals.shards_to_be_deposited' | translate }}:</span>
      <span class="total-info__value">{{ preview.total }}</span>
    </li>
  </ul>

  <p class="modal__bottom-note">{{ 'pages.modals.by_clicking_deploy_custody_contract' | translate }}</p>

  <div class="modal__buttons">
    <app-button
      primary
      class="modal__button"
      (btnClick)="submit()"
    >
      {{ 'pages.modals.deploy_custody_contract' | translate }}
    </app-button>
  </div>
</div>

