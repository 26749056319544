<ng-container *ngIf="!routerLink; else link">
  <ng-container *ngTemplateOutlet="button"></ng-container>
</ng-container>

<ng-template #link>
  <a [routerLink]="routerLink">
    <ng-container *ngTemplateOutlet="button"></ng-container>
  </a>
</ng-template>

<ng-template #button>
  <button
    class="button"
    routerLinkActive="active"
    [class.primary]="primary"
    [class.secondary]="secondary"
    [class.action]="action"
    [class.ghost]="ghost"
    [class.ghost-secondary]="ghostSecondary"
    [class.crumb]="crumb"
    [class.crumb-secondary]="crumbSecondary"
    [class.warn]="warn"
    [class.text]="text"
    [class.black]="black"
    [class.light]="light"
    [class.blue]="blue"
    [class.gray]="gray"
    [class.small]="small"
    [class.navigate-back]="navigateBack"
    [class.header-navigation]="headerNavigation"
    [attr.type]="type"
    [attr.disabled]="disabledButton"
    [class.selected]="selected"
    [title]="tip"
    (click)="click()"
  >
    <span class="icon-wrapper" [class.margin]="content.childNodes.length !== 0" *ngIf="icon">
      <img class="icon" [src]="'assets/icons/' + icon + '.svg'" [attr.alt]="icon">
      <img class="icon icon-hover" [src]="'assets/icons/' + (iconHover || icon) + '.svg'" [attr.alt]="icon" *ngIf="iconHover || icon">
    </span>
    <span class="projected-text" #content><ng-content></ng-content></span>
  </button>
</ng-template>
