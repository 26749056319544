export const VALIDATORS_VALUES = {
  maxLengthPassword: 255,
  minLengthPassword: 8,
  maxLengthInputField: 255,
  minLengthInputField: 3,
  maxLengthTextareaField: 1000,
  emailPatterns: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/, // eslint-disable-line
  fileSizeLimitMb: (10 * 1024 * 1024),
  minTotalRating: 1,
};
