import { Component, Input, TemplateRef } from '@angular/core';

@Component({
  selector: 'app-image',
  templateUrl: './image.component.html',
  styleUrls: ['./image.component.scss'],
})
export class ImageComponent {
  @Input() public alt?: string;
  @Input() public src!: string;
  @Input() public backupSrc?: string;
  @Input() public objectFit = 'cover';
  @Input() public backUpTemplate?: TemplateRef<any>;

  public showStub = true;

  public loadSuccess() {
    this.showStub = false;
  }

  public loadFail() {
    this.showStub = true;
  }
}
