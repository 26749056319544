import { Injectable } from '@angular/core';
import { Observable, Subscriber } from 'rxjs';
import { AbiItem } from 'ethereum-abi-types-generator';
import { switchMap } from 'rxjs/operators';
import { Web3Service } from '../../core/common/services/web3.service';
import { ContractService } from '../../core/common/services/contract.service';

@Injectable()
export class WriteNftService {
  constructor(private web3: Web3Service, private contractService: ContractService) {}

  public setApprovalForAll(from: string, contractAddress: string, approved: boolean = true): Observable<string> {
    return this.contractService.contracts().pipe(
      switchMap((contracts) => new Observable((subscriber: Subscriber<string>) => {
        (async () => {
          try {
            const abi: AbiItem[] = [
              {
                name: 'setApprovalForAll',
                inputs: [
                  {
                    internalType: 'address',
                    name: 'operator',
                    type: 'address',
                  },
                  {
                    internalType: 'bool',
                    name: 'approved',
                    type: 'bool',
                  },
                ],
                outputs: [],
                stateMutability: 'payable',
                type: 'function',
              },
            ];
            const contract = new this.web3.eth.Contract(abi, contracts.nftContractAddress);
            contract.methods.setApprovalForAll(contractAddress, approved).send({
              from,
            }).then((block: { blockHash: string }) => {
              subscriber.next(block.blockHash);
            }).catch((error: any) => {
              subscriber.error(error);
            });
          } catch (error) {
            subscriber.error(error);
          }
        })();
      })),
    );
  }
}
