<div class="input-filed">
  <div class="input-filed__input-wrapper">
    <div class="currency-wrapper">
      <img *ngIf="currency"
           class="icon"
           [src]="'assets/icons/currencies/' + currency + '.svg'" [attr.alt]="currency">
      <span class="currency">{{currency}}</span>
    </div>

    <input #input
           class="input-filed__input input-main"
           [attr.name]="label"
           type="text"
           [(ngModel)]="value"
           (ngModelChange)="setValue()"
           (blur)="setTouched()"
           [disabled]="disabled"
           [placeholder]="placeholder">
  </div>
</div>
