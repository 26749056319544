import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';

@Injectable()
export class TranslateCustomService {
  constructor(
    private translateService: TranslateService,
  ) {
  }

  public instant(key : string, values?: { [key: string]: string }): string {
    return this.translateService.instant(key, values);
  }

  public get(key : string, values?: { [key: string]: string }): Observable<string> {
    return this.translateService.get(key, values);
  }
}
