<svg class="settings-btn"
     width="30"
     height="28"
     viewBox="0 0 30 28"
     fill="none"
     (click)="headerSettingsModal.open()">
  <path d="M22.8569 14.1599C22.9072 14.5746 22.9575 14.9894 22.9575 15.4168C22.9575 18.0311 21.952 20.4066 20.318 22.1914C19.9912 21.1733 19.0611 20.4443 17.9299 20.4443H16.673V16.6736C16.673 15.9824 16.1074 15.4168 15.4161 15.4168H7.87473V12.903H10.3885C11.0798 12.903 11.6454 12.3374 11.6454 11.6461V9.13227H14.1592C15.5418 9.13227 16.673 8.00106 16.673 6.61847V3.42595C15.479 3.04888 14.2221 2.84778 12.9023 2.84778C5.96424 2.84778 0.333344 8.47868 0.333344 15.4168C0.333344 22.3548 5.96424 27.9857 12.9023 27.9857C19.8404 27.9857 25.4713 22.3548 25.4713 15.4168C25.4713 14.9894 25.4462 14.5746 25.4084 14.1599H22.8569ZM11.6454 25.3839C6.68068 24.7681 2.84714 20.5449 2.84714 15.4168C2.84714 14.6375 2.94769 13.8959 3.11109 13.1669L9.13162 19.1874V20.4443C9.13162 21.8269 10.2628 22.9581 11.6454 22.9581V25.3839Z" fill="#DDDDDD"/>
  <path d="M28.4333 6.24841C28.4557 6.08007 28.467 5.90612 28.467 5.72094C28.467 5.54137 28.4557 5.36181 28.4277 5.19347L29.5668 4.30686C29.6678 4.2283 29.6959 4.0768 29.6341 3.96457L28.5567 2.10158C28.4894 1.97813 28.3491 1.93885 28.2257 1.97813L26.8845 2.51682C26.604 2.30359 26.3066 2.12402 25.9755 1.98935L25.7735 0.564052C25.751 0.429378 25.6388 0.333984 25.5041 0.333984H23.3493C23.2147 0.333984 23.1081 0.429378 23.0856 0.564052L22.8836 1.98935C22.5525 2.12402 22.2495 2.3092 21.9746 2.51682L20.6334 1.97813C20.51 1.93324 20.3697 1.97813 20.3024 2.10158L19.2306 3.96457C19.1632 4.08241 19.1857 4.2283 19.2979 4.30686L20.437 5.19347C20.409 5.36181 20.3865 5.54699 20.3865 5.72094C20.3865 5.89489 20.3977 6.08007 20.4258 6.24841L19.2867 7.13502C19.1857 7.21358 19.1576 7.36508 19.2193 7.47731L20.2967 9.3403C20.3641 9.46375 20.5044 9.50303 20.6278 9.46375L21.9689 8.92505C22.2495 9.13829 22.5469 9.31785 22.878 9.45253L23.08 10.8778C23.1081 11.0125 23.2147 11.1079 23.3493 11.1079H25.5041C25.6388 11.1079 25.751 11.0125 25.7679 10.8778L25.9699 9.45253C26.301 9.31785 26.604 9.13829 26.8789 8.92505L28.2201 9.46375C28.3435 9.50864 28.4838 9.46375 28.5511 9.3403L29.6285 7.47731C29.6959 7.35386 29.6678 7.21358 29.5612 7.13502L28.4333 6.24841ZM24.4267 7.74105C23.3157 7.74105 22.4066 6.832 22.4066 5.72094C22.4066 4.60988 23.3157 3.70083 24.4267 3.70083C25.5378 3.70083 26.4468 4.60988 26.4468 5.72094C26.4468 6.832 25.5378 7.74105 24.4267 7.74105Z" fill="#DDDDDD"/>
</svg>

<div class="header-settings">
  <div class="header-settings__item">{{('common.header.' + (userSettings$ | async)?.lang) | translate}}</div>

  <div class="header-settings__item">{{('common.header.' + (userSettings$ | async)?.units) | translate}}</div>

  <div class="header-settings__item header-settings__currency">
    <app-amount [currency]="(userSettings$ | async)?.currency"></app-amount>
  </div>

  <div
    (click)="headerSettingsModal.open()"
    class="header-settings__arrow">
    <img src="../../../../../../assets/icons/arrow.svg" alt="arrow">
  </div>
</div>

<ngx-smart-modal
  [backdrop]="true"
  #headerSettingsModal
  identifier="headerSettingsModal"
>
  <div class="form-modal">
    <div class="form-modal__title">
      {{'common.header.measurements' | translate}}
    </div>

    <div class="form-modal__form form-main" [formGroup]="form">
      <div class="form-main__block">
        <div class="form-main__field">
          <app-field-select
            [items]="headerUserSettingsItemsValues.lang"
            class="form-main__field"
            [clearable]="false"
            [label]="'common.header.language' | translate"
            formControlName="lang"
          ></app-field-select>
        </div>

        <div class="form-main__field">
          <app-field-select
            [items]="headerUserSettingsItemsValues.units"
            class="form-main__field"
            [clearable]="false"
            [label]="'common.header.crop_share_measure' | translate"
            formControlName="units"
          ></app-field-select>
        </div>

        <div class="form-main__field">
          <app-field-select
            [items]="headerUserSettingsItemsValues.currency"
            class="form-main__field"
            [clearable]="false"
            [label]="'common.header.currency' | translate"
            formControlName="currency"
          ></app-field-select>
        </div>
      </div>

      <div class="form-main__submit-block">
        <div class="form-main__button">
          <app-button
            primary
            (click)="setUserSettings(); headerSettingsModal.close()"
          >
            {{'common.header.save' | translate}}
          </app-button>
        </div>
      </div>
    </div>
  </div>
</ngx-smart-modal>
