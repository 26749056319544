import { createSelector, State } from '@ngrx/store';
import { AuthState } from '../states/auth.state';
import { RolesNames } from '../../constants/roles-names';

// @ts-ignore
export const selectAuth = (state: State) => state.auth;

export const selectAuthLoggedIn = createSelector(
  selectAuth,
  (state: AuthState) => state.loggedIn,
);

export const selectAuthLoading = createSelector(
  selectAuth,
  (state: AuthState) => state.loading,
);

export const selectAuthToken = createSelector(
  selectAuth,
  (state: AuthState) => state.token,
);

export const selectRoleName = createSelector(
  selectAuth,
  (state: AuthState) => state.role,
);

export const selectRoleIsAdmin = createSelector(
  selectAuth,
  (state: AuthState) => state.role === RolesNames.Admin,
);

export const selectRoleIsVerifier = createSelector(
  selectAuth,
  (state: AuthState) => state.role === RolesNames.Verifier,
);

export const selectRoleIsUser = createSelector(
  selectAuth,
  (state: AuthState) => state.role === RolesNames.User,
);

export const selectIsLoggedInVerifierOrAdmin = createSelector(
  selectAuth,
  (state: AuthState) => (state.loggedIn && state.role === RolesNames.Verifier)
    || (state.loggedIn && state.role === RolesNames.Admin),
);
