import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { LandApiService } from './land.api.service';
import { IHttpResponse } from '../../common/intefaces/http-response.interface';
import { LandInterface } from '../../../shared/modules/lends/interfaces/land.interface';

@Injectable({
  providedIn: 'root',
})
export class LandService {
  constructor(
    private landApiService: LandApiService,
  ) { }

  public lands(filters: { [key: string]: any } = {}): Observable<IHttpResponse<LandInterface[]>> {
    const params: any = {};
    Object.keys(filters).forEach((name) => {
      if (filters[name]) {
        params[name] = filters[name];
      }
    });
    return this.landApiService.lands(params);
  }

  public land(id: number): Observable<LandInterface> {
    return this.landApiService.land(id).pipe(
      map(({ data }) => data),
    );
  }
}
