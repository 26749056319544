import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EMPTY, Observable, throwError } from 'rxjs';
import { catchError, first, switchMap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { JwtHelperService } from '@auth0/angular-jwt';
import { environment } from '../../../../environments/environment';
import { selectAuthToken } from '../store/selectors/autth.selectors';
import { LogOut } from '../store/actions/auth.actions';
import { MainRoutes } from '../constants/main-routes';

export const HTTP_CODE_401 = 401;

export const UNHANDLED_URLS = [
  'public/auth/signin',
];

const helper = new JwtHelperService();

@Injectable()
export class AuthenticationInterceptor implements HttpInterceptor {
  constructor(
    private store: Store,
    private router: Router,
  ) {}

  private static isApiUrl(url: string): boolean {
    return url.startsWith(environment.apiEndpoint);
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (!AuthenticationInterceptor.isApiUrl(req.url)) {
      return next.handle(req);
    }

    return this.store.select(selectAuthToken).pipe(
      first(),
      switchMap((authToken) => {
        let reqClone = req.clone();
        if (authToken && helper.isTokenExpired(authToken)) {
          this.store.dispatch(LogOut());
          return EMPTY;
        }
        if (authToken && !req.headers.has('Authorization')) {
          reqClone = AuthenticationInterceptor.addToken(reqClone, authToken);
        }
        return next.handle(reqClone);
      }),
      catchError((error) => {
        if (error instanceof HttpErrorResponse && error.status === HTTP_CODE_401 && !this.unhandledUrl(req.url)) {
          this.store.dispatch(LogOut());
          this.router.navigate([MainRoutes.Home]).then();
        }
        return throwError(error);
      }),
    );
  }

  private static addToken(request: HttpRequest<any>, token: string): HttpRequest<any> {
    return request.clone({
      headers: request.headers
        .set('Authorization', `Bearer ${token}`),
    });
  }

  private unhandledUrl(url: string) {
    return UNHANDLED_URLS.find((unhandledUrls) => url.includes(unhandledUrls));
  }
}
