<div
  class="filter-group"
  [class.opened]="opened"
>
  <div class="filter-group__header">
    <div class="filter-group__title">
      {{title | translate}}
    </div>

    <div class="filter-group__button"
         (click)="toggle()"
    >
      <img
        class="filter__open-arrow"
        src="../../../../../../assets/icons/arrow-select.svg"
        alt="right-arrow"
      >
    </div>
  </div>

  <div class="filter-group__body" #body>
    <ng-content></ng-content>
  </div>
</div>
