<div class="circle-progress">
  <circle-progress class="circle-progress__circle"
                   [percent]="fill"
                   [maxPercent]="100"
                   [radius]="10"
                   [clockwise]="true"
                   [showTitle]="false"
                   [showSubtitle]="false"
                   [showUnits]="false"
                   [animation]="false"
                   [showInnerStroke]="true"
                   [outerStrokeWidth]="2"
                   [innerStrokeWidth]="2"
                   [backgroundStrokeWidth]="0"
                   [backgroundPadding]="0"
                   [outerStrokeLinecap]="'square'"
                   [space]="-2"
                   [backgroundOpacity]="1"
                   [outerStrokeColor]="'#3083FF'"
                   [innerStrokeColor]="'#C4C4C4'">
  </circle-progress>
  <span class="circle-progress__value">{{ fill }}%</span>
</div>
