import {
  Component, forwardRef, Host, Input, OnInit, Optional, SkipSelf,
} from '@angular/core';
import { NgxDropzoneChangeEvent } from 'ngx-dropzone';
import {
  ControlContainer,
  ControlValueAccessor,
  FormControl,
  NG_VALUE_ACCESSOR,
} from '@angular/forms';
import { VALIDATORS_VALUES } from '../../../core/common/constants/validators-values';
import { FILE_TYPES_IMAGE, FILE_TYPES_IMAGE_FOR_VIEW } from '../../../core/common/constants/mimeTypes';
import { ErrorCodes } from '../../../core/common/constants/error-codes';

export const SIZE_REASON = 'size';

@Component({
  selector: 'app-drag-and-drop',
  templateUrl: './drag-and-drop.component.html',
  styleUrls: ['./drag-and-drop.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DragAndDropComponent),
      multi: true,
    },
  ],
})

export class DragAndDropComponent implements ControlValueAccessor, OnInit {
  @Input() multiple = true;
  @Input() disabled = false;
  @Input() dragZoneLabel = 'Drag  & Drop or <br>Browse your file';
  @Input() resolution: string = '500x500px';
  @Input() extension: string = FILE_TYPES_IMAGE;
  @Input() extensionView: string = FILE_TYPES_IMAGE_FOR_VIEW;
  @Input() maxFileSize: number = VALIDATORS_VALUES.fileSizeLimitMb;
  @Input() formControlName!: string;

  public files: File[] = [];
  public maxFileSizeView: string;
  public touched = false;
  public uploaded: boolean = false;

  private onChange = (value: File[]) => {}; // eslint-disable-line @typescript-eslint/no-unused-vars
  private onTouched = () => {};
  private control: FormControl = new FormControl();

  constructor(
    @Optional() @Host() @SkipSelf()
    private controlContainer: ControlContainer,
  ) {
    this.maxFileSizeView = String(this.maxFileSize / 1024 / 1024);
  }

  ngOnInit() {
    if (this.controlContainer && this.formControlName) {
      this.control = this.controlContainer.control!.get(this.formControlName) as FormControl;
    }
  }

  public writeValue(value: File[]) {
    this.files = [...value];
    this.setUploadedClass();
  }

  public registerOnChange(onChange: any) {
    this.onChange = onChange;
  }

  public registerOnTouched(onTouched: any) {
    this.onTouched = onTouched;
  }

  public setDisabledState(disabled: boolean) {
    this.disabled = disabled;
  }

  public setTouched() {
    if (!this.touched) {
      this.onTouched();
      this.touched = true;
    }
  }

  onSelect($event: NgxDropzoneChangeEvent) {
    if ($event.rejectedFiles.length) {
      // @ts-ignore
      if ($event.rejectedFiles.find(({ reason }) => reason === SIZE_REASON)) {
        this.control.setErrors({ [ErrorCodes.FileSize]: { value: this.maxFileSize } });
      } else {
        this.control.setErrors({ [ErrorCodes.InvalidFileType]: { fileType: this.extension } });
      }
      this.setTouched();
      return;
    }
    this.setTouched();
    if (this.multiple) {
      this.files.push(...$event.addedFiles);
    } else {
      this.files = [...$event.addedFiles];
    }
    this.onChange(this.files);
    this.setUploadedClass();
  }

  onRemove(file: any) {
    this.files.splice(this.files.indexOf(file), 1);
    this.onChange(this.files);
    this.setUploadedClass();
  }

  private setUploadedClass() {
    this.uploaded = this.files.length > 0;
  }
}
