import {
  Component, Input,
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FilterGroupFieldsInterface } from '../../interfaces/filter-group.interface';

@Component({
  selector: 'app-filter-buttons',
  templateUrl: './filter-buttons.component.html',
  styleUrls: ['./filter-buttons.component.scss'],
})
export class FilterButtonsComponent {
  @Input() buttonsGroup?: FilterGroupFieldsInterface;
  @Input() form?: FormGroup;

  public toggleValue(name: string) {
    if (this.form?.get(name)?.value) {
      this.form?.get(name)?.setValue(false);
    } else {
      this.form?.get(name)?.setValue(true);
    }
  }

  public chekButtonSelectedStatus(name: string): boolean {
    return this.form?.get(name)?.value;
  }
}
