<div class="modal">
  <div class="modal__title">
    {{ 'pages.modals.remove_from_sale_title' | translate }}
  </div>

  <div class="modal__subtitle">
    {{ 'pages.modals.do_you_want_to_remove_from_sale' | translate }}
  </div>

  <div class="modal__location-info location-info" *ngIf="land$ | async as land">
    <div class="location-info__title">
      <img class="location-info__icon" src="assets/images/marker.svg" alt="marker.svg"> {{ land.name }}

      <div class="location-info__sub-title">{{ land.location }}</div>
    </div>
  </div>

  <div class="modal__buttons">
    <app-button (btnClick)="submit()" ghost warn class="modal__button">
      {{ 'pages.modals.remove_from_sale' | translate }}
    </app-button>
  </div>
</div>
