import { createSelector, State } from '@ngrx/store';
import { SettingsState } from '../states/settings.state';

// @ts-ignore
export const selectSettingsState = (state: State) => state.settings;

export const selectSettings = createSelector(
  selectSettingsState,
  (state: SettingsState) => state.settings,
);

export const selectSettingsLoading = createSelector(
  selectSettingsState,
  (state: SettingsState) => state.loading,
);

export const selectSettingsSaving = createSelector(
  selectSettingsState,
  (state: SettingsState) => state.saving,
);

export const selectUnits = createSelector(
  selectSettingsState,
  (state: SettingsState) => state.settings.units,
);

export const selectCurrency = createSelector(
  selectSettingsState,
  (state: SettingsState) => state.settings.currency,
);
