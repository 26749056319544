import { Component, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-unsubscribe-destroy-helper',
  template: '<div></div>',
})
export abstract class UnsubscribeDestroyHelperComponent implements OnDestroy {
  protected unsubscribeSubject = new Subject();

  public ngOnDestroy() {
    this.unsubscribeSubject.next();
    this.unsubscribeSubject.complete();
  }
}
