import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AUTH_API } from '../api/auth.api';
import { IHttpResponse } from '../../common/intefaces/http-response.interface';

@Injectable({
  providedIn: 'root',
})
export class AuthApiService {
  constructor(
    private httpClient: HttpClient,
  ) {
  }

  public signIn(body: { email: string, password: string }): Observable<IHttpResponse<{ token: string }>> {
    return this.httpClient.post<IHttpResponse<{ token: string }>>(
      AUTH_API.signIn,
      body,
    );
  }

  public signUp(body: { email: string, password: string, name: string }): Observable<IHttpResponse<{ token: string }>> {
    return this.httpClient.post<IHttpResponse<{ token: string }>>(
      AUTH_API.signUp,
      body,
    );
  }

  public refresh(token: string): Observable<IHttpResponse<{ token: string }>> {
    return this.httpClient.post<IHttpResponse<{ token: string }>>(
      AUTH_API.refresh,
      {},
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );
  }

  public generateNonceWithMetamask(body: { metamaskEthAddress: string }) {
    return this.httpClient.post<IHttpResponse<{ nonce: string }>>(
      AUTH_API.generateNonce,
      body,
    );
  }

  public loginWithMetamask(
    body: { metamaskEthAddress: string; metamaskEthSignature: string },
  ): Observable<IHttpResponse<{ token: string }>> {
    return this.httpClient.post<IHttpResponse<{ token: string }>>(
      AUTH_API.signinWithMetamask,
      body,
    );
  }
}
