import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl, FormGroup } from '@angular/forms';
import {
  FilterButtonsGroupInterface, FilterCheckboxFieldInterface,
  FilterFormFieldsInterface,
  FilterGroupFieldsInterface,
} from '../../interfaces/filter-group.interface';
import { FilterFieldsTypes, FilterGroupsTypes } from '../../constants/filter-group-types';
import { CrumbInterface } from '../../interfaces/crumb.interface';
import { DATE_FORMAT_12_HOURS } from '../../../../../core/common/constants/common-settings-values';

@Component({
  selector: 'app-filter-crumbs',
  templateUrl: './filter-crumbs.component.html',
  styleUrls: ['./filter-crumbs.component.scss'],
})
export class FilterCrumbsComponent implements OnInit {
  @Input() public set groups(groups: FilterGroupFieldsInterface[]) {
    this.currentGroups = groups;
    this.createCrumbs();
  }
  @Input() public form?: FormGroup;
  @Input() public resultCounter: number | undefined;

  public dateFormat = DATE_FORMAT_12_HOURS;
  public crumbs: Set<CrumbInterface> = new Set();
  public get crumbsList(): CrumbInterface[] {
    return Array.from(this.crumbs);
  }

  private currentGroups: FilterGroupFieldsInterface[] = [];

  ngOnInit(): void {
    this.subscribeToFormChanges();
  }

  private createCrumbs() {
    this.crumbs.clear();
    const price = {
      type: 'price',
      currency: '',
      min: '',
      max: '',
      controls: [],
    };
    const area = {
      type: 'area',
      label: 'area',
      units: '',
      min: '',
      max: '',
      controls: [],
    };
    const date = {
      type: 'date',
      label: 'date',
      min: '',
      max: '',
      controls: [] as AbstractControl[],
    };
    this.currentGroups?.forEach((fields) => {
      if (fields.control && (fields.type !== FilterGroupsTypes.Form)) {
        if (fields.control.value) {
          this.crumbs.add({
            name: fields.name,
            control: fields.control,
            label: fields.control.value,
          });
        }
      } else if (fields.items) {
        fields.items.forEach(
          (field: FilterFormFieldsInterface | FilterButtonsGroupInterface | FilterCheckboxFieldInterface) => {
            if ('controlMax' in field) {
              if (field.controlMax.value) {
                if (field.name === 'price') {
                  price.max = `${field.controlMax.value}`;
                  price.currency = field.currency!;
                  // @ts-ignore
                  price.controls.push(field.controlMax);
                } else if (field.name === 'area') {
                  area.max = `${field.controlMax.value}`;
                  area.units = field.areaUnits!;
                  // @ts-ignore
                  area.controls.push(field.controlMax);
                } else {
                  this.crumbs.add({
                    name: `${field.name}:lte`,
                    control: field.controlMax,
                    label: `${(field.label || '')}_max`,
                    value: `${field.controlMax.value}`,
                  });
                }
              }
            }
            if ('controlMin' in field) {
              if (field.controlMin.value) {
                if (field.name === 'price') {
                  price.min = `${field.controlMin.value}`;
                  price.currency = field.currency!;
                  // @ts-ignore
                  price.controls.push(field.controlMin);
                } else if (field.name === 'area') {
                  area.min = `${field.controlMin.value}`;
                  area.units = field.areaUnits!;
                  // @ts-ignore
                  area.controls.push(field.controlMin);
                } else {
                  this.crumbs.add({
                    name: `${field.name}:gte`,
                    control: field.controlMin,
                    label: `${(field.label || '')}_min`,
                    value: `${field.controlMin.value}`,
                  });
                }
              }
            }
            if ('control' in field && 'type' in field && field.type === FilterFieldsTypes.Select) {
              if (field.control?.value?.label) {
                this.crumbs.add({
                  name: field.name,
                  control: field.control,
                  label: field.control.value?.label,
                });
              }
              if (field.name === 'currency') {
                price.currency = field.control.value;
                return;
              }
            }
            if ('control' in field && 'type' in field && field.type === FilterFieldsTypes.DateFrom) {
              if (field.control.value) {
                date.min = `${field.control.value}`;
                date.controls.push(field.control);
              }
            }
            if ('control' in field && 'type' in field && field.type === FilterFieldsTypes.DateTo) {
              if (field.control.value) {
                date.max = `${field.control.value}`;
                date.controls.push(field.control);
              }
            }
            if ('control' in field && 'label' in field && !('type' in field)) {
              if (field.control.value) {
                this.crumbs.add({
                  name: field.name,
                  control: field.control,
                  label: field.label || '',
                });
              }
            }
          },
        );
      }
    });
    if (price.min || price.max) {
      this.crumbs.add(price);
    }
    if (area.min || area.max) {
      this.crumbs.add(area);
    }
    if (date.min || date.max) {
      this.crumbs.add(date);
    }
  }

  private subscribeToFormChanges() {
    this.form?.valueChanges.subscribe(
      () => {
        this.createCrumbs();
      },
    );
  }

  remove(crumb: CrumbInterface) {
    const resetValue = (typeof crumb.control?.value === 'boolean') ? false : null;
    if (crumb.control) {
      crumb.control.setValue(resetValue, { emitEvent: true });
    }
    if (crumb.controls) {
      crumb.controls.forEach((control) => {
        control.setValue(resetValue, { emitEvent: true });
      });
    }
    this.crumbs.delete(crumb);
  }

  public clear() {
    this.crumbsList.forEach((crumb) => {
      this.remove(crumb);
    });
  }
}
