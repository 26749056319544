import {
  Attribute,
  Component, ElementRef, EventEmitter, forwardRef, Input, Output, ViewChild,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'app-field-input',
  templateUrl: './field-input.component.html',
  styleUrls: ['./field-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FieldInputComponent),
      multi: true,
    },
  ],
})

export class FieldInputComponent implements ControlValueAccessor {
  @ViewChild('input') input!: ElementRef;

  @Input() label?: string = '';
  @Input() note = '';
  @Input() error = '';
  @Input() placeholder = '';
  @Input() formControlName: string | undefined;
  @Input() disabled = false;
  @Input() white: boolean = false;
  @Input() black: boolean = false;
  @Input() iconPath?: string;
  @Input() minValue: string = '';
  @Input() inputId?: string;

  // eslint-disable-next-line @angular-eslint/no-output-native
  @Output() blur: EventEmitter<void> = new EventEmitter();

  public value: string | number | null = null;
  public touched = false;
  public type: string;
  public initialType: string;
  public dateTo = false;
  public dateFrom = false;

  private onChange = (value: string | number | null) => {}; // eslint-disable-line @typescript-eslint/no-unused-vars
  private onTouched = () => {};

  constructor(
    @Attribute('white') public attrWhite: string | null,
    @Attribute('type') public attrType: string | null,
    @Attribute('date-to') public attrDateTo: string | null,
    @Attribute('date-from') public attrDateForm: string | null,
  ) {
    this.white = typeof attrWhite === 'string';
    this.initialType = attrType || 'text';
    this.type = this.initialType;
    this.dateTo = typeof attrDateTo === 'string';
    this.dateFrom = typeof attrDateForm === 'string';
  }

  public focus() {
    setTimeout(() => { // this will make the execution after the above boolean has changed
      this.input.nativeElement.focus();
    }, 0);
  }

  public writeValue(value: string | number | null) {
    if (value && this.type === 'date') {
      [this.value] = new Date(value).toISOString().split('T');
    } else {
      this.value = value;
    }
  }

  public registerOnChange(onChange: any) {
    this.onChange = onChange;
  }

  public registerOnTouched(onTouched: any) {
    this.onTouched = onTouched;
  }

  public setDisabledState(disabled: boolean) {
    this.disabled = disabled;
  }

  public setValue() {
    if (!this.disabled) {
      if (this.type === 'date' && this.value) {
        const date = new Date(this.value as string);
        if (this.dateTo) {
          date.setUTCHours(23, 59, 59);
        } else if (this.dateFrom) {
          date.setUTCHours(0, 0, 0);
        }
        this.onChange(date.toISOString());
      } else {
        this.onChange(this.value);
      }
    }
  }

  public setTouched() {
    this.blur.emit();
    if (!this.touched) {
      this.onTouched();
      this.touched = true;
    }
  }

  public showPassword() {
    if (this.type === 'password') {
      this.type = 'text';
    } else {
      this.type = 'password';
    }
  }
}
