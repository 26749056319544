import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ModalNotificationsComponent } from './container-components/modal-notifications/modal-notifications.component';
import { ModalNotificationsService } from './services/modal-notifications.service';
import { SharedModule } from '../../shared.module';

@NgModule({
  declarations: [
    ModalNotificationsComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
  ],
  exports: [
    ModalNotificationsComponent,
  ],
  providers: [
    ModalNotificationsService,
  ],
})
export class ModalNotificationsModule { }
