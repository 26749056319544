import { Component } from '@angular/core';
import { Observable } from 'rxjs';
import { ModalService } from '../../services/modal.service';
import { PreviewInterface } from '../../../../../landx-shard/interfaces/preview.interface';
import { ShardCultureNames } from '../../../../../user-profile/enums/shard-culture-names.enum';

@Component({
  selector: 'app-nft-to-shard-success-modal',
  templateUrl: './nft-to-shard-success-modal.component.html',
  styleUrls: ['../../../../../styles/blocks/modals.scss'],
})
export class NftToShardSuccessModalComponent {
  static readonly modalIdentifier = 'nftToShardSuccessModal';
  public $preview: Observable<PreviewInterface>;
  public shardCultureNames: { [key: string]: string } = ShardCultureNames;

  constructor(
    private modalService: ModalService,
  ) {
    this.$preview = this.modalService.getModalData(NftToShardSuccessModalComponent.modalIdentifier).preview;
  }

  public close() {
    this.modalService.close(NftToShardSuccessModalComponent.modalIdentifier);
  }

  public getCultureByType(shardType: string): string {
    return this.shardCultureNames[shardType];
  }
}
