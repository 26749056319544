import { Injectable } from '@angular/core';
import { Observable, Subscriber } from 'rxjs';
import { AbiItem } from 'ethereum-abi-types-generator';
import { switchMap } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { Web3Service } from '../../core/common/services/web3.service';
import { ContractService } from '../../core/common/services/contract.service';
import { selectShardsAddressesWithCultureNames } from '../../core/common/store/selectors/network.selector';

@Injectable()
export class WriteShardService {
  constructor(
    private web3: Web3Service,
    private contractService: ContractService,
    private store: Store,
  ) {}

  public increaseAllowance(from: string, addedValue: number): Observable<string> {
    return this.contractService.contracts().pipe(
      switchMap((contracts) => new Observable((subscriber: Subscriber<string>) => {
        (async () => {
          try {
            const abi: AbiItem[] = [
              {
                name: 'increaseAllowance',
                inputs: [
                  {
                    internalType: 'address',
                    name: 'spender',
                    type: 'address',
                  },
                  {
                    internalType: 'uint256',
                    name: 'addedValue',
                    type: 'uint256',
                  },
                ],
                outputs: [],
                stateMutability: 'payable',
                type: 'function',
              },
            ];
            const contract = new this.web3.eth.Contract(abi, contracts.ldxsShardManagerContractAddress);
            contract.methods.increaseAllowance(contracts.ldxsShardManagerContractAddress,
              this.web3.utils.toWei(addedValue.toString())).send({
              from,
            }).then((block: { blockHash: string }) => {
              subscriber.next(block.blockHash);
            }).catch((error: any) => {
              subscriber.error(error);
            });
          } catch (error) {
            subscriber.error(error);
          }
        })();
      })),
    );
  }

  public getShards(from: string, id: number, shardType: string): Observable<string> {
    return this.store.select(selectShardsAddressesWithCultureNames).pipe(
      switchMap((contracts: { [key: string]: string }) => new Observable((subscriber: Subscriber<string>) => {
        (async () => {
          try {
            const abi: AbiItem[] = [
              {
                name: 'getShards',
                inputs: [
                  {
                    internalType: 'uint256',
                    name: '_id',
                    type: 'uint256',
                  },
                ],
                outputs: [],
                stateMutability: 'payable',
                type: 'function',
              },
            ];
            const contract = new this.web3.eth.Contract(abi, contracts[shardType]);
            contract.methods.getShards(id).send({
              from,
            }).then((block: { blockHash: string }) => {
              subscriber.next(block.blockHash);
            }).catch((error: any) => {
              subscriber.error(error);
            });
          } catch (error) {
            subscriber.error(error);
          }
        })();
      })),
    );
  }

  public getNft(from: string, id: number, contractAddress: string): Observable<string> {
    return new Observable((subscriber: Subscriber<string>) => {
      (async () => {
        try {
          const abi: AbiItem[] = [
            {
              name: 'getTheNFT',
              inputs: [
                {
                  internalType: 'uint256',
                  name: '_id',
                  type: 'uint256',
                },
              ],
              outputs: [],
              stateMutability: 'payable',
              type: 'function',
            },
          ];
          const contract = new this.web3.eth.Contract(abi, contractAddress);
          contract.methods.getTheNFT(id).send({
            from,
          }).then((block: { blockHash: string }) => {
            subscriber.next(block.blockHash);
          }).catch((error: any) => {
            subscriber.error(error);
          });
        } catch (error) {
          subscriber.error(error);
        }
      })();
    });
  }
}
