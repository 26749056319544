<div class="modal">
  <img class="modal__icon" src="assets/icons/success.svg" alt="success icon">

  <div class="modal__title">
    {{ 'common.notifications.success' | translate }}!
  </div>

  <span class="modal__subtitle">
    {{ 'pages.modals.operation_successfully_completed' | translate }}
  </span>

  <div class="modal__buttons">
    <app-button (btnClick)="close()" primary class="modal__button">
      {{ 'pages.modals.ok' | translate }}
    </app-button>
  </div>
</div>
