import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { MetamaskService } from '../../core/auth/services/metamask.service';
import { EthAccountInterface } from '../../core/common/intefaces/eth-account.interface';
import { CropShareApiService } from '../../user-profile/services/crop-share/crop-share.api.service';
import { WithdrawInterface } from '../../user-profile/interfaces/widthdraw.interface';
import { WriteRentFoundationService } from './write-rent-foundation.service';
import { Web3Service } from '../../core/common/services/web3.service';

@Injectable()
export class WithdrawFacadeService {
  constructor(
    private metamaskService: MetamaskService,
    private cropShareApiService: CropShareApiService,
    private writeRentFoundationService: WriteRentFoundationService,
    private web3: Web3Service,
  ) {
  }

  public account(): Observable<EthAccountInterface|undefined> {
    return this.metamaskService.getAccounts().pipe(
      map((accounts) => (accounts ? accounts[0] : undefined)),
    );
  }

  public initWithdraw(): Observable<WithdrawInterface> {
    return this.cropShareApiService.withdraw().pipe(
      map((response) => {
        response.data.convertedAmount = this.web3.utils.fromWei(response.data.amount.toString());
        return response.data;
      }),
    );
  }

  public withdrawRent(from: string, amount: number, nonce: number, sig: string): Observable<string> {
    return this.writeRentFoundationService.withdrawRent(from, amount, nonce, sig);
  }
}
