import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../../shared.module';
import { MakeBidModalComponent } from './components/make-bid-modal/make-bid-modal.component';
import { BuyModalComponent } from './components/buy-modal/buy-modal.component';
import { CheckoutConfirmModalComponent }
  from './components/checkout-confirm-modal/checkout-confirm-modal.component';
import { CheckoutSuccessModalComponent } from './components/checkout-success-modal/checkout-success-modal.component';
import { NftToShardModalComponent } from './components/nft-to-shard-modal/nft-to-shard-modal.component';
import { SuccessModalWrapperComponent } from './components/success-modal-wrapper/success-modal-wrapper.component';
import {
  NftToShardSuccessModalComponent,
} from './components/nft-to-shard-success-modal/nft-to-shard-success-modal.component';
import { MakeOfferModalComponent } from './components/make-offer-modal/make-offer-modal.component';
import { RemoveFromSaleModalComponent } from './components/remove-from-sale/remove-from-sale-modal.component';
import { AuctionModule } from '../../../auction/auction.module';
import { CheckoutFailedModalComponent } from './components/checkout-failed-modal/checkout-failed-modal.component';
import { PendingModalComponent } from './components/pending-modal/pending-modal.component';
import { SuccessModalComponent } from './components/success-modal/success-modal.component';
import { CancelOfferModalComponent } from './components/cancel-offer-modal/cancel-offer-modal.component';
import { AcceptOfferModalComponent } from './components/accept-offer-modal/accept-offer-modal.component';
import { RejectOfferModalComponent } from './components/reject-offer-modal/reject-offer-modal.component';
import { FinishAuctionModalComponent } from './components/finish-auction/finish-auction-modal.component';
import { LandxShardModule } from '../../../landx-shard/landx-shard.module';
import { ShardToNftModalComponent } from './components/shard-to-nft-modal/shard-to-nft-modal.component';
import {
  ShardToNftSuccessModalComponent,
} from './components/shard-to-nft-success-modal/shard-to-nft-success-modal.component';
import { WtcModule } from '../../../wtc/wtc.module';
import { RentFoundationModule } from '../../../rent-foundation/rent-foundation.module';
import { SelectModalComponent } from './components/select-modal/select-modal.component';
import { ConfirmModalComponent } from './components/confirm-modal/confirm-modal.component';
import {
  ShareLinkToSocialNetworksComponent,
} from './components/share-link-to-social-networks/share-link-to-social-networks.component';

@NgModule({
  declarations: [
    CheckoutSuccessModalComponent,
    BuyModalComponent,
    CheckoutConfirmModalComponent,
    MakeBidModalComponent,
    NftToShardModalComponent,
    SuccessModalWrapperComponent,
    NftToShardSuccessModalComponent,
    MakeOfferModalComponent,
    RemoveFromSaleModalComponent,
    CheckoutFailedModalComponent,
    PendingModalComponent,
    SuccessModalComponent,
    CancelOfferModalComponent,
    AcceptOfferModalComponent,
    RejectOfferModalComponent,
    FinishAuctionModalComponent,
    ShardToNftModalComponent,
    ShardToNftSuccessModalComponent,
    ConfirmModalComponent,
    SelectModalComponent,
    ShareLinkToSocialNetworksComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    AuctionModule,
    LandxShardModule,
    RentFoundationModule,
    WtcModule,
  ],
  exports: [
    MakeOfferModalComponent,
    SuccessModalWrapperComponent,
  ],
})
export class ModalModule { }
