import { DealTypes } from '../../core/common/constants/deal-types';

export class LandRequestModel {
  public location?: string;
  public name?: string;
  public countryCode?: string;
  public cadastreNumber?: string;
  public description?: string;
  public ownerType?: string;
  public ownerName?: string;
  public ownerAddress?: string;
  public inn?: number;
  public arableArea?: number;
  public irrigatedArea?: number;
  public pastureArea?: number;
  public hayfieldArea?: number;
  public forestBeltArea?: number;
  public unusableArea?: number;
  public includeIrrigatedArable?: boolean;
  public irrigatedType?: string;
  public irrigatedWaterSource?: string;
  public documents: string[];
  public gallery: string[];
  public cropRotations: {
    cultureId: number,
    year: number
    yield:number,
  }[];
  public fields?: {
    name: string,
    area: number
  }[];
  public hasLease?: boolean;
  public leaseDetails?: string;
  public hasMortgage?: boolean;
  public mortgageDetails?: string;
  public earnings?: {
    type: number,
    year: number
    value: number,
  }[];
  public benefits?: {
    name?: string,
    details?: string,
  }[];
  public productDetails?: {
    dealType?: DealTypes;
    nftPriceHa?: number;
    cropShare?: number;
    auctionSalePeriod?: number;
    auctionStartPrice?: number;
    cropShareType?: string;
  };
  public landOptions?: {
    becomeLandOwner?: boolean,
    optionPremium?: string,
    optionStrikePrice?: string,
    duration?: string
  };

  constructor(data: any) {
    if (data.location) {
      this.location = data.location;
    }
    if (data.name) {
      this.name = data.name;
    }
    if (data.cadastreNumber) {
      this.cadastreNumber = data.cadastreNumber;
    }
    if (data.countryCode) {
      this.countryCode = data.countryCode;
    }
    if (data.description) {
      this.description = data.description;
    }
    if (data.ownerType) {
      this.ownerType = data.ownerType;
    }
    if (data.ownerName) {
      this.ownerName = data.ownerName;
    }
    if (data.ownerAddress) {
      this.ownerAddress = data.ownerAddress;
    }
    if (data.inn) {
      this.inn = data.inn;
    }
    if (data.arableArea) {
      this.arableArea = data.arableArea;
    }
    if (data.irrigatedArea) {
      this.irrigatedArea = data.irrigatedArea;
    }
    if (data.pastureArea) {
      this.pastureArea = data.pastureArea;
    }
    if (data.hayfieldArea) {
      this.hayfieldArea = data.hayfieldArea;
    }
    if (data.forestBeltArea) {
      this.forestBeltArea = data.forestBeltArea;
    }
    if (data.unusableArea) {
      this.unusableArea = data.unusableArea;
    }
    if (data.includeIrrigatedArable) {
      this.includeIrrigatedArable = data.includeIrrigatedArable;
    }
    if (data.irrigatedType) {
      this.irrigatedType = data.irrigatedType;
    }
    if (data.irrigatedWaterSource) {
      this.irrigatedWaterSource = data.irrigatedWaterSource;
    }
    if (data.documents?.length) {
      this.documents = data.documents.map((id: string) => ({ landFileId: Number(id) }));
    } else {
      this.documents = [];
    }
    if (data.gallery?.length) {
      this.gallery = data.gallery.map((id: string) => ({ landFileId: Number(id) }));
    } else {
      this.gallery = [];
    }

    this.cropRotations = this.replaceEmptyToZero<{
      cultureId: number,
      year: number
      yield:number,
    }>(data.cropRotations).map((item) => ({ ...item, cultureId: item.cultureId }));

    if (data.fields) {
      this.fields = data.fields;
    }
    if (data.hasLease) {
      this.hasLease = data.hasLease;
    }
    if (data.leaseDetails) {
      this.leaseDetails = data.leaseDetails;
    }
    if (data.hasMortgage) {
      this.hasMortgage = data.hasMortgage;
    }
    if (data.mortgageDetails) {
      this.mortgageDetails = data.mortgageDetails;
    }
    this.earnings = this.replaceEmptyToZero<{
      type: number,
      year: number
      value: number,
    }>(data.earnings);
    if (data.benefits) {
      this.benefits = data.benefits;
    }
    this.productDetails = {};
    if (data.dealType === DealTypes.FixPrice) {
      if (data.dealType) {
        this.productDetails.dealType = data.dealType;
      }
      if (data.nftPriceHa) {
        this.productDetails.nftPriceHa = data.nftPriceHa;
      }
      if (data.cropShare) {
        this.productDetails.cropShare = data.cropShare;
      }
    } else {
      if (data.dealType) {
        this.productDetails.dealType = data.dealType;
      }
      if (parseFloat(data.nftPriceHa)) {
        this.productDetails.nftPriceHa = parseFloat(data.nftPriceHa);
      }
      if (parseFloat(data.cropShare)) {
        this.productDetails.cropShare = parseFloat(data.cropShare);
      }
      if (data.auctionSalePeriod) {
        this.productDetails.auctionSalePeriod = data.auctionSalePeriod;
      }
      if (data.cropShareType) {
        this.productDetails.cropShareType = data.cropShareType;
      }
      if (parseFloat(data.auctionStartPrice)) {
        this.productDetails.auctionStartPrice = parseFloat(data.auctionStartPrice);
      }
    }
    this.landOptions = {};
    this.landOptions.becomeLandOwner = data.becomeLandOwner || false;
    if (data.optionStrikePrice) {
      this.landOptions.optionStrikePrice = data.optionStrikePrice;
    }
    if (data.duration) {
      this.landOptions.duration = data.duration;
    }
  }

  private replaceEmptyToZero<T>(array: T[]): T[] {
    return array.map((item: T) => {
      const newItem: T = { ...item };
      Object.keys(item).forEach((key: string) => {
        // @ts-ignore
        newItem[key] = item[key] ? item[key] : 0;
      });
      return newItem;
    });
  }
}
