<div class="tooltip-wrapper"
     appClickOutside
     (clickOutside)="hide($event)">
  <img class="info"
       src="assets/icons/info.svg"
       alt="info"
       (click)="show(isShow)">
  <div class="main-tooltip-block"
       [class.top]="positionY === TooltipPositionY.Top"
       [class.bottom]="positionY === TooltipPositionY.Bottom"
       [class.right]="positionX === TooltipPositionX.Right"
       [class.left]="positionX === TooltipPositionX.Left"
       [style.display]="isDisplay ? 'block' : 'none'"
       [style.opacity]="isShow ? 1 : 0">
    <div class="triangle"></div>
    <div class="tooltip-info">
      <ng-content></ng-content>
    </div>
  </div>
</div>
