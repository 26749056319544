import {
  Component,
  ElementRef,
  HostListener,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ActionInterface } from '../../../core/common/intefaces/action-interface';

@Component({
  selector: 'app-context-menu',
  templateUrl: './context-menu.component.html',
  styleUrls: ['./context-menu.component.scss'],
})
export class ContextMenuComponent implements OnInit {
  @Input() public items: ActionInterface[] = [];
  @Input() public prefix: string = '';
  @Input() public disabled: boolean = false;
  @ViewChild('switcher') switcher?: ElementRef;

  public opened: boolean = false;
  public selectItems: any[] = [];
  public form = new FormGroup({
    menu: new FormControl(null),
  });

  public positionX = 0;
  public positionY = 0;

  @HostListener('document:click', ['$event'])
  documentClick(event: any) {
    if (event.target !== this.switcher?.nativeElement) {
      this.close();
    }
  }

  ngOnInit(): void {
    this.selectItems = this.items.map((item, index) => ({ ...item, id: index }));
    this.form.valueChanges.subscribe(() => {
      this.selectItem(this.form.controls.menu.value);

      if (this.form.controls.menu.value) {
        this.form.controls.menu.setValue(null);
      }
    });
  }

  public open() {
    if (this.disabled) {
      return;
    }

    this.opened = true;
  }

  public close() {
    this.opened = false;
  }

  public toggle(event: MouseEvent) {
    this.positionX = event.clientX;
    this.positionY = event.clientY + 15;

    if (this.disabled) {
      return;
    }

    this.opened = !this.opened;
  }

  public selectItem(item: any) {
    if (!item) {
      return;
    }

    const selectedItem = this.items[item.id];
    selectedItem?.action(selectedItem.userId);
  }
}
