import {
  Attribute, Component, EventEmitter, Input, Output,
} from '@angular/core';

@Component({
  selector: 'app-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
})
export class ButtonComponent {
  @Input() public icon: string = '';
  @Input() public iconHover: string = '';
  @Input() public selected: boolean = false;
  @Input() public routerLink: string | undefined;

  @Input() set disabled(value: boolean) {
    this.disabledButton = value ? true : undefined;
  }

  @Input() public tip: string = '';

  @Output() btnClick = new EventEmitter();

  public disabledButton: boolean | undefined;
  public primary: boolean = false;
  public action: boolean = false;
  public secondary: boolean = false;
  public ghost: boolean = false;
  public ghostSecondary: boolean = false;
  public text: boolean = false;
  public crumb: boolean = false;
  public crumbSecondary: boolean = false;
  public warn: boolean = false;
  public black: boolean = false;
  public light: boolean = false;
  public blue: boolean = false;
  public gray: boolean = false;
  public navigateBack: boolean = false;
  public headerNavigation: boolean = false;
  public small: boolean = false;

  constructor(
    @Attribute('type') public type: string | null,
    @Attribute('primary') public attrPrimary: string | null,
    @Attribute('action') public attrAction: string | null,
    @Attribute('secondary') public attrSecondary: string | null,
    @Attribute('ghost') public attrGhost: string | null,
    @Attribute('ghostSecondary') public attrGhostSecondary: string | null,
    @Attribute('text') public attrText: string | null,
    @Attribute('crumb') public attrCrumb: string | null,
    @Attribute('crumbSecondary') public attrCrumbSecondary: string | null,
    @Attribute('warn') public attrWarn: string | null,
    @Attribute('black') public attrBlack: string | null,
    @Attribute('light') public attrLight: string | null,
    @Attribute('blue') public attrBlue: string | null,
    @Attribute('gray') public attrGray: string | null,
    @Attribute('small') public attrSmall: string | null,
    @Attribute('navigateBack') public attrNavigateBack: string | null,
    @Attribute('headerNavigation') public attrHeaderNavigation: string | null,
  ) {
    this.primary = typeof attrPrimary === 'string';
    this.action = typeof attrAction === 'string';
    this.secondary = typeof attrSecondary === 'string';
    this.ghost = typeof attrGhost === 'string';
    this.text = typeof attrText === 'string';
    this.ghostSecondary = typeof attrGhostSecondary === 'string';
    this.crumb = typeof attrCrumb === 'string';
    this.crumbSecondary = typeof attrCrumbSecondary === 'string';
    this.warn = typeof attrWarn === 'string';
    this.black = typeof attrBlack === 'string';
    this.light = typeof attrLight === 'string';
    this.blue = typeof attrBlue === 'string';
    this.gray = typeof attrGray === 'string';
    this.small = typeof attrSmall === 'string';
    this.navigateBack = typeof attrNavigateBack === 'string';
    this.headerNavigation = typeof attrHeaderNavigation === 'string';
  }

  public click(): void {
    if (!this.disabledButton) {
      this.btnClick.emit();
    }
  }
}
