<ng-container [formGroup]="form">
  <ng-select
    class="white"
    [loading]="loading"
    [title]="title"
    [searchable]="searchable"
    [clearable]="clearable"
    [multiple]="multiple"
    [placeholder]="placeholder"
    [items]="items"
    [bindValue]="bindValue"
    [bindLabel]="bindLabel"
    [groupBy]="groupBy"
    [formControlName]="controlName"
    [typeahead]="typeahead"
    (ngModelChange)="valueChanged.emit($event)"
    [closeOnSelect]="!multiple || items?.length === 1"
  >
  </ng-select>
</ng-container>
