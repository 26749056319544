import {
  Component,
  ElementRef,
  HostListener,
  Input,
  ViewChild,
} from '@angular/core';
import { Observable } from 'rxjs';
import { AuthService } from '../../../core/auth/services/auth.service';
import { ModalService } from '../../../shared/modules/modals/services/modal.service';
import { WalletInfoPopupComponent } from '../wallet-info-popup/wallet-info-popup.component';
import { UserInterface } from '../../../core/user/interfaces/user.interface';
import { HeaderFacadeService } from '../../services/header.facade.service';

@Component({
  selector: 'app-user-menu',
  templateUrl: './user-menu.component.html',
  styleUrls: ['./user-menu.component.scss'],
})
export class UserMenuComponent {
  @Input() public admin: boolean = false;
  @Input() public loggedIn!: boolean;
  @Input() public avatarUrl?: string;
  @Input() public user?: UserInterface;
  @Input() public hasNotifications: boolean = false;

  @ViewChild('avatar') avatar?: ElementRef;

  public menuOpened: boolean = false;
  public walletAddressWrong$: Observable<boolean>;
  public totalCountProcessingTransaction$: Observable<number>;

  @HostListener('document:click', ['$event'])
  documentClick(event: any) {
    if (event.target.parentElement !== this.avatar?.nativeElement) {
      this.hideMenu();
    }
  }

  constructor(
    private authService: AuthService,
    private modalService: ModalService,
    private headerFacadeService: HeaderFacadeService,
  ) {
    this.walletAddressWrong$ = authService.walletAddressWrong$();
    this.totalCountProcessingTransaction$ = this.headerFacadeService.totalCountProcessingTransaction();
  }

  public openMenu(): void {
    if (this.loggedIn) {
      this.menuOpened = !this.menuOpened;
      return;
    }

    this.authService.connectWallet();
  }

  public connectWallet(): void {
    this.authService.connectWallet();
  }

  public hideMenu() {
    this.menuOpened = false;
  }

  public openWalletInfoPopup(): void {
    this.modalService.open(WalletInfoPopupComponent.modalIdentifier, WalletInfoPopupComponent);
  }
}
