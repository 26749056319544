<form
  class="modal"
  (submit)="submit()"
  [formGroup]="form"
  *ngIf="land$ | async as land"
>
  <div class="modal__title">
    {{ 'pages.modals.checkout' | translate }}
  </div>

  <div class="modal__subtitle">
    {{ 'pages.modals.purchase_of' | translate }}<br>{{ 'pages.modals.to_collect' | translate }}
  </div>

  <div *ngIf="(currency$| async) === currenciesNames.USDC" class="warning__subtitle-payments">
    <div class="subtitle-payments__icon">
      <img src="./assets/icons/info-full-blue.svg">
    </div>
    <div class="subtitle-payments__info">{{ 'pages.modals.all_payments_are_available_only_in_wtc' | translate }}</div>
    <a class="subtitle-payments__link">{{ 'pages.landing.more' | translate }}</a>
  </div>


  <div class="modal__location-info location-info">
    <div class="location-info__title">
      <img class="location-info__icon" src="assets/images/marker.svg" alt="marker.svg"> {{ land.name }}
      <div class="location-info__sub-title">{{ land.location }}</div>
    </div>
  </div>

  <div class="modal__land-info land-info">
    <div class="land-info__item">
      {{ 'pages.modals.crop_share_whet' | translate }}:
      <span class="land-info__highlight">{{land.productDetails?.cropShare | number : amountFormat}}</span>
      {{ ('common.units.' + (units$ | async)) | translate }}
    </div>
    &nbsp;&#8231;&nbsp;
    <div class="land-info__item">
      <span class="land-info__highlight">{{ land.area }}</span>
      {{ ('common.units.' + areaUnits[(units$ | async)]) | translate }}
    </div>
  </div>

  <ul class="modal__total-info total-info">
    <li class="total-info__row">
      <div class="total-info__label">{{'pages.modals.nft_price' | translate}}:</div>

      <div class="total-info__value">{{ data.nftPrice | number }} {{ land.productDetails.currency }}</div>
    </li>

    <li class="total-info__row total-info__row--line-divider"></li>

    <li class="total-info__row total-info__row--main">
      <div class="total-info__label">{{ 'pages.modals.total_amount' | translate }}:</div>

      <div class="total-info__value"><strong>{{ getTotalAmount() }}</strong> {{ land.productDetails.currency }}</div>
    </li>
  </ul>

  <div class="modal__bottom-note">
    {{ 'pages.modals.by_clicking_complete_purchase' | translate }}
  </div>

  <div *ngIf="this.balance < this.data.nftPrice" class="modal__warning-window">
    <div class="warning-window__info">
      <div class="warning-window__icon"><img src="./../../../assets/icons/warning-triangle.svg"></div>
      <div class="warning-window__description">{{'pages.modals.do_not_have_balance' | translate : { currency: (land.productDetails.currency) } }}
        <a>{{'pages.modals.buy_more' | translate}}</a>
        {{land.productDetails.currency | currencyFromCulture | uppercase}}
      </div>
    </div>
  </div>

  <div class="modal__buttons">
    <app-button type="submit" primary class="modal__button"
                [disabled]="!form.valid || this.balance < this.data.nftPrice">
      {{ 'pages.modals.complete_purchase' | translate }}
    </app-button>

    <app-button type="button" (btnClick)="close()" secondary class="modal__button">
      {{ 'pages.modals.cancel' | translate | titlecase }}
    </app-button>
  </div>
</form>
