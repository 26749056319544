import { Injectable } from '@angular/core';
import { TranslateCustomService } from './translate-custom.service';
import { NotificationContextInterface } from '../intefaces/notification-context.interface';
import { NotificationsCustomService } from './notifications-custom.service';

@Injectable()
export class NotificationsServiceWithTranslate {
  constructor(
    private notificationsServiceCustom: NotificationsCustomService,
    private translateCustomService: TranslateCustomService,
  ) {
  }

  public success(
    notificationContext: NotificationContextInterface,
  ): void {
    return this.notificationsServiceCustom.success(
      this.translateCustomService.instant(notificationContext.content, notificationContext.contentParams),
    );
  }

  public error(
    notificationContext: NotificationContextInterface,
  ): void {
    return this.notificationsServiceCustom.error(
      this.translateCustomService.instant(notificationContext.content, notificationContext.contentParams),
    );
  }
}
