<div class="input-filed">
  <label
    [attr.for]="label"
    class="input-filed__label"
  >
    {{label}}
  </label>

  <div class="input-filed__input-wrapper">
    <textarea
      #textarea
      class="input-filed__input input-main"
      [class.white]="white"
      [attr.name]="label"
      [(ngModel)]="value"
      (ngModelChange)="setValue()"
      (blur)="setTouched()"
      [disabled]="disabled"
      [placeholder]="placeholder"
    >
    </textarea>
  </div>

  <div *ngIf="note" class="input-filed__note">{{note}}</div>
</div>
