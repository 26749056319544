import { Injectable } from '@angular/core';
import {
  CanActivate,
} from '@angular/router';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { take, tap } from 'rxjs/operators';
import { selectAuthLoggedIn } from '../store/selectors/autth.selectors';
import { AuthService } from '../../auth/services/auth.service';

@Injectable({
  providedIn: 'root',
})
export class ConnectWalletIfLoggedOutGuard implements CanActivate {
  constructor(
    private store: Store,
    private authService: AuthService,
  ) {}

  canActivate(): Observable<boolean> {
    return this.store.select(selectAuthLoggedIn).pipe(
      tap((value) => {
        if (!value) {
          this.authService.connectWallet();
        }
      }),
      take(1),
    );
  }
}
