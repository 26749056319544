<div
  class="side-menu__menu"
  [class.opened]="opened$ | async"
>
  <ng-container [ngSwitch]="type$ | async">
    <ng-container *ngSwitchCase="headerSideMenuTypes.User">
      <app-side-user-menu-content></app-side-user-menu-content>
    </ng-container>

<!--    <ng-container *ngSwitchCase="headerSideMenuTypes.Settings">-->
<!--      <app-side-setting-menu-content></app-side-setting-menu-content>-->
<!--    </ng-container>-->
  </ng-container>
</div>

<div
  *ngIf="showUnderlay"
  class="side-menu__underlay"
  [class.opened]="opened$ | async"
  (click)="close()"
>
</div>
