import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {
  combineLatest, Observable, of,
} from 'rxjs';
import {
  switchMap, takeUntil,
} from 'rxjs/operators';
import { LandInterface } from '../../../lends/interfaces/land.interface';
import { PRICE_FORMAT } from '../../../../../core/common/constants/common-settings-values';
import { ModalService } from '../../services/modal.service';
import { MakeBidDataInterface } from '../../interfaces/makeBidData.interface';
import { CheckoutModalDataInterface } from '../../interfaces/checkout-modal-data.interface';
import { CheckoutConfirmModalComponent } from '../checkout-confirm-modal/checkout-confirm-modal.component';
import { PlaceBidFacadeService } from '../../../../../auction/services/place-bid.facade.service';
import {
  UnsubscribeDestroyHelperComponent,
} from '../../../../../core/common/helpers/unsubscribe-destroy-helper.component';
import { EthAccountInterface } from '../../../../../core/common/intefaces/eth-account.interface';
import { highestAmountValidator } from '../../../../../core/common/validators/highest-amount.validator';
import { CurrenciesNames } from '../../../../../core/settings/constants/currencies-names';

@Component({
  selector: 'app-make-bid-modal',
  templateUrl: './make-bid-modal.component.html',
  styleUrls: ['../../../../../styles/blocks/modals.scss'],
})
export class MakeBidModalComponent extends UnsubscribeDestroyHelperComponent implements OnInit {
  public static readonly modalIdentifier: string = 'makeBidModal';

  public form: FormGroup = new FormGroup({});
  public land$: Observable<LandInterface | null>;
  public currency$: Observable<string>;
  public amountFormat = PRICE_FORMAT;
  public currenciesNames = CurrenciesNames;

  public data: MakeBidDataInterface = {
    ethAccount: undefined,
    auction: undefined,
    feeAmount: 0,
    fee: 0,
    totalAmount: 0,
    bidAmount: 0,
    outLand: true,
    outLandAmount: 0,
  };
  private landTokenId?: number;
  public balance?: number;

  constructor(
    public modalService: ModalService,
    private formBuilder: FormBuilder,
    private placeBidFacadeService: PlaceBidFacadeService,
  ) {
    super();
    this.land$ = this.modalService.getModalData(MakeBidModalComponent.modalIdentifier).land$;
    this.currency$ = this.placeBidFacadeService.getCurrency$();
    this.initForm();
  }

  public ngOnInit() {
    this.subscribeToEthAccountChanged();
    this.subscribeToEthAccount();
    this.subscribeToAuctionDetails();
    this.subscribeToForm();
  }

  public submit(): void {
    this.modalService.close(MakeBidModalComponent.modalIdentifier);

    this.data.outLand = this.form.value.outLand;
    this.data.bidAmount = this.form.value.bidAmount;

    const data: CheckoutModalDataInterface = {
      ...this.data,
      successSubTitle: 'you_successfully_placed_bid',
      confirmSubTitle: 'you_placing_bid_for_nft',
      totalLabel: 'total_bid',
      priceLabel: 'bid_price',
      nextButtonLabel: 'place_bid',
      isMakeBid: true,
    };

    this.modalService.open(
      CheckoutConfirmModalComponent.modalIdentifier,
      CheckoutConfirmModalComponent,
      { land$: this.land$, data },
    );
  }

  public close(): void {
    this.modalService.close(MakeBidModalComponent.modalIdentifier);
  }

  private initForm(): void {
    this.form = this.formBuilder.group({
      bidAmount: [null, [Validators.required]],
      outLand: false,
    });
  }

  private subscribeToEthAccount(): void {
    combineLatest(
      this.land$,
      this.placeBidFacadeService.account(),
      this.placeBidFacadeService.getNetwork(),
    ).pipe(
      takeUntil(this.unsubscribeSubject),
      switchMap(([land, account, network]) => combineLatest(
        of(account),
        of(network),
        this.placeBidFacadeService.getContractAddress(network.id, land!.productDetails.currency!),
        of(land!.productDetails.currency!),
      )),
      switchMap(([account, network, tokenAddress, currency]) => combineLatest(
        of(account),
        of(tokenAddress),
        this.placeBidFacadeService.getBalance(
          network.id,
          account!.address,
          tokenAddress!,
        ),
        of(currency),
      )),
    ).subscribe((([account, tokenAddress, balance, currency]) => {
      if (!account) {
        this.placeBidFacadeService.openWallet();
        return;
      }
      this.data.ethAccount = account;
      this.data.tokenAddress = tokenAddress;
      this.balance = parseFloat(balance);
      this.form.controls.bidAmount.setValidators(
        [
          highestAmountValidator(this.data.auction?.higherBid as number, currency),
        ],
      );
    }));
  }

  private subscribeToAuctionDetails(): void {
    this.land$.pipe(
      takeUntil(this.unsubscribeSubject),
      switchMap((land: any) => combineLatest(
        of(land),
        this.placeBidFacadeService.auctions(land.productDetails.auctionId),
        this.placeBidFacadeService.highestBid(land.token.id, land.id),
      )),
    ).subscribe(([land, auction, bid]) => {
      if (land?.token.id) {
        this.landTokenId = land?.token.id;
      }
      this.data.auction = auction;
      this.data.auction.higherBid = bid || land.productDetails.auctionStartPrice || 0;
    });
  }

  private subscribeToEthAccountChanged(): void {
    this.placeBidFacadeService.accountChanged().pipe(
      takeUntil(this.unsubscribeSubject),
    ).subscribe((address) => {
      this.data.ethAccount = {
        address,
      } as EthAccountInterface;
    });
  }

  private subscribeToForm() {
    this.form.valueChanges.pipe(
      takeUntil(this.unsubscribeSubject),
    ).subscribe(
      (value) => {
        if (this.data.outLandAmount && value.outLand) {
          this.data.totalAmount = Number(value.bidAmount) + Number(this.data.outLandAmount);
        } else {
          this.data.totalAmount = Number(value.bidAmount);
        }
      },
    );
  }
}
