import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { Store } from '@ngrx/store';
import { SettingsActionsTypes } from '../actions/settings.actions';
import { SettingsApiService } from '../../../settings/services/settings.api.service';
import { selectAuthLoggedIn } from '../selectors/autth.selectors';
import { AuthActionsTypes } from '../actions/auth.actions';

@Injectable()
export class SettingsEffects {
  LoadSettings$ = createEffect(() => this.actions$.pipe(
    ofType(SettingsActionsTypes.LoadSettings),
    switchMap(() => this.settingsApiService.loadUserSettings()),
    map(({ data }) => ({ type: SettingsActionsTypes.LoadSettingsSuccess, settings: data })),
    catchError(() => of({ type: SettingsActionsTypes.LoadSettingsFailure })),
  ));

  SaveSettings$ = createEffect(() => this.actions$.pipe(
    ofType(SettingsActionsTypes.SaveSettings),
    switchMap(({ settings }) => this.store.select(selectAuthLoggedIn).pipe(
      switchMap((loggedIn) => {
        if (loggedIn) {
          return this.settingsApiService.saveUserSettings(settings);
        }
        return of({ data: settings });
      }),
    )),
    map(({ data }) => ({ type: SettingsActionsTypes.SaveSettingsSuccess, settings: data })),
    catchError(() => of({ type: SettingsActionsTypes.SaveSettingsFailure })),
  ));

  LoadSettingsSuccess$ = createEffect(() => this.actions$.pipe(
    ofType(SettingsActionsTypes.LoadSettingsSuccess),
    map(() => ({ type: AuthActionsTypes.FinishAppInitializer })),
  ));

  constructor(
    private actions$: Actions,
    private settingsApiService: SettingsApiService,
    private store: Store,
  ) {
  }
}
