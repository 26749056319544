import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { IHttpResponse } from '../../intefaces/http-response.interface';
import { NETWORK_API } from '../../api/network.api';
import { NetworkInterface } from '../../intefaces/network.interface';

@Injectable({
  providedIn: 'root',
})
export class NetworkApiService {
  constructor(private httpClient: HttpClient) {}

  public network(): Observable<IHttpResponse<NetworkInterface>> {
    return this.httpClient.get<IHttpResponse<NetworkInterface>>(
      NETWORK_API.network,
    );
  }
}
