import { Component, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { ModalService } from '../../services/modal.service';

@Component({
  selector: 'app-pending-modal',
  templateUrl: './pending-modal.component.html',
  styleUrls: ['../../../../../styles/blocks/modals.scss'],
})
export class PendingModalComponent implements OnDestroy {
  public static readonly modalIdentifier: string = 'pendingModalComponent';
  private readonly unsubscribeSubject?: Subject<any>;
  public data?: { subtitle: string; note: string; };
  public closeAction = () => {};

  constructor(private modalService: ModalService) {
    this.unsubscribeSubject = modalService.getModalData(PendingModalComponent.modalIdentifier).unsubscribeSubject;
    this.data = this.modalService.getModalData(PendingModalComponent.modalIdentifier).data;
    this.closeAction = this.modalService.getModalData(PendingModalComponent.modalIdentifier).closeAction || (() => {});
    this.modalService.get(PendingModalComponent.modalIdentifier).onAnyCloseEvent.subscribe(
      () => {
        this.closeAction();
      },
    );
  }

  public close(): void {
    this.modalService.close(PendingModalComponent.modalIdentifier);
    if (this.unsubscribeSubject) {
      this.unsubscribeSubject.next();
    }
  }

  public ngOnDestroy() {
    if (this.unsubscribeSubject) {
      this.unsubscribeSubject.next();
    }
  }
}
