import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { WriteAuctionService } from './write-auction.service';
import { MetamaskService } from '../../core/auth/services/metamask.service';
import { EthAccountInterface } from '../../core/common/intefaces/eth-account.interface';
import { NetworkInterface } from '../../exchange/interfaces/network.interface';
import { TokensListService } from '../../exchange/services/tokens-list.service';
import { ReadAuctionService } from './read-auction.service';
import { UniswapService } from '../../core/auth/services/uniswap.service';
import { selectCurrency, selectUnits } from '../../core/common/store/selectors/settings.selectors';
import { ReadWtcService } from '../../wtc/services/read-wtc.service';
import { ContractService } from '../../core/common/services/contract.service';
import { WriteWtcService } from '../../wtc/services/write-wtc.service';

@Injectable()
export class BuyNowFacadeService {
  constructor(
    private metamaskService: MetamaskService,
    private writeAuctionService: WriteAuctionService,
    private readAuctionService: ReadAuctionService,
    private readWtcService: ReadWtcService,
    private writeWtcService: WriteWtcService,
    private contractService: ContractService,
    private tokensListService: TokensListService,
    private uniswapService: UniswapService,
    private store: Store,
  ) {
  }

  public account(): Observable<EthAccountInterface|undefined> {
    return this.metamaskService.getAccounts().pipe(
      map((accounts) => (accounts ? accounts[0] : undefined)),
    );
  }

  public buyItem(from: string, saleId: number): Observable<string> {
    return this.writeAuctionService.buyItem(from, saleId);
  }

  public accountChanged(): Observable<string|undefined> {
    return this.metamaskService.getAccountChanged$().pipe(
      map((accounts) => (accounts ? accounts[0] : undefined)),
    );
  }

  public getCurrency$():Observable<string> {
    return this.store.select(selectCurrency);
  }

  public get getUnits$(): Observable<string> {
    return this.store.select(selectUnits);
  }

  public getBalance(chainId: number, ethAddress: string, contractAddress: string): Observable<string> {
    return this.uniswapService.getBalance(chainId, ethAddress, contractAddress);
  }

  public openWallet(): void {
    return this.metamaskService.enable();
  }

  public approveIfNotEnough(from: string, tokenAddress: string, amount: number): Observable<string|null> {
    return this.readAuctionService.allowance(from, tokenAddress).pipe(
      switchMap((allowance) => {
        if (parseFloat(allowance) < amount) {
          return this.writeAuctionService.approve(from, tokenAddress, amount);
        }
        return of(null);
      }),
    );
  }

  public loadMinSellAmount(id: number): Observable<number> {
    return this.readAuctionService.loadMinSellAmount(id).pipe(
      map((amount) => parseFloat(amount)),
    );
  }

  public wtcRentFoundationAllowance(owner: string): Observable<number> {
    return this.contractService.contracts().pipe(
      switchMap((contracts) => this.readWtcService.allowance(owner, contracts.rentFoundationAddress).pipe(
        map((allowance) => parseFloat(allowance)),
      )),
    );
  }

  public wtcApproveRentFoundation(owner: string, amount: number): Observable<string> {
    return this.contractService.contracts().pipe(
      switchMap((contracts) => this.writeWtcService.approve(owner, contracts.rentFoundationAddress, amount)),
    );
  }

  public getNetwork(): Observable<NetworkInterface> {
    return this.metamaskService.getNetwork();
  }

  public getContractAddress(chainId: number, currencyCode: string): Observable<string|undefined> {
    return this.tokensListService.getTokens(chainId).pipe(
      map((tokens) => tokens.filter((token) => token.symbol === currencyCode)),
      map((tokens) => (tokens.length ? tokens[0].contractAddress : undefined)),
    );
  }
}
