<div class="modal">
  <div class="modal__title">
    {{ 'pages.modals.finish_auction' | translate }}
  </div>

  <div class="modal__subtitle">
    {{ 'pages.modals.do_you_want_to_finish_auction' | translate }}
  </div>

  <ng-container *ngIf="land$ | async as land">
    <div class="modal__location-info location-info">
      <div class="location-info__title">
        <img class="location-info__icon" src="assets/images/marker.svg" alt="marker.svg">{{ land.name }}
        <div class="location-info__sub-title">{{ land.location }}</div>
      </div>
    </div>

    <div class="modal__land-info land-info">
      <div class="land-info__item">
        {{ 'pages.land.crop_share' | translate }}
        , {{ ('common.cultures.' + land.productDetails.cropShareType) | translate | lowercase}}:
        <span class="font-weight">{{land.productDetails?.cropShare | number : amountFormat}}</span>
        {{ ('common.units.' + (units$ | async)) | translate }}
      </div>
      &nbsp;&#8231;&nbsp;
      <div class="land-info__item">
        <span class="font-weight">{{ land.area }}</span>
        {{ ('common.units.' + areaUnits[(units$ | async)]) | translate }}
      </div>
    </div>

    <ul class="modal__total-info total-info" *ngIf="land.productDetails?.price">
      <li class="total-info__row">
        <div class="total-info__label">{{ 'pages.modals.bid_price' | translate }}:</div>

        <div class="total-info__value"><strong>{{ land.productDetails?.price }}</strong> {{ land.currency }}</div>
      </li>

      <li class="total-info__row">
        <div class="total-info__label"></div>

        <div class="total-info__value"></div>
      </li>
    </ul>
    <ng-container *ngIf="auction$ | async as auction">
      <div class="modal__bottom-note modal__bottom-note--warn"
           *ngIf="(auction?.currentBid | number) && auction?.reservedPrice <= (auction.currentBid | number)">
        {{'pages.modals.finish_auction_note' | translate}}
      </div>

      <div class="modal__bottom-note modal__bottom-note--warn"
           *ngIf="!(auction?.currentBid | number) || auction?.reservedPrice > (auction.currentBid | number)">
        {{'pages.modals.finish_auction_note_without_price' | translate}}
      </div>
    </ng-container>
  </ng-container>

  <div class="modal__buttons">
    <app-button (btnClick)="submit()" primary class="modal__button">
      {{ 'pages.modals.finish_auction' | translate }}
    </app-button>

    <app-button (btnClick)="close()" secondary class="modal__button">
      {{ 'pages.modals.cancel' | translate }}
    </app-button>
  </div>
</div>
