import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { ModalNotificationContextInterface } from '../interfaces/modal-notofication.interfece';

@Injectable()
export class ModalNotificationsService {
  private show$ = new Subject<ModalNotificationContextInterface>();

  public getShow$(): Observable<ModalNotificationContextInterface> {
    return this.show$.asObservable();
  }

  public show(notificationContext: ModalNotificationContextInterface): void {
    this.show$.next(notificationContext);
  }
}
