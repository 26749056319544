import { Action, createReducer, on } from '@ngrx/store';
import * as ProcessingTransactionActions from '../actions/processing-transaction.actions';
import { initialProcessingTransactionState, ProcessingTransactionState } from '../states/processing-transaction.state';

const processingTransactionReducer = createReducer(
  initialProcessingTransactionState,
  on(ProcessingTransactionActions.LoadProcessingTransaction, (state): ProcessingTransactionState => ({
    ...state,
    loading: true,
  })),
  on(
    ProcessingTransactionActions.LoadProcessingTransactionSuccess,
    (state, { items, totalCount }): ProcessingTransactionState => ({
      ...state,
      items,
      totalCount,
      loading: false,
    }),
  ),
  on(ProcessingTransactionActions.LoadProcessingTransactionFailure, (state): ProcessingTransactionState => ({
    ...state,
    loading: false,
  })),
  on(ProcessingTransactionActions.ResetNewProcessingTransaction, (state): ProcessingTransactionState => ({
    ...state,
    items: [],
    totalCount: 0,
  })),
);

export function reducer(state: ProcessingTransactionState | undefined, action: Action) {
  return processingTransactionReducer(state, action);
}
