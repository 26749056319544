import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'secondsToMonth',
})
export class SecondsToMonthPipe implements PipeTransform {
  private readonly SEC_IN_HOUR: number = 3600;
  private readonly SEC_IN_DAY: number = 86400;
  private readonly SEC_IN_MONTH: number = 2629746;

  transform(seconds: number) {
    let timeString: string = '';
    let plural: string;

    if (Math.floor(seconds / this.SEC_IN_MONTH) > 0) {
      plural = Math.ceil(seconds / this.SEC_IN_MONTH) > 1 ? 's' : '';
      timeString += `${Math.ceil(seconds / this.SEC_IN_MONTH)} month${plural}`;
    } else if (Math.floor(seconds / this.SEC_IN_DAY) > 0) {
      plural = Math.ceil(seconds / this.SEC_IN_DAY) > 1 ? 's' : '';
      timeString += `${Math.ceil(seconds / this.SEC_IN_DAY)} day${plural}`;
    } else {
      plural = Math.ceil(seconds / this.SEC_IN_HOUR) > 1 ? 's' : '';
      timeString += `${Math.ceil(seconds / this.SEC_IN_HOUR)} hour${plural}`;
    }

    return timeString;
  }
}
