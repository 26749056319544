import { Action, createReducer, on } from '@ngrx/store';
import { initialUserState, UserState } from '../states/user.state';
import * as UserActions from '../actions/user.actions';
import { UserInterface } from '../../../user/interfaces/user.interface';

const userReducer = createReducer(
  initialUserState,
  on(UserActions.LoadUser, (state): UserState => ({
    ...state,
    loading: true,
  })),
  on(UserActions.LoadUserSuccess, (state, { user }): UserState => ({
    ...state,
    user,
    loading: false,
  })),
  on(UserActions.LoadUserFailure, (state): UserState => ({
    ...state,
    loading: false,
  })),
  on(UserActions.LoadWallpaper, (state): UserState => ({
    ...state,
    loading: true,
  })),
  on(UserActions.LoadWallpaperSuccess, (state, { wallpaperUrl }): UserState => ({
    ...state,
    wallpaperUrl,
    loading: false,
  })),
  on(UserActions.LoadWallpaperFailure, (state): UserState => ({
    ...state,
    loading: false,
  })),
  on(UserActions.UploadUserImage, (state): UserState => ({
    ...state,
    loading: true,
  })),
  on(UserActions.UploadUserImageSuccess, (state, { profileImage }): UserState => ({
    ...state,
    user: {
      ...state.user,
      profileImage,
    } as UserInterface,
    loading: false,
  })),
  on(UserActions.UploadUserImageFailure, (state): UserState => ({
    ...state,
    loading: false,
  })),
  on(UserActions.UploadWallpaper, (state): UserState => ({
    ...state,
    loading: true,
  })),
  on(UserActions.UploadWallpaperSuccess, (state, { wallpaper }): UserState => ({
    ...state,
    user: {
      ...state.user,
      wallpaper,
    } as UserInterface,
    loading: false,
  })),
  on(UserActions.UploadWallpaperFailure, (state): UserState => ({
    ...state,
    loading: false,
  })),
  on(UserActions.ClearUser, (): UserState => ({
    user: undefined,
    wallpaperUrl: '',
    loading: false,
  })),
);

export function reducer(state: UserState | undefined, action: Action) {
  return userReducer(state, action);
}
