<div class="input-filed">
  <label
    [attr.for]="label"
    class="input-filed__label"
  >
    {{label}}
  </label>

  <div class="input-filed__input-wrapper">
    <input
      #input
      class="input-filed__input"
      [class.white]="white"
      [attr.name]="label"
      [type]="type"
      [(ngModel)]="value"
      (ngModelChange)="setValue()"
      (blur)="setTouched()"
      [disabled]="disabled"
      [placeholder]="placeholder"
    >

    <div
      class="input-filed__equivalent"
      *ngIf="equivalent && equivalentCurrency"
    >
      {{'common.fields.equivalent' | translate}}:{{equivalent}}{{equivalentCurrency}}
    </div>
  </div>

  <div *ngIf="note" class="input-filed__note">{{note}}</div>
</div>
