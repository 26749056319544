import {
  AfterViewInit, Component, Input, OnDestroy, OnInit,
} from '@angular/core';
import {
  debounceTime,
  distinctUntilChanged,
  startWith,
  tap,
} from 'rxjs/operators';
import { FormGroup } from '@angular/forms';
import { Subject } from 'rxjs';
import { NgxSpinnerService } from 'ngx-spinner';
import { FilterGroupFieldsInterface, FilterListItemGroupInterface } from '../../interfaces/filter-group.interface';

@Component({
  selector: 'app-filter-search',
  templateUrl: './filter-search.component.html',
  styleUrls: ['./filter-search.component.scss'],
})
export class FilterSearchComponent implements AfterViewInit, OnDestroy, OnInit {
  @Input() form?: FormGroup;
  @Input() searchGroup?: FilterGroupFieldsInterface;

  public loading = true;
  public searchInputValue: string = '';
  public searchInput$: Subject<string> = new Subject<string>();
  public filteredItems: any[] = [];

  private value: string = '';
  private items: any[] = [];

  constructor(
    private ngxSpinnerService: NgxSpinnerService,
  ) {
  }

  ngOnInit(): void {
    if (this.searchGroup) {
      this.items = [...this.searchGroup.items];
    }
  }

  ngAfterViewInit(): void {
    if (this.searchGroup) {
      this.searchInput$.pipe(
        debounceTime(100),
        distinctUntilChanged(),
        startWith(''),
        tap(() => {
          this.loading = true;
          this.ngxSpinnerService.show('filter-spinner');
        }),
      ).subscribe(
        (term: string) => {
          if (term) {
            this.filteredItems = this.items
              .filter((item: FilterListItemGroupInterface) => item.label?.toLocaleLowerCase()
                .includes(term.toLocaleLowerCase()));
          } else {
            this.filteredItems = [...this.items];
          }
          this.loading = false;
          this.ngxSpinnerService.hide('filter-spinner');
        },
      );
    }
  }

  ngOnDestroy() {
    this.searchInput$.complete();
  }

  public setValue(value: any) {
    if (this.value === value) {
      this.value = '';
    } else {
      this.value = value;
    }
    if (this.form && this.searchGroup) {
      this.form.get(this.searchGroup.name)?.setValue(this.value);
    }
  }
}
