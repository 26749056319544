import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxDropzoneModule } from 'ngx-dropzone';
import { NgSelectModule } from '@ng-select/ng-select';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { NgxSmartModalModule } from 'ngx-smart-modal';
import { NgxPaginationModule } from 'ngx-pagination';
import { TooltipModule } from 'ng2-tooltip-directive';
import { NgCircleProgressModule } from 'ng-circle-progress';
import { LightgalleryModule } from 'lightgallery/angular';
import { ShareButtonsModule } from 'ngx-sharebuttons/buttons';
import { ShareIconsModule } from 'ngx-sharebuttons/icons';
import { FieldInputComponent } from './components/field-input/field-input.component';
import { ControlErrorsDirective } from './directives/control-errors-directive';
import { ButtonComponent } from './components/button/button.component';
import { DragAndDropComponent } from './container-components/drag-and-drop/drag-and-drop.component';
import { SafePipe } from './pipes/safe.pipe';
import { CheckboxComponent } from './components/checkbox/checkbox.component';
import { TranslateSelectItemsPipe } from './pipes/translate-select-items.pipe';
import { AmountComponent } from './components/amount/amount.component';
import { FilterSelectComponent } from './components/filter-select/filter-select.component';
import { DragAndDropFilesComponent } from './container-components/drag-and-drop-files/drag-and-drop-files.component';
import { FieldInputAmountComponent } from './components/field-input-amount/field-input-amount.component';
import { FieldSelectComponent } from './components/field-select/field-select.component';
import { AddFieldButtonComponent } from './components/add-field-button/add-field-button.component';
import { FieldTextComponent } from './components/field-text/field-text.component';
import { FieldInputEquivalentComponent } from './components/field-input-equivalent/field-input-equivalent.component';
import { NoItemsComponent } from './components/no-items/no-items.component';
import { PaginationControlsComponent } from './components/pagination-controls/pagination-controls.component';
import { SimpleTableComponent } from './components/user-table/simple-table.component';
import { SwitchComponent } from './components/switch/switch.component';
import { InfiniteScrollComponent } from './components/infinite-scroll/infinite-scroll.component';
import { ContextMenuComponent } from './components/context-menu/context-menu.component';
import { FieldCopyableComponent } from './components/field-copyable/field-copyable.component';
import { ScrollFormErrorDirective } from './directives/scroll-form-error.directive';
import { DateFromNowPipe } from './pipes/date-from-now.pipe';
import { HiddenWalletAddressPipe } from './pipes/hidden-wallet-address.pipe';
import { UserNamePipe } from './pipes/user-name.pipe';
import { AvatarImageComponent } from './components/avatar-image/avatar-image.component';
import { FieldInputWithCurrencyComponent }
  from './components/field-input-value-with-currency/field-input-with-currency.component';
import { ImageComponent } from './components/image/image.component';
import { FieldInputExchangeComponent } from './components/field-input-exchange/field-input-exchange.component';
import { CustomSpinnerComponent } from './components/custom-spinner/custom-spinner.component';
import { FormatBytesPipe } from './pipes/format-bytes.pipe';
import { CollapsBlocComponent } from './components/collaps-bloc/collaps-bloc.component';
import { FieldSearchComponent } from './components/field-search/field-search.component';
import { EllipsisDirective } from './directives/ellipsis.directive';
import {
  ConnectWalletPopupComponent,
} from './container-components/connect-wallet-popup/connect-wallet-popup.component';
import { YearsUntilPipe } from './pipes/years-until.pipe';
import { ExpirationDatePipe } from './pipes/expiration-date.pipe';
import { CurrencyFromCulturePipe } from './pipes/curency-from-culture.pipe';
import { FirstSymbolUppercasePipe } from './pipes/first-symbol-uppercase.pipe';
import { TooltipComponent } from './components/tooltip/tooltip.component';
import { ClickOutsideDirective } from './directives/click-outside.directive';
import { SimpleBoxLayoutComponent } from './components/simple-box-layout/simple-box-layout.component';
import { SecondsToMonthPipe } from './pipes/seconds-to-month.pipe';
import { StakeNameComponent } from './components/stake-name/stake-name.component';
import { ProgressCircleComponent } from './components/progress-circle/progress-circle.component';

@NgModule({
  declarations: [
    FieldInputComponent,
    DragAndDropComponent,
    ControlErrorsDirective,
    ClickOutsideDirective,
    ButtonComponent,
    CheckboxComponent,
    SimpleBoxLayoutComponent,
    InfiniteScrollComponent,
    SafePipe,
    AmountComponent,
    TranslateSelectItemsPipe,
    FilterSelectComponent,
    DragAndDropFilesComponent,
    FieldInputAmountComponent,
    FieldSelectComponent,
    AddFieldButtonComponent,
    FieldTextComponent,
    FieldInputEquivalentComponent,
    NoItemsComponent,
    PaginationControlsComponent,
    SimpleTableComponent,
    SwitchComponent,
    HiddenWalletAddressPipe,
    ContextMenuComponent,
    FieldCopyableComponent,
    ScrollFormErrorDirective,
    DateFromNowPipe,
    UserNamePipe,
    TooltipComponent,
    AvatarImageComponent,
    FieldInputWithCurrencyComponent,
    ImageComponent,
    FieldInputExchangeComponent,
    CustomSpinnerComponent,
    FormatBytesPipe,
    FirstSymbolUppercasePipe,
    CollapsBlocComponent,
    FieldSearchComponent,
    EllipsisDirective,
    ConnectWalletPopupComponent,
    YearsUntilPipe,
    ExpirationDatePipe,
    CurrencyFromCulturePipe,
    SecondsToMonthPipe,
    StakeNameComponent,
    ProgressCircleComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    NgxDropzoneModule,
    ReactiveFormsModule,
    RouterModule,
    NgSelectModule,
    TranslateModule,
    NgxSmartModalModule,
    NgxPaginationModule,
    TooltipModule,
    NgCircleProgressModule,
    LightgalleryModule,
    ShareButtonsModule,
    ShareIconsModule,
  ],
  exports: [
    FieldInputComponent,
    DragAndDropComponent,
    SimpleBoxLayoutComponent,
    ControlErrorsDirective,
    ButtonComponent,
    CheckboxComponent,
    InfiniteScrollComponent,
    RouterModule,
    SafePipe,
    NgSelectModule,
    FormsModule,
    NgxSmartModalModule,
    TranslateModule,
    AmountComponent,
    TranslateSelectItemsPipe,
    ReactiveFormsModule,
    TranslateModule,
    FilterSelectComponent,
    DragAndDropFilesComponent,
    FieldInputAmountComponent,
    FieldSelectComponent,
    AddFieldButtonComponent,
    FieldTextComponent,
    ClickOutsideDirective,
    FieldInputEquivalentComponent,
    NoItemsComponent,
    NgxPaginationModule,
    PaginationControlsComponent,
    SimpleTableComponent,
    SwitchComponent,
    FieldCopyableComponent,
    ScrollFormErrorDirective,
    HiddenWalletAddressPipe,
    DateFromNowPipe,
    UserNamePipe,
    AvatarImageComponent,
    FieldInputWithCurrencyComponent,
    ImageComponent,
    FieldInputExchangeComponent,
    CustomSpinnerComponent,
    FormatBytesPipe,
    FirstSymbolUppercasePipe,
    CollapsBlocComponent,
    ContextMenuComponent,
    FieldSearchComponent,
    EllipsisDirective,
    YearsUntilPipe,
    ExpirationDatePipe,
    CurrencyFromCulturePipe,
    TooltipComponent,
    SecondsToMonthPipe,
    NgCircleProgressModule,
    StakeNameComponent,
    ProgressCircleComponent,
    LightgalleryModule,
    ShareButtonsModule,
    ShareIconsModule,
  ],
  providers: [
    DateFromNowPipe,
  ],
})
export class SharedModule { }
