import { Injectable } from '@angular/core';
import { empty, Observable, of } from 'rxjs';
import {
  catchError, map, switchMap, take,
} from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { ClaimLandApiService } from './claim-land.api.service';
import { CallerService } from '../../core/common/services/caller.service';
import { IFileInterface } from '../../core/common/intefaces/file.interface';
import { IHttpResponse } from '../../core/common/intefaces/http-response.interface';
import { ReferenceService } from '../../core/common/services/reference/reference.service';
import { ClaimLandFormStateService } from '../state/claim-land-form.state.service';
import { SelectItemInterface } from '../../core/common/intefaces/select-item.interface';
import { selectCurrency, selectUnits } from '../../core/common/store/selectors/settings.selectors';
import { LandRequestModel } from '../models/land-request.model';
import { LandInterface } from '../../shared/modules/lends/interfaces/land.interface';
import { Country } from '../../core/countries/interfaces/country';
import { LandVerificationRequestModel } from '../models/land-verification-request.model';
import { selectRoleIsVerifier } from '../../core/common/store/selectors/autth.selectors';
import { ModalNotificationsTypes } from '../../shared/modules/modal-notifications/constants/modal-notifications-types';
import { selectUser } from '../../core/common/store/selectors/user.selector';
import { UserInterface } from '../../core/user/interfaces/user.interface';

@Injectable()
export class ClaimLandFacadeService {
  constructor(
    private claimLandApiService: ClaimLandApiService,
    private callerService: CallerService,
    private referenceService: ReferenceService,
    private claimLandFormStateService: ClaimLandFormStateService,
    private store: Store,
  ) { }

  public getUnits$():Observable<string> {
    return this.store.select(selectUnits);
  }

  public getUser$():Observable<UserInterface| undefined> {
    return this.store.select(selectUser);
  }

  public getCurrency$():Observable<string> {
    return this.store.select(selectCurrency);
  }

  public search(
    filters: { [key: string]: string | number },
  ): Observable<LandInterface> {
    return this.callerService.call(
      this.claimLandApiService.search(filters).pipe(
        map((response) => (response.data)),
        catchError(() => empty()),
      ),
      null,
      null,
      false,
    );
  }

  public uploadLandFile(file: File): Observable<IFileInterface> {
    const formData = new FormData();
    formData.append('file', file);

    return this.callerService.call(
      this.store.select(selectRoleIsVerifier).pipe(
        switchMap((verifier: boolean) => {
          if (verifier) {
            return this.claimLandApiService.uploadLandFileByVerifier(formData);
          }
          return this.claimLandApiService.uploadLandFile(formData);
        }),
        take(1),
        map(({ data }) => data),
      ),
      { content: 'common.notifications.file_uploaded' },
    );
  }

  public downloadLandFile(url: string, type: string): Observable<File> {
    return this.callerService.call(
      this.claimLandApiService.downloadLandFile(url).pipe(
        map((data) => new File([data], 'profile-image.png', { type })),
      ),
      null,
      null,
      false,
    );
  }

  public deleteFIle(removedFileId: number): Observable<IHttpResponse<void>> {
    return this.callerService.call(
      this.store.select(selectRoleIsVerifier).pipe(
        switchMap((verifier: boolean) => {
          if (verifier) {
            return this.claimLandApiService.deleteFIleByVerifier(removedFileId);
          }
          return this.claimLandApiService.deleteFIle(removedFileId);
        }),
        take(1),
      ),
      { content: 'common.notifications.file_deleted' },
      null,
      false,
    );
  }

  public saveLand(data: any) {
    const body = new LandRequestModel(data);
    this.callerService.call(
      this.claimLandApiService.saveLand(body),
      { content: 'pages.claim_land.draft_has_been_saved' },
      { content: 'pages.claim_land.draft_hasnt_been_saved' },
      true,
    ).subscribe();
  }

  public submitLand(data: any, onDone?: () => void) {
    const body = new LandRequestModel(data);
    this.callerService.call(
      this.claimLandApiService.submitLand(body),
      null,
      { content: 'pages.claim_land.draft_hasnt_been_send' },
      true,
      {
        type: ModalNotificationsTypes.Success,
        content: 'pages.claim_land.you_successfully_submited_land',
        onDone,
      },
    ).pipe(
      catchError(() => empty()),
    ).subscribe();
  }

  public submitLandVerification(data: any, landId: string, onDone?: () => void) {
    const body = new LandVerificationRequestModel(data);
    this.callerService.call(
      this.claimLandApiService.submitLandVerification(body, landId),
      null,
      { content: 'pages.claim_land.draft_hasnt_been_send' },
      true,
      {
        type: ModalNotificationsTypes.Success,
        content: 'pages.claim_land.successfully_verified_land',
        onDone,
      },
    ).pipe(
      catchError(() => empty()),
    ).subscribe();
  }

  public loadLandVerification(landId: number): void {
    this.callerService.call(
      this.claimLandApiService.loadLandVerification(landId),
      null,
      null,
      false,
    ).subscribe(
      ({ data }) => this.claimLandFormStateService.setLand(data),
    );
  }

  public saveLandVerification(data: any, landId: string): void {
    const body = new LandVerificationRequestModel(data);
    this.callerService.call(
      this.claimLandApiService.saveLandVerification(body, landId),
      null,
      { content: 'pages.claim_land.draft_hasnt_been_saved' },
      true,
      { type: ModalNotificationsTypes.Info, content: 'pages.claim_land.information_has_been_changed' },
    ).subscribe();
  }

  public loadCulturesList(): void {
    this.callerService.call(
      this.referenceService.getCulturesList(),
      null,
      null,
      false,
    ).subscribe(
      (cultures) => this.claimLandFormStateService.setCultures(cultures),
    );
  }

  public getLand$(): Observable<LandInterface> {
    return this.claimLandFormStateService.getLand$();
  }

  public getLandById$(): Observable<LandInterface> {
    return this.claimLandFormStateService.getLand$();
  }

  public getCultures$(): Observable<SelectItemInterface[]> {
    return this.claimLandFormStateService.getCultures$().pipe(
      map((cultures) => cultures.map((culture) => ({ value: culture.id, label: culture.name }))),
    );
  }

  public loadEquivalentPricePerHa(
    amountToConvert: number, from: string, to: string,
  ) {
    this.convertCurrency(from, to, amountToConvert).subscribe(
      ({ convertedAmount }) => {
        this.claimLandFormStateService.setPricePerHa(convertedAmount);
      },
    );
  }

  public loadEquivalentNftPrice(
    amountToConvert: number, from: string, to: string,
  ) {
    this.convertCurrency(from, to, amountToConvert).subscribe(
      ({ convertedAmount }) => {
        this.claimLandFormStateService.setPrice(convertedAmount);
      },
    );
  }

  public loadEquivalentAuctionPrice(
    amountToConvert: number, from: string, to: string,
  ) {
    this.convertCurrency(from, to, amountToConvert).subscribe(
      ({ convertedAmount }) => {
        this.claimLandFormStateService.setAuctionPrice(convertedAmount);
      },
    );
  }

  public getPricePerHa$(): Observable<string> {
    return this.claimLandFormStateService.getPricePerHa$().pipe(
      map((data) => (data === null ? '' : String(data))),
    );
  }
  public getPrice$(): Observable<string> {
    return this.claimLandFormStateService.getPrice$().pipe(
      map((data) => (data === null ? '' : String(data))),
    );
  }
  public getAuctionPrice$(): Observable<string> {
    return this.claimLandFormStateService.getAuctionPrice$().pipe(
      map((data) => (data === null ? '' : String(data))),
    );
  }

  public convertCurrency(
    from: string, to: string, amount: number,
  ): Observable<{ amount: number, convertedAmount: number | null }> {
    return this.callerService.call(
      this.referenceService.convertCurrency(from, to, amount),
      null,
      null,
      false,
    ).pipe(
      catchError(() => of({ amount: 0, convertedAmount: null })),
    );
  }

  public countries(): Observable<Country[]> {
    return this.callerService.call(
      this.claimLandApiService.countries().pipe(
        map((response) => (response.data)),
      ),
      null,
      null,
      false,
    );
  }

  public decline(id: number): Observable<void | null> {
    return this.callerService.call(
      this.claimLandApiService.decline(id).pipe(
        map((response) => (response.data)),
      ),
    );
  }
}
