import {
  Component, Input,
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FilterGroupFieldsInterface } from '../../interfaces/filter-group.interface';

@Component({
  selector: 'app-filter-checkbox-list',
  templateUrl: './filter-checkbox-list.component.html',
  styleUrls: ['./filter-checkbox-list.component.scss'],
})
export class FilterCheckboxListComponent {
  private value: string = '';

  @Input() checkboxListGroup?: FilterGroupFieldsInterface;
  @Input() form?: FormGroup;
}
