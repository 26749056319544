import { Pipe, PipeTransform } from '@angular/core';
import { TranslateCustomService } from '../../core/common/services/translate-custom.service';

@Pipe({
  name: 'translateSelectItems',
})
export class TranslateSelectItemsPipe implements PipeTransform {
  constructor(
    private translateCustomService: TranslateCustomService,
  ) {}
  transform(
    items: { [key: string]: string | any }[],
    prefix: string,
    param?: { [key: string]: string } | undefined,
    label = 'label',
  ): any {
    const translatedItems = JSON.parse(JSON.stringify(items));
    if (!items || !items.length) {
      return translatedItems;
    }
    return translatedItems.map((item: { [key: string]: string | any }) => {
      const translatedItem = { ...item };
      if (prefix) {
        translatedItem[label] = this.translateCustomService.instant(`${prefix}.${item[label]}`, param);
      } else {
        translatedItem[label] = this.translateCustomService.instant(item[label], param);
      }

      return translatedItem;
    });
  }
}
