import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Web3Service } from '../../core/common/services/web3.service';
import { ContractService } from '../../core/common/services/contract.service';

@Injectable()
export class ReadRentFoundationService {
  constructor(
    private web3: Web3Service,
    private contractService: ContractService,
    private store: Store,
  ) {}
}
