import { Component } from '@angular/core';
import {
  catchError, map, pairwise, startWith, switchMap, take, takeUntil, tap,
} from 'rxjs/operators';
import { FormBuilder, FormGroup } from '@angular/forms';
import { combineLatest, of, throwError } from 'rxjs';
import { ETH, TradeContext } from 'simple-uniswap-sdk';
import { TransactionReceipt } from 'web3-core';
import { Store } from '@ngrx/store';
import { UnsubscribeDestroyHelperComponent } from '../../../core/common/helpers/unsubscribe-destroy-helper.component';
import { SelectItemInterface } from '../../../core/common/intefaces/select-item.interface';
import { ExchangeFacadeService } from '../../services/exchange.facade.service';
import { NetworkInterface } from '../../interfaces/network.interface';
import { ModalService } from '../../../shared/modules/modals/services/modal.service';
import { ExchangePendingComponent } from '../exchange-pending/exchange-pending.component';
import { ExchangeSuccessComponent } from '../exchange-success/exchange-success.component';
import {
  CheckoutFailedModalComponent,
} from '../../../shared/modules/modals/components/checkout-failed-modal/checkout-failed-modal.component';
import {
  selectShardsAddresses,
} from '../../../core/common/store/selectors/network.selector';
import { NoLiquidityTradeContext } from '../../models/no-liquidity-trade-context';

@Component({
  selector: 'app-exchange',
  templateUrl: './exchange.component.html',
  styleUrls: ['../../../styles/blocks/modals.scss', './exchange.component.scss'],
})
export class ExchangeComponent extends UnsubscribeDestroyHelperComponent {
  public static readonly modalIdentifier: string = 'exchangeComponent';

  public blockConfirmBnt: boolean = false;
  public tokens: SelectItemInterface[] = [];
  public form: FormGroup;
  public tradeContext?: TradeContext;
  public receipt?: TransactionReceipt;
  public fromBalance?: string;
  public toBalance?: string;
  public buying: boolean = false;
  private ethAddress?: string;
  private network?: NetworkInterface;
  private shardType!: string;

  constructor(
    private exchangeFacadeService: ExchangeFacadeService,
    private fb: FormBuilder,
    private modalService: ModalService,
    private store: Store,
  ) {
    super();
    this.form = fb.group({
      from: [{}],
      to: [{}],
    });
    this.buying = this.modalService.getModalData(ExchangeComponent.modalIdentifier).buying ?? true;
    this.shardType = this.modalService.getModalData(ExchangeComponent.modalIdentifier).shardType;
    exchangeFacadeService.getNetwork().pipe(
      takeUntil(this.unsubscribeSubject),
    ).subscribe((network) => {
      this.network = network;
      this.initForm();
    });

    this.subscribeToAccounts();
    this.subscribeToTokens();
    this.subscribeToForm();
  }

  private initForm(): void {
    if (!this.network) {
      return;
    }

    this.store.select(selectShardsAddresses)
      .subscribe((shardContracts: { [key: string]: string }) => {
        if (this.buying) {
          this.form.patchValue({
            from: { currency: ETH.info(this.network!.id).contractAddress },
            to: { currency: shardContracts[this.shardType] },
          });
        } else {
          this.form.patchValue({
            from: { currency: shardContracts[this.shardType] },
            to: { currency: ETH.info(this.network!.id).contractAddress },
          });
        }
      });
  }

  public execute(context: TradeContext): void {
    this.modalService.close(ExchangeComponent.modalIdentifier);
    this.modalService.open(
      ExchangePendingComponent.modalIdentifier,
      ExchangePendingComponent,
      { tradeContext: context },
    );
    this.exchangeFacadeService.contracts().pipe(
      take(1),
      switchMap((contracts) => combineLatest(
        of(contracts),
        this.exchangeFacadeService.account(),
      )),
      switchMap(([contracts, account]) => combineLatest(
        of(contracts),
        of(account),
        this.exchangeFacadeService.isApprovalRequired(account!.address,
          context.fromToken.contractAddress, contracts.uniswapRouterContractAddress),
      )),
      switchMap(([contracts, account, isApprovalRequired]) => (isApprovalRequired
        ? this.exchangeFacadeService.approveAllowance(
          this.network!.id,
          account!.address,
          context.fromToken.contractAddress,
          contracts.uniswapRouterContractAddress,
        ) : of(null))),
      switchMap(() => this.exchangeFacadeService.executeTrade(context.transaction)),
      catchError((error) => {
        this.modalService.close(ExchangePendingComponent.modalIdentifier);
        this.modalService.open(CheckoutFailedModalComponent.modalIdentifier, CheckoutFailedModalComponent, {});
        return throwError(error);
      }),
    ).subscribe((receipt) => {
      this.modalService.close(ExchangePendingComponent.modalIdentifier);
      this.modalService.open(
        ExchangeSuccessComponent.modalIdentifier,
        ExchangeSuccessComponent,
        { receipt },
      );
    });
  }

  public cancel(): void {
    this.modalService.close(ExchangeComponent.modalIdentifier);
  }

  private subscribeToAccounts(): void {
    this.exchangeFacadeService.getAccounts().pipe(
      takeUntil(this.unsubscribeSubject),
    ).subscribe((accounts) => {
      if (accounts.length) {
        const [account] = accounts;
        this.ethAddress = account.address;
      }
    });
  }

  private subscribeToTokens(): void {
    this.exchangeFacadeService.getNetwork().pipe(
      takeUntil(this.unsubscribeSubject),
      switchMap((network) => this.exchangeFacadeService.getTokens(network.id)),
      map((tokens) => tokens.map((token) => ({
        label: token.symbol,
        value: token.contractAddress,
        data: {
          iconUrl: token.logoURI,
          chainId: token.chainId,
        },
      } as SelectItemInterface))),
    ).subscribe((tokens) => {
      this.tokens = tokens;
    });
  }

  private subscribeToForm(): void {
    this.form.valueChanges.pipe(
      startWith(null),
      pairwise(),
      tap(([first, second]): void => {
        if (first && first?.from?.currency !== second?.from?.currency) {
          this.blockConfirmBnt = true;
        }
      }),
      switchMap(([, second]) => of(second)),
      takeUntil(this.unsubscribeSubject),
      switchMap((value) => combineLatest(
        of(value),
        value.from?.currency && this.ethAddress && this.network
          ? this.exchangeFacadeService.getBalance(this.network.id, this.ethAddress, value.from.currency)
          : of(undefined),
        value.to?.currency && this.ethAddress && this.network
          ? this.exchangeFacadeService.getBalance(this.network.id, this.ethAddress, value.to.currency) : of(undefined),
        !this.ethAddress
        || !value.from?.amount
        || !value.from?.currency
        || !value.to?.currency || value.from?.currency === value.to?.currency
        || value.from?.amount < 0 ? of(undefined) : this.exchangeFacadeService.tradeInfo(
            this.tokens[0].data.chainId,
            value.from?.amount,
            value.from?.currency,
            value.to?.currency,
            this.ethAddress,
          ).pipe(
            catchError(() => this.exchangeFacadeService.getBalance(
              this.network!.id, this.ethAddress!, value.from.currency,
            ).pipe(
              map((balance) => new NoLiquidityTradeContext(balance)),
            )),
          ),
      )),
      switchMap(([formValue, fromBalance, toBalance, context]) => combineLatest(
        of(fromBalance),
        of(toBalance),
        of(context),
        formValue.from
          ? this.exchangeFacadeService.getDecimalPlacesByContractAddress(this.network!.id, formValue.from.currency)
          : of(undefined),
        formValue.to
          ? this.exchangeFacadeService.getDecimalPlacesByContractAddress(this.network!.id, formValue.to.currency)
          : of(undefined),
      )),
      catchError((error) => throwError(error)),
    ).subscribe(([fromBalance, toBalance, context, fromDecimalPlaces, toDecimalPlaces]) => {
      this.blockConfirmBnt = false;
      this.fromBalance = fromBalance ? parseFloat(fromBalance).toFixed(fromDecimalPlaces) : fromBalance;
      this.toBalance = toBalance ? parseFloat(toBalance).toFixed(toDecimalPlaces) : toBalance;
      if (context) {
        context.expectedConvertQuote = parseFloat(context.expectedConvertQuote).toFixed(toDecimalPlaces);
        const to = this.form.get('to')?.value;
        to.amount = context.expectedConvertQuote;
        this.form.patchValue({ to }, { emitEvent: false });
        context?.destroy();
      }
      this.tradeContext = context;
    }, () => {
      this.blockConfirmBnt = false;
    });
  }
}
