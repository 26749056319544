<div class="box">
  <div class="box-content">
    <div class="box-header">
      <div class="box-header__title">{{title}}</div>
    </div>
    <div class="box-body">
      <ng-content></ng-content>
    </div>
  </div>
</div>
