import { Injectable } from '@angular/core';

@Injectable()
export class LocalStorageService {
  private storage: Storage;

  constructor() {
    this.storage = localStorage;
  }

  public setItem(key: string, data: string): void {
    return this.storage.setItem(key, data);
  }

  public removeItem(key: string): void {
    return this.storage.removeItem(key);
  }

  public getItem(key: string): string | null {
    return this.storage.getItem(key);
  }

  public clear(): void {
    return this.storage.clear();
  }
}
