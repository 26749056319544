import { Component } from '@angular/core';
import { TransactionReceipt } from 'web3-core';
import { ModalService } from '../../../shared/modules/modals/services/modal.service';

@Component({
  selector: 'app-exchange-pending',
  templateUrl: './exchange-success.component.html',
  styleUrls: ['./exchange-success.component.scss'],
})
export class ExchangeSuccessComponent {
  public static readonly modalIdentifier: string = 'exchangeSuccessComponent';

  public receipt: TransactionReceipt;

  constructor(private modalService: ModalService) {
    this.receipt = modalService.getModalData(ExchangeSuccessComponent.modalIdentifier).receipt;
  }

  public close(): void {
    this.modalService.close(ExchangeSuccessComponent.modalIdentifier);
  }
}
