<div class="border" [class.hidden]="!loggedIn || admin">
  <div *ngIf="loggedIn && user?.name"
       class="wallet-address">
    {{ user?.name }}
  </div>

<!--  <div *ngIf="loggedIn && !admin && (walletAddressWrong$ | async)"-->
<!--       class="account-button"-->
<!--       (click)="connectWallet()">-->
<!--    {{ 'common.header.switch_wallet' | translate}}-->
<!--  </div>-->

<!--  <div class="account-button pending"-->
<!--       *ngIf="totalCountProcessingTransaction$ | async"-->
<!--       (click)="openWalletInfoPopup()">-->
<!--    {{ 'common.header.pending' | translate : {totalCount: (totalCountProcessingTransaction$ | async)} }}-->
<!--  </div>-->

<!--  <div class="transactions-status" (click)="openWalletInfoPopup()">-->
<!--    3 Pending ...-->
<!--  </div>-->

  <div class="user-avatar"
       [class.notifications]="hasNotifications"
       (click)="openMenu()"
       #avatar>

    <svg *ngIf="!loggedIn"
         width="27"
         height="27"
         viewBox="0 0 27 27"
         fill="none">
      <path d="M21.9526 21.6818C20.4974 18.4652 17.26 16.2273 13.5 16.2273C9.73995 16.2273 6.50261 18.4652 5.04735 21.6818M25.5 13.5C25.5 20.1274 20.1274 25.5 13.5 25.5C6.87258 25.5 1.5 20.1274 1.5 13.5C1.5 6.87258 6.87258 1.5 13.5 1.5C20.1274 1.5 25.5 6.87258 25.5 13.5ZM17.3182 9.13636C17.3182 11.2451 15.6087 12.9545 13.5 12.9545C11.3913 12.9545 9.68182 11.2451 9.68182 9.13636C9.68182 7.02764 11.3913 5.31818 13.5 5.31818C15.6087 5.31818 17.3182 7.02764 17.3182 9.13636Z" stroke="#DDDDDD" stroke-width="2" stroke-linejoin="round"/>
    </svg>

    <img *ngIf="loggedIn"
         [src]="avatarUrl || '../../../../assets/images/avatar-account.svg'"
         alt="avatar">
  </div>

<!--  <div *ngIf="hasNotifications" class="notification-indicator"></div>-->
</div>

<div class="menu"
     [class.opened]="menuOpened"
     (click)="hideMenu()">
  <ng-content select="app-user-menu-item"></ng-content>
</div>
