import { Component } from '@angular/core';
import {
  combineLatest, Observable, of, Subject, throwError,
} from 'rxjs';
import {
  catchError, finalize, map, switchMap, take, takeUntil,
} from 'rxjs/operators';
import { LandInterface } from '../../../lends/interfaces/land.interface';
import { ModalService } from '../../services/modal.service';
import { NftToShardSuccessModalComponent } from '../nft-to-shard-success-modal/nft-to-shard-success-modal.component';
import { NftToShardFacadeService } from '../../../../../landx-shard/services/nft-to-shard.facade.service';
import { PreviewInterface } from '../../../../../landx-shard/interfaces/preview.interface';
import { CheckoutFailedModalComponent } from '../checkout-failed-modal/checkout-failed-modal.component';
import { SpinnerService } from '../../../../../core/common/services/spinner.service';
import { PendingModalComponent } from '../pending-modal/pending-modal.component';
import { ShardCultureNames } from '../../../../../user-profile/enums/shard-culture-names.enum';

@Component({
  selector: 'app-nft-to-shard-modal',
  templateUrl: './nft-to-shard-modal.component.html',
  styleUrls: ['../../../../../styles/blocks/modals.scss'],
})
export class NftToShardModalComponent {
  static readonly modalIdentifier = 'nftToShardModal';
  public land: LandInterface;
  public $preview: Observable<PreviewInterface>;
  public shardCultureNames: { [key: string]: string } = ShardCultureNames;

  constructor(
    private modalService: ModalService,
    private nftToShardFacadeService: NftToShardFacadeService,
    private spinnerService: SpinnerService,
  ) {
    this.spinnerService.show();
    this.land = this.modalService.getModalData(NftToShardModalComponent.modalIdentifier).land;
    this.$preview = this.preview();
  }

  public submit() {
    const unsubscribeSubject = new Subject();
    this.modalService.close(NftToShardModalComponent.modalIdentifier);
    this.modalService.open(PendingModalComponent.modalIdentifier, PendingModalComponent, {
      unsubscribeSubject,
    });
    this.nftToShardFacadeService.account().pipe(
      takeUntil(unsubscribeSubject),
      switchMap((ethAccount) => combineLatest(
        of(ethAccount),
        this.nftToShardFacadeService.isShardContractApprovedToUseNft(
          ethAccount!.address, this.land.productDetails.cropShareType,
        ),
      )),
      switchMap(([ethAccount, isApprovedForAll]) => combineLatest([
        of(ethAccount),
        isApprovedForAll
          ? of(null)
          : this.nftToShardFacadeService.approveShardContractToUseNft(
            ethAccount!.address, this.land.productDetails.cropShareType,
          ),
      ])),
      switchMap(([ethAccount]) => combineLatest([
        of(ethAccount),
      ])),
      switchMap(([ethAccount]) => this.nftToShardFacadeService.getShards(
        ethAccount!.address, this.land.token.id, this.land.productDetails.cropShareType,
      )),
      catchError((error) => {
        this.modalService.close(PendingModalComponent.modalIdentifier);
        this.modalService.open(CheckoutFailedModalComponent.modalIdentifier, CheckoutFailedModalComponent);
        return throwError(error);
      }),
    ).subscribe(() => {
      this.modalService.close(PendingModalComponent.modalIdentifier);
      this.modalService.open(NftToShardSuccessModalComponent.modalIdentifier,
        NftToShardSuccessModalComponent, { preview: this.$preview });
    });
  }

  private preview(): Observable<PreviewInterface> {
    return this.nftToShardFacadeService.preview(this.land.token.id, this.land.productDetails.cropShareType).pipe(
      map((preview) => ({ ...preview, cropShareType: this.land.productDetails.cropShareType })),
      finalize(() => this.spinnerService.hide()),
      take(1),
    );
  }

  public getCultureByType(shardType: string): string {
    return this.shardCultureNames[shardType];
  }
}
