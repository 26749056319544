import { AbstractControl, ValidatorFn } from '@angular/forms';
import { ErrorCodes } from '../constants/error-codes';

export function lowestAmountValidator(lowestAmount: number, currency?: string): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    if (typeof control.value !== 'number' && !control.value && lowestAmount) {
      return null;
    }
    const { value } = control;
    return lowestAmount > value
      ? { [ErrorCodes.LowestAmount]: { value: (`${lowestAmount} ${currency}`) } } : null;
  };
}
