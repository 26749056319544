import { Component } from '@angular/core';
import { Observable } from 'rxjs';
import { ModalService } from '../../services/modal.service';
import { LandInterface } from '../../../lends/interfaces/land.interface';
import { PRICE_FORMAT } from '../../../../../core/common/constants/common-settings-values';
import { CheckoutModalDataInterface } from '../../interfaces/checkout-modal-data.interface';
import { AreaUnits } from '../../../../../core/settings/constants/units-names';
import { BuyNowFacadeService } from '../../../../../auction/services/buy-now.facade.service';

@Component({
  selector: 'app-checkout-success-modal',
  templateUrl: './checkout-success-modal.component.html',
  styleUrls: ['../../../../../styles/blocks/modals.scss'],
})
export class CheckoutSuccessModalComponent {
  public static readonly modalIdentifier: string = 'checkoutSuccessModalComponent';
  public land$: Observable<LandInterface | null>;
  public units$: Observable<string>;
  public data: CheckoutModalDataInterface;
  public areaUnits = AreaUnits;
  public amountFormat = PRICE_FORMAT;

  constructor(private modalService: ModalService, private buyNowFacadeService: BuyNowFacadeService) {
    this.land$ = this.modalService.getModalData(CheckoutSuccessModalComponent.modalIdentifier).land$;
    this.data = this.modalService.getModalData(CheckoutSuccessModalComponent.modalIdentifier).data;
    this.units$ = this.buyNowFacadeService.getUnits$;
  }

  public close(): void {
    this.modalService.close(CheckoutSuccessModalComponent.modalIdentifier);
  }

  public get getTotalAmount() {
    return this.data.totalAmount;
  }
}
