<div class="field-label" *ngIf="label">
  {{label}}
</div>

<div class="switch-container">
  <label class="switch">
    <input
      type="checkbox"
      [attr.name]="label"
      [(ngModel)]="value"
      (ngModelChange)="setValue()"
      (blur)="setTouched()"
      [disabled]="disabled"
    >

    <span class="slider"></span>
  </label>

  <div class="label" *ngIf="label || note">
    <span class="title" *ngIf="title">{{title}}</span>
    <span class="note" *ngIf="note">{{note}}</span>
  </div>
</div>
