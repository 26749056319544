import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { CONTRACT_API } from '../api/contract.api';
import { IHttpResponse } from '../intefaces/http-response.interface';

@Injectable()
export class ContractApiService {
  constructor(private httpClient: HttpClient) {}

  public contracts(): Observable<IHttpResponse<any>> {
    return this.httpClient.get<IHttpResponse<any>>(
      CONTRACT_API.contracts,
    );
  }
}
