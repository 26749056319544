import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, first, switchMap } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Router } from '@angular/router';
import { selectAuthToken } from '../store/selectors/autth.selectors';
import { AuthService } from '../../auth/services/auth.service';
import { LoginSuccess, LogOut } from '../store/actions/auth.actions';
import { AUTH_API } from '../../auth/api/auth.api';

const helper = new JwtHelperService();

export const UNHANDLED_URLS = [
  '/private/user/notifications/unread-count',
];

@Injectable()
export class RefreshInterceptor implements HttpInterceptor {
  private refreshInProgress = false;

  constructor(
    private store: Store,
    private injector: Injector,
    private router: Router,
  ) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (req.url.includes(AUTH_API.refresh) || req.url.includes('/public') || UNHANDLED_URLS.includes(req.url)) {
      return next.handle(req);
    }

    return this.store.select(selectAuthToken).pipe(
      first(),
      switchMap((authToken) => {
        if (!authToken || !this.canRefresh(authToken) || this.refreshInProgress) {
          return next.handle(req);
        }

        this.refreshInProgress = true;
        setTimeout(() => this.injector.get(AuthService).refresh(authToken)
          .pipe(
            first(),
            catchError(() => of(null)),
            switchMap((token) => {
              if (!token) {
                this.store.dispatch(LogOut());
                this.refreshInProgress = false;
                return next.handle(req);
              }
              const role = helper.decodeToken(token)?.role || null;
              this.store.dispatch(LoginSuccess({ token, role }));
              const reqClone = req.clone({
                headers: req.headers
                  .set('Authorization', `Bearer ${token}`),
              });
              this.refreshInProgress = false;
              return next.handle(reqClone);
            }),
          ).subscribe());
        return next.handle(req);
      }),
    );
  }

  private canRefresh(token: string): boolean {
    const data = helper.decodeToken(token);
    const difference = (data.exp - Date.now() / 1000) / 60;

    return (difference >= 0) && (difference < 5);
  }
}
