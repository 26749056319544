import { Injectable, OnDestroy } from '@angular/core';

import { Observable, Subject } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { ApiError } from '../models/api-error.model';
import { ErrorTargetsNames } from '../constants/error-targets-names';
import { NotificationsServiceWithTranslate } from './notificationsServiceWithTranslate';

@Injectable()
export class ApiErrorService implements OnDestroy {
  public errorsFormSubject: Subject<boolean> = new Subject<boolean>();

  private errorsSubject: Subject<ApiError[]> = new Subject<ApiError[]>();
  /** Add in this array error codes of errors which shouldn't be with notification */
  private unhandledErrors: string[] = [];

  constructor(
    private notificationsServiceWithTranslate: NotificationsServiceWithTranslate,
  ) {
  }

  public get errorsPipe(): Observable<ApiError[]> {
    return this.errorsSubject.asObservable();
  }

  public ngOnDestroy(): void {
    this.errorsSubject.complete();
    this.errorsFormSubject.complete();
  }

  public getCommonErrorFromResponse(errorResponse: HttpErrorResponse): ApiError | null {
    let errors: ApiError[];
    if (errorResponse && errorResponse.error && errorResponse.error.errors) {
      errors = errorResponse.error.errors.map((err: any): ApiError => new ApiError(err));
      errors.filter((error: ApiError): boolean => error.target === ErrorTargetsNames.Common);
      return errors && errors.length ? errors[0] : null;
    }
    return null;
  }

  public handleErrorResponse(errorResponse: HttpErrorResponse): void {
    let errors: ApiError[];
    if (errorResponse && errorResponse.error && errorResponse.error.errors) {
      errors = errorResponse.error.errors.map((err: any): ApiError => new ApiError(err));

      errors.filter((error: ApiError): boolean => error.target === ErrorTargetsNames.Common)
        .forEach((error: ApiError) => {
          this.showError(error);
        });

      this.errorsSubject.next(errors);
    }
  }

  private showError(error: ApiError): void {
    if (!this.unhandledErrors.includes(error.code)) {
      this.notificationsServiceWithTranslate.error({
        content: `errors.${error.code}`,
      });
    }
  }
}
