import { Component, forwardRef, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'app-field-search',
  templateUrl: './field-search.component.html',
  styleUrls: ['./field-search.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FieldSearchComponent),
      multi: true,
    },
  ],
})
export class FieldSearchComponent implements ControlValueAccessor {
  @Input() placeholder = '';
  @Input() formControlName: string | undefined;

  public value: string = '';

  private onChange = (value: string) => {}; // eslint-disable-line @typescript-eslint/no-unused-vars
  private onTouched = () => {};

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  writeValue(value: any): void {
    this.value = value;
  }

  public setValue(value: string) {
    this.value = value;
    this.onChange(value);
  }

  public clear(): void {
    this.setValue('');
  }
}
