import { AfterViewInit, Component, ViewChild } from '@angular/core';
import {
  catchError,
  debounceTime, delay,
  distinctUntilChanged,
  startWith,
  switchMap,
  takeUntil,
} from 'rxjs/operators';
import { Observable, of, Subject } from 'rxjs';
import { Router } from '@angular/router';
import { NgSelectComponent } from '@ng-select/ng-select';
import { HeaderFacadeService } from '../../services/header.facade.service';
import { DEFAULT_DEBOUNCE_TIME } from '../../../core/common/constants/common-settings-values';
import { UnsubscribeDestroyHelperComponent } from '../../../core/common/helpers/unsubscribe-destroy-helper.component';
import { LandInterface } from '../../../shared/modules/lends/interfaces/land.interface';

@Component({
  selector: 'app-header-search',
  templateUrl: './header-search.component.html',
  styleUrls: ['./header-search.component.scss'],
})
export class HeaderSearchComponent extends UnsubscribeDestroyHelperComponent implements AfterViewInit {
  @ViewChild('headerSearchInput') private headerSearchInput?: NgSelectComponent;

  public result$: Observable<any> | undefined;
  public searchInput$ = new Subject<string>();
  public loading$: Observable<boolean>;

  constructor(
    private headerService: HeaderFacadeService,
    private router: Router,
  ) {
    super();
    this.loading$ = this.headerService.getSearchLoading().pipe(takeUntil(this.unsubscribeSubject));
  }

  ngAfterViewInit(): void {
    this.result$ = this.searchInput$.pipe(
      debounceTime(DEFAULT_DEBOUNCE_TIME),
      distinctUntilChanged(),
      startWith(''),
      delay(0),
      switchMap((term: string) => {
        if (term) {
          return this.headerService.loadSearchResults(term);
        }
        return of([]);
      }),
      catchError(() => of([])),
      takeUntil(this.unsubscribeSubject),
    );
  }

  public navigateToSelectedLand(item: LandInterface) {
    this.headerSearchInput?.handleClearClick();
    this.headerSearchInput?.blur();
    this.router.navigate([`/land/${item.id}/land-details`]);
  }
}
