<div class="connect-wallet">
  <div class="connect-wallet__title">
    {{ (reconnect ? 'popups.reconnect_wallet' : 'popups.connect_your_wallet') | translate}}
  </div>

  <div
    *ngIf="reconnect"
    class="connect-wallet__sub-title"
  >{{'popups.reconnect_wallet_description' | translate}}</div>

  <div
    *ngIf="!reconnect"
    class="connect-wallet__sub-title"
  >
    {{'popups.by_connecting_your_wallet' | translate}} <br> <a class="link">{{'popups.terms_of_service' | translate}}</a> {{'popups.and our' | translate}} <a class="link">{{'popups.privacy_policy' | translate}}</a>.
  </div>


  <ng-container *ngIf="(metamaskInstalled$ | async) || !mobilePlatform; else mobileLink">
    <div
      class="connect-wallet__button connect-button"
      (click)="connectWallet()"
    >
      <img class="connect-button__icon" src="assets/icons/metamask.svg" alt="metamask icon">

      <div class="connect-button__label">
        {{'popups.metamask' | translate}}
      </div>

      <img class="connect-button__arrow" src="assets/icons/arrow-back-black.svg" alt="arrow left">
    </div>
  </ng-container>

  <ng-template #mobileLink>
    <a
      class="connect-wallet__button connect-button"
      href="{{deeplink}}"
    >
      <img class="connect-button__icon" src="assets/icons/metamask.svg" alt="metamask icon">

      <div class="connect-button__label">
        {{'popups.metamask' | translate}}
      </div>

      <img class="connect-button__arrow" src="assets/icons/arrow-back-black.svg" alt="arrow left">
    </a>
  </ng-template>

  <a class="connect-wallet__note" *ngIf="!reconnect">
    {{'popups.first_time_setting_up_a_wallet' | translate}}
  </a>
</div>
