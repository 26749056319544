import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { UserInterface } from '../../core/user/interfaces/user.interface';
import { selectUser } from '../../core/common/store/selectors/user.selector';
import { TradingHistoryInterface } from '../../land-details/interfaces/trading-history.interface';
import { CallerService } from '../../core/common/services/caller.service';
import { WalletInfoApiService } from './wallet-info.api.service';
import { selectProcessingTransactionItems } from '../../core/common/store/selectors/processing-transaction.selectors';

@Injectable()
export class WalletInfoFacadeService {
  constructor(
    private store: Store,
    private callerService: CallerService,
    private walletInfoApiService: WalletInfoApiService,
  ) { }

  public getUser$(): Observable<UserInterface | undefined> {
    return this.store.select(selectUser);
  }

  public getProcessingTransactions$(): Observable<TradingHistoryInterface[]> {
    return this.store.select(selectProcessingTransactionItems);
  }
}
