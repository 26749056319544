import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { WriteAuctionService } from './write-auction.service';
import { MetamaskService } from '../../core/auth/services/metamask.service';
import { EthAccountInterface } from '../../core/common/intefaces/eth-account.interface';

@Injectable()
export class CancelOfferFacadeService {
  constructor(
    private metamaskService: MetamaskService,
    private writeAuctionService: WriteAuctionService,
  ) {
  }

  public account(): Observable<EthAccountInterface|undefined> {
    return this.metamaskService.getAccounts().pipe(
      map((accounts) => (accounts ? accounts[0] : undefined)),
    );
  }

  public cancelOffer(from: string, offerId: number, contractAddress: string|null = null): Observable<string> {
    return this.writeAuctionService.cancelOffer(from, offerId, contractAddress);
  }
}
