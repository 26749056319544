<div class="filter-selected-list" *ngIf="checkboxListGroup && form">
  <ng-container [formGroup]="form">
    <div
      *ngFor="let item of checkboxListGroup.items"
      class="filter-selected-list__item"
    >
      <app-checkbox
        [label]="item.label | translate"
        [formControlName]="item.name"
      ></app-checkbox>
    </div>
  </ng-container>
</div>
