import {
  AfterViewInit,
  Attribute,
  Component, ElementRef, EventEmitter, forwardRef, Input, Output, ViewChild,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'app-field-input-amount',
  templateUrl: './field-input-amount.component.html',
  styleUrls: ['./field-input-amount.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FieldInputAmountComponent),
      multi: true,
    },
  ],
})

export class FieldInputAmountComponent implements AfterViewInit, ControlValueAccessor {
  @ViewChild('input') input!: ElementRef;
  @ViewChild('hiddenText') hiddenText!: ElementRef;

  @Input() label?: string = '';
  @Input() unit?: string = '';
  @Input() equivalent?: string | number = '';
  @Input() equivalentCurrency?: string = '';
  @Input() available?: string | number = '';
  @Input() availableCurrency?: string = '';
  @Input() note = '';
  @Input() minValue = '';
  @Input() error = '';
  @Input() placeholder = '';
  @Input() formControlName: string | undefined;
  @Input() disabled = false;

  // eslint-disable-next-line @angular-eslint/no-output-native
  @Output() blur: EventEmitter<void> = new EventEmitter();

  public value: string | number | null = null;
  public touched = false;
  public type: string;
  public minWidth: number = 0;
  public width: number = this.minWidth;
  public white: boolean = false;

  private onChange = (value: string | number | null) => {}; // eslint-disable-line @typescript-eslint/no-unused-vars
  private onTouched = () => {};

  constructor(
    @Attribute('white') public attrWhite: string | null,
    @Attribute('type') public attrType: string | null,
  ) {
    this.white = typeof attrWhite === 'string';
    this.type = attrType || 'text';
  }

  ngAfterViewInit() {
    this.resize();
  }

  public focus() {
    setTimeout(() => { // this will make the execution after the above boolean has changed
      this.input.nativeElement.focus();
    }, 0);
  }

  public writeValue(value: string | number | null) {
    this.value = value;
    this.resize();
  }

  public registerOnChange(onChange: any) {
    this.onChange = onChange;
  }

  public registerOnTouched(onTouched: any) {
    this.onTouched = onTouched;
  }

  public setDisabledState(disabled: boolean) {
    this.disabled = disabled;
  }

  public setValue() {
    if (!this.disabled) {
      this.onChange(this.value);
    }
  }

  public setTouched() {
    this.blur.emit();
    if (!this.touched) {
      this.onTouched();
      this.touched = true;
    }
  }

  public resize() {
    setTimeout(() => {
      if (!this.value && this.value !== 0) {
        this.hiddenText.nativeElement.innerText = this.placeholder;
      } else {
        this.hiddenText.nativeElement.innerText = this.value;
      }
      this.width = Math.max(this.minWidth, this.hiddenText.nativeElement.offsetWidth + 12);
    });
  }
}
