import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { IHttpResponse } from '../../core/common/intefaces/http-response.interface';
import { CLAIM_LAND_API } from '../api/claim-land.api';
import { IFileInterface } from '../../core/common/intefaces/file.interface';
import { LandRequestModel } from '../models/land-request.model';
import { LandInterface } from '../../shared/modules/lends/interfaces/land.interface';
import { Country } from '../../core/countries/interfaces/country';

@Injectable()
export class ClaimLandApiService {
  constructor(
    private httpClient: HttpClient,
  ) {
  }

  public search(
    params: { [key: string]: string | number },
  ): Observable<IHttpResponse<LandInterface>> {
    return this.httpClient.get<IHttpResponse<LandInterface>>(
      CLAIM_LAND_API.search,
      {
        params,
      },
    );
  }

  public saveLand(body: LandRequestModel): Observable<IHttpResponse<any>> {
    return this.httpClient.post<IHttpResponse<any>>(
      CLAIM_LAND_API.landDraft,
      body,
    );
  }

  public saveLandVerification(body: LandRequestModel, landId: string): Observable<IHttpResponse<any>> {
    return this.httpClient.post<IHttpResponse<any>>(
      CLAIM_LAND_API.saveLandVerification(landId),
      body,
    );
  }

  public loadLandVerification(landId: number): Observable<IHttpResponse<LandInterface>> {
    return this.httpClient.get<IHttpResponse<LandInterface>>(
      CLAIM_LAND_API.getLandVerification(landId),
    );
  }

  public submitLand(body: LandRequestModel): Observable<IHttpResponse<any>> {
    return this.httpClient.post<IHttpResponse<any>>(
      CLAIM_LAND_API.landClaim,
      body,
    );
  }

  public submitLandVerification(body: LandRequestModel, landId: string): Observable<IHttpResponse<any>> {
    return this.httpClient.post<IHttpResponse<any>>(
      CLAIM_LAND_API.approveLandVerification(landId),
      body,
    );
  }

  public uploadLandFile(body: FormData): Observable<IHttpResponse<IFileInterface>> {
    return this.httpClient.post<IHttpResponse<IFileInterface>>(
      CLAIM_LAND_API.file,
      body,
    );
  }

  public uploadLandFileByVerifier(body: FormData): Observable<IHttpResponse<IFileInterface>> {
    return this.httpClient.post<IHttpResponse<IFileInterface>>(
      CLAIM_LAND_API.fileByVerifier,
      body,
    );
  }

  public downloadLandFile(url: string): Observable<Blob> {
    return this.httpClient.get(
      url,
      { responseType: 'blob' },
    );
  }

  public deleteFIle(removedFileId: number): Observable<IHttpResponse<void>> {
    return this.httpClient.delete<IHttpResponse<void>>(
      CLAIM_LAND_API.fileId(removedFileId),
    );
  }

  public deleteFIleByVerifier(removedFileId: number): Observable<IHttpResponse<void>> {
    return this.httpClient.delete<IHttpResponse<void>>(
      CLAIM_LAND_API.fileIdByVerifier(removedFileId),
    );
  }

  public countries(): Observable<IHttpResponse<Country[]>> {
    return this.httpClient.get<IHttpResponse<Country[]>>(
      CLAIM_LAND_API.countries,
    );
  }

  public decline(id: number): Observable<IHttpResponse<void>> {
    return this.httpClient.post<IHttpResponse<void>>(CLAIM_LAND_API.decline(id), {});
  }
}
