import {
  AfterContentInit,
  Component, ContentChild, ElementRef, Input,
} from '@angular/core';
import { fromEvent } from 'rxjs';

@Component({
  selector: 'app-collaps-bloc',
  templateUrl: './collaps-bloc.component.html',
  styleUrls: ['./collaps-bloc.component.scss'],
})
export class CollapsBlocComponent implements AfterContentInit {
  @ContentChild('collapsBlockHeader') collapsBlockHeader?: ElementRef;
  @ContentChild('collapsBlockBody') collapsBlockBody?: ElementRef;

  @Input() opened = true;

  public ngAfterContentInit() {
    if (this.collapsBlockHeader) {
      fromEvent(this.collapsBlockHeader.nativeElement, 'click').subscribe(
        () => {
          this.toggle();
        },
      );
    }
  }

  private toggle() {
    this.opened = !this.opened;
    if (this.collapsBlockBody) {
      this.collapsBlockBody.nativeElement.style.display = this.opened ? 'block' : 'none';
      this.collapsBlockHeader?.nativeElement.classList.toggle('closed');
    }
  }
}
