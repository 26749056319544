import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { ReferenceApiService } from './reference-api.service';
import { CallerService } from '../caller.service';

@Injectable({
  providedIn: 'root',
})
export class ReferenceService {
  constructor(
    private referenceApiService: ReferenceApiService,
    private callerService: CallerService,
  ) {
  }

  public getCulturesList(): Observable<{ id: number, name: string }[]> {
    return this.callerService.call(
      this.referenceApiService.getCulturesList(),
      null,
      null,
      false,
    ).pipe(
      map(({ data }) => data),
    );
  }

  public convertCurrency(
    from: string, to: string, amount: number,
  ): Observable<{ amount: number, convertedAmount: number }> {
    return this.referenceApiService.convertCurrency(from, to, amount).pipe(
      map(({ data }) => data),
    );
  }
}
