import { Observable } from 'rxjs';
import { Component } from '@angular/core';
import { HeaderSideMenuService } from '../../services/header-side-menu.service';

@Component({
  selector: 'app-side-setting-menu-content',
  templateUrl: './side-setting-menu-content.component.html',
  styleUrls: ['./side-setting-menu-content.component.scss'],
})
export class SideSettingMenuContentComponent {
  public loggedIn$: Observable<boolean>;

  constructor(
    private headerSideMenuService: HeaderSideMenuService,
  ) {
    this.loggedIn$ = headerSideMenuService.loggedIn$;
  }

  public close() {
    this.headerSideMenuService.close();
  }
}
