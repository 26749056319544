import { Injectable } from '@angular/core';
import { combineLatest, Observable, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { ChainId, ETH } from 'simple-uniswap-sdk';
import { TokenInterface } from '../interfaces/token.interface';
import { CompoundTokensListApiService } from './compound-tokens-list.api.service';
import { ContractService } from '../../core/common/services/contract.service';
import { USDC } from '../../shared/tokens/usdc';
import { WTC } from '../../shared/tokens/wtc';
import { LDXS } from '../../shared/tokens/ldxs';
import { LDXM } from '../../shared/tokens/ldxm';
import { LDXR } from '../../shared/tokens/ldxr';
import { LDXW } from '../../shared/tokens/ldxw';

@Injectable()
export class TokensListService {
  public constructor(
    private compoundTokensListService: CompoundTokensListApiService,
    private contractService: ContractService,
  ) {}

  public getTokenBySymbol(symbol: string, chainId: number): Observable<TokenInterface|null> {
    return this.getTokens(chainId).pipe(
      map((tokens) => tokens.find((token) => token.symbol === symbol) || null),
    );
  }

  public getTokens(chainId: number): Observable<TokenInterface[]> {
    return this.compoundTokensListService.getTokens(chainId).pipe(
      switchMap((tokens) => combineLatest(
        of(tokens),
        this.getBackendContracts(),
      )),
      map(([tokens, backendTokens]) => {
        tokens.push(...backendTokens, ...TokensListService.getCustomTokens(chainId));
        return tokens;
      }),
    );
  }

  private getBackendContracts(): Observable<TokenInterface[]> {
    return this.contractService.contracts().pipe(
      map((contracts) => [
        {
          chainId: ChainId.RINKEBY,
          contractAddress: contracts.usdcContractAddress,
          decimals: USDC.RINKEBY().decimals,
          symbol: USDC.RINKEBY().symbol,
          name: USDC.RINKEBY().name,
        },
        {
          chainId: ChainId.RINKEBY,
          contractAddress: contracts.wtcContractAddress,
          decimals: WTC.RINKEBY().decimals,
          symbol: WTC.RINKEBY().symbol,
          name: WTC.RINKEBY().name,
        },
        {
          chainId: ChainId.RINKEBY,
          contractAddress: contracts.ldxsShardManagerContractAddress,
          decimals: LDXS.RINKEBY().decimals,
          symbol: LDXS.RINKEBY().symbol,
          name: LDXS.RINKEBY().name,
        },
        {
          chainId: ChainId.RINKEBY,
          contractAddress: contracts.ldxmShardManagerContractAddress,
          decimals: LDXM.RINKEBY().decimals,
          symbol: LDXM.RINKEBY().symbol,
          name: LDXM.RINKEBY().name,
        },
        {
          chainId: ChainId.RINKEBY,
          contractAddress: contracts.ldxrShardManagerContractAddress,
          decimals: LDXR.RINKEBY().decimals,
          symbol: LDXR.RINKEBY().symbol,
          name: LDXR.RINKEBY().name,
        },
        {
          chainId: ChainId.RINKEBY,
          contractAddress: contracts.ldxwShardManagerContractAddress,
          decimals: LDXW.RINKEBY().decimals,
          symbol: LDXW.RINKEBY().symbol,
          name: LDXW.RINKEBY().name,
        },
      ] as TokenInterface[]),
    );
  }

  static getCustomTokens(chainId: number): TokenInterface[] {
    return [
      ETH.info(chainId),
    ];
  }
}
