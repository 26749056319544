import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ContractApiService } from './contract-api.service';
import { ContractsInterface } from '../intefaces/contracts.interface';

@Injectable()
export class ContractService {
  constructor(private contractApiService: ContractApiService) {}

  public contracts(): Observable<ContractsInterface> {
    return this.contractApiService.contracts().pipe(
      map((response) => response.data),
    );
  }
}
