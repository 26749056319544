<div class="modal">
  <div class="modal__title">
    {{ (buying ? 'popups.exchange.buy_shards' : 'popups.exchange.sell_shards') | translate }}
  </div>

  <div class="modal__subtitle">
    {{ (buying ? 'popups.exchange.you_are_buying_shards' : 'popups.exchange.you_are_selling_shards') | translate }}
  </div>

  <app-exchange-form [form]="form"
                     [tokens]="tokens"
                     [tradeContext]="tradeContext"
                     [fromBalance]="fromBalance"
                     [toBalance]="toBalance"
                     (submitted)="execute($event)"
                     (canceled)="cancel()"
                     [fieldFromDisabled]="!buying"
                     [fieldToDisabled]="buying"
                     [blockConfirmBnt]="blockConfirmBnt">
  </app-exchange-form>
</div>

