import { ChainId } from 'simple-uniswap-sdk/dist/esm/enums/chain-id';
import { UniswapError } from 'simple-uniswap-sdk/dist/esm/common/errors/uniswap-error';
import { ErrorCodes } from 'simple-uniswap-sdk/dist/esm/common/errors/error-codes';
import { TokenInterface } from '../../exchange/interfaces/token.interface';
import { environment } from '../../../environments/environment';

const symbol = 'LDXS';
const name = 'landx_nft_shards_of_soybeans';

export class LDXS {
  static MAINNET() {
    return {
      chainId: ChainId.MAINNET,
      contractAddress: '0x37533aFF22a3728a4Cbe94F004EcF1062b3Ef672',
      decimals: 18,
      symbol,
      name,
    };
  }

  static ROPSTEN() {
    return {
      chainId: ChainId.ROPSTEN,
      contractAddress: '0x37533aFF22a3728a4Cbe94F004EcF1062b3Ef672',
      decimals: 18,
      symbol,
      name,
    };
  }

  static RINKEBY() {
    return {
      chainId: ChainId.RINKEBY,
      contractAddress: environment.name === 'dev' ? '0x37533aFF22a3728a4Cbe94F004EcF1062b3Ef672'
        : '0x4Bae84271ED418Bda8a7e679Ae2423aF0CFCC4C1',
      decimals: 18,
      symbol,
      name,
    };
  }

  static GÖRLI() {
    return {
      chainId: ChainId.GÖRLI,
      contractAddress: '0x37533aFF22a3728a4Cbe94F004EcF1062b3Ef672',
      decimals: 18,
      symbol,
      name,
    };
  }

  static KOVAN() {
    return {
      chainId: ChainId.KOVAN,
      contractAddress: '0x37533aFF22a3728a4Cbe94F004EcF1062b3Ef672',
      decimals: 18,
      symbol,
      name,
    };
  }

  static info(chainId: number): TokenInterface {
    switch (chainId) {
      case ChainId.MAINNET:
        return this.MAINNET();
      case ChainId.ROPSTEN:
        return this.ROPSTEN();
      case ChainId.RINKEBY:
        return this.RINKEBY();
      case ChainId.GÖRLI:
        return this.GÖRLI();
      case ChainId.KOVAN:
        return this.KOVAN();
      default:
        throw new UniswapError(`${chainId} is not allowed`, ErrorCodes.tokenChainIdContractDoesNotExist);
    }
  }
}
