import { createSelector, State } from '@ngrx/store';
import { UnreadMessageState } from '../states/unread-message.state';

// @ts-ignore
const selectUnreadMessageState = (state: State) => state.unreadMessage;

export const selectNewNotificationsCount = createSelector(
  selectUnreadMessageState,
  (state: UnreadMessageState) => state.newNotificationsCount,
);

export const selectUnreadNotificationsCount = createSelector(
  selectUnreadMessageState,
  (state: UnreadMessageState) => state.unreadNotificationsCount,
);
