import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { IHttpResponse } from '../../common/intefaces/http-response.interface';
import { LANDS_API } from '../api/lands.api';
import { LandInterface } from '../../../shared/modules/lends/interfaces/land.interface';

@Injectable({
  providedIn: 'root',
})
export class LandApiService {
  constructor(
    private httpClient: HttpClient,
  ) { }

  public lands(params: { [key: string]: string | number }): Observable<IHttpResponse<LandInterface[]>> {
    return this.httpClient.get<IHttpResponse<LandInterface[]>>(
      LANDS_API.lands,
      {
        params,
      },
    );
  }

  public land(id: number): Observable<IHttpResponse<LandInterface>> {
    return this.httpClient.get<IHttpResponse<LandInterface>>(
      LANDS_API.land(id),
    );
  }
}
