<span [formGroup]="form" class="content">
  <img class="switcher"
       [class.disabled]="disabled"
       src="assets/icons/vertical-dots.svg"
       alt="Dots"
       (click)="toggle($event)"
       #switcher>

  <ng-select class="menu"
             formControlName="menu"
             [items]="selectItems | translateSelectItems : prefix : null : 'title'"
             bindLabel="title"
             [isOpen]="opened"
             [ngStyle]="{left: positionX + 'px', top: positionY + 'px'}">
  </ng-select>
</span>

