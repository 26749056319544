import { ChainId } from 'simple-uniswap-sdk/dist/esm/enums/chain-id';
import { UniswapError } from 'simple-uniswap-sdk/dist/esm/common/errors/uniswap-error';
import { ErrorCodes } from 'simple-uniswap-sdk/dist/esm/common/errors/error-codes';
import { TokenInterface } from '../../exchange/interfaces/token.interface';

const symbol = 'LDXM';
const name = 'landx_nft_shards_of_maize';

export class LDXM {
  static MAINNET() {
    return {
      chainId: ChainId.MAINNET,
      contractAddress: '0xba8432412160E57cD7B82F5de039033cAc40a374',
      decimals: 18,
      symbol,
      name,
    };
  }

  static ROPSTEN() {
    return {
      chainId: ChainId.ROPSTEN,
      contractAddress: '0xba8432412160E57cD7B82F5de039033cAc40a374',
      decimals: 18,
      symbol,
      name,
    };
  }

  static RINKEBY() {
    return {
      chainId: ChainId.RINKEBY,
      contractAddress: '0xD35C9a94048DE4537ebB3560e11DCc98dE2ABFC2',
      decimals: 18,
      symbol,
      name,
    };
  }

  static GÖRLI() {
    return {
      chainId: ChainId.GÖRLI,
      contractAddress: '0xba8432412160E57cD7B82F5de039033cAc40a374',
      decimals: 18,
      symbol,
      name,
    };
  }

  static KOVAN() {
    return {
      chainId: ChainId.KOVAN,
      contractAddress: '0xba8432412160E57cD7B82F5de039033cAc40a374',
      decimals: 18,
      symbol,
      name,
    };
  }

  static info(chainId: number): TokenInterface {
    switch (chainId) {
      case ChainId.MAINNET:
        return this.MAINNET();
      case ChainId.ROPSTEN:
        return this.ROPSTEN();
      case ChainId.RINKEBY:
        return this.RINKEBY();
      case ChainId.GÖRLI:
        return this.GÖRLI();
      case ChainId.KOVAN:
        return this.KOVAN();
      default:
        throw new UniswapError(`${chainId} is not allowed`, ErrorCodes.tokenChainIdContractDoesNotExist);
    }
  }
}
