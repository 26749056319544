<div class="filter-crumbs" *ngIf="crumbsList.length && form">
  <div class="filter-crumbs__item" *ngFor="let crumb of crumbsList">
    <ng-container [ngSwitch]="crumb.type">
      <ng-container *ngSwitchCase="'price'">
        <app-button crumb="crumb"
                    (btnClick)="remove(crumb)">
          <app-amount [currency]="crumb.currency"></app-amount>
          <span *ngIf="crumb.min && crumb.max">
            :&nbsp; {{crumb.min | number : '1.2-2' || ''}} - {{crumb.max | number : '1.2-2' || ''}}
          </span>
          <span *ngIf="!crumb.min && crumb.max">
            {{'common.filter.max' | translate}} {{crumb.max | number : '1.2-2' || ''}}
          </span>
          <span *ngIf="crumb.min && !crumb.max">
            {{'common.filter.min' | translate}} {{crumb.min | number : '1.2-2' || ''}}
          </span>
        </app-button>
      </ng-container>

      <ng-container *ngSwitchCase="'area'">
        <app-button crumb="crumb"
                    (btnClick)="remove(crumb)">
          {{'common.filter.' + crumb.label | translate}}
          ({{'common.units.' + crumb.units | translate}})
          <span *ngIf="crumb.min && crumb.max">:&nbsp;{{crumb.min | number : '1.2-2' || ''}} - {{crumb.max | number : '1.2-2' || ''}}
          </span>
          <span *ngIf="!crumb.min && crumb.max">
            &nbsp;{{'common.filter.max' | translate}} {{crumb.max | number : '1.2-2' || ''}}
          </span>
          <span *ngIf="crumb.min && !crumb.max">
            &nbsp;{{'common.filter.min' | translate}} {{crumb.min | number : '1.2-2' || ''}}
          </span>
        </app-button>
      </ng-container>

      <ng-container *ngSwitchCase="'date'">
        <app-button crumb="crumb"
                    (btnClick)="remove(crumb)">
          {{'common.filter.' + crumb.label | translate}}
          <span *ngIf="crumb.min && crumb.max">
            :&nbsp;{{crumb.min | date  : 'd.M.y'}} - {{crumb.max | date  : 'd.M.y'}}
          </span>
          <span *ngIf="!crumb.min && crumb.max">
            &nbsp;{{'common.filter.to' | translate}} {{crumb.max | date  : 'd.M.y'}}
          </span>
          <span *ngIf="crumb.min && !crumb.max">
            &nbsp;{{'common.filter.from' | translate}} {{crumb.min | date  : 'd.M.y'}}
          </span>
        </app-button>
      </ng-container>

      <ng-container *ngSwitchDefault>
        <app-button crumb="crumb"
                    (btnClick)="remove(crumb)"
                    *ngIf="crumb.control.value">
          {{crumb.label | translate}}&nbsp;<span *ngIf="crumb.value">{{crumb.value}}</span>
        </app-button>
      </ng-container>
    </ng-container>
  </div>

  <a (click)="clear()"
     class="filter-crumbs__item--clear filter-crumbs__item text-link">
    {{'common.filter.clear_all' | translate}}
  </a>
</div>
