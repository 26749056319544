import {
  Attribute,
  Component, ElementRef, EventEmitter, forwardRef, Input, Output, ViewChild,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'app-field-input-with-currency',
  templateUrl: './field-input-with-currency.component.html',
  styleUrls: ['./field-input-with-currency.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FieldInputWithCurrencyComponent),
      multi: true,
    },
  ],
})

export class FieldInputWithCurrencyComponent implements ControlValueAccessor {
  @ViewChild('input') input!: ElementRef;

  @Input() label?: string = '';
  @Input() placeholder = 'Amount';
  @Input() formControlName: string | undefined;
  @Input() disabled = false;
  @Input() currency: string = '';

  // eslint-disable-next-line @angular-eslint/no-output-native
  @Output() blur: EventEmitter<void> = new EventEmitter();

  public value: string | number | null = null;
  public touched = false;

  private onChange = (value: string | number | null) => {}; // eslint-disable-line @typescript-eslint/no-unused-vars
  private onTouched = () => {};

  constructor(
    @Attribute('white') public attrWhite: string | null,
    @Attribute('type') public attrType: string | null,
  ) {
  }

  public writeValue(value: string | number | null) {
    this.value = value;
  }

  public registerOnChange(onChange: any) {
    this.onChange = onChange;
  }

  public registerOnTouched(onTouched: any) {
    this.onTouched = onTouched;
  }

  public setValue() {
    if (!this.disabled) {
      this.onChange(this.value);
    }
  }

  public setTouched() {
    this.blur.emit();
    if (!this.touched) {
      this.onTouched();
      this.touched = true;
    }
  }
}
