import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { HeaderComponent } from './container-components/header/header.component';
import { HeaderSearchComponent } from './container-components/header-search/header-search.component';
import { HeaderFacadeService } from './services/header.facade.service';
import { SharedModule } from '../shared/shared.module';
import { FilterModule } from '../shared/modules/filter/filter.module';
import { UserMenuComponent } from './container-components/user-menu/user-menu.component';
import { UserMenuItemComponent } from './components/user-menu-item/user-menu-item.component';
import { HeaderSideMenuModule } from './modules/header-side-menu/header-side-menu.module';
// import { HeaderWarningComponent } from './container-components/header-warning/header-warning.component';
import { HeaderWarningFacadeService } from './services/header-warning.facade.service';
import { HeaderWarningStateService } from './state/header-warning.state.service';
import { WalletInfoPopupComponent } from './container-components/wallet-info-popup/wallet-info-popup.component';
import { WalletInfoFacadeService } from './services/wallet-info.facade.service';
import { WalletInfoApiService } from './services/wallet-info.api.service';

@NgModule({
  declarations: [
    HeaderComponent,
    HeaderSearchComponent,
    UserMenuComponent,
    UserMenuItemComponent,
    WalletInfoPopupComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    FilterModule,
    ReactiveFormsModule,
    HeaderSideMenuModule,
  ],
  exports: [
    HeaderComponent,
  ],
  providers: [
    HeaderFacadeService,
    HeaderWarningFacadeService,
    HeaderWarningStateService,
    WalletInfoFacadeService,
    WalletInfoApiService,
  ],
})
export class HeaderModule { }
