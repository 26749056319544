import {
  Attribute,
  Component, EventEmitter, forwardRef, Input, Output,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'app-checkbox',
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CheckboxComponent),
      multi: true,
    },
  ],
})

export class CheckboxComponent implements ControlValueAccessor {
  @Input() label: string = '';

  public value: boolean | null = false;

  private onChange = (value: boolean | null) => {}; // eslint-disable-line @typescript-eslint/no-unused-vars

  private onTouched = () => {};

  public touched = false;
  public disabled = false;
  public filter = false;

  // eslint-disable-next-line @angular-eslint/no-output-native
  @Output() blur: EventEmitter<void> = new EventEmitter();

  constructor(
    @Attribute('filter') public attrFilter: string | null,
  ) {
    this.filter = typeof attrFilter === 'string';
  }

  public writeValue(value: boolean | null) {
    this.value = value;
  }

  public registerOnChange(onChange: any) {
    this.onChange = onChange;
  }

  public registerOnTouched(onTouched: any) {
    this.onTouched = onTouched;
  }

  public setDisabledState(disabled: boolean) {
    this.disabled = disabled;
  }

  public setValue() {
    if (!this.disabled) {
      this.onChange(this.value);
    }
  }

  public setTouched() {
    this.blur.emit();
    if (!this.touched) {
      this.onTouched();
      this.touched = true;
    }
  }
}
