<div class="side-menu-content">
  <div class="side-menu-content__setting" (click)="close()">
    <app-header-settings></app-header-settings>
  </div>

  <ul class="side-menu-content__list">
    <li class="side-menu-content__item" *ngIf="loggedIn$ | async">
      <a class="side-menu-content__link" (click)="close()" [routerLink]="'/user-profile'">{{'common.header.my_landx' | translate}}</a>
    </li>

    <li class="side-menu-content__item">
      <a class="side-menu-content__link" (click)="close()" [routerLink]="'/marketplace'">{{'common.header.marketplace' | translate}}</a>
    </li>

    <li class="side-menu-content__item">
      <a class="side-menu-content__link" (click)="close()">{{'common.header.resources' | translate}}</a>
    </li>

    <li class="side-menu-content__item">
      <a class="side-menu-content__link" (click)="close()">{{'common.header.stats' | translate}}</a>
    </li>

    <li class="side-menu-content__item side-menu-content__item--create-land-nfts">
      <a class="side-menu-content__link" (click)="close()" [routerLink]="'/lands/claim'">{{'common.header.create_land_nfts' | translate}}</a>
    </li>
  </ul>
</div>
