import { Injectable } from '@angular/core';
import { AbiItem } from 'ethereum-abi-types-generator';
import { Observable, Subscriber } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { Web3Service } from '../../core/common/services/web3.service';
import { AuctionInterface } from '../interfaces/auction.interface';
import { SellListingsInterface } from '../interfaces/sell-listings.interface';
import { ContractService } from '../../core/common/services/contract.service';
import { selectAuctionContractAddress } from '../../core/common/store/selectors/network.selector';

@Injectable()
export class ReadAuctionService {
  constructor(
    private web3: Web3Service,
    private contractService: ContractService,
    private store: Store,
  ) {}

  public auctionActive(auctionId: number): Observable<boolean> {
    return this.contractService.contracts().pipe(
      switchMap((contracts) => new Observable((subscriber: Subscriber<boolean>) => {
        (async () => {
          try {
            const abi: AbiItem[] = [
              {
                name: 'auctionActive',
                inputs: [
                  {
                    internalType: 'uint256',
                    name: 'auctionId',
                    type: 'uint256',
                  },
                ],
                outputs: [
                  {
                    internalType: 'bool',
                    name: 'auctionActive',
                    type: 'bool',
                  },
                ],
                stateMutability: 'view',
                type: 'function',
              },
            ];
            const contract = new this.web3.eth.Contract(abi, contracts.auctionContractAddress);
            const result = await contract.methods.auctionActive(auctionId).call();
            subscriber.next(result);
          } catch (error) {
            subscriber.error(error);
          }
        })();
      })),
    );
  }

  public auctionBoost(): Observable<number> {
    return this.contractService.contracts().pipe(
      switchMap((contracts) => new Observable((subscriber: Subscriber<number>) => {
        (async () => {
          try {
            const abi: AbiItem[] = [
              {
                name: 'auctionBoost',
                inputs: [],
                outputs: [
                  {
                    internalType: 'uint256',
                    name: '',
                    type: 'uint256',
                  },
                ],
                stateMutability: 'view',
                type: 'function',
              },
            ];
            const contract = new this.web3.eth.Contract(abi, contracts.auctionContractAddress);
            const result = await contract.methods.auctionBoost().call();
            subscriber.next(result);
          } catch (error) {
            subscriber.error(error);
          }
        })();
      })),
    );
  }

  public auctionCount(): Observable<number> {
    return this.contractService.contracts().pipe(
      switchMap((contracts) => new Observable((subscriber: Subscriber<number>) => {
        (async () => {
          try {
            const abi: AbiItem[] = [
              {
                name: 'auctionCount',
                inputs: [],
                outputs: [
                  {
                    internalType: 'uint256',
                    name: '',
                    type: 'uint256',
                  },
                ],
                stateMutability: 'view',
                type: 'function',
              },
            ];
            const contract = new this.web3.eth.Contract(abi, contracts.auctionContractAddress);
            const result = await contract.methods.auctionCount().call();
            subscriber.next(result);
          } catch (error) {
            subscriber.error(error);
          }
        })();
      })),
    );
  }

  public auctionPeriod(): Observable<number> {
    return this.contractService.contracts().pipe(
      switchMap((contracts) => new Observable((subscriber: Subscriber<number>) => {
        (async () => {
          try {
            const abi: AbiItem[] = [
              {
                name: 'auctionPeriod',
                inputs: [],
                outputs: [
                  {
                    internalType: 'uint256',
                    name: '',
                    type: 'uint256',
                  },
                ],
                stateMutability: 'view',
                type: 'function',
              },
            ];
            const contract = new this.web3.eth.Contract(abi, contracts.auctionContractAddress);
            const result = await contract.methods.auctionPeriod().call();
            subscriber.next(result);
          } catch (error) {
            subscriber.error(error);
          }
        })();
      })),
    );
  }

  public auctions(auctionId: number): Observable<AuctionInterface> {
    return this.contractService.contracts().pipe(
      switchMap((contracts) => new Observable((subscriber: Subscriber<AuctionInterface>) => {
        (async () => {
          try {
            const abi: AbiItem[] = [
              {
                name: 'auctions',
                inputs: [
                  {
                    internalType: 'uint256',
                    name: 'auctionId',
                    type: 'uint256',
                  },
                ],
                outputs: [
                  {
                    internalType: 'string',
                    name: 'auctioneer',
                    type: 'address',
                  },
                  {
                    internalType: 'uint256',
                    name: 'auctionId',
                    type: 'uint256',
                  },
                  {
                    internalType: 'uint256',
                    name: 'nftID',
                    type: 'uint256',
                  },
                  {
                    internalType: 'uint256',
                    name: 'endTime',
                    type: 'uint256',
                  },
                  {
                    internalType: 'uint256',
                    name: 'currency',
                    type: 'uint256',
                  },
                  {
                    internalType: 'uint256',
                    name: 'startPrice',
                    type: 'uint256',
                  },
                  {
                    internalType: 'uint256',
                    name: 'reservedPrice',
                    type: 'uint256',
                  },
                  {
                    internalType: 'uint256',
                    name: 'currentBid',
                    type: 'uint256',
                  },
                  {
                    internalType: 'uint256',
                    name: 'tick',
                    type: 'uint256',
                  },
                  {
                    internalType: 'uint256',
                    name: 'bidCount',
                    type: 'uint256',
                  },
                  {
                    internalType: 'address',
                    name: 'highBidder',
                    type: 'address',
                  },
                ],
                stateMutability: 'view',
                type: 'function',
              },
            ];
            const contract = new this.web3.eth.Contract(abi, contracts.auctionContractAddress);
            const result = await contract.methods.auctions(auctionId).call();
            result.currentBid = this.web3.utils.fromWei(result.currentBid);
            result.reservedPrice = this.web3.utils.fromWei(result.reservedPrice);
            subscriber.next(result as AuctionInterface);
          } catch (error) {
            subscriber.error(error);
          }
        })();
      })),
    );
  }

  public getTimeLeft(auctionId: number): Observable<number> {
    return this.contractService.contracts().pipe(
      switchMap((contracts) => new Observable((subscriber: Subscriber<number>) => {
        (async () => {
          try {
            const abi: AbiItem[] = [
              {
                name: 'getTimeLeft',
                inputs: [
                  {
                    internalType: 'uint256',
                    name: 'auctionId',
                    type: 'uint256',
                  },
                ],
                outputs: [
                  {
                    internalType: 'uint256',
                    name: 'getTimeLeft',
                    type: 'uint256',
                  },
                ],
                stateMutability: 'view',
                type: 'function',
              },
            ];
            const contract = new this.web3.eth.Contract(abi, contracts.auctionContractAddress);
            const result = await contract.methods.getTimeLeft(auctionId).call();
            subscriber.next(result);
          } catch (error) {
            subscriber.error(error);
          }
        })();
      })),
    );
  }

  public landxNft(): Observable<string> {
    return this.contractService.contracts().pipe(
      switchMap((contracts) => new Observable((subscriber: Subscriber<string>) => {
        (async () => {
          try {
            const abi: AbiItem[] = [
              {
                name: 'landXNFT',
                inputs: [],
                outputs: [
                  {
                    internalType: 'address',
                    name: '',
                    type: 'address',
                  },
                ],
                stateMutability: 'view',
                type: 'function',
              },
            ];
            const contract = new this.web3.eth.Contract(abi, contracts.auctionContractAddress);
            const result = await contract.methods.landXNFT().call();
            subscriber.next(result);
          } catch (error) {
            subscriber.error(error);
          }
        })();
      })),
    );
  }

  public owner(): Observable<string> {
    return this.contractService.contracts().pipe(
      switchMap((contracts) => new Observable((subscriber: Subscriber<string>) => {
        (async () => {
          try {
            const abi: AbiItem[] = [
              {
                name: 'owner',
                inputs: [],
                outputs: [
                  {
                    internalType: 'address',
                    name: '',
                    type: 'address',
                  },
                ],
                stateMutability: 'view',
                type: 'function',
              },
            ];
            const contract = new this.web3.eth.Contract(abi, contracts.auctionContractAddress);
            const result = await contract.methods.owner().call();
            subscriber.next(result);
          } catch (error) {
            subscriber.error(error);
          }
        })();
      })),
    );
  }

  public sellListings(auctionId: number): Observable<SellListingsInterface> {
    return this.contractService.contracts().pipe(
      switchMap((contracts) => new Observable((subscriber: Subscriber<SellListingsInterface>) => {
        (async () => {
          try {
            const abi: AbiItem[] = [
              {
                name: 'sellListings',
                inputs: [
                  {
                    internalType: 'uint256',
                    name: 'auctionId',
                    type: 'uint256',
                  },
                ],
                outputs: [
                  {
                    internalType: 'uint256',
                    name: 'currency',
                    type: 'uint256',
                  },
                  {
                    internalType: 'address',
                    name: 'seller',
                    type: 'address',
                  },
                  {
                    internalType: 'uint256',
                    name: 'nftID',
                    type: 'uint256',
                  },
                  {
                    internalType: 'uint256',
                    name: 'startTime',
                    type: 'uint256',
                  },
                  {
                    internalType: 'uint256',
                    name: 'endTime',
                    type: 'uint256',
                  },
                  {
                    internalType: 'uint256',
                    name: 'price',
                    type: 'uint256',
                  },
                  {
                    internalType: 'bool',
                    name: 'sold',
                    type: 'bool',
                  },
                  {
                    internalType: 'bool',
                    name: 'removedFromSale',
                    type: 'bool',
                  },
                ],
                stateMutability: 'view',
                type: 'function',
              },
            ];
            const contract = new this.web3.eth.Contract(abi, contracts.auctionContractAddress);
            const result = await contract.methods.sellListings(auctionId).call();
            subscriber.next(result as SellListingsInterface);
          } catch (error) {
            subscriber.error(error);
          }
        })();
      })),
    );
  }

  public sellsCount(): Observable<number> {
    return this.contractService.contracts().pipe(
      switchMap((contracts) => new Observable((subscriber: Subscriber<number>) => {
        (async () => {
          try {
            const abi: AbiItem[] = [
              {
                name: 'sellsCount',
                inputs: [],
                outputs: [
                  {
                    internalType: 'uint256',
                    name: '',
                    type: 'uint256',
                  },
                ],
                stateMutability: 'view',
                type: 'function',
              },
            ];
            const contract = new this.web3.eth.Contract(abi, contracts.auctionContractAddress);
            const result = await contract.methods.sellsCount().call();
            subscriber.next(result);
          } catch (error) {
            subscriber.error(error);
          }
        })();
      })),
    );
  }

  public tickUsd(): Observable<number> {
    return this.contractService.contracts().pipe(
      switchMap((contracts) => new Observable((subscriber: Subscriber<number>) => {
        (async () => {
          try {
            const abi: AbiItem[] = [
              {
                name: 'tickUSD',
                inputs: [],
                outputs: [
                  {
                    internalType: 'uint256',
                    name: '',
                    type: 'uint256',
                  },
                ],
                stateMutability: 'view',
                type: 'function',
              },
            ];
            const contract = new this.web3.eth.Contract(abi, contracts.auctionContractAddress);
            const result = await contract.methods.tickUSD().call();
            subscriber.next(result);
          } catch (error) {
            subscriber.error(error);
          }
        })();
      })),
    );
  }

  public tickWtc(): Observable<number> {
    return this.contractService.contracts().pipe(
      switchMap((contracts) => new Observable((subscriber: Subscriber<number>) => {
        (async () => {
          try {
            const abi: AbiItem[] = [
              {
                name: 'tickWTC',
                inputs: [],
                outputs: [
                  {
                    internalType: 'uint256',
                    name: '',
                    type: 'uint256',
                  },
                ],
                stateMutability: 'view',
                type: 'function',
              },
            ];
            const contract = new this.web3.eth.Contract(abi, contracts.auctionContractAddress);
            const result = await contract.methods.tickWTC().call();
            subscriber.next(result);
          } catch (error) {
            subscriber.error(error);
          }
        })();
      })),
    );
  }

  public usdcAddress(): Observable<string> {
    return this.contractService.contracts().pipe(
      switchMap((contracts) => new Observable((subscriber: Subscriber<string>) => {
        (async () => {
          try {
            const abi: AbiItem[] = [
              {
                name: 'usdc',
                inputs: [],
                outputs: [
                  {
                    internalType: 'uint256',
                    name: '',
                    type: 'uint256',
                  },
                ],
                stateMutability: 'view',
                type: 'function',
              },
            ];
            const contract = new this.web3.eth.Contract(abi, contracts.auctionContractAddress);
            const result = await contract.methods.usdc().call();
            subscriber.next(result);
          } catch (error) {
            subscriber.error(error);
          }
        })();
      })),
    );
  }

  public wtcAddress(): Observable<string> {
    return this.contractService.contracts().pipe(
      switchMap((contracts) => new Observable((subscriber: Subscriber<string>) => {
        (async () => {
          try {
            const abi: AbiItem[] = [
              {
                name: 'wtc',
                inputs: [],
                outputs: [
                  {
                    internalType: 'uint256',
                    name: '',
                    type: 'uint256',
                  },
                ],
                stateMutability: 'view',
                type: 'function',
              },
            ];
            const contract = new this.web3.eth.Contract(abi, contracts.auctionContractAddress);
            const result = await contract.methods.wtc().call();
            subscriber.next(result);
          } catch (error) {
            subscriber.error(error);
          }
        })();
      })),
    );
  }

  public allowance(from: string, tokenAddress: string): Observable<string> {
    return this.contractService.contracts().pipe(
      switchMap((contracts) => new Observable((subscriber: Subscriber<string>) => {
        (async () => {
          try {
            const abi: AbiItem[] = [
              {
                name: 'allowance',
                inputs: [
                  {
                    name: '_owner',
                    type: 'address',
                  },
                  {
                    name: '_spender',
                    type: 'address',
                  },
                ],
                outputs: [
                  {
                    name: '',
                    type: 'uint256',
                  },
                ],
                stateMutability: 'view',
                type: 'function',
              },
            ];
            const contract = new this.web3.eth.Contract(abi, tokenAddress);
            contract.methods.allowance(from, contracts.auctionContractAddress).call().then((allowance: string) => {
              subscriber.next(this.web3.utils.fromWei(allowance));
            });
          } catch (error) {
            subscriber.error(error);
          }
        })();
      })),
    );
  }

  public loadOptionPremium(tokenAddress: string, tokenId: number) {
    return this.store.select(selectAuctionContractAddress).pipe(
      switchMap((contractAddress) => new Observable((subscriber: Subscriber<string>) => {
        (async () => {
          try {
            const abi: AbiItem[] = [
              {
                name: 'getOptionAmountInWTC',
                inputs: [
                  {
                    internalType: 'uint256',
                    name: 'tokenID',
                    type: 'uint256',
                  },
                ],
                outputs: [
                  {
                    name: '',
                    type: 'uint256',
                  },
                ],
                stateMutability: 'view',
                type: 'function',
              },
            ];
            const contract = new this.web3.eth.Contract(abi, contractAddress);
            contract.methods.getOptionAmountInWTC(tokenId).call().then((optionAmount: string) => {
              subscriber.next(this.web3.utils.fromWei(optionAmount));
            });
          } catch (error) {
            subscriber.error(error);
          }
        })();
      })),
    );
  }

  public loadMinSellAmount(tokenId: number): Observable<string> {
    return this.store.select(selectAuctionContractAddress).pipe(
      switchMap((contractAddress) => new Observable((subscriber: Subscriber<string>) => {
        (async () => {
          try {
            const abi: AbiItem[] = [
              {
                name: 'getMinimumTokenPrice',
                inputs: [
                  {
                    internalType: 'uint256',
                    name: 'tokenID',
                    type: 'uint256',
                  },
                ],
                outputs: [
                  {
                    name: '',
                    type: 'uint256',
                  },
                ],
                stateMutability: 'view',
                type: 'function',
              },
            ];
            const contract = new this.web3.eth.Contract(abi, contractAddress);
            contract.methods.getMinimumTokenPrice(tokenId).call().then((optionAmount: string) => {
              subscriber.next(this.web3.utils.fromWei(optionAmount));
            });
          } catch (error) {
            subscriber.error(error);
          }
        })();
      })),
    );
  }
}
