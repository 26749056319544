import { Pipe, PipeTransform } from '@angular/core';
import { format } from 'timeago.js';

@Pipe({
  name: 'dateFromNow',
})
export class DateFromNowPipe implements PipeTransform {
  transform(date: string): string {
    return format(date);
  }
}
