import { Component } from '@angular/core';
import { Observable } from 'rxjs';
import { HeaderSideMenuDataInterface } from '../../interfaces/header-side-menu-data.interface';
import { HeaderSideMenuService } from '../../services/header-side-menu.service';
import { HeaderFacadeService } from '../../../../services/header.facade.service';

@Component({
  selector: 'app-side-user-menu-content',
  templateUrl: './side-user-menu-content.component.html',
  styleUrls: ['./side-user-menu-content.component.scss'],
})
export class SideUserMenuContentComponent {
  public menuData: HeaderSideMenuDataInterface;
  public selectedMenuData: HeaderSideMenuDataInterface;
  public notificationsCount$: Observable<number>;

  constructor(
    private headerSideMenuService: HeaderSideMenuService,
    private headerFacadeService: HeaderFacadeService,
  ) {
    this.notificationsCount$ = this.headerFacadeService.getNewNotificationsCount$();
    this.menuData = this.getMenuData();
    this.selectedMenuData = { ...this.menuData };
  }

  public close() {
    this.headerSideMenuService.close();
  }

  private getMenuData() {
    return {
      items: [
        {
          title: 'common.header.marketplace',
          url: '/marketplace',
        },
        {
          title: 'common.header.apply',
          url: '/lands/claim',
        },
        {
          title: 'common.header.my_lands',
          url: '/user-profile',
        },
        // {
        //   title: 'common.header.my_favorites',
        //   url: '/user-profile/favorites',
        // },
        // {
        //   title: 'common.header.messages',
        //   url: '/messages',
        // },
        {
          title: 'common.header.general_setting',
          url: '/user-settings/settings/general',
          // subMenu: {
          //   parentTitle: 'common.header.my_account_settings',
          //   items: [
          //     {
          //       title: 'common.header.general_setting',
          //       url: '/user-settings/settings/general',
          //     },
          //     {
          //       title: 'common.header.my_wallet',
          //       url: '/user-settings/settings/my-wallet',
          //     },
          //     {
          //       title: 'common.header.notification_settings',
          //       url: '/user-settings/settings/notifications',
          //     },
          //   ],
          // },
        },
        // {
        //   title: 'common.header.notifications',
        //   url: '/notifications',
        //   counter: this.notificationsCount$,
        // },
        {
          title: 'common.header.log_out',
          url: 'logout',
        },
      ],
    };
  }
}
