import { Component } from '@angular/core';
import { Observable } from 'rxjs';
import { delay, tap } from 'rxjs/operators';
import { HeaderSideMenuService, TRANSITION_TIME } from '../../services/header-side-menu.service';
import { HeaderSideMenuTypes } from '../../constants/header-side-menu-types';

@Component({
  selector: 'app-header-side-menu',
  templateUrl: './header-side-menu.component.html',
  styleUrls: ['./header-side-menu.component.scss'],
})
export class HeaderSideMenuComponent {
  public opened$: Observable<boolean>;
  public headerSideMenuTypes = HeaderSideMenuTypes;
  public type$: Observable<string>;
  public showUnderlay = false;

  constructor(
    private headerSideMenuService: HeaderSideMenuService,
  ) {
    this.opened$ = this.headerSideMenuService.getOpened$().pipe(
      tap((opened) => {
        if (opened) {
          this.showUnderlay = true;
        } else {
          // Wait for closing animation before render underlay
          setTimeout(() => {
            this.showUnderlay = false;
          }, TRANSITION_TIME);
        }
      }),
      // Wait for render underlay before animation
      delay(0),
    );
    this.type$ = this.headerSideMenuService.getOpenedType$();
  }

  public close() {
    this.headerSideMenuService.close();
  }
}
