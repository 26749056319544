import { Injectable } from '@angular/core';
import { NotifierService } from 'angular-notifier';

@Injectable()
export class NotificationsCustomService {
  constructor(
    protected notificationService: NotifierService,
  ) {
  }

  public success(content?: any): void {
    return this.notificationService.notify('success', content);
  }
  public error(content?: any): void {
    return this.notificationService.notify('error', content);
  }
}
