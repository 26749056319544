export enum FilterFieldsTypes {
  Select = 'select',
  MinMax = 'min-max',
  Submit = 'submit',
  DateFrom = 'date-from',
  DateTo = 'date-to',
}

export enum FilterGroupsTypes {
  Form = 'form',
  Buttons = 'buttons',
  Search = 'search',
  SelectedList = 'selected-list',
  CheckboxList = 'checkbox-list',
}
