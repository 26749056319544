import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-simple-box-layout',
  templateUrl: 'simple-box-layout.component.html',
  styleUrls: ['simple-box-layout.component.scss'],
})
export class SimpleBoxLayoutComponent {
  @Input() title: string = '';
}
