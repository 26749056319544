import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ActivatedRoute, Params } from '@angular/router';
import { FilterSortingInterface, FilterSortingItemInterface } from '../../interfaces/filter-sorting.interface';
import {
  UnsubscribeDestroyHelperComponent,
} from '../../../../../core/common/helpers/unsubscribe-destroy-helper.component';

@Component({
  selector: 'app-sort-bar',
  templateUrl: './sort-bar.component.html',
  styleUrls: ['./sort-bar.component.scss'],
})
export class SortBarComponent extends UnsubscribeDestroyHelperComponent implements OnInit {
  @Input() sorting?: FilterSortingInterface[];
  @Input() form?: FormGroup;

  constructor(private activatedRoute: ActivatedRoute) {
    super();
  }

  ngOnInit(): void {
    this.addControls();
    this.setInitialSortingValue();
  }

  private setInitialSortingValue(): void {
    if (this.sorting) {
      const params: Params = this.activatedRoute.snapshot.queryParams;

      this.sorting.forEach((sort: FilterSortingInterface) => {
        if (params[sort.name]) {
          const selectedItem = sort.items
            .find((item: FilterSortingItemInterface) => item.value === params[sort.name]);

          if (selectedItem) {
            this.form?.get(sort.name)?.setValue(selectedItem.value);
          }
        } else {
          this.form?.get(sort.name)?.reset();
        }
      });
    }
  }

  private addControls() {
    this.sorting?.forEach((fields: FilterSortingInterface) => {
      if (fields.control && fields.name) {
        this.form?.registerControl(fields.name, fields.control);
      }
    });
  }
}
