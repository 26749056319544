import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ExchangeComponent } from './container-components/exchange/exchange.component';
import { ExchangeRoutingModule } from './exchange-routing.module';
import { ExchangeFormComponent } from './components/exchange-form/exchange-form.component';
import { SharedModule } from '../shared/shared.module';
import { ExchangeFacadeService } from './services/exchange.facade.service';
import { TokensListService } from './services/tokens-list.service';
import { CompoundTokensListApiService } from './services/compound-tokens-list.api.service';
import { ModalModule } from '../shared/modules/modals/modals.module';
import { ExchangePendingComponent } from './container-components/exchange-pending/exchange-pending.component';
import { ExchangeSuccessComponent } from './container-components/exchange-success/exchange-success.component';

@NgModule({
  declarations: [
    ExchangeComponent,
    ExchangeFormComponent,
    ExchangePendingComponent,
    ExchangeSuccessComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    ExchangeRoutingModule,
    ModalModule,
  ],
  providers: [
    ExchangeFacadeService,
    TokensListService,
    CompoundTokensListApiService,
  ],
})
export class ExchangeModule { }
