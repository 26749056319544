import { ChangeDetectorRef, Component } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { filter, map, startWith } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { HeaderFacadeService } from '../../../../header/services/header.facade.service';
import { NetworkInterface } from '../../intefaces/network.interface';

@Component({
  selector: 'app-main-layout',
  templateUrl: './main-layout.component.html',
  styleUrls: ['./main-layout.component.scss'],
})
export class MainLayoutComponent {
  public requiredNetwork?: NetworkInterface;
  public currentWalletNetworkId: number | null = null;
  public hideHeader$: Observable<boolean>;
  public showAdminHeader$: Observable<boolean>;
  public hideHeaderSearchBar$: Observable<boolean>;
  public loggedAdminOrVerifier$: Observable<boolean>;
  public loggedIn$: Observable<boolean>;

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private headerService: HeaderFacadeService,
    private changeDetectorRef: ChangeDetectorRef,
  ) {
    this.loggedIn$ = headerService.loggedIn$;

    this.loggedAdminOrVerifier$ = headerService.loggedAdminOrVerifier$;

    this.hideHeader$ = this.router.events.pipe(
      filter((event) => event instanceof NavigationEnd),
      map(() => !!this.activatedRoute.firstChild?.snapshot.data.hideHeader),
    );

    this.showAdminHeader$ = this.router.events.pipe(
      filter((event) => event instanceof NavigationEnd),
      map(() => !!this.activatedRoute.firstChild?.snapshot.data.showAdminHeader),
      startWith(false),
    );

    this.hideHeaderSearchBar$ = this.router.events.pipe(
      filter((event) => event instanceof NavigationEnd),
      map(() => !!this.activatedRoute.firstChild?.snapshot.data.hideHeaderSearchBar),
      startWith(true),
    );
  }

  public setRequiredNetwork(requiredNetwork: NetworkInterface) {
    this.requiredNetwork = requiredNetwork;
    this.changeDetectorRef.detectChanges();
  }

  setCurrentWalletNetworkId(id: number) {
    this.currentWalletNetworkId = id;
    this.changeDetectorRef.detectChanges();
  }
}
