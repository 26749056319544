import { AuthEffects } from './auth.effects';
import { SettingsEffects } from './settings.effects';
import { UserEffects } from './user.effects';
import { UnreadMessageEffects } from './unread-message.effects';
import { NetworkEffects } from './network.effects';
import { ProcessingTransactionEffects } from './processing-transaction.effects';

export const CoreEffects = [
  AuthEffects,
  SettingsEffects,
  UserEffects,
  UnreadMessageEffects,
  NetworkEffects,
  ProcessingTransactionEffects,
];
