<app-success-modal-wrapper
  *ngIf="$preview | async as preview"
  [subTitle]="'pages.modals.you_successfully_create_nft_shards' | translate"
  (closeModal)="close()"
  class="modal"
>
  <ul class="modal__total-info modal__total-info--big-margin total-info">
    <li class="total-info__row">
      <span class="total-info__label" appEllipsis>{{ 'pages.modals.blockchain' | translate }}:</span>
      <span class="total-info__value">{{ 'pages.modals.ethereum' | translate }}</span>
    </li>

    <li class="total-info__row">
      <span class="total-info__label" appEllipsis>{{ 'pages.modals.ticker' | translate }}:</span>
      <span class="total-info__value">{{ preview.cropShareType | currencyFromCulture | uppercase }}</span>
    </li>

    <li class="total-info__row">
      <span class="total-info__label" appEllipsis>{{ 'pages.modals.name' | translate }}:</span>
      <span class="total-info__value">{{ 'pages.user_profile.nft_shards.' + getCultureByType(preview.cropShareType | currencyFromCulture | uppercase) | translate | titlecase }} {{ 'pages.user_profile.nft_shards.shards' | translate }}</span>
    </li>

    <li class="total-info__row total-info__row--secondary">
      <span class="total-info__label">{{ 'pages.modals.contract_address' | translate }}</span>
      <span class="total-info__value">0x195...84f</span>
    </li>

    <li class="total-info__row total-info__row--main">
      <span class="total-info__label">{{ 'pages.modals.shards_minted' | translate }}:</span>
      <span class="total-info__value">{{ preview.total }}</span>
    </li>
  </ul>
</app-success-modal-wrapper>
