import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { TOKENS_LIST_API } from '../api/tokens-list.api';
import { TokenInterface } from '../interfaces/token.interface';

@Injectable()
export class CompoundTokensListApiService {
  public constructor(
    private httpClient: HttpClient,
  ) {}

  public getTokens(chainId: number): Observable<TokenInterface[]> {
    return this.httpClient.get<{ tokens: any[] }>(
      TOKENS_LIST_API.compoundList,
    ).pipe(
      map((response) => response.tokens.filter((token) => token.chainId === chainId)),
      map((response) => response.map((token) => ({
        chainId: token.chainId,
        contractAddress: token.address,
        decimals: token.decimals,
        symbol: token.symbol,
        name: token.name,
        logoURI: token.logoURI,
      } as TokenInterface))),
    );
  }
}
