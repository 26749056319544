import { Component, Input } from '@angular/core';
import { NotificationsServiceWithTranslate } from '../../../core/common/services/notificationsServiceWithTranslate';

@Component({
  selector: 'app-field-copyable',
  templateUrl: './field-copyable.component.html',
  styleUrls: ['./field-copyable.component.scss'],
})
export class FieldCopyableComponent {
  @Input() public value?: string;
  @Input() public label?: string;
  @Input() public dark: boolean = false;

  constructor(
    private notificationService: NotificationsServiceWithTranslate,
  ) {}

  public async copy(): Promise<void> {
    if (this.value != null) {
      await navigator.clipboard.writeText(this.value);
      this.notificationService.success({
        content: 'common.notifications.copied',
      });
    }
  }
}
