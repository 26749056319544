import { Injectable } from '@angular/core';
import Web3 from 'web3';

@Injectable()
export class Web3Service extends Web3 {
  constructor() {
    super();
    this.setProvider(Web3.givenProvider);
  }
}
