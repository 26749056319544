<div class="modal">
  <div class="pending">
    <div class="pending__spinner">
      <app-custom-spinner></app-custom-spinner>
    </div>
  </div>

  <div class="modal__title">{{ 'popups.exchange.pending' | translate }}</div>

  <div class="modal__subtitle" *ngIf="tradeContext">
    {{ 'popups.exchange.you_are_selling' | translate: {
    fromAmount: tradeContext?.baseConvertRequest,
    fromCurrency: tradeContext?.fromToken.symbol,
    toAmount: tradeContext?.expectedConvertQuote,
    toCurrency: tradeContext?.toToken.symbol
  } }}
  </div>

  <div class="modal__bottom-note">{{ 'popups.exchange.confirm_the_operation_in_your_wallet' | translate}}</div>

  <div class="modal__buttons">
    <app-button primary class="modal__button" [disabled]="true">
      {{ 'common.continue' | translate }}
    </app-button>

    <app-button secondary class="modal__button" tabindex="0" (btnClick)="close()">
      {{ 'common.cancel' | translate }}
    </app-button>
  </div>
</div>
