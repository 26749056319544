import { Injectable } from '@angular/core';
import { Observable, Subscriber } from 'rxjs';
import { AbiItem } from 'ethereum-abi-types-generator';
import { switchMap } from 'rxjs/operators';
import { Web3Service } from '../../core/common/services/web3.service';
import { ContractService } from '../../core/common/services/contract.service';
import { LDXS } from '../../shared/tokens/ldxs';
import { LDXM } from '../../shared/tokens/ldxm';
import { LDXR } from '../../shared/tokens/ldxr';
import { LDXW } from '../../shared/tokens/ldxw';
import { ContractsInterface } from '../../core/common/intefaces/contracts.interface';

@Injectable()
export class ShardService {
  constructor(private web3: Web3Service, private contractService: ContractService) {}

  public totalSupply(shardType: string): Observable<number> {
    return this.contractService.contracts().pipe(
      switchMap((contracts) => new Observable((subscriber: Subscriber<number>) => {
        (async () => {
          try {
            const abi: AbiItem[] = [
              {
                name: 'totalSupply',
                inputs: [],
                outputs: [
                  {
                    internalType: 'uint256',
                    name: '',
                    type: 'uint256',
                  },
                ],
                stateMutability: 'payable',
                type: 'function',
              },
            ];

            const contract = new this.web3.eth.Contract(abi, this.getShardAddress(contracts, shardType));
            contract.methods.totalSupply().call().then((data: any) => {
              subscriber.next(parseFloat(this.web3.utils.fromWei(data)));
            }).catch((error: any) => {
              subscriber.error(error);
            });
          } catch (error) {
            subscriber.error(error);
          }
        })();
      })),
    );
  }

  public getShardAddress(contracts: ContractsInterface, shardType: string): string {
    switch (shardType) {
      case LDXS.MAINNET().symbol:
        return contracts.ldxsShardManagerContractAddress;
      case LDXW.MAINNET().symbol:
        return contracts.ldxwShardManagerContractAddress;
      case LDXR.MAINNET().symbol:
        return contracts.ldxrShardManagerContractAddress;
      case LDXM.MAINNET().symbol:
        return contracts.ldxmShardManagerContractAddress;
      default:
        throw new Error(`unknown shard type ${shardType}`);
    }
  }
}
