export enum ErrorCodes {
  // Internal error codes
  FileSize = 'file_size',
  InvalidFileType = 'invalid_file_type',
  NumberRequired = 'number_required',
  LowercaseLetterRequired = 'lowercase_letter_required',
  UppercaseLetterRequired = 'uppercase_letter_required',
  SpecialCharacterRequired = 'special_character_required',
  PasswordsDoNotMatch = 'passwords_do_not_match',
  AcceptAgreementRequired = 'accept_agreement_required',
  WalletRequired = 'wallet_required',
  OnlyNumber = 'only_number',
  NotNumber = 'not_number',
  DecimalNotValid = 'decimal_not_valid',
  ConfirmPasswordInvalid = 'confirm_password_invalid',
  ConfirmEmailInvalid = 'confirm_email_invalid',
  ConfirmFieldInvalid = 'confirm_field_invalid',
  TotalRatingError = 'totalRatingError',
  TableSelectExists = 'table_select_exists',
  MinLessThanMax = 'min_less_than_max',
  DateInvalid = 'date_invalid',
  TimeInFuture = 'time_in_future',
  AvailableAmount = 'available_amount',
  HighestAmount = 'highest_amount',
  LowestAmount = 'lowest_amount',

  // Standard error codes,
  Required = 'required',
  RequiredTrue = 'required_true',
  RequiredIf = 'required_if',
  Pattern = 'pattern',
  Email = 'email',
  Min = 'min',
  Max = 'max',
  Minlength = 'minlength',
  Maxlength = 'maxlength',
  BsDate = 'bs_date',
  Invalid = 'invalid',
  CcNumber = 'cc_number',

  // Api error codes
  AccessTokenInvalid = 'TOKEN_INVALID',
  TokenNotFound = 'TOKEN_NOT_FOUND',
  MaxValue = 'max_value',
  MinValue = 'min_value',
  UsersInvalidUsernameOrPassword = 'InvalidCredentials',

  // Unhandled error codes
  UnhandledError = 'unhandled_error',
}
