import { Injectable } from '@angular/core';
import MetaMaskOnboarding from '@metamask/onboarding';
import { fromEvent, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
// @ts-ignore
import detectEthereumProvider from '@metamask/detect-provider';
import { IHttpResponse } from '../../common/intefaces/http-response.interface';
import { NetworkInterface } from '../../../exchange/interfaces/network.interface';
import { EthAccountInterface } from '../../common/intefaces/eth-account.interface';
import { Web3Service } from '../../common/services/web3.service';

export const forwarderOrigin = 'https://metamask.app.link/dapp/dev.dev-landx.be/marketplace?currency=USDC';

@Injectable({
  providedIn: 'root',
})
export class MetamaskService {
  private onboarding: MetaMaskOnboarding;
  private readonly ethereum: any;

  constructor(
    private web3: Web3Service,
  ) {
    // On this object we have startOnboarding which will start the onboarding process for our end user
    this.onboarding = new MetaMaskOnboarding({ forwarderOrigin });
    // @ts-ignore
    this.ethereum = window.ethereum || null; // ethereum property provided by metamask extension
  }

  public extensionInstalled(): Observable<boolean> {
    return new Observable((subscriber) => {
      (async () => {
        try {
          const provider = await detectEthereumProvider({ timeout: 3000 });
          subscriber.next(Boolean(provider));
          subscriber.complete();
        } catch (error) {
          subscriber.error(error);
        }
      })();
    });
  }

  public installExtension(): Observable<string[] | null> {
    return new Observable((subscriber) => {
      (async () => {
        try {
          await this.onboarding.startOnboarding();
          subscriber.next();
          subscriber.complete();
        } catch (error) {
          subscriber.error(error);
        }
      })();
    });
  }

  public connectWallet(): Observable<IHttpResponse<number>> {
    return new Observable((subscriber) => {
      (async () => {
        try {
          const connection = await this.ethereum.request({ method: 'eth_requestAccounts' });
          subscriber.next({ data: connection[0] } as IHttpResponse<number>);
          subscriber.complete();
        } catch (error) {
          subscriber.error(error);
        }
      })();
    });
  }

  public getAccounts(): Observable<EthAccountInterface[]> {
    return new Observable((subscriber) => {
      (async () => {
        try {
          const accounts = await this.ethereum.request({ method: 'eth_accounts' });
          subscriber.next(accounts.map((account: string) => ({ address: account } as EthAccountInterface)));
        } catch (error) {
          subscriber.error(error);
        }
      })();
    });
  }

  public getAccountChanged$(): Observable<string[]> {
    return fromEvent(this.ethereum, 'accountsChanged');
  }

  public getNetworkChanged$(): Observable<number> {
    return fromEvent(this.ethereum, 'chainChanged')
      .pipe(map((chainId) => parseInt(`${chainId}`, 16)));
  }

  public getNetwork(): Observable<NetworkInterface> {
    return new Observable((subscriber) => {
      (async () => {
        try {
          const currentProvider = await this.web3.eth.net.currentProvider;
          const id = await this.web3.eth.net.getId();
          subscriber.next({ id, provider: currentProvider } as NetworkInterface);
        } catch (error) {
          subscriber.error(error);
        }
      })();
    });
  }

  public addToken$(address: string, symbol: string, decimals: number = 18): Observable<boolean> {
    return new Observable((subscriber) => {
      (async () => {
        try {
          const wasAdded: boolean = await this.ethereum.request({
            method: 'wallet_watchAsset',
            params: {
              type: 'ERC20',
              options: {
                address,
                symbol,
                decimals,
              },
            },
          });
          subscriber.next(wasAdded);
        } catch (error) {
          subscriber.error(error);
        }
      })();
    });
  }

  public switchNetwork$(id: number): Observable<null> {
    return new Observable((subscriber) => {
      (async () => {
        try {
          const switched: null = await this.ethereum.request({
            method: 'wallet_switchEthereumChain',
            params: [{ chainId: `0x${Number(id).toString(16)}` }],
          });
          subscriber.next(switched);
        } catch (error) {
          subscriber.error(error);
        }
      })();
    });
  }

  public enable(): void {
    this.ethereum.enable();
  }
}
