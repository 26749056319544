import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { WriteRentFoundationService } from './write-rent-foundation.service';
import { MetamaskService } from '../../core/auth/services/metamask.service';
import { EthAccountInterface } from '../../core/common/intefaces/eth-account.interface';
import { WriteWtcService } from '../../wtc/services/write-wtc.service';
import { ReadWtcService } from '../../wtc/services/read-wtc.service';
import { ContractService } from '../../core/common/services/contract.service';
import { NetworkInterface } from '../../exchange/interfaces/network.interface';
import { TokensListService } from '../../exchange/services/tokens-list.service';
import { UniswapService } from '../../core/auth/services/uniswap.service';

@Injectable()
export class PayRentFacadeService {
  constructor(
    private metamaskService: MetamaskService,
    private writeRentFoundationService: WriteRentFoundationService,
    private writeWtcService: WriteWtcService,
    private readWtcService: ReadWtcService,
    private contractService: ContractService,
    private tokensListService: TokensListService,
    private uniswapService: UniswapService,
  ) {
  }

  public account(): Observable<EthAccountInterface|undefined> {
    return this.metamaskService.getAccounts().pipe(
      map((accounts) => (accounts ? accounts[0] : undefined)),
    );
  }

  public wtcRentFoundationAllowance(owner: string): Observable<number> {
    return this.contractService.contracts().pipe(
      switchMap((contracts) => this.readWtcService.allowance(owner, contracts.rentFoundationAddress).pipe(
        map((allowance) => parseFloat(allowance)),
      )),
    );
  }

  public getNetwork(): Observable<NetworkInterface> {
    return this.metamaskService.getNetwork();
  }

  public wtcApproveRentFoundation(owner: string, amount: number): Observable<string> {
    return this.contractService.contracts().pipe(
      switchMap((contracts) => this.writeWtcService.approve(owner, contracts.rentFoundationAddress, amount)),
    );
  }

  public getBalance(chainId: number, ethAddress: string, contractAddress: string): Observable<string> {
    return this.uniswapService.getBalance(chainId, ethAddress, contractAddress);
  }

  public getContractAddress(chainId: number, currencyCode: string): Observable<string|undefined> {
    return this.tokensListService.getTokens(chainId).pipe(
      map((tokens) => tokens.filter((token) => token.symbol === currencyCode)),
      map((tokens) => (tokens.length ? tokens[0].contractAddress : undefined)),
    );
  }

  public payRent(from: string, tokenId: number, amount: number): Observable<string> {
    return this.writeRentFoundationService.payRent(from, tokenId, amount);
  }
}
