import { Component } from '@angular/core';
import {
  combineLatest,
  Observable, of, Subject, throwError,
} from 'rxjs';
import { catchError, switchMap, takeUntil } from 'rxjs/operators';
import { LandInterface } from '../../../lends/interfaces/land.interface';
import { ModalService } from '../../services/modal.service';
import {
  UnsubscribeDestroyHelperComponent,
} from '../../../../../core/common/helpers/unsubscribe-destroy-helper.component';
import { RemoveFromSaleFacadeService } from '../../../../../auction/services/remove-from-sale.facade.service';
import { PRICE_FORMAT } from '../../../../../core/common/constants/common-settings-values';
import { CheckoutSuccessModalComponent } from '../checkout-success-modal/checkout-success-modal.component';
import { CheckoutModalDataInterface } from '../../interfaces/checkout-modal-data.interface';
import { PendingModalComponent } from '../pending-modal/pending-modal.component';
import { CheckoutFailedModalComponent } from '../checkout-failed-modal/checkout-failed-modal.component';
import { AreaUnits } from '../../../../../core/settings/constants/units-names';
import { BuyNowFacadeService } from '../../../../../auction/services/buy-now.facade.service';
import { AuctionInterface } from '../../../../../auction/interfaces/auction.interface';

@Component({
  selector: 'app-finish-auction-modal',
  templateUrl: './finish-auction-modal.component.html',
  styleUrls: ['../../../../../styles/blocks/modals.scss'],
})
export class FinishAuctionModalComponent extends UnsubscribeDestroyHelperComponent {
  public static readonly modalIdentifier: string = 'finishAuctionModalComponent';
  public land$: Observable<LandInterface | null>;
  public auction$: Observable<AuctionInterface | null>;
  public amountFormat = PRICE_FORMAT;
  public units$: Observable<string>;
  public areaUnits = AreaUnits;

  constructor(
    private modalService: ModalService,
    private removeFromSellFacadeService: RemoveFromSaleFacadeService,
    private buyNowFacadeService: BuyNowFacadeService,
  ) {
    super();
    this.land$ = this.modalService.getModalData(FinishAuctionModalComponent.modalIdentifier).land$;
    this.auction$ = this.modalService.getModalData(FinishAuctionModalComponent.modalIdentifier).auction$;
    this.units$ = this.buyNowFacadeService.getUnits$;
  }

  public submit() {
    const unsubscribeSubject = new Subject();
    this.modalService.open(PendingModalComponent.modalIdentifier, PendingModalComponent, {
      unsubscribeSubject,
    });
    this.modalService.close(FinishAuctionModalComponent.modalIdentifier);
    this.land$.pipe(
      takeUntil(unsubscribeSubject),
      switchMap((land) => combineLatest(
        of(land),
        this.removeFromSellFacadeService.account(),
      )),
      switchMap(([land, ethAccount]) => combineLatest(
        of(land),
        this.removeFromSellFacadeService.claim(
          ethAccount!.address, land!.productDetails.auctionId, land!.token.auctionContractAddress,
        ),
      )),
      catchError((error) => {
        this.modalService.close(PendingModalComponent.modalIdentifier);
        this.modalService.open(CheckoutFailedModalComponent.modalIdentifier, CheckoutFailedModalComponent, {});
        return throwError(error);
      }),
    ).subscribe(([land]) => {
      this.modalService.close(PendingModalComponent.modalIdentifier);
      const data: CheckoutModalDataInterface = {
        successSubTitle: land?.productDetails?.price ? 'you_successfully_finished_auction_to_new_owner'
          : 'you_successfully_finished_auction_to_your_account',
      };
      this.modalService.open(
        CheckoutSuccessModalComponent.modalIdentifier,
        CheckoutSuccessModalComponent,
        { land$: this.land$, data },
      );
    });
  }

  public close(): void {
    this.modalService.close(FinishAuctionModalComponent.modalIdentifier);
  }
}
