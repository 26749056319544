import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'userName',
})

export class UserNamePipe implements PipeTransform {
  transform(user: { name: string, metamaskEthAddress: string } | undefined | null, stubName: string = ''): string {
    if (!user?.name && !user?.metamaskEthAddress) {
      return stubName;
    }
    return user?.name || (user?.metamaskEthAddress && user.metamaskEthAddress.slice(2, 8)) || '';
  }
}
