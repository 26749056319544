import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';
import { DateFromNowPipe } from './date-from-now.pipe';
import { DATE_FORMAT_12_HOURS } from '../../core/common/constants/common-settings-values';

@Pipe({
  name: 'expirationDate',
})
export class ExpirationDatePipe implements PipeTransform {
  private oneDayMilliseconds = 24 * 60 * 60 * 1000;

  constructor(
    private datePipe: DatePipe,
    private dateFromNowPipe: DateFromNowPipe,
  ) {}

  transform(date: string): string | null {
    const dateDiff = +new Date(date) - +Date.now();
    if (dateDiff > 0 && dateDiff < this.oneDayMilliseconds) {
      return this.dateFromNowPipe.transform(date);
    }

    return this.datePipe.transform(date, DATE_FORMAT_12_HOURS);
  }
}
