import { ChangeDetectorRef, Component, Input } from '@angular/core';
import { TooltipPositionX, TooltipPositionY } from 'src/app/core/common/constants/tooltip-positions';

@Component({
  selector: 'app-tooltip',
  templateUrl: 'tooltip.component.html',
  styleUrls: ['tooltip.component.scss'],
})
export class TooltipComponent {
  @Input() positionY: TooltipPositionY = TooltipPositionY.Top;
  @Input() positionX: TooltipPositionX = TooltipPositionX.Left;

  public TooltipPositionY = TooltipPositionY;
  public TooltipPositionX = TooltipPositionX;
  public isShow: boolean = false;
  public isDisplay: boolean = false;

  constructor(private cdr: ChangeDetectorRef) {}

  public show(event: boolean) {
    if (!event) {
      this.isDisplay = !event;
      setTimeout(() => {
        this.isShow = !event;
        this.cdr.detectChanges();
      }, 0);
      return;
    }
    setTimeout(() => {
      this.isDisplay = !event;
      this.cdr.detectChanges();
    }, 200);
    this.isShow = !event;
  }

  public hide(event: boolean) {
    this.isShow = event;
    setTimeout(() => {
      this.isDisplay = event;
    }, 200);
  }
}
