import {
  AfterViewInit, Directive, ElementRef, OnDestroy, Renderer2,
} from '@angular/core';
import { ControlContainer, FormGroupDirective } from '@angular/forms';
import { merge, Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ApiErrorService } from '../../core/common/services/api-error.service';

@Directive({
  selector: '[appScrollTopError]',
})
export class ScrollFormErrorDirective implements AfterViewInit, OnDestroy {
  public unsubscribeSubject = new Subject();

  constructor(
    private elementRef: ElementRef,
    private renderer: Renderer2,
    private apiErrorService: ApiErrorService,
    private controlContainer: ControlContainer,
  ) {
  }

  public ngAfterViewInit() {
    merge(
      this.getFormSubmitPipe(),
      this.apiErrorService.errorsPipe,
    ).pipe(
      takeUntil(this.unsubscribeSubject),
    ).subscribe(() => {
      this.seekErrorField();
    });
  }

  public ngOnDestroy() {
    this.unsubscribeSubject.next();
    this.unsubscribeSubject.complete();
  }

  private getFormSubmitPipe(): Observable<any> {
    return (this.controlContainer.formDirective as FormGroupDirective).ngSubmit;
  }

  private static cursorFocus(fields: any): void {
    if (!fields || fields.length < 1) {
      return;
    }

    const topErrorField = fields[0];

    topErrorField.focus();
    topErrorField.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'center' });
  }

  private seekErrorField(): void {
    setTimeout(() => {
      const fields = this.elementRef.nativeElement.getElementsByClassName('error-field');
      ScrollFormErrorDirective.cursorFocus(fields);
    }, 100);
  }
}
