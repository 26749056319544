import {
  Attribute,
  Component, ElementRef, EventEmitter, forwardRef, Input, Output, ViewChild,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'app-field-input-equivalent',
  templateUrl: './field-input-equivalent.component.html',
  styleUrls: ['./field-input-equivalent.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FieldInputEquivalentComponent),
      multi: true,
    },
  ],
})

export class FieldInputEquivalentComponent implements ControlValueAccessor {
  @ViewChild('input') input!: ElementRef;

  @Input() label?: string = '';
  @Input() note = '';
  @Input() error = '';
  @Input() placeholder = '';
  @Input() formControlName: string | undefined;
  @Input() equivalent?: string = '0.00';
  @Input() equivalentCurrency?: string = 'USDC';
  @Input() disabled = false;
  @Input() white: boolean = false;

  // eslint-disable-next-line @angular-eslint/no-output-native
  @Output() blur: EventEmitter<void> = new EventEmitter();

  public value: string | number | null = null;
  public touched = false;
  public type: string | null;

  private onChange = (value: string | number | null) => {}; // eslint-disable-line @typescript-eslint/no-unused-vars
  private onTouched = () => {};

  constructor(
    @Attribute('white') public attrWhite: string | null,
    @Attribute('type') public attrType: string | null,
  ) {
    this.white = typeof attrWhite === 'string';
    this.type = attrType;
  }

  public focus() {
    setTimeout(() => { // this will make the execution after the above boolean has changed
      this.input.nativeElement.focus();
    }, 0);
  }

  public writeValue(value: string | number | null) {
    this.value = value;
  }

  public registerOnChange(onChange: any) {
    this.onChange = onChange;
  }

  public registerOnTouched(onTouched: any) {
    this.onTouched = onTouched;
  }

  public setDisabledState(disabled: boolean) {
    this.disabled = disabled;
  }

  public setValue() {
    if (!this.disabled) {
      this.onChange(this.value);
    }
  }

  public setTouched() {
    this.blur.emit();
    if (!this.touched) {
      this.onTouched();
      this.touched = true;
    }
  }
}
