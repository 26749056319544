import { createAction, props } from '@ngrx/store';
import { TradingHistoryInterface } from '../../../../land-details/interfaces/trading-history.interface';

export enum ProcessingTransactionActionsTypes {
  LoadProcessingTransaction = '[ProcessingTransaction] Load',
  LoadProcessingTransactionSuccess = '[ProcessingTransaction] Load Success',
  LoadProcessingTransactionFailure = '[ProcessingTransaction] Load Failure',
  ResetNewProcessingTransaction = '[ProcessingTransaction] Reset new notifications counter',
}

export const LoadProcessingTransaction = createAction(
  ProcessingTransactionActionsTypes.LoadProcessingTransaction,
);

export const LoadProcessingTransactionSuccess = createAction(
  ProcessingTransactionActionsTypes.LoadProcessingTransactionSuccess,
  props<{ items: TradingHistoryInterface[], totalCount: number }>(),
);

export const LoadProcessingTransactionFailure = createAction(
  ProcessingTransactionActionsTypes.LoadProcessingTransactionFailure,
);

export const ResetNewProcessingTransaction = createAction(
  ProcessingTransactionActionsTypes.ResetNewProcessingTransaction,
);
