<div class="modal">
  <img class="modal__icon" src="assets/icons/error.svg" alt="error icon">

  <div class="modal__title">
    {{ 'common.notifications.error' | translate }}!
  </div>

  <div class="modal__subtitle">{{ 'pages.modals.failed_to_execute_operation' | translate }}</div>

  <div class="modal__buttons">
    <app-button (btnClick)="close()" primary class="modal__button">
      {{ 'pages.modals.ok' | translate }}
    </app-button>
  </div>
</div>
