import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { Observable, of } from 'rxjs';
import { Store } from '@ngrx/store';
import { switchMapTo, tap } from 'rxjs/operators';
import { AuthService } from '../../auth/services/auth.service';
import {
  selectIsLoggedInVerifierOrAdmin,
} from '../store/selectors/autth.selectors';

@Injectable({
  providedIn: 'root',
})
export class CheckRoleGuard implements CanActivate {
  constructor(
    private authService: AuthService,
    private store: Store,
  ) {}

  canActivate(): Observable<boolean> {
    return this.store.select(selectIsLoggedInVerifierOrAdmin)
      .pipe(
        tap((isLoggedInVerifierOrAdmin: boolean) => {
          if (isLoggedInVerifierOrAdmin) {
            this.authService.logOut();
          }
        }),
        switchMapTo(of(true)),
      );
  }
}
