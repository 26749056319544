import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../shared/shared.module';
import { WriteShardService } from './services/write-shard.service';
import { ReadShardService } from './services/read-shard.service';
import { NftToShardFacadeService } from './services/nft-to-shard.facade.service';
import { LandxNftModule } from '../landx-nft/landx-nft.module';
import { ShardToNftFacadeService } from './services/shard-to-nft-facade.service';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    SharedModule,
    CommonModule,
    LandxNftModule,
  ],
  providers: [
    WriteShardService,
    ReadShardService,
    NftToShardFacadeService,
    ShardToNftFacadeService,
  ],
})
export class LandxShardModule { }
