export const PERCENT_DIVIDER = 100;
export const STAKE_POOLS_ICON_COLORS = [
  '#AFAFAF',
  '#50AF95',
  '#3083FF',
  '#EAE3C9',
  '#CDE0FF',
  '#AB8E27',
  '#DFEEE7',
];
