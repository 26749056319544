import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable()
export class BuyPopupsStateService {
  private minSellAmount$ = new BehaviorSubject<number>(0);

  public setMinSellAmount(minSellAmount: number): void {
    this.minSellAmount$.next(minSellAmount);
  }

  public getMinSellAmount$(): Observable<number> {
    return this.minSellAmount$.asObservable();
  }
}
