import { Component } from '@angular/core';
import { ModalService } from '../../services/modal.service';
import { LandInterface } from '../../../lends/interfaces/land.interface';

@Component({
  selector: 'app-shard-to-nft-success-modal',
  templateUrl: './shard-to-nft-success-modal.component.html',
  styleUrls: ['../../../../../styles/blocks/modals.scss'],
})
export class ShardToNftSuccessModalComponent {
  static readonly modalIdentifier = 'nftToShardSuccessModal';

  public land: LandInterface;

  constructor(
    private modalService: ModalService,
  ) {
    this.land = this.modalService.getModalData(ShardToNftSuccessModalComponent.modalIdentifier).land;
  }

  public close() {
    this.modalService.close(ShardToNftSuccessModalComponent.modalIdentifier);
  }
}
