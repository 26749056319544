import {
  ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output,
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-filter-select',
  templateUrl: './filter-select.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FilterSelectComponent implements OnInit {
  @Input() form!: FormGroup;
  @Input() searchable = false;
  @Input() clearable = false;
  @Input() placeholder = '';
  @Input() multiple = false;
  @Input() items: any[] = [];
  @Input() bindValue: string = 'value';
  @Input() bindLabel: string = 'label';
  @Input() controlName!: string;
  @Input() title?: string;
  @Input() loading: boolean = false;
  @Input() typeahead?: Subject<any>;
  @Input() groupBy?: string;

  @Output() valueChanged = new EventEmitter();

  ngOnInit(): void {
    this.form.addControl(this.controlName, new FormControl(null));
  }
}
