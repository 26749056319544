import { Injectable } from '@angular/core';
import { map, switchMap, take } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import * as Personal from 'web3-eth-personal';
import {
  BehaviorSubject, combineLatest, Observable, of,
} from 'rxjs';
import { Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { MetamaskService } from './metamask.service';
import { AuthApiService } from './auth.api.service';
import {
  Login, LoginFailure, LoginSuccess, LogOut,
} from '../../common/store/actions/auth.actions';
import {
  selectAuthLoading,
  selectRoleIsVerifier, selectRoleName,
} from '../../common/store/selectors/autth.selectors';
import { CallerService } from '../../common/services/caller.service';
import { RolesNames } from '../../common/constants/roles-names';
import { MainRoutes } from '../../common/constants/main-routes';
import { ModalService } from '../../../shared/modules/modals/services/modal.service';
import { LocalStorageService } from '../../common/services/local-storage.service';
import { selectUser } from '../../common/store/selectors/user.selector';
import { UserInterface } from '../../user/interfaces/user.interface';

const helper = new JwtHelperService();

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private currentMetamaskAccount$ = new BehaviorSubject<string>('');
  public locked: boolean = true;

  constructor(
    private metamaskApiService: MetamaskService,
    private authApiService: AuthApiService,
    private callerService: CallerService,
    private store: Store,
    private router: Router,
    private modalService: ModalService,
    private localStorageService: LocalStorageService,
  ) {
    this.metamaskApiService.getAccounts().pipe(
      take(1),
    ).subscribe((accounts) => {
      this.currentMetamaskAccount$.next(accounts.length ? accounts[0].address : '');
    });

    this.metamaskApiService.getAccountChanged$().subscribe((res) => this.currentMetamaskAccount$.next(res![0]));
  }

  public signIn(request: { email: string, password: string }): void {
    this.store.dispatch(Login());
    this.callerService.call(
      this.authApiService.signIn(request),
    ).subscribe(
      ({ data }) => {
        const role = helper.decodeToken(data.token).role || null;
        this.locked = false;
        this.store.dispatch(LoginSuccess({ ...data, role }));
        this.navigateToStartPage(role);
      },
      () => this.store.dispatch(LoginFailure()),
    );
  }

  public signUp(request: { email: string, password: string, name: string }): void {
    this.callerService.call(
      this.authApiService.signUp(request),
    ).subscribe(() => {
      this.router.navigate([MainRoutes.SignIn]);
    });
  }

  public refresh(token: string): Observable<string> {
    return this.callerService.call(
      this.authApiService.refresh(token),
      null,
      null,
      false,
    ).pipe(
      map((response) => response.data.token),
    );
  }

  public logOut(): void {
    this.store.dispatch(LogOut());
  }

  public connectWallet(): any {
    this.router.navigate(['auth/sign-in']);

    // this.modalService.open(ConnectWalletPopupComponent.modalIdentifier, ConnectWalletPopupComponent, {
    //   reconnect,
    // });
  }

  public get loginLoading$(): Observable<boolean> {
    return this.store.select(selectAuthLoading);
  }

  public get loggedVerifier$() {
    return this.store.select(selectRoleIsVerifier);
  }

  public getAccountChanged() {
    this.store.select(selectRoleName)
      .pipe(
        switchMap((roleName) => {
          if (roleName === RolesNames.User) {
            return this.metamaskApiService.getAccountChanged$().pipe(
              take(1),
            );
          }
          return of(null);
        }),
      )
      .subscribe((res) => {
        if (res) {
          this.store.dispatch(LoginFailure());
          this.connectWallet();
        }
      });
  }

  public generateSignature(metamaskEthAddress: string, nonce: string): Promise<string> {
    // @ts-ignore
    const personal = new Personal(Personal.givenProvider);
    return personal!.sign(
      nonce,
      metamaskEthAddress,
      '', // will ignore the password argument here
    );
  }

  private navigateToStartPage(role: string) {
    switch (role) {
      case RolesNames.Admin:
        this.router.navigate([MainRoutes.StartAdmin]);
        break;

      case RolesNames.User:
        this.router.navigate([MainRoutes.StartUser]);
        break;

      case RolesNames.Verifier:
        this.router.navigate([MainRoutes.StartVerifier]);
        break;

      default:
        this.router.navigate([MainRoutes.Home]);
    }
  }

  public isLocked() {
    return this.locked;
  }

  public unLock() {
    this.locked = false;
  }

  public unLockAndSave() {
    this.locked = false;
    const locker = String(Date.now());
    this.localStorageService.setItem('locker', locker);
    this.router.navigate(['marketplace']);
  }

  public lock() {
    this.locked = true;
    this.localStorageService.removeItem('locker');
    this.router.navigate(['auth/sign-in']);
  }

  public walletAddressWrong$(): Observable<boolean> {
    return combineLatest([this.currentMetamaskAccount$, this.store.select(selectUser)]).pipe(
      map((result) => {
        const currentAddress = result[0] as string;
        const user = result[1] as UserInterface;

        return currentAddress !== user?.metamaskEthAddress;
      }),
    );
  }
}
