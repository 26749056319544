import {
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';

@Component({
  selector: 'app-success-modal-wrapper',
  templateUrl: './success-modal-wrapper.component.html',
  styleUrls: ['../../../../../styles/blocks/modals.scss'],
})
export class SuccessModalWrapperComponent {
  @Input() subTitle!: string;
  @Output() closeModal = new EventEmitter();
}
