import { Injectable } from '@angular/core';
import { Observable, Subscriber } from 'rxjs';
import { AbiItem } from 'ethereum-abi-types-generator';
import { switchMap } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { Web3Service } from '../../core/common/services/web3.service';
import { ContractService } from '../../core/common/services/contract.service';
import { PreviewInterface } from '../interfaces/preview.interface';
import { selectShardsAddressesWithCultureNames } from '../../core/common/store/selectors/network.selector';

@Injectable()
export class ReadShardService {
  constructor(
    private web3: Web3Service,
    private contractService: ContractService,
    private store: Store,
  ) {}

  public allowance(from: string): Observable<number> {
    return this.contractService.contracts().pipe(
      switchMap((contracts) => new Observable((subscriber: Subscriber<number>) => {
        (async () => {
          try {
            const abi: AbiItem[] = [
              {
                name: 'allowance',
                inputs: [
                  {
                    internalType: 'address',
                    name: 'owner',
                    type: 'address',
                  },
                  {
                    internalType: 'address',
                    name: 'spender',
                    type: 'address',
                  },
                ],
                outputs: [
                  {
                    internalType: 'uint64',
                    name: '',
                    type: 'uint64',
                  },
                ],
                stateMutability: 'payable',
                type: 'function',
              },
            ];
            const contract = new this.web3.eth.Contract(abi, contracts.ldxsShardManagerContractAddress);
            contract.methods.allowance(from,
              contracts.ldxsShardManagerContractAddress).call().then((allowance: any) => {
              subscriber.next(parseFloat(this.web3.utils.fromWei(allowance.toString())));
            }).catch((error: any) => {
              subscriber.error(error);
            });
          } catch (error) {
            subscriber.error(error);
          }
        })();
      })),
    );
  }

  public preview(id: number, shardType: string): Observable<PreviewInterface> {
    return this.store.select(selectShardsAddressesWithCultureNames).pipe(
      switchMap((contracts: { [key: string]: string }) => new Observable((subscriber: Subscriber<PreviewInterface>) => {
        (async () => {
          try {
            const abi: AbiItem[] = [
              {
                name: 'preview',
                inputs: [
                  {
                    internalType: 'uint256',
                    name: 'id',
                    type: 'uint256',
                  },
                ],
                outputs: [
                  {
                    internalType: 'uint256',
                    name: 'shards',
                    type: 'uint256',
                  },
                  {
                    internalType: 'uint256',
                    name: 'fee',
                    type: 'uint256',
                  },
                  {
                    internalType: 'uint256',
                    name: 'total',
                    type: 'uint256',
                  },
                ],
                stateMutability: 'view',
                type: 'function',
              },
            ];
            const contract = new this.web3.eth.Contract(abi, contracts[shardType]);
            contract.methods.preview(id).call().then((data: any) => {
              subscriber.next({
                shards: parseFloat(this.web3.utils.fromWei(data.shards)),
                fee: parseFloat(this.web3.utils.fromWei(data.fee)),
                total: parseFloat(this.web3.utils.fromWei(data.total)),
              });
            }).catch((error: any) => {
              subscriber.error(error);
            });
          } catch (error) {
            subscriber.error(error);
          }
        })();
      })),
    );
  }
}
