import { Injectable } from '@angular/core';
import { AbiItem } from 'ethereum-abi-types-generator';
import { Observable, Subscriber } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { Web3Service } from '../../core/common/services/web3.service';
import { ContractService } from '../../core/common/services/contract.service';

@Injectable()
export class WriteAuctionService {
  constructor(private web3: Web3Service, private contractService: ContractService) {}

  public bid(from: string, auctionId: number, bidAmount: number): Observable<string> {
    return this.contractService.contracts().pipe(
      switchMap((contracts) => new Observable((subscriber: Subscriber<string>) => {
        (async () => {
          try {
            const abi: AbiItem[] = [
              {
                name: 'bid',
                inputs: [
                  {
                    internalType: 'uint256',
                    name: 'auctionId',
                    type: 'uint256',
                  },
                  {
                    internalType: 'uint256',
                    name: 'bidAmount',
                    type: 'uint256',
                  },
                ],
                outputs: [],
                stateMutability: 'payable',
                type: 'function',
              },
            ];
            const contract = new this.web3.eth.Contract(abi, contracts.auctionContractAddress);
            contract.methods.bid(auctionId, this.web3.utils.toWei(bidAmount.toString())).send({
              from,
            }).then((data: { transactionHash: string }) => {
              subscriber.next(data.transactionHash);
            }).catch((error: any) => {
              subscriber.error(error);
            });
          } catch (error) {
            subscriber.error(error);
          }
        })();
      })),
    );
  }

  public buyItem(from: string, saleId: number): Observable<string> {
    return this.contractService.contracts().pipe(
      switchMap((contracts) => new Observable((subscriber: Subscriber<string>) => {
        (async () => {
          try {
            const abi: AbiItem[] = [
              {
                name: 'buyItem',
                inputs: [
                  {
                    internalType: 'uint256',
                    name: 'saleId',
                    type: 'uint256',
                  },
                ],
                outputs: [],
                stateMutability: 'payable',
                type: 'function',
              },
            ];
            const contract = new this.web3.eth.Contract(abi, contracts.auctionContractAddress);
            contract.methods.buyItem(saleId).send({
              from,
            }).then((data: { transactionHash: string }) => {
              subscriber.next(data.transactionHash);
            }).catch((error: any) => {
              subscriber.error(error);
            });
          } catch (error) {
            subscriber.error(error);
          }
        })();
      })),
    );
  }

  public makeOffer(
    from: string,
    nftId: number,
    price: number,
    currencyId: number,
    listingId: number,
    duration: number,
  ): Observable<string> {
    return this.contractService.contracts().pipe(
      switchMap((contracts) => new Observable((subscriber: Subscriber<string>) => {
        (async () => {
          try {
            const abi: AbiItem[] = [
              {
                name: 'makeOffer',
                inputs: [
                  {
                    internalType: 'uint256',
                    name: 'nftID',
                    type: 'uint256',
                  },
                  {
                    internalType: 'uint256',
                    name: 'price',
                    type: 'uint256',
                  },
                  {
                    internalType: 'uint256',
                    name: 'currency',
                    type: 'uint256',
                  },
                  {
                    internalType: 'uint256',
                    name: 'listingID',
                    type: 'uint256',
                  },
                  {
                    internalType: 'uint256',
                    name: 'duration',
                    type: 'uint256',
                  },
                ],
                outputs: [],
                stateMutability: 'payable',
                type: 'function',
              },
            ];
            const contract = new this.web3.eth.Contract(abi, contracts.auctionContractAddress);
            contract.methods.makeOffer(
              nftId,
              this.web3.utils.toWei(price.toString()),
              currencyId,
              listingId,
              duration,
            ).send({
              from,
            }).then((data: { transactionHash: string }) => {
              subscriber.next(data.transactionHash);
            }).catch((error: any) => {
              subscriber.error(error);
            });
          } catch (error) {
            subscriber.error(error);
          }
        })();
      })),
    );
  }

  public cancelOffer(from: string, offerId: number, contractAddress: string|null = null): Observable<string> {
    return this.contractService.contracts().pipe(
      switchMap((contracts) => new Observable((subscriber: Subscriber<string>) => {
        (async () => {
          try {
            const abi: AbiItem[] = [
              {
                name: 'cancelOffer',
                inputs: [
                  {
                    internalType: 'uint256',
                    name: 'offerID',
                    type: 'uint256',
                  },
                ],
                outputs: [],
                stateMutability: 'payable',
                type: 'function',
              },
            ];

            let { auctionContractAddress } = contracts;
            if (contractAddress !== null) {
              auctionContractAddress = contractAddress;
            }
            const contract = new this.web3.eth.Contract(abi, auctionContractAddress);
            contract.methods.cancelOffer(
              offerId,
            ).send({
              from,
            }).then((block: { blockHash: string }) => {
              subscriber.next(block.blockHash);
            }).catch((error: any) => {
              subscriber.error(error);
            });
          } catch (error) {
            subscriber.error(error);
          }
        })();
      })),
    );
  }

  public acceptOffer(from: string, offerId: number): Observable<string> {
    return this.contractService.contracts().pipe(
      switchMap((contracts) => new Observable((subscriber: Subscriber<string>) => {
        (async () => {
          try {
            const abi: AbiItem[] = [
              {
                name: 'acceptOffer',
                inputs: [
                  {
                    internalType: 'uint256',
                    name: 'offerID',
                    type: 'uint256',
                  },
                ],
                outputs: [],
                stateMutability: 'payable',
                type: 'function',
              },
            ];
            const contract = new this.web3.eth.Contract(abi, contracts.auctionContractAddress);
            contract.methods.acceptOffer(
              offerId,
            ).send({
              from,
            }).then((block: { blockHash: string }) => {
              subscriber.next(block.blockHash);
            }).catch((error: any) => {
              subscriber.error(error);
            });
          } catch (error) {
            subscriber.error(error);
          }
        })();
      })),
    );
  }

  public declineOffer(from: string, offerId: number): Observable<string> {
    return this.contractService.contracts().pipe(
      switchMap((contracts) => new Observable((subscriber: Subscriber<string>) => {
        (async () => {
          try {
            const abi: AbiItem[] = [
              {
                name: 'declineOffer',
                inputs: [
                  {
                    internalType: 'uint256',
                    name: 'offerID',
                    type: 'uint256',
                  },
                ],
                outputs: [],
                stateMutability: 'payable',
                type: 'function',
              },
            ];
            const contract = new this.web3.eth.Contract(abi, contracts.auctionContractAddress);
            contract.methods.declineOffer(
              offerId,
            ).send({
              from,
            }).then((block: { blockHash: string }) => {
              subscriber.next(block.blockHash);
            }).catch((error: any) => {
              subscriber.error(error);
            });
          } catch (error) {
            subscriber.error(error);
          }
        })();
      })),
    );
  }

  public cancelAuction(from: string, auctionId: number, contractAddress: string): Observable<string> {
    return new Observable((subscriber: Subscriber<string>) => {
      (async () => {
        try {
          const abi: AbiItem[] = [
            {
              name: 'cancelAuction',
              inputs: [
                {
                  internalType: 'uint256',
                  name: 'auctionId',
                  type: 'uint256',
                },
              ],
              outputs: [],
              stateMutability: 'payable',
              type: 'function',
            },
          ];
          const contract = new this.web3.eth.Contract(abi, contractAddress);
          contract.methods.cancelAuction(auctionId).send({
            from,
          }).then((block: { blockHash: string }) => {
            subscriber.next(block.blockHash);
          }).catch((error: any) => {
            subscriber.error(error);
          });
        } catch (error) {
          subscriber.error(error);
        }
      })();
    });
  }

  public changeMarketFeeUsdc(from: string, marketFee: number): Observable<string> {
    return this.contractService.contracts().pipe(
      switchMap((contracts) => new Observable((subscriber: Subscriber<string>) => {
        (async () => {
          try {
            const abi: AbiItem[] = [
              {
                name: 'changeMarketFeeUSDC',
                inputs: [
                  {
                    internalType: 'uint256',
                    name: '_marketFee',
                    type: 'uint256',
                  },
                ],
                outputs: [],
                stateMutability: 'payable',
                type: 'function',
              },
            ];
            const contract = new this.web3.eth.Contract(abi, contracts.auctionContractAddress);
            contract.methods.changeMarketFeeUSDC(marketFee).send({
              from,
            }).on('transactionHash', (hash: string) => {
              subscriber.next(hash);
            });
          } catch (error) {
            subscriber.error(error);
          }
        })();
      })),
    );
  }

  public changeMarketFeeWtc(from: string, marketFee: number): Observable<string> {
    return this.contractService.contracts().pipe(
      switchMap((contracts) => new Observable((subscriber: Subscriber<string>) => {
        (async () => {
          try {
            const abi: AbiItem[] = [
              {
                name: 'changeMarketFeeWTC',
                inputs: [
                  {
                    internalType: 'uint256',
                    name: '_marketFee',
                    type: 'uint256',
                  },
                ],
                outputs: [],
                stateMutability: 'payable',
                type: 'function',
              },
            ];
            const contract = new this.web3.eth.Contract(abi, contracts.auctionContractAddress);
            contract.methods.changeMarketFeeWTC(marketFee).send({
              from,
            }).on('transactionHash', (hash: string) => {
              subscriber.next(hash);
            });
          } catch (error) {
            subscriber.error(error);
          }
        })();
      })),
    );
  }

  public changeMinTime(from: string, time: number): Observable<string> {
    return this.contractService.contracts().pipe(
      switchMap((contracts) => new Observable((subscriber: Subscriber<string>) => {
        (async () => {
          try {
            const abi: AbiItem[] = [
              {
                name: 'changeMinTime',
                inputs: [
                  {
                    internalType: 'uint256',
                    name: '_newMinTime',
                    type: 'uint256',
                  },
                ],
                outputs: [],
                stateMutability: 'payable',
                type: 'function',
              },
            ];
            const contract = new this.web3.eth.Contract(abi, contracts.auctionContractAddress);
            contract.methods.changeMinTime(time).send({
              from,
            }).on('transactionHash', (hash: string) => {
              subscriber.next(hash);
            });
          } catch (error) {
            subscriber.error(error);
          }
        })();
      })),
    );
  }

  public claim(from: string, auctionId: number, contractAddress: string): Observable<string> {
    return new Observable((subscriber: Subscriber<string>) => {
      (async () => {
        try {
          const abi: AbiItem[] = [
            {
              name: 'claim',
              inputs: [
                {
                  internalType: 'uint256',
                  name: 'auctionId',
                  type: 'uint256',
                },
              ],
              outputs: [],
              stateMutability: 'payable',
              type: 'function',
            },
          ];
          const contract = new this.web3.eth.Contract(abi, contractAddress);
          contract.methods.claim(auctionId).send({
            from,
          }).then((block: { blockHash: string }) => {
            subscriber.next(block.blockHash);
          }).catch((error: Error) => {
            subscriber.error(error);
          });
        } catch (error) {
          subscriber.error(error);
        }
      })();
    });
  }

  public createAuction(
    from: string,
    nftId: number,
    startPrice: number,
    reservedPrice: number,
    auctionPeriod: number,
    currency: number = 0,
  ): Observable<number> {
    return this.contractService.contracts().pipe(
      switchMap((contracts) => new Observable((subscriber: Subscriber<number>) => {
        (async () => {
          try {
            const abi: AbiItem[] = [
              {
                name: 'createAuction',
                inputs: [
                  {
                    internalType: 'uint256',
                    name: 'nftID',
                    type: 'uint256',
                  },
                  {
                    internalType: 'uint256',
                    name: 'startPrice',
                    type: 'uint256',
                  },
                  {
                    internalType: 'uint256',
                    name: 'reservedPrice',
                    type: 'uint256',
                  },
                  {
                    internalType: 'uint256',
                    name: 'currency',
                    type: 'uint256',
                  },
                  {
                    internalType: 'uint256',
                    name: 'auctionPeriod',
                    type: 'uint256',
                  },
                ],
                outputs: [],
                stateMutability: 'payable',
                type: 'function',
              },
            ];
            const contract = new this.web3.eth.Contract(abi, contracts.auctionContractAddress);
            contract.methods.createAuction(
              nftId,
              this.web3.utils.toWei(startPrice.toString()),
              this.web3.utils.toWei(reservedPrice.toString()),
              currency,
              auctionPeriod,
            ).send({
              from,
            }).then((data: { transactionIndex: number }) => {
              subscriber.next(data.transactionIndex);
            }).catch((error: any) => {
              subscriber.error(error);
            });
          } catch (error) {
            subscriber.error(error);
          }
        })();
      })),
    );
  }

  public putForSale(
    from: string,
    nftId: number,
    price: number,
    startTime: number = 0,
    privacy: string = '0x0000000000000000000000000000000000000000',
    currency: number = 0,
  ): Observable<number> {
    return this.contractService.contracts().pipe(
      switchMap((contracts) => new Observable((subscriber: Subscriber<number>) => {
        (async () => {
          try {
            const abi: AbiItem[] = [
              {
                name: 'putForSale',
                inputs: [
                  {
                    internalType: 'uint256',
                    name: 'currency',
                    type: 'uint256',
                  },
                  {
                    internalType: 'uint256',
                    name: 'ndfID',
                    type: 'uint256',
                  },
                  {
                    internalType: 'uint256',
                    name: 'price',
                    type: 'uint256',
                  },
                  {
                    internalType: 'uint256',
                    name: 'startTime',
                    type: 'uint256',
                  },
                  {
                    internalType: 'address',
                    name: 'privacy',
                    type: 'address',
                  },
                ],
                outputs: [],
                stateMutability: 'payable',
                type: 'function',
              },
            ];
            const contract = new this.web3.eth.Contract(abi, contracts.auctionContractAddress);
            contract.methods.putForSale(
              currency,
              nftId,
              this.web3.utils.toWei(price.toString()),
              startTime,
              privacy,
            ).send({
              from,
            }).then((data: { transactionIndex: number }) => {
              subscriber.next(data.transactionIndex);
            }).catch((error: any) => {
              subscriber.error(error);
            });
          } catch (error) {
            subscriber.error(error);
          }
        })();
      })),
    );
  }

  public reclaimErc20(
    from: string,
    contractAddress: string,
  ): Observable<string> {
    return this.contractService.contracts().pipe(
      switchMap((contracts) => new Observable((subscriber: Subscriber<string>) => {
        (async () => {
          try {
            const abi: AbiItem[] = [
              {
                name: 'reclaimERC20',
                inputs: [
                  {
                    internalType: 'address',
                    name: '_tokenContract',
                    type: 'address',
                  },
                ],
                outputs: [],
                stateMutability: 'payable',
                type: 'function',
              },
            ];
            const contract = new this.web3.eth.Contract(abi, contracts.auctionContractAddress);
            contract.methods.reclaimERC20(contractAddress).send({
              from,
            }).on('transactionHash', (hash: string) => {
              subscriber.next(hash);
            });
          } catch (error) {
            subscriber.error(error);
          }
        })();
      })),
    );
  }

  public reclaimNft(
    from: string,
    nftId: number,
  ): Observable<string> {
    return this.contractService.contracts().pipe(
      switchMap((contracts) => new Observable((subscriber: Subscriber<string>) => {
        (async () => {
          try {
            const abi: AbiItem[] = [
              {
                name: 'reclaimNFT',
                inputs: [
                  {
                    internalType: 'uint256',
                    name: '_nftID',
                    type: 'uint256',
                  },
                ],
                outputs: [],
                stateMutability: 'payable',
                type: 'function',
              },
            ];
            const contract = new this.web3.eth.Contract(abi, contracts.auctionContractAddress);
            contract.methods.reclaimNFT(nftId).send({
              from,
            }).on('transactionHash', (hash: string) => {
              subscriber.next(hash);
            });
          } catch (error) {
            subscriber.error(error);
          }
        })();
      })),
    );
  }

  public removeFromSale(
    from: string,
    saleId: number,
    contractAddress: string,
  ): Observable<string> {
    return new Observable((subscriber: Subscriber<string>) => {
      (async () => {
        try {
          const abi: AbiItem[] = [
            {
              name: 'removeFromSale',
              inputs: [
                {
                  internalType: 'uint256',
                  name: 'saleID',
                  type: 'uint256',
                },
              ],
              outputs: [],
              stateMutability: 'payable',
              type: 'function',
            },
          ];
          const contract = new this.web3.eth.Contract(abi, contractAddress);
          contract.methods.removeFromSale(saleId).send({
            from,
          }).then((block: { blockHash: string }) => {
            subscriber.next(block.blockHash);
          }).catch((error: any) => {
            subscriber.error(error);
          });
        } catch (error) {
          subscriber.error(error);
        }
      })();
    });
  }

  public renounceOwnership(from: string): Observable<string> {
    return this.contractService.contracts().pipe(
      switchMap((contracts) => new Observable((subscriber: Subscriber<string>) => {
        (async () => {
          try {
            const abi: AbiItem[] = [
              {
                name: 'renounceOwnership',
                inputs: [],
                outputs: [],
                stateMutability: 'payable',
                type: 'function',
              },
            ];
            const contract = new this.web3.eth.Contract(abi, contracts.auctionContractAddress);
            contract.methods.renounceOwnership().send({
              from,
            }).on('transactionHash', (hash: string) => {
              subscriber.next(hash);
            });
          } catch (error) {
            subscriber.error(error);
          }
        })();
      })),
    );
  }

  public setAuctionPeriod(from: string, time: number): Observable<string> {
    return this.contractService.contracts().pipe(
      switchMap((contracts) => new Observable((subscriber: Subscriber<string>) => {
        (async () => {
          try {
            const abi: AbiItem[] = [
              {
                name: 'setAuctionPeriod',
                inputs: [
                  {
                    internalType: 'uint256',
                    name: '_newPeriod',
                    type: 'uint256',
                  },
                ],
                outputs: [],
                stateMutability: 'payable',
                type: 'function',
              },
            ];
            const contract = new this.web3.eth.Contract(abi, contracts.auctionContractAddress);
            contract.methods.setAuctionPeriod(time).send({
              from,
            }).on('transactionHash', (hash: string) => {
              subscriber.next(hash);
            });
          } catch (error) {
            subscriber.error(error);
          }
        })();
      })),
    );
  }

  public setBidTickUsd(from: string, tick: number): Observable<string> {
    return this.contractService.contracts().pipe(
      switchMap((contracts) => new Observable((subscriber: Subscriber<string>) => {
        (async () => {
          try {
            const abi: AbiItem[] = [
              {
                name: 'setBidTickUSD',
                inputs: [
                  {
                    internalType: 'uint256',
                    name: '_newTick',
                    type: 'uint256',
                  },
                ],
                outputs: [],
                stateMutability: 'payable',
                type: 'function',
              },
            ];
            const contract = new this.web3.eth.Contract(abi, contracts.auctionContractAddress);
            contract.methods.setBidTickUSD(tick).send({
              from,
            }).on('transactionHash', (hash: string) => {
              subscriber.next(hash);
            });
          } catch (error) {
            subscriber.error(error);
          }
        })();
      })),
    );
  }

  public setBidTickWtc(from: string, tick: number): Observable<string> {
    return this.contractService.contracts().pipe(
      switchMap((contracts) => new Observable((subscriber: Subscriber<string>) => {
        (async () => {
          try {
            const abi: AbiItem[] = [
              {
                name: 'setBidTickWTC',
                inputs: [
                  {
                    internalType: 'uint256',
                    name: '_newTick',
                    type: 'uint256',
                  },
                ],
                outputs: [],
                stateMutability: 'payable',
                type: 'function',
              },
            ];
            const contract = new this.web3.eth.Contract(abi, contracts.auctionContractAddress);
            contract.methods.setBidTickWTC(tick).send({
              from,
            }).on('transactionHash', (hash: string) => {
              subscriber.next(hash);
            });
          } catch (error) {
            subscriber.error(error);
          }
        })();
      })),
    );
  }

  public setPaused(from: string, paused: boolean): Observable<string> {
    return this.contractService.contracts().pipe(
      switchMap((contracts) => new Observable((subscriber: Subscriber<string>) => {
        (async () => {
          try {
            const abi: AbiItem[] = [
              {
                name: 'setPaused',
                inputs: [
                  {
                    internalType: 'bool',
                    name: '_setPaused',
                    type: 'bool',
                  },
                ],
                outputs: [],
                stateMutability: 'payable',
                type: 'function',
              },
            ];
            const contract = new this.web3.eth.Contract(abi, contracts.auctionContractAddress);
            contract.methods.setPaused(paused).send({
              from,
            }).on('transactionHash', (hash: string) => {
              subscriber.next(hash);
            });
          } catch (error) {
            subscriber.error(error);
          }
        })();
      })),
    );
  }

  public transferOwnership(from: string, newOwnerAddress: string): Observable<string> {
    return this.contractService.contracts().pipe(
      switchMap((contracts) => new Observable((subscriber: Subscriber<string>) => {
        (async () => {
          try {
            const abi: AbiItem[] = [
              {
                name: 'transferOwnership',
                inputs: [
                  {
                    internalType: 'address',
                    name: 'newOwner',
                    type: 'address',
                  },
                ],
                outputs: [],
                stateMutability: 'payable',
                type: 'function',
              },
            ];
            const contract = new this.web3.eth.Contract(abi, contracts.auctionContractAddress);
            contract.methods.transferOwnership(newOwnerAddress).send({
              from,
            }).on('transactionHash', (hash: string) => {
              subscriber.next(hash);
            });
          } catch (error) {
            subscriber.error(error);
          }
        })();
      })),
    );
  }

  public withdrawEth(from: string, amount: string): Observable<string> {
    return this.contractService.contracts().pipe(
      switchMap((contracts) => new Observable((subscriber: Subscriber<string>) => {
        (async () => {
          try {
            const abi: AbiItem[] = [
              {
                name: 'withdrawETH',
                inputs: [
                  {
                    internalType: 'uint256',
                    name: 'amount',
                    type: 'uint256',
                  },
                ],
                outputs: [],
                stateMutability: 'payable',
                type: 'function',
              },
            ];
            const contract = new this.web3.eth.Contract(abi, contracts.auctionContractAddress);
            contract.methods.withdrawETH(amount).send({
              from,
            }).on('transactionHash', (hash: string) => {
              subscriber.next(hash);
            });
          } catch (error) {
            subscriber.error(error);
          }
        })();
      })),
    );
  }

  public approve(from: string, tokenAddress: string, amount: number): Observable<string> {
    return this.contractService.contracts().pipe(
      switchMap((contracts) => new Observable((subscriber: Subscriber<string>) => {
        (async () => {
          try {
            const abi: AbiItem[] = [
              {
                name: 'approve',
                inputs: [
                  {
                    internalType: 'address',
                    name: '_spender',
                    type: 'address',
                  },
                  {
                    internalType: 'uint256',
                    name: '_value',
                    type: 'uint256',
                  },
                ],
                outputs: [],
                stateMutability: 'payable',
                type: 'function',
              },
              {
                name: 'allowance',
                inputs: [
                  {
                    name: '_owner',
                    type: 'address',
                  },
                  {
                    name: '_spender',
                    type: 'address',
                  },
                ],
                outputs: [
                  {
                    name: '',
                    type: 'uint256',
                  },
                ],
                stateMutability: 'view',
                type: 'function',
              },
            ];
            const amountWei = this.web3.utils.toWei(amount.toString());
            const contract = new this.web3.eth.Contract(abi, tokenAddress);
            contract.methods.approve(contracts.auctionContractAddress, amountWei).send({
              from,
            }).then((data: { blockHash: string }) => {
              subscriber.next(data.blockHash);
            }).catch((error: any) => {
              subscriber.error(error);
            });
          } catch (error) {
            subscriber.error(error);
          }
        })();
      })),
    );
  }
}
