import { Component } from '@angular/core';
import {
  combineLatest, Observable, of, Subject, throwError,
} from 'rxjs';
import { catchError, switchMap, takeUntil } from 'rxjs/operators';
import { ModalService } from '../../services/modal.service';
import {
  UnsubscribeDestroyHelperComponent,
} from '../../../../../core/common/helpers/unsubscribe-destroy-helper.component';
import { PendingModalComponent } from '../pending-modal/pending-modal.component';
import { CheckoutFailedModalComponent } from '../checkout-failed-modal/checkout-failed-modal.component';
import { OfferInterface } from '../../../../../land-details/interfaces/offer.interface';
import { AcceptOfferFacadeService } from '../../../../../auction/services/accept-offer.facade.service';
import { SuccessModalComponent } from '../success-modal/success-modal.component';
import { PRICE_FORMAT } from '../../../../../core/common/constants/common-settings-values';
import { AreaUnits } from '../../../../../core/settings/constants/units-names';
import { BuyNowFacadeService } from '../../../../../auction/services/buy-now.facade.service';

@Component({
  selector: 'app-cancel-offer',
  templateUrl: './accept-offer-modal.component.html',
  styleUrls: ['../../../../../styles/blocks/modals.scss'],
})
export class AcceptOfferModalComponent extends UnsubscribeDestroyHelperComponent {
  public static readonly modalIdentifier: string = 'removeFromSaleModalComponent';
  public offer$: Observable<OfferInterface | null>;
  public amountFormat = PRICE_FORMAT;
  public units$: Observable<string>;
  public areaUnits = AreaUnits;

  constructor(
    private modalService: ModalService,
    private acceptOfferFacadeService: AcceptOfferFacadeService,
    private buyNowFacadeService: BuyNowFacadeService,
  ) {
    super();
    this.offer$ = this.modalService.getModalData(AcceptOfferModalComponent.modalIdentifier).offer$;
    this.units$ = this.buyNowFacadeService.getUnits$;
  }

  public submit() {
    const unsubscribeSubject = new Subject();
    this.modalService.open(PendingModalComponent.modalIdentifier, PendingModalComponent, {
      unsubscribeSubject,
    });
    this.modalService.close(AcceptOfferModalComponent.modalIdentifier);
    this.offer$.pipe(
      takeUntil(unsubscribeSubject),
      switchMap((offer) => combineLatest(
        of(offer),
        this.acceptOfferFacadeService.account(),
      )),
      switchMap(([offer, ethAccount]) => this.acceptOfferFacadeService
        .acceptOffer(ethAccount!.address, offer!.blockchainOfferId)),
      catchError((error) => {
        this.modalService.close(PendingModalComponent.modalIdentifier);
        this.modalService.open(CheckoutFailedModalComponent.modalIdentifier, CheckoutFailedModalComponent, {});
        return throwError(error);
      }),
    ).subscribe(() => {
      this.modalService.close(PendingModalComponent.modalIdentifier);
      this.modalService.open(SuccessModalComponent.modalIdentifier, SuccessModalComponent, {});
    });
  }

  public close(): void {
    this.modalService.close(AcceptOfferModalComponent.modalIdentifier);
  }
}
