<div class="drag-and-drop">
  <ngx-dropzone
    #drop
    class="drag-zone"
    [class.uploaded]="uploaded"
    (change)="onSelect($event)"
    [multiple]="multiple"
    accept="{{extension}}"
    [disabled]="disabled"
    [disableClick]="files.length > 0"
  >
    <!--    used when no error   -->

    <ngx-dropzone-label class="drag-zone__label">
      <img class="drag-zone__label-icon" src="../../../../assets/icons/drag-and-drop.svg" alt="drag-zone-label">
      <span class="drag-zone__label-text" [innerHTML]="dragZoneLabel"></span>
    </ngx-dropzone-label>

    <!--    used when error   -->

    <div class="drag-zone__label drag-zone__label--custom" (click)="drop.showFileSelector()">
      <img class="drag-zone__label-icon" src="../../../../assets/icons/drag-and-drop-error.svg" alt="drag-zone-label">
      <span class="drag-zone__label-text" [innerHTML]="dragZoneLabel"></span>
    </div>

    <ngx-dropzone-image-preview
      ngProjectAs="ngx-dropzone-preview"
      [file]="f"
      *ngFor="let f of files"
      [removable]="false"
      class="drag-zone__preview"
    >

<!--To add label for uploaded file preview, uncomment this block-->
<!--      <ngx-dropzone-label-->
<!--        class="drag-zone__preview-label"-->
<!--      >-->
<!--        {{ f.name }} ({{ f.type }})-->
<!--      </ngx-dropzone-label>-->

    </ngx-dropzone-image-preview>

    <div class="drag-zone__custom-button" (click)="drop.showFileSelector()"></div>
  </ngx-dropzone>
</div>

<div class="drag-and-drop__notes">
  {{('common.drag_and_drop.recommended_size' | translate)}}
  <span *ngIf="resolution">{{ resolution }}. </span>
  <span *ngIf="extensionView">{{ extensionView }}. </span>
  <span *ngIf="maxFileSizeView">{{('common.drag_and_drop.max_size' | translate : { maxFileSizeView: maxFileSizeView })}}</span>
</div>
