import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReadRentFoundationService } from './services/read-rent-foundation.service';
import { RentFoundationRoutingModule } from './rent-foundation-routing.module';
import { WithdrawFacadeService } from './services/withdraw.facade.service';
import { WriteRentFoundationService } from './services/write-rent-foundation.service';
import { SharedModule } from '../shared/shared.module';
import { PayRentFacadeService } from './services/pay-rent.facade.service';
import { CropShareApiService } from '../user-profile/services/crop-share/crop-share.api.service';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    SharedModule,
    RentFoundationRoutingModule,
  ],
  providers: [
    ReadRentFoundationService,
    WriteRentFoundationService,
    WithdrawFacadeService,
    PayRentFacadeService,
    CropShareApiService,
  ],
})
export class RentFoundationModule { }
