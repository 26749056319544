import { Component } from '@angular/core';
import { ModalService } from '../../services/modal.service';

@Component({
  selector: 'app-success-modal',
  templateUrl: './share-link-to-social-networks.component.html',
  styleUrls: ['./share-link-to-social-networks.component.scss', '../../../../../styles/blocks/modals.scss'],
})
export class ShareLinkToSocialNetworksComponent {
  public static readonly modalIdentifier: string = 'shareLinkToSocialNetworks';
  public url: string;

  constructor(private modalService: ModalService) {
    this.url = this.modalService.getModalData(ShareLinkToSocialNetworksComponent.modalIdentifier).url;
  }

  public close(): void {
    this.modalService.close(ShareLinkToSocialNetworksComponent.modalIdentifier);
  }
}
