import {
  Component, EventEmitter, Input, Output,
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { TradeContext } from 'simple-uniswap-sdk';
import { SelectItemInterface } from '../../../core/common/intefaces/select-item.interface';
import { NoLiquidityTradeContext } from '../../models/no-liquidity-trade-context';

@Component({
  selector: 'app-exchange-form',
  templateUrl: './exchange-form.component.html',
  styleUrls: ['../../../styles/blocks/modals.scss', './exchange-form.component.scss'],
})
export class ExchangeFormComponent {
  @Input() form: FormGroup = new FormGroup({});
  @Input() tokens: SelectItemInterface[] = [];
  @Input() tradeContext?: TradeContext | NoLiquidityTradeContext;
  @Input() fromBalance?: string;
  @Input() toBalance?: string;
  @Input() fieldFromDisabled: boolean = false;
  @Input() fieldToDisabled: boolean = false;
  @Input() blockConfirmBnt: boolean = false;

  @Output() submitted: EventEmitter<TradeContext> = new EventEmitter<TradeContext>();
  @Output() canceled: EventEmitter<any> = new EventEmitter<any>();

  public get isNoLiquidityTrade(): boolean {
    return (this.tradeContext as NoLiquidityTradeContext)?.isNoLiquidityTrade;
  }

  public get currency() {
    const currentCurrency: string = this.form.get('from')?.value.currency;
    return this.tokens.find((token: SelectItemInterface) => token.value === currentCurrency)?.label || '';
  }

  public get routeTexts(): string[] {
    return this.tradeContext!.routeText.split(' > ');
  }

  public submit(): void {
    this.submitted.emit(this.tradeContext);
  }

  public cancel(): void {
    this.canceled.emit();
  }

  public fromInputChanges(): void {
    this.resetTo();
  }

  public fromSelectChanges(): void {
    this.resetFrom();
  }

  public toInputChanges(): void {
    this.resetFrom();
  }

  public toSelectChanges(): void {
    this.resetTo();
  }

  private resetTo(): void {
    const { to } = this.form.value;
    to.amount = undefined;
    this.form.patchValue({ to }, { emitEvent: false });
  }

  private resetFrom(): void {
    const { to } = this.form.value;
    to.amount = undefined;
    this.form.patchValue({ to }, { emitEvent: false });
  }
}
